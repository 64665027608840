import React from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/index';

function Ingredients(): JSX.Element {
  const ingredients = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);
  const ingredientsList =
    ingredients &&
    Object.keys(ingredients).map((key) => {
      return { id: key, name: ingredients[key].titleKor, type: ingredients[key].type, src: ingredients[key].image };
    });

  return (
    <ul css={ingredients__wrap}>
      {ingredientsList &&
        ingredientsList.map(({ id, name, type, src }) => {
          return (
            <li key={name} css={ingredient__item}>
              <div css={ingredient__item__left}>
                <img src={src} />
                <h1>{name}</h1>
                <h3>({type})</h3>
              </div>

              <div css={ingredient__item__right}>
                <button
                  onClick={() => {
                    const selBox = document.createElement('textarea');
                    selBox.style.position = 'fixed';
                    selBox.style.left = '0';
                    selBox.style.top = '0';
                    selBox.style.opacity = '0';
                    selBox.value = id;

                    document.body.appendChild(selBox);
                    selBox.focus();
                    selBox.select();

                    document.execCommand('copy');
                    document.body.removeChild(selBox);
                    window.alert('음료 재료 아이디가 복사되었습니다.');
                  }}
                >
                  복사
                </button>
              </div>
            </li>
          );
        })}
    </ul>
  );
}

const ingredients__wrap = css`
  width: 100vw;
  height: calc(100vh - 360px);
`;

const ingredient__item = css`
  width: 100vw;
  height: 240px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
  font-size: 36px;
  font-family: Spoqa Han Sans;
  box-sizing: border-box;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  background-color: #f8f7f5;
`;

const ingredient__item__left = css`
  flex: 2.5;
  display: flex;
  flex-direction: row;
  align-items: center;
  img {
    width: 160px;
    height: 200px;
    margin-right: 24px;
  }
  h1 {
    font-weight: bold;
    font-size: 48px;
    margin-right: 8px;
    letter-spacing: -0.3px;
  }
`;

const ingredient__item__right = css`
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  button {
    width: 128px;
    height: 84px;
    border: none;
    border-radius: 10px;
    background-color: #b67d56;
    color: white;
    font-size: 32px;
  }
`;
export default Ingredients;
