/* eslint-disable curly */
/* eslint-disable react/jsx-key */

// 외부모듈
import React, { useCallback } from 'react';
import {
  DataGrid,
  GridCellEditCommitParams,
  GridColumnMenuContainer,
  GridColumnMenuProps,
  GridFilterMenuItem,
  SortGridMenuItems,
  useGridSlotComponentProps,
} from '@material-ui/data-grid';
import { Pagination } from '@material-ui/lab';
import { useDropzone } from 'react-dropzone';
import { css } from '@emotion/core';

// 내부모듈
import CreateWallet from './CreateWallet';
import WalletToolbar from './WalletToolbar';
import NoRowsOverlay from './NoRowsOverlay';
import { firestore } from 'app/common/firebase';
import { CorpGiftWallet } from '@definitions/wallet';
import { createWalletData } from './utils/createWalletData';
import { createColumns, createRows } from './utils/createTableData';
import { Admin } from '@definitions/admins';

interface WalletTableProps {
  wallets: CorpGiftWallet[];
  setReload: () => void;
  isLoading: boolean;
  handleLoading: (bol: boolean) => void;
  handleWallets: (wallets: CorpGiftWallet[]) => void;
  auth: Admin;
  uid: string;
}

interface WalletTableFooterProps {
  setReload: () => void;
  handleLoading: (bol: boolean) => void;
  auth: Admin;
}

function WalletTableFooter({ handleLoading, setReload, auth }: WalletTableFooterProps) {
  const { state, apiRef } = useGridSlotComponentProps();
  // apiRef.current.
  return (
    <div css={footer__cotainer}>
      <Pagination
        style={{ border: 'none' }}
        color="primary"
        count={state.pagination.pageCount}
        page={state.pagination.page + 1}
        onChange={(_, value) => apiRef.current.setPage(value - 1)}
      />
      <CreateWallet handleLoading={handleLoading} setReload={setReload} auth={auth} />
    </div>
  );
}

function ColumnMenu(props: GridColumnMenuProps) {
  const { hideMenu, currentColumn } = props;
  return (
    <GridColumnMenuContainer open hideMenu={hideMenu} currentColumn={currentColumn}>
      <SortGridMenuItems onClick={hideMenu} column={currentColumn} />
      <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
    </GridColumnMenuContainer>
  );
}

function cleanKeyAndValue(obj: {}) {
  const cleanedObj = {};

  for (const [key, value] of Object.entries(obj)) {
    const cleanedKey = key.replace(/[\s"]+/g, ''); // 키에서 공백 및 따옴표 제거

    let cleanedValue = value;
    if (typeof value === 'string') {
      cleanedValue = value.replace(/[\s"\r\n]+/g, '').trim(); // 값에서 공백, 따옴표, \r, \n 제거
    }

    cleanedObj[cleanedKey] = cleanedValue;
  }

  return cleanedObj;
}

export default function WalletTable(props: WalletTableProps) {
  const { handleLoading, setReload, wallets, isLoading, auth, uid } = props;
  const csvFileToArray = (string: string) => {
    const batch = firestore.batch();
    const csvHeader = string.slice(0, string.indexOf('\n')).split(',');
    const csvRows = string
      .slice(string.indexOf('\n') + 1)
      .split('\n')
      .filter((row) => row !== '');

    const csvArray = csvRows.map((i) => {
      const values = i.split(',');
      const obj = csvHeader.reduce((object, header, index) => {
        if (header.trim() !== '' && header !== '\r') {
          object[header.replace(/\"/gi, '')] = values[index].replace(/\"/gi, '');
        }

        return object;
      }, {} as any);
      return obj;
    });

    handleLoading(true);
    for (let i = 0; i < csvArray.length; i++) {
      const item = cleanKeyAndValue(csvArray[i]);
      console.log('item!?!?', item);
      if (!item.id || !item.phoneNumber) {
        alert('csv 포맷이 올바르지 않습니다. 체크후 다시 업로드해주세요');
        location.reload();
        return;
      } else {
        const ref = firestore.collection('version/v3/wallets').doc();
        const walletData: CorpGiftWallet = createWalletData(item, auth, ref.id);
        batch.set(ref, walletData);
      }
    }
    batch.commit().then(() => setReload());
  };

  const onDrop = useCallback(([file]) => {
    // Do something with the files
    if (file && file.type === 'text/csv') {
      const reader = new FileReader();
      reader.onload = function (event) {
        if (event.target) {
          const text = event.target.result;
          if (typeof text === 'string') {
            csvFileToArray(text);
          } else {
            alert('등록에 실패했습니다. Planz로 문의 부탁드립니다.');
          }
        } else {
          alert('등록에 실패했습니다. Planz로 문의 부탁드립니다.');
        }
      };

      reader.readAsText(file);
    } else {
      alert('csv 파일만 등록이 가능합니다.');
    }
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const handleClickDropzone = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  // 현재는 cell edit 이 tag 만 있어서 핸들러를 바로 연결해주지만 추가로 edit 할수 있는 cell 이 생기면 분리
  const handleEditTag = (e: GridCellEditCommitParams) => {
    const id = e.id.toString();
    if (id && e.value) {
      const value = e.value.toString();
      if (value.split('&').length > 5) {
        alert('tag는 최대 5개 이상 등록할 수 없습니다.');
      } else {
        firestore
          .doc(`version/v3/wallets/${id}`)
          .update({ tags: value })
          .then(() => setReload());
      }
    }
  };

  // 강제로 재로딩
  const handleRemoveTage = (_: any, e: any) => {
    const { target } = e;
    if (target.classList.contains('tagRemoveBtn')) {
      setReload();
    }
  };

  return (
    <div css={container}>
      {wallets.length === 0 && (
        <div {...getRootProps({ onClick: handleClickDropzone })}>
          <input {...getInputProps({ accept: '.csv' })} />
          <div css={dropzone__style} />
        </div>
      )}
      <DataGrid
        loading={isLoading}
        rowsPerPageOptions={[5, 10, 20]}
        rows={createRows(wallets)}
        columns={createColumns()}
        hideFooterSelectedRowCount
        pageSize={10}
        checkboxSelection
        disableSelectionOnClick
        onCellClick={handleRemoveTage}
        onCellEditCommit={handleEditTag}
        components={{
          Toolbar: WalletToolbar,
          // Pagination: WalletTablePagination,
          Footer: WalletTableFooter,
          NoRowsOverlay,
          ColumnMenu,
        }}
        componentsProps={{
          toolbar: { wallets, setReload, handleLoading, auth, uid },
          footer: { handleLoading, setReload, auth, uid },
          noRowsOverlay: { isDragActive },
        }}
      />
    </div>
  );
}

const container = css`
  position: relative;
  height: 800px;
`;

const dropzone__style = css`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 9999;
`;

const footer__cotainer = css`
  display: flex;

  nav {
    height: auto;
  }
`;
