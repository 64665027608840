// 외부 모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { CombinationObjectType } from '@definitions/recipes';
import { useSelector } from 'react-redux';
import { Input, Dropdown, Button, Dialog, ShorthandValue, DropdownItemProps } from '@fluentui/react-northstar';
import { useFirestore } from 'react-redux-firebase';

// 내부 모듈
import {
  BeverageType,
  RecipeCategory,
  BeverageGroup,
  RoastingPoint,
  TagType,
  ConcnCharge,
  SizeCharge,
  OriginalRecipeType,
} from '@definitions/recipes';
import { RootStateType } from '@reducers';
import { omit } from '@utils/omitObject';

function GetAndUpdateRecipe(): JSX.Element {
  const firestore = useFirestore();
  const beverageTypeArray = [BeverageType.both, BeverageType.icedOnly, BeverageType.hotOnly];
  const categoryArray = [RecipeCategory.original];
  const syrupAdjustableArray = ['시럽추가가능', '시럽추가불가능'];
  const roastingPointArray = [
    RoastingPoint.light,
    RoastingPoint.medium,
    RoastingPoint.mediumDark,
    RoastingPoint.dark,
    RoastingPoint.veryDark,
  ];
  const tagTypeArray = [TagType.new, TagType.best, TagType.event, TagType.soldout];
  const [keyArray, setKeyArray] = useState<ShorthandValue<DropdownItemProps>[]>([]);
  const getA11ySelectionMessage = {
    onAdd: (item: ShorthandValue<DropdownItemProps>) => {
      setKeyArray([...keyArray, item]);
    },
    onRemove: (item: ShorthandValue<DropdownItemProps>) => {
      const removedArray = keyArray.filter((list) => list !== item);
      setKeyArray(removedArray);
    },
  };
  const recipes: OriginalRecipeType[] = useSelector(
    ({ firestore: { ordered } }: RootStateType) => ordered['version/v3/recipes'],
  );
  const ingredients = useSelector((state: RootStateType) => state.firestore.data['version/v3/ingredients']);
  const ingredientsArray = Object.keys(ingredients).concat(['ice', 'water', 'hotWater']);
  const [selectedRecipe, setSelectedRecipe] = useState<OriginalRecipeType[]>([
    {
      isSoldout: false,
      condition: {
        syrupAdjustable: true,
      },
      detail: {
        beverageGroup: BeverageGroup.coffee,
        idleImage: '',
        image: '',
        name: '',
        mainImage: '',
        simpleInfo: '',
        summary: '',
      },
      price: {
        basic: {},
        concnCharge: { type: '' },
        sizeCharge: { type: '' },
      },
      combination: {},
    },
  ]);
  const [changeRecipe, setChangeRecipe] = useState({
    isSoldout: false,
    beverageType: '',
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: '',
      name: '',
      idleImage: '',
      image: '',
      mainImage: '',
      simpleInfo: '',
      tagType: '',

      summary: '',
    },
    price: {
      basic: {},
      concnCharge: {},
      sizeCharge: {},
    },
    combination: {},
    category: '',
    recipeId: '',
    id: '',
  });
  const [iceCombinationInput, setIceCombinationInput] = useState<Array<{ [key: string]: number | string }>>([
    {
      metric: 1,
      startAt: 0,
    },
  ]);

  const [hotCombinationInput, setHotCombinationInput] = useState<Array<{ [key: string]: number | string }>>([
    { metric: 1, startAt: 0 },
  ]);
  const handleRecipeCombination = (
    event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: string,
  ) => {
    const { name, value } = event.target;
    if (type === 'iced') {
      const list = [...iceCombinationInput];
      list[index][name] = name === 'amount' ? parseFloat(value) : value;
      setIceCombinationInput(list);
      return;
    }
    const list = [...hotCombinationInput];
    list[index][name] = name === 'amount' ? parseFloat(value) : value;
    setHotCombinationInput(list);
  };

  const combinationRemoveHanlder = (index: number, type: string) => {
    if (type === 'iced') {
      const combinationList = [...iceCombinationInput];
      combinationList.splice(index, 1);
      setIceCombinationInput(combinationList);

      return;
    }
    const combinationList = [...hotCombinationInput];
    combinationList.splice(index, 1);
    setHotCombinationInput(combinationList);
  };

  const combinationAddHandler = (type: string) => {
    if (type === 'iced') {
      setIceCombinationInput([...iceCombinationInput, { amount: 0, ingredientId: '', metric: 1, startAt: 0 }]);
      return;
    }
    setHotCombinationInput([...hotCombinationInput, { amount: 0, ingredientId: '', metric: 1, startAt: 0 }]);
  };

  const setCombinationHandler = () => {
    if (iceCombinationInput.length > 1 && hotCombinationInput.length === 1) {
      setChangeRecipe({ ...changeRecipe, combination: { iced: iceCombinationInput } });
    } else if (iceCombinationInput.length === 1 && hotCombinationInput.length > 1) {
      setChangeRecipe({ ...changeRecipe, combination: { hot: hotCombinationInput } });
    } else {
      setChangeRecipe({ ...changeRecipe, combination: { hot: hotCombinationInput, iced: iceCombinationInput } });
    }
  };

  const priceHandler = (event: React.SyntheticEvent<HTMLElement, Event>, type: string): void => {
    const { name, value } = event.target as HTMLInputElement;

    if (type === 'basic') {
      setChangeRecipe({
        ...changeRecipe,
        price: {
          ...changeRecipe.price,
          basic: { ...changeRecipe.price.basic, [name]: Number(value) },
        },
      });
    }
    if (type === 'concnCharge') {
      if (Number(value) === 0) {
        let isHotDeleted = false;
        let isIcedDeleted = false;
        for (const prop in changeRecipe.price.concnCharge) {
          if (Number(changeRecipe.price.concnCharge[prop]) < 10) {
            prop === 'iced' ? (isIcedDeleted = true) : (isHotDeleted = true);
            const filteredObj = omit(changeRecipe.price.concnCharge, prop);
            if (Object.values(filteredObj).includes(ConcnCharge.both) && isHotDeleted) {
              setChangeRecipe({
                ...changeRecipe,
                price: {
                  ...changeRecipe.price,
                  concnCharge: {
                    ...filteredObj,
                    type: ConcnCharge.iced,
                  },
                },
              });
            } else if (Object.values(filteredObj).includes(ConcnCharge.both) && isIcedDeleted) {
              setChangeRecipe({
                ...changeRecipe,
                price: {
                  ...changeRecipe.price,
                  concnCharge: {
                    ...filteredObj,
                    type: ConcnCharge.hot,
                  },
                },
              });
            } else {
              setChangeRecipe({
                ...changeRecipe,
                price: {
                  ...changeRecipe.price,
                  concnCharge: {
                    type: ConcnCharge.none,
                  },
                },
              });
            }
          }
        }
      } else {
        if (name === 'hot') {
          if (
            changeRecipe.price.concnCharge.type === ConcnCharge.none ||
            changeRecipe.price.concnCharge.type === ConcnCharge.hot
          ) {
            setChangeRecipe({
              ...changeRecipe,
              price: {
                ...changeRecipe.price,
                concnCharge: {
                  ...changeRecipe.price.concnCharge,
                  [name]: Number(value),
                  type: ConcnCharge.hot,
                },
              },
            });
          } else {
            setChangeRecipe({
              ...changeRecipe,
              price: {
                ...changeRecipe.price,
                concnCharge: {
                  ...changeRecipe.price.concnCharge,
                  [name]: Number(value),
                  type: ConcnCharge.both,
                },
              },
            });
          }
        }
        if (name === 'iced') {
          if (
            changeRecipe.price.concnCharge.type === ConcnCharge.none ||
            changeRecipe.price.concnCharge.type === ConcnCharge.iced
          ) {
            setChangeRecipe({
              ...changeRecipe,
              price: {
                ...changeRecipe.price,
                concnCharge: {
                  ...changeRecipe.price.concnCharge,
                  [name]: Number(value),
                  type: ConcnCharge.iced,
                },
              },
            });
          } else {
            setChangeRecipe({
              ...changeRecipe,
              price: {
                ...changeRecipe.price,
                concnCharge: {
                  ...changeRecipe.price.concnCharge,
                  [name]: Number(value),
                  type: ConcnCharge.both,
                },
              },
            });
          }
        }
      }
    }

    if (type === 'sizeCharge') {
      if (Number(value) === 0) {
        setChangeRecipe({
          ...changeRecipe,
          price: {
            ...changeRecipe.price,
            sizeCharge: {
              type: SizeCharge.none,
            },
          },
        });
      } else {
        setChangeRecipe({
          ...changeRecipe,
          price: {
            ...changeRecipe.price,
            sizeCharge: {
              ...changeRecipe.price.sizeCharge,
              [name]: Number(value),
              type: SizeCharge.iced,
            },
          },
        });
      }
    }
  };

  const handleRecipeSearch = (event: React.SyntheticEvent): void => {
    const { name, value } = event.target as HTMLInputElement | HTMLSelectElement;
    if (name === 'recipeName') {
      const filteredRecipe = recipes.filter((item) => item.detail.name === value);
      filteredRecipe.length > 0 && setSelectedRecipe(filteredRecipe);
      filteredRecipe.length === 1 && setChangeRecipe(filteredRecipe[0]);
    } else if (name === 'ingredientId') {
      const ingreidentIncludedArray = recipes.filter((item) => JSON.stringify(item.combination).includes(value));
      setSelectedRecipe(ingreidentIncludedArray);
    } else if (name === 'recipe') {
      const finalRecipe = selectedRecipe[Number(value)];
      setSelectedRecipe([finalRecipe]);
      setChangeRecipe(finalRecipe);
    }
  };

  const handleChangeRecipe = (event: React.SyntheticEvent | React.ChangeEvent<HTMLSelectElement>): void => {
    const detailKeys = ['idleImage', 'image', 'mainImage', 'simpleInfo', 'summary', 'tagType', 'name', 'roastingPoint'];
    const { name, value } = event.target as HTMLInputElement | HTMLSelectElement;

    const syrupBooleanValue = {
      시럽추가가능: true,
      시럽추가불가능: false,
    };
    if (value === '시럽추가가능' || value === '시럽추가불가능') {
      setChangeRecipe({ ...changeRecipe, condition: { syrupAdjustable: syrupBooleanValue[value] } });
    } else if (detailKeys.includes(name)) {
      if (name === 'roastingPoint') {
        setChangeRecipe({
          ...changeRecipe,
          detail: { ...changeRecipe.detail, [name]: Number(value) },
        });
      }
      setChangeRecipe({ ...changeRecipe, detail: { ...changeRecipe.detail, [name]: value } });
    } else {
      setChangeRecipe({ ...changeRecipe, [name]: value });
    }
  };

  const dropDownKeyArray = Object.keys(selectedRecipe[0])
    .concat(Object.keys(selectedRecipe[0].detail))
    .filter((item) => item !== 'detail' && item !== 'beverageGroup' && item !== 'id');

  const confirmRecipe = async (id: string) => {
    await firestore.collection('/version/v3/recipes').doc(id).update(changeRecipe);
    await firestore
      .collection('/version/v3/recipes')
      .doc(id)
      .get()
      .then((doc) => {
        if (doc.exists) {
          window.location.reload(true);
        }
      });
  };

  return (
    <>
      <div css={recipe__header}>
        <h1>recipe 조회</h1>
        <h2>등록된 레시피를 메뉴명(한글)로 검색합니다</h2>
        <Input name="recipeName" type="text" placeholder="한글메뉴명" onChange={handleRecipeSearch} />

        <h2>등록된 레시피를 ingredient 로 검색합니다</h2>

        <select name="ingredientId" onChange={handleRecipeSearch}>
          {ingredientsArray.map((item, index) => (
            <option key={index} value={item}>
              {item}
            </option>
          ))}
        </select>
        <h1>recipe 결과</h1>
        {selectedRecipe.map((item, index) => (
          <>
            <div key={index} css={selected__recipe__wrap}>
              <h3>
                <span>
                  {index + 1}.{item.recipeId}
                </span>
              </h3>
              <img css={bev__img} src={item.detail.image} alt="beverage_img" />
              <img css={main__img} src={item.detail.mainImage} alt="mainImage" />
              <h3>
                <span>음료명</span> : {item.detail.name}
              </h3>
              <div>
                <span>음료 타입</span> : {item.beverageType}
              </div>
              <div>
                <span>음료 카테고리</span> : {item.category}
              </div>
              <div>
                <span>시럽 적용여부</span> : {item.condition.syrupAdjustable === true ? '가능' : '불가능'}
              </div>
              <div>
                <span>커피류/음료류</span> : {item.detail.beverageGroup}
              </div>
              <div>
                <span>idle이미지링크</span>: {item.detail.idleImage}
              </div>
              <div>
                <span>음료이미지링크</span> : {item.detail.image}
              </div>
              <div>
                <span>메인이미지링크</span> : {item.detail.mainImage}
              </div>
              <div>
                <span>짧은소개문구</span> : {item.detail.simpleInfo}
              </div>
              <div>
                <span>소개글</span> : {item.detail.summary}
              </div>
              <div>
                <span>태그명</span> : {item.detail.tagType !== undefined ? item.detail.tagType : ''}
              </div>
              <div>
                <span>음료가격</span> :{JSON.stringify(item.price.basic)}
              </div>
              <div>
                <span>진하기가격</span> :{JSON.stringify(item.price.concnCharge)}
              </div>
              <div>
                <span>사이즈업가격</span> :{JSON.stringify(item.price.sizeCharge)}
              </div>
              <div>
                <span>음료조합양 </span>
              </div>
              {Object.keys(item.combination).length === 1 && item.beverageType !== BeverageType.hotOnly && (
                <div>
                  <div>
                    <span>ICED</span>
                  </div>
                  {Object.entries(item.combination)[0][1].map((ingredientList: CombinationObjectType) => (
                    <>
                      <span>{ingredientList.ingredientId} </span>
                      <span>: {ingredientList.amount}, </span>
                    </>
                  ))}
                </div>
              )}
              {Object.keys(item.combination).length === 1 && item.beverageType !== BeverageType.icedOnly && (
                <div>
                  <div>
                    <span>HOT</span>
                  </div>
                  {Object.entries(item.combination)[0][1].map((ingredientList: CombinationObjectType) => (
                    <>
                      <span>{ingredientList.ingredientId} </span>
                      <span>: {ingredientList.amount}, </span>
                    </>
                  ))}
                </div>
              )}
              {Object.keys(item.combination).length === 2 && item.beverageType === BeverageType.both && (
                <>
                  <div>
                    <div>HOT</div>
                    {Object.entries(item.combination)
                      .sort((a, b) => {
                        if (a[0] < b[0]) {
                          return -1;
                        }
                        if (a[0] > b[0]) {
                          return 1;
                        }
                        return 0;
                      })[0][1]
                      .map((ingredientList: CombinationObjectType) => (
                        <>
                          <span>{ingredientList.ingredientId} </span>
                          <span>: {ingredientList.amount}, </span>
                        </>
                      ))}
                  </div>
                  <div>
                    <div>ICED</div>
                    {Object.entries(item.combination)
                      .sort((a, b) => {
                        if (a[0] < b[0]) {
                          return -1;
                        } else if (a[0] > b[0]) {
                          return 1;
                        }
                        return 0;
                      })[1][1]
                      .map((ingredientList: CombinationObjectType) => (
                        <>
                          <span>{ingredientList.ingredientId} </span>
                          <span>: {ingredientList.amount}, </span>
                        </>
                      ))}
                  </div>
                </>
              )}
            </div>
          </>
        ))}
        <h1>recipe 수정</h1>
        <h2>수정하고자 하는 레시피를 선택하세요</h2>
        <select name="recipe" onChange={handleRecipeSearch}>
          <option value="">아래 레시피 중 선택하세요</option>
          {selectedRecipe.map((item, index) => (
            <option key={index} value={index}>
              {index + 1}.{item.recipeId}
            </option>
          ))}
        </select>
        <h2>수정하고자 하는 요소를 선택하세요</h2>
        <Dropdown
          multiple
          items={dropDownKeyArray}
          placeholder="수정이 필요한 요소를 추가하세요"
          getA11ySelectionMessage={getA11ySelectionMessage}
          noResultsMessage="모든 가능한 요소를 다 클릭하였습니다"
        />

        {keyArray.includes('recipeId') && (
          <label>
            레시피id (메뉴영문명)
            <Input name="recipeId" placeholder="예시:brewedCoffee" onChange={handleChangeRecipe} />
          </label>
        )}

        {keyArray.includes('beverageType') && (
          <label>
            음료 타입 :
            <select name="beverageType" onChange={handleChangeRecipe}>
              {beverageTypeArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        )}

        {keyArray.includes('category') && (
          <label>
            음료 카테고리 :
            <select name="category" onChange={handleChangeRecipe}>
              {categoryArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        )}
        {keyArray.includes('condition') && (
          <label>
            시럽 적용여부 :
            <select name="syrupAdjustable" onChange={handleChangeRecipe}>
              {syrupAdjustableArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
        )}
        {keyArray.includes('idleImage') && (
          <label>
            음료 idle 이미지 링크 추가
            <Input name="idleImage" fluid placeholder="idle이미지 링크" onChange={handleChangeRecipe} />
          </label>
        )}
        {keyArray.includes('image') && (
          <label>
            음료 이미지 링크 추가
            <Input name="image" fluid placeholder="음료 이미지 링크" onChange={handleChangeRecipe} />
          </label>
        )}
        {keyArray.includes('mainImage') && (
          <label>
            음료 메인이미지 링크 추가
            <Input name="mainImage" fluid placeholder="메인 이미지 링크" onChange={handleChangeRecipe} />
          </label>
        )}
        {keyArray.includes('name') && (
          <label>
            음료명(한글)
            <Input name="name" placeholder="음료명(한글)" onChange={handleChangeRecipe} />
          </label>
        )}
        {keyArray.includes('roastingPoint') && (
          <label>
            로스팅포인트(0: light, 1 : medium, 2:mediumDark, 3:dark, 4: veryDark)
            <select name="roastingPoint" onChange={handleChangeRecipe}>
              {roastingPointArray.map((item, index) => (
                <option key={index} value={index}>
                  {index}
                </option>
              ))}
            </select>
          </label>
        )}

        {keyArray.includes('simpleInfo') && (
          <label>
            음료 짧은소개문구 :
            <Input fluid name="simpleInfo" placeholder="예시:바닐라향이 감미로운 라떼" onChange={handleChangeRecipe} />
          </label>
        )}
        {keyArray.includes('summary') && (
          <label>
            음료 소개글 :
            <Input
              name="summary"
              fluid
              placeholder="예시:고메 바닐라빈 시럽의 감미로운 향이 부드럽게 어우러진 바닐라 라떼 음료"
              onChange={handleChangeRecipe}
            />
          </label>
        )}

        {keyArray.includes('tagType') && (
          <label>
            tagType (옵션)
            <input list="tagTypes" name="tagType" onChange={handleChangeRecipe} />
            <datalist id="tagTypes">
              <option value="">아래 옵션 중 선택하세요</option>
              {tagTypeArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </datalist>
          </label>
        )}
        {keyArray.includes('price') && changeRecipe.beverageType !== BeverageType.hotOnly && (
          <>
            <label>
              iced 음료 가격
              <Input
                name="iced"
                type="number"
                value={changeRecipe.price.basic.iced}
                onChange={(event) => priceHandler(event, 'basic')}
              />
            </label>
            <label>
              iced 음료 진하기 가격(옵션)
              <Input
                name="iced"
                type="number"
                placeholder="iced  진하기 가격"
                value={changeRecipe.price.concnCharge.iced}
                onChange={(event) => priceHandler(event, 'concnCharge')}
              />
            </label>
            <label>
              iced 음료 xl사이즈 가격(옵션)
              <Input
                name="iced"
                type="number"
                value={changeRecipe.price.sizeCharge.iced}
                onChange={(event) => priceHandler(event, 'sizeCharge')}
              />
            </label>
          </>
        )}
        {keyArray.includes('price') && changeRecipe.beverageType !== BeverageType.icedOnly && (
          <>
            <label>
              hot음료 가격
              <Input
                name="hot"
                type="number"
                value={changeRecipe.price.basic.hot}
                onChange={(event) => priceHandler(event, 'basic')}
              />
            </label>
            <label>
              hot 음료 진하기 가격(옵션)
              <Input
                name="hot"
                type="number"
                value={changeRecipe.price.concnCharge.hot}
                onChange={(event) => priceHandler(event, 'concnCharge')}
              />
            </label>
          </>
        )}
        <div css={combination__wrap}>
          {keyArray.includes('combination') && changeRecipe.beverageType !== BeverageType.icedOnly && (
            <>
              <span>hot : </span>

              {hotCombinationInput.map((combination, idx) => (
                <>
                  <div key={idx}>
                    <select name="ingredientId" onChange={(e) => handleRecipeCombination(e, idx, 'hot')}>
                      {ingredientsArray.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    <input
                      name="amount"
                      type="number"
                      placeholder="amount"
                      onChange={(e) => handleRecipeCombination(e, idx, 'hot')}
                    />
                    <span>
                      {hotCombinationInput.length !== 1 && (
                        <Button content="Remove" size="small" onClick={() => combinationRemoveHanlder(idx, 'hot')} />
                      )}
                      {hotCombinationInput.length - 1 === idx && (
                        <Button content="add" size="small" onClick={() => combinationAddHandler('hot')} />
                      )}
                    </span>
                  </div>
                </>
              ))}
            </>
          )}
          {keyArray.includes('combination') && changeRecipe.beverageType !== BeverageType.hotOnly && (
            <div css={ice__combination__wrap}>
              <span>iced : </span>
              {iceCombinationInput.map((combination, idx) => (
                <>
                  <div key={idx}>
                    <select name="ingredientId" onChange={(e) => handleRecipeCombination(e, idx, 'iced')}>
                      {ingredientsArray.map((item, index) => (
                        <option key={index} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>

                    <input
                      name="amount"
                      type="text"
                      placeholder="amount"
                      onChange={(e) => handleRecipeCombination(e, idx, 'iced')}
                    />
                    <span>
                      {iceCombinationInput.length !== 1 && (
                        <Button content="Remove" size="small" onClick={() => combinationRemoveHanlder(idx, 'iced')} />
                      )}
                      {iceCombinationInput.length - 1 === idx && (
                        <Button content="Add" size="small" onClick={() => combinationAddHandler('iced')} />
                      )}
                    </span>
                  </div>
                </>
              ))}
            </div>
          )}
          {keyArray.includes('combination') && (
            <Button
              css={confirm__btn}
              content="combination 수정 확정"
              size="small"
              onClick={() => setCombinationHandler()}
            />
          )}
        </div>
        <Dialog
          backdrop={true}
          cancelButton="취소"
          confirmButton="확인"
          style={{ whiteSpace: 'pre-line', overflow: `scroll` }}
          onConfirm={() => confirmRecipe(changeRecipe.id)}
          content={`변경리스트 : ${keyArray.map((item) => `${item}`)}
            beverageType : ${changeRecipe.beverageType},
            category : ${changeRecipe.category},
            시럽적용여부 : ${changeRecipe.condition.syrupAdjustable},
            커피류/음료류:${changeRecipe.detail.beverageGroup}, 
            음료한글명 : ${changeRecipe.detail.name},
            idle이미지링크 : ${changeRecipe.detail.idleImage},
            음료이미지링크 : ${changeRecipe.detail.image},
            메인이미지링크 : ${changeRecipe.detail.mainImage},
            소개문구(simpleInfo) : ${changeRecipe.detail.simpleInfo},
            소개글(summary) : ${changeRecipe.detail.summary},
            태그명(tagType) : ${changeRecipe.detail.tagType},
            레시피 영문명(recipeId) : ${changeRecipe.recipeId},
            음료가격 : 
            ${JSON.stringify(changeRecipe.price.basic)},
            진하기가격 : 
            ${JSON.stringify(changeRecipe.price.concnCharge)},
            사이즈업가격 : 
            ${JSON.stringify(changeRecipe.price.sizeCharge)},
            HOT combination :
            ${
              changeRecipe.beverageType !== BeverageType.icedOnly &&
              JSON.stringify(changeRecipe.combination).split(']')[0]
            },    
            ICED combination :  
            ${
              changeRecipe.beverageType === BeverageType.both
                ? JSON.stringify(changeRecipe.combination).split(']')[1]
                : JSON.stringify(changeRecipe.combination).split(']')[0]
            }
            `}
          header="아래 recipe 변경 세부사항이 맞으면 확인을 눌러주세요 확인을 누르면 정상적으로 변경됩니다"
          trigger={<Button css={confirm__btn} content="레시피 수정 확정" />}
        />
      </div>
    </>
  );
}

const recipe__header = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 20px;
    color: grey;
    margin-bottom: 10px;
  }
  input,
  select {
    max-width: 500px;
    margin-bottom: 5px;
  }
`;

const selected__recipe__wrap = css`
  width: 100%;
  height: 100%;
  border-radius: 30px;
  background-color: #f3f2f1;
  font-family: Spoqa Han Sans;
  margin-bottom: 14px;
  text-align: left;
  h3 {
    color: #666666;
    font-size: 20px;
    font-weight: normal;
    span {
      font-weight: bold;
    }
  }
  div {
    color: #666666;
    font-size: 15px;

    span {
      font-weight: bold;
      font-size: 17px;
    }
  }
`;

const bev__img = css`
  width: 200px;
  display: inline-block;
`;

const main__img = css`
  width: 400px;
`;
const combination__wrap = css`
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
  }
`;

const confirm__btn = css`
  margin-top: 10px;
  margin-bottom: 10px;
`;

const ice__combination__wrap = css`
  margin-bottom: 10px;
`;
export default GetAndUpdateRecipe;
