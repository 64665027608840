/* eslint-disable curly */

// 외부모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Button, Input } from '@material-ui/core';
import { useSelector } from 'react-redux';

// 내부모듈
import { RootStateType } from '@reducers/index';
import { firestore } from 'app/common/firebase';

function CreateVm() {
  const [vmId, setVmId] = useState('');
  const vms = useSelector((state: RootStateType) => state.firestore.data['version/v3/vms']);
  const vmList = Object.keys(vms);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (vmId.length !== 8) return alert('vmId는 8자리 입니다. ex) BETA0000');
    if (vmId.slice(0, 4) !== 'BETA') return alert('vmId의 형식은 BATA + 숫자4자리 입니다.');
    if (vmList.includes(vmId)) return alert('이미 존재하는 vmId입니다.');

    await firestore.doc(`version/v3/vms/${vmId}`).set({ ...vms.INIT, id: vmId });
    alert('생성이 완료되었습니다!');
    location.reload();
  };
  const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setVmId(e.target.value);
  };

  return (
    <form css={form} onSubmit={handleSubmit}>
      <Input css={input} placeholder="생성할 vmId를 입력해주세요." type="text" onChange={handleChange}></Input>
      <Button variant="contained" type="submit" style={{ marginLeft: '30px' }}>
        생성
      </Button>
    </form>
  );
}

const form = css`
  margin: 50px;
  display: flex;
`;
const input = css`
  width: 500px;
`;

export default CreateVm;
