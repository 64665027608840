import { MountPosition } from '@definitions/recipes';

export const getPosition = (mp: number, version: string): number => {
  if (version === '3.8') {
    if (MountPosition.keg0 <= mp && mp <= MountPosition.keg3) {
      return mp + 120;
    } else if (MountPosition.pump0 <= mp && mp <= MountPosition.pump8) {
      return mp + 60;
    } else if (MountPosition.milk0 <= mp && mp <= MountPosition.milk1) {
      return mp - 1860;
    } else if (mp === MountPosition.water) {
      return 0;
    } else if (mp === MountPosition.hotWater) {
      return 1;
    }
  } else if (version === '4.0') {
    if (mp === MountPosition.water) {
      return 0;
    } else if (mp === MountPosition.hotWater) {
      return 1;

      // 냉장(병합) 라인
    } else if (0 <= mp && mp <= 7) {
      return mp + 22;
      // 냉장(독립) 라인
    } else if (2000 <= mp && mp <= 2005) {
      return mp - 1984; // 16 ~ 21 (6개)
      // 상온 라인
    } else if (100 <= mp && mp <= 115) {
      return mp - 100;
    } // 상온 라인
  }

  throw new Error(`적절하지 않은 mountPosition이 들어왔습니다. mp:${mp}`);
};

export const getPositionName = (mp: number, version: string): string => {
  if (version === '3.8') {
    if (MountPosition.keg0 <= mp && mp <= MountPosition.keg3) {
      return `냉장(병합:커피) ${mp + 1}`;
    } else if (MountPosition.pump0 <= mp && mp <= MountPosition.pump8) {
      return `상온 ${mp - MountPosition.pump0 + 1}`;
    } else if (MountPosition.milk0 <= mp && mp <= MountPosition.milk1) {
      return `냉장(독립:우유) ${mp - MountPosition.milk0 + 1}`;
    } else if (mp === MountPosition.water) {
      return '정수';
    } else if (mp === MountPosition.hotWater) {
      return '온수';
    }
  } else if (version === '4.0') {
    if (mp === MountPosition.water) {
      return '정수';
    } else if (mp === MountPosition.hotWater) {
      return '온수';

      // 냉장(병합) 라인
    } else if (0 <= mp && mp <= 7) {
      return `냉장(병합:커피) ${mp + 1}`;
      // 냉장(독립) 라인
    } else if (2000 <= mp && mp <= 2005) {
      return `냉장(독립:우유) ${mp - MountPosition.milk0 + 1}`;
      // 상온 라인
    } else if (100 <= mp && mp <= 115) {
      return `상온 ${mp - MountPosition.pump0 + 1}`;
    } // 상온 라인
  }

  throw new Error(`적절하지 않은 mountPosition이 들어왔습니다. mp:${mp}`);
};
