// 타입
import { PaymentWay } from '@definitions/payment';
import { AUTH_ACTIONS, SessionHistory } from '@definitions/session';
import { orderObj } from 'app/utils/orderHistory/getOrderHistoryObj';
import { getRefundText } from './orderHistory/ver1.2.4Order';

export function format(date: Date): string {
  return `${date.getFullYear()}-${date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : date.getMonth() + 1}-${
    date.getDate() < 10 ? `0${date.getDate()}` : date.getDate()
  }`;
}

export function getYear(date: string): number {
  return Number(date.slice(0, 4));
}

export function getMonth(date: string): number {
  return Number(date.slice(5, 7));
}

export function getDate(date: string): number {
  return Number(date.slice(8, 10));
}

export function getSalesInfo(order, location, planzAdminNumArr, recipes) {
  const { session, type } = order;
  const curVersion: string = (session && session.version) || '0.0.0';
  const result = orderObj[curVersion](order);

  // 시간,장소, 승인방법, 승인번호,카드번호, 메뉴, 옵션,가격,총가격, 쿠폰사용여부,쿠폰사용갯수, admin번호주문
  const {
    itemRecipes,
    timeStamp,
    items,
    paymentWayUserId,
    isCouponUsed,
    phoneNum,
    infoForRefunds,
    paymentWay,
    totalPrice,
    usedCouponNum,
    companyName,
    couponPrice,
    membersId,
    email,
    adminName,
    balance,
  } = result;
  let isPlanzAdmin = 'X';
  let isAdminNonPaymentOrder = 'X';
  let totalCouponPrice = couponPrice === 'N/A' ? 0 : couponPrice * usedCouponNum;

  // // 플랜즈 어드민 번호로 members 가입하여 구매한 주문인지 확인
  isPlanzAdmin = planzAdminNumArr.includes(phoneNum) ? '관리자번호 로그인 구매' : 'X';

  // // 일반 관리자 기능으로 비결제모드로 구매한 주문인지 확인
  isAdminNonPaymentOrder =
    paymentWayUserId === 'N/A' && (phoneNum === '미가입주문' || phoneNum === '관리자주문')
      ? totalPrice == 0
        ? 'X'
        : '관리자주문'
      : type >= 90
      ? getRefundText(type)
      : 'X';

  let finalResult = '';
  const date = format(timeStamp.toDate());
  const pre = `${location},${getYear(date)},${getMonth(date)},${getDate(date)},${timeStamp
    .toDate()
    .toLocaleTimeString('ko-KR')},${companyName},${paymentWay},${infoForRefunds},${paymentWayUserId},${phoneNum}`;

  items.forEach((item) => {
    const {
      price: { basic, concnCharge, sizeCharge },
      recipeName,
      selectedOptions,
    } = item;

    // MDS테크의 경우 특별 처리 필요
    if (location == 'MDS테크') {
      const itemRecipe = itemRecipes.find((r) => r.recipeName === recipeName);
      // console.log(itemRecipe);
      if (itemRecipe) {
        const recipeId = itemRecipe.recipeId;

        const r_ = recipes.find(({ id }) => id === recipeId);

        const rn = r_ ? r_.detail.name : recipeName;
        finalResult += `${pre},${rn},${selectedOptions['H/I']},${selectedOptions['사이즈']},${
          selectedOptions['진하기']
        },${selectedOptions['설탕시럽']},${basic},${sizeCharge},${concnCharge},${basic + sizeCharge + concnCharge},${
          totalCouponPrice > 0 ? couponPrice : 0
        },${balance},${isPlanzAdmin},${isAdminNonPaymentOrder},${membersId || '멤버스 없음'}`;
      } else {
        finalResult += `${pre},${recipeName},${selectedOptions['H/I']},${selectedOptions['사이즈']},${
          selectedOptions['진하기']
        },${selectedOptions['설탕시럽']},${basic},${sizeCharge},${concnCharge},${basic + sizeCharge + concnCharge},${
          totalCouponPrice > 0 ? couponPrice : 0
        },${balance},${isPlanzAdmin},${isAdminNonPaymentOrder},${membersId || '멤버스 없음'}`;
      }
    } else {
      finalResult += `${pre},${recipeName},${selectedOptions['H/I']},${selectedOptions['사이즈']},${
        selectedOptions['진하기']
      },${selectedOptions['설탕시럽']},${basic},${sizeCharge},${concnCharge},${basic + sizeCharge + concnCharge},${
        totalCouponPrice > 0 ? couponPrice : 0
      },${balance},${isPlanzAdmin},${isAdminNonPaymentOrder},${membersId || '멤버스 없음'}`;
    }

    if (email) {
      finalResult += `,${email}`;
    } else {
      finalResult += ',';
    }
    finalResult += phoneNum === '관리자주문' ? `,${totalPrice}\r\n` : ',\r\n';

    totalCouponPrice -= couponPrice;
  });

  return finalResult;
}

// 혹시 나중에 이 폼을 사용할지 몰라 주석으로 처리해둡니다.
// let result = `${timeStamp.toDate()},${location},${id ? id : '아이디없음'},${
//   membership.type === MembershipType.general && membership.status === MembershipStatus.signedIn ? '회원' : '비회원'
// },${
//   membership.type === MembershipType.general && membership.status === MembershipStatus.signedIn
//     ? membership.phoneNum
//     : ''
// },${
//   membership.type === MembershipType.general &&
//   membership.status === MembershipStatus.signedIn &&
//   membership.selectedCouponIdList &&
//   membership.selectedCouponIdList.length !== 0
//     ? '쿠폰사용'
//     : '쿠폰미사용'
// },${payment.type === PaymentType.general ? '결제모드' : '비결제모드'},${
//   payment.type === PaymentType.general && payment.status.step === PaymentStep.success
//     ? payment.status.result.successMsg.cardNumber
//     : ''
// },${
//   payment.type === PaymentType.general && payment.status.step === PaymentStep.success
//     ? payment.status.result.successMsg.successNumber
//     : ''
// },${
//   payment.type === PaymentType.general && payment.status.step === PaymentStep.success
//     ? payment.status.result.successMsg.total
//     : ''
// },${
//   payment.type === PaymentType.general && payment.status.step === PaymentStep.success
//     ? payment.status.result.successMsg.tax
//     : ''
// },${items.length > 1 ? '여러잔주문' : '한잔주문'},`;

// items.forEach((item) => {
//   const {
//     price: { basic, concnCharge, sizeCharge },
//     recipeName,
//     selectedOptions,
//   } = item;

//   finalResult += `${recipeName},${selectedOptions['H/I']},${selectedOptions['사이즈']},${selectedOptions['진하기']},${selectedOptions['설탕시럽']},${basic},${concnCharge},${sizeCharge},`;
// });

// finalResult += '\r\n';
