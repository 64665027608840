import React from 'react';
import CollapsibleTable from './CollapsibleTable';
import { Monitoring } from '@definitions/monitoring';

interface MonitoringTableProps {
  logs: Monitoring[];
}

function MonitoringTable({ logs }: MonitoringTableProps): JSX.Element {
  return <CollapsibleTable rows={logs} />;
}

export default MonitoringTable;
