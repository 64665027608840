import React, { useEffect, useState } from 'react';
import MobileMainComponent from '@components/Main';
import DesktopMainComponent from '@components/MainDesktop';
import ProcessModal from '@components/modal/processModal';
import WalletControl from '@components/desktop/walletControl';

function Main(): JSX.Element {
  const [isDesktop, setIsDesktop] = useState<boolean>(false);
  useEffect(() => {
    if (screen.width > 768) {
      setIsDesktop(true);
    } else {
      setIsDesktop(false);
    }
    window.addEventListener('resize', () => {
      // clearTimeout(resizeId);
      // resizeId = setTimeout(() => console.log(window.innerWidth), 250);
      if (screen.width > 768) {
        setIsDesktop(true);
      } else {
        setIsDesktop(false);
      }
    });
  }, []);

  return (
    <div>
      <ProcessModal />
      {isDesktop ? <DesktopMainComponent /> : <MobileMainComponent />}
      {/* <WalletControl /> */}
    </div>
  );
}

export default Main;
