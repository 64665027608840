import { SH_TYPE } from '@definitions/session';
import { Wallet } from '@definitions/wallet';

export enum WALLET_ACTIONS {
  OWNER_GENERATE_SUCCESS = 'OWNER_GENERATE_SUCCESS',
  OWNER_GENERATE_FAILED = 'OWNER_GENERATE_FAILED',
  OWNER_PASSWORD_UPDATE_SUCCESS = 'OWNER_PASSWORD_UPDATE_SUCCESS',
  OWNER_PASSWORD_UPDATE_FAILED = 'OWNER_PASSWORD_UPDATE_FAILED',
  USER_SIGN_IN_REQUESTED = 'USER_SIGN_IN_REQUESTED',
  USER_SIGN_IN_SUCCESS = 'USER_SIGN_IN_SUCCESS',
  USER_SIGN_IN_PASSWORD_REQUESTED = 'USER_SIGN_IN_PASSWORD_REQUESTED',
  USER_SIGN_IN_FAILED = 'USER_SIGN_IN_FAILED',
  WALLET_SELECTED = 'WALLET_SELECTED',
}

export interface WalletGenerateSuccess {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.OWNER_GENERATE_SUCCESS;
  payload: {
    generatedWalletId: string;
    ownerPhoneNum: string;
  };
  timeStamp: Date;
}
export interface WalletGenerateFailed {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.OWNER_GENERATE_FAILED;
  payload: {
    ownerPhoneNum: string;
    ownerSignedUpMembers: boolean;
  };
  timeStamp: Date;
}
export interface WalletPasswordUpdateSuccess {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.OWNER_PASSWORD_UPDATE_SUCCESS;
  payload: {
    generatedWalletId: string;
  };
  timeStamp: Date;
}
export interface WalletUserSignInRequested {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.USER_SIGN_IN_REQUESTED;
  payload: {
    selectedWalletId: string;
  };
  timeStamp: Date;
}
export interface WalletUserSignInSuccess {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.USER_SIGN_IN_SUCCESS;
  payload: {
    selectedWallet: Wallet;
    phoneNum: string;
  };
  timeStamp: Date;
}
export interface WalletUserSignInPasswordRequested {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.USER_SIGN_IN_PASSWORD_REQUESTED;
  payload: {
    selectedWalletId: string;
    phoneNum: string;
  };
  timeStamp: Date;
}
export interface WalletUserSignInFailed {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.USER_SIGN_IN_FAILED;
  payload: {
    selectedWalletId: string;
    phoneNum: string;
  };
  timeStamp: Date;
}
export interface WalletSelected {
  type: SH_TYPE.WALLET_AUTH;
  action: WALLET_ACTIONS.WALLET_SELECTED;
  payload: Wallet;
  timeStamp: Date;
}
// export interface SelectedRouteSet {
//   type: SH_TYPE.SELECTED_ENTRANCES;
//   action: ROUTE_ACTIONS.SELECT_ROUTE;
//   payload: {
//     currentPage: OrderPageStep;
//     currentModal: OrderModalStep;
//     nextPage: OrderPageStep;
//     nextModal: OrderModalStep;
//     props: {
//       gateType: 'auth' | 'orderWay';
//       orderWays: string[];
//     };
//   };
//   timeStamp: Date;
// }

export type WalletAuth =
  | WalletGenerateSuccess
  | WalletGenerateFailed
  | WalletPasswordUpdateSuccess
  | WalletUserSignInRequested
  | WalletUserSignInSuccess
  | WalletUserSignInFailed
  | WalletUserSignInPasswordRequested
  | WalletSelected;
