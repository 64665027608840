/* eslint-disable curly */
// 외부모듈
import React, { useEffect, useState } from 'react';
import { firestore } from 'app/common/firebase';
import { useSelector } from 'react-redux';
import { css } from '@emotion/core';

// 내부모듈
import WalletTable from './WalletTable';
import { RootStateType } from '@reducers/index';
import { Admin } from '@definitions/admins';
import { CorpGiftWallet } from '@definitions/wallet';
import SampleFormat from './SampleFormat';

export const getCorpGiftWallets = (vms: string[] | undefined) => {
  if (vms === undefined || vms.length === 0) {
    return firestore.collection('version/v3/wallets').where('type', '==', 'corpGift').get();
  } else {
    return firestore
      .collection('version/v3/wallets')
      .where('targetLocations', 'array-contains-any', vms)
      .where('type', '==', 'corpGift')
      .get();
  }
};

function WalletControl() {
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  const auth: Admin = admins[uid];

  const [needReload, setNeedReload] = useState(false);
  const [wallets, setWallets] = useState<CorpGiftWallet[]>([]);
  const [isLoading, setLoading] = useState(true);

  const handleLoading = (bol: boolean) => setLoading(bol);
  const handleWallets = (newWallets: CorpGiftWallet[]) => setWallets(newWallets);
  const setReload = () => setNeedReload(true);

  useEffect(() => {
    setLoading(true);
    console.log('리로드!!!');
    getCorpGiftWallets(auth.vms).then((ref) => {
      if (ref.size === 0) {
        setLoading(false);
        setWallets([]);
        return;
      }
      const wallets: any = ref.docs.map((doc) => ({ ...doc.data(), walletId: doc.id }));
      setWallets(wallets);
      setLoading(false);
    });
    setNeedReload(false);
  }, [needReload]);

  return (
    <div css={container}>
      <div css={header}>
        <h1>GIFT WALLET 관리 페이지</h1>
        <SampleFormat />
      </div>
      <WalletTable
        wallets={wallets}
        setReload={setReload}
        isLoading={isLoading}
        handleLoading={handleLoading}
        handleWallets={handleWallets}
        auth={auth}
        uid={uid}
      />
    </div>
  );
}

export default WalletControl;

const container = css`
  padding: 10px 30px;
`;

const header = css`
  display: flex;
  align-items: center;
`;
