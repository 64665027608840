export const vmCsGroup = {
  wholeVmGroup: [
    'BETA0007',
    'BETA0008',
    'BETA0010',
    'BETA0012',
    'BETA0013',
    'BETA0014',
    'BETA0015',
    'BETA0016',
    'BETA0017',
    'BETA0018',
    'BETA0019',
    'BETA0020',
    'BETA0021',
    'BETA0022',
    'BETA0023',
    'BETA0024',
    'BETA0025',
    'BETA0026',
    'BETA0027',
    'BETA0028',
    'BETA0029',
  ],
  testGroup: ['TEST0000', 'BETA0001'],
  exceptGroup: ['BETA0001', 'BETA0002', 'BETA0008', 'BETA0009', 'BETA0010', 'BETA0014', 'BETA9999', 'INIT'],
};

export const vmCsGroupArr = ['wholeVmGroup', 'testGroup', 'exceptGroup'];
