// 내부모듈
import { Admin } from '@definitions/admins';
import { CorpGiftWallet } from '@definitions/wallet';

export const createWalletData = (
  item: { id: string; phoneNumber: string; tags?: string },
  auth: Admin,
  docId: string,
): CorpGiftWallet => ({
  type: 'corpGift',
  point: 0,
  id: item.id,
  walletId: docId,
  signedUpDate: new Date(),
  signedUpLocation: 'adminWeb',
  receiver: item.phoneNumber,
  sender: auth.name,
  ownerId: '',
  password: '',
  isLocked: false,
  css: [],
  ownermembers: [],
  targetLocations: auth.vms || [],
  initialedDate: null,
  tags: item.tags || '',
  name: '법인선물지갑',
});
