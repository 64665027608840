// 외부모듈
import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

// 내부모듈
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/core';
import { formatDateToKorean } from 'app/utils/format';
import { firestore } from 'app/common/firebase';
import { TimeStamp, VendingMachine } from '@definitions/vm';
import UserOrderRow from './UserOrderRow';

interface UserInfoProps {
  user: any;
  members: { value: { id: string; name: string } };
  vms: { value: VendingMachine };
  userRefreshHandler: () => void;
}

function UserInfo({ user, members, vms, userRefreshHandler }: UserInfoProps): JSX.Element {
  const {
    phoneNum,
    signUpDate,
    availableMembershipCouponNum,
    availableStickerNum,
    marketingAgreement,
    signedUpMembers,
  } = user;
  const [refundOpen, setRefundOpen] = useState(false);
  const [orders, setOrders] = useState<
    { order: {}; session: {}; sessionId: string; steps: string[]; timeStamp: TimeStamp; type: number; vmId: string }[]
  >([]);
  const [refunds, setRefunds] = useState([]);

  if (!phoneNum) {
    return (
      <div>
        <h1>해당 고객님 번호가 잘못되었습니다</h1>
      </div>
    );
  }
  useEffect(() => {
    const sevenDaysInMilliseconds = 14 * 24 * 60 * 60 * 1000;
    const now = new Date();
    const ref = firestore
      .collection(`version/v3/users/${phoneNum}/orders`)
      .where('timeStamp', '>=', new Date(now.getTime() - sevenDaysInMilliseconds));

    const ref2 = firestore
      .collection(`version/v3/users/${phoneNum}/refunds`)
      .where('timeStamp', '>=', new Date(now.getTime() - sevenDaysInMilliseconds));
    ref.get().then((result) => {
      const orders = result.docs.map((doc) => doc.data()) as {
        order: {};
        session: {};
        sessionId: string;
        steps: string[];
        timeStamp: TimeStamp;
        type: number;
        vmId: string;
      }[];
      setOrders(orders);
    });

    ref2.get().then((result) => {
      const refunds = result.docs.map((doc) => doc.data()) as [];
      setRefunds(refunds);
    });
  }, []);
  return (
    <>
      <Card style={{ width: '90%', height: '880px', borderRadius: '25px', position: 'relative', marginBottom: '20px' }}>
        <CardMedia
          image="https://react.semantic-ui.com/images/avatar/large/matthew.png"
          style={{ objectFit: 'cover', height: '50%' }}
        ></CardMedia>
        <CardContent></CardContent>
        <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
          <p>가입날짜 : {formatDateToKorean(signUpDate.toDate())}</p>
        </Typography>
        <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
          <p>
            가입 멤버스 :
            {(signedUpMembers &&
              signedUpMembers.reduce(
                (acc: string, cur: string, index: number) =>
                  acc +
                  Object.values(members).find((m) => m.id === cur)?.name +
                  (index < signedUpMembers.length - 1 ? ', ' : ']'),
                ' [',
              )) ||
              '오리지널'}
          </p>
        </Typography>
        <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
          <p>
            최근 사용 장소 :{' '}
            {orders
              .map(({ vmId }) => vmId)
              .map(
                (vmId) =>
                  (Object.values(vms).find((vm) => vm.id === vmId) || { location: { name: '미확인 장소' } }).location
                    .name,
              )
              .filter((item, index, arr) => arr.indexOf(item) === index)
              .reduce((acc, cur, index, arr) => acc + cur + (index < arr.length - 1 ? ', ' : ']'), '[') || 0}
          </p>
        </Typography>
        <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
          <p>마케팅 동의 : {marketingAgreement ? 'O' : 'X'}</p>
        </Typography>
        <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
          <p>사용 가능한 스티커 갯수 : {availableStickerNum || 0}</p>
        </Typography>
        <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
          <p>사용 가능한 쿠폰 갯수 : {availableMembershipCouponNum || 0}</p>
        </Typography>

        <div css={redo__icon} onClick={userRefreshHandler}>
          <FontAwesomeIcon size="2x" icon={faRedo} flip="horizontal" />
        </div>
      </Card>
      <div style={{ marginTop: '12px', marginBottom: '16px' }}>
        <h1>2주간 주문 내역</h1>
      </div>
      <TableContainer style={{ width: '90%', fontSize: '30px' }} component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>시간</TableCell>
              <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>장소</TableCell>
              <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>메뉴</TableCell>
              <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }} align="right">
                상태
              </TableCell>
              <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }} align="right">
                결제금액
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders
              .sort((a, b) => b.timeStamp.seconds - a.timeStamp.seconds)
              .map((row, idx) => (
                <UserOrderRow
                  key={idx}
                  row={row}
                  setRefundOpen={setRefundOpen}
                  refundOpen={refundOpen}
                  refundedOrders={refunds}
                  vms={Object.values(vms)}
                  // selectedVm={selectedVm}
                />
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

const redo__icon = css`
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #3f51b5;
`;

export default React.memo(UserInfo);
