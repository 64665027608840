// 외부모듈
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Box } from '@material-ui/core';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';

// 내부모듈
import { RootStateType } from '@reducers/index';
import { CouponPriceTarget, RecipeCondition } from '@definitions/benefits';
import { RecipeType } from '@definitions/recipes';
import { compareFunction, intersection, not, union } from '@utils/controlDataUtils';
import CustomList from './CustomList';

interface TargetTransferListProps {
  data: CouponPriceTarget;
  onChange: any;
}

/**
 * 현재는 conditions 에 recipe 를 그리고 있습니다.
 * 나중에 쿠폰의 종류가 늘어나면 targets -> conditions로  계층을 나눠야 합니다.
 * 또한 couponPrices가 모두 존재할거라는 가정하에 작동하는 코드입니다. -최다빈
 * */
function CouponPriceController({ onChange, data }: TargetTransferListProps) {
  const recipes: { [key: string]: RecipeType } =
    useSelector((state: RootStateType) => state.firestore.data['version/v3/recipes']) || {};
  const recipesList = Object.values(recipes);
  const { price, conditions, priority } = data as CouponPriceTarget;
  const recipeNameCondition = conditions.find((condition) => condition.type === 'recipe') as RecipeCondition;
  if (!recipeNameCondition) {
    return <div>condition이 잘못되었습니다. 개발팀에 문의해주세요.</div>;
  }

  const recipeNames =
    recipeNameCondition.recipeNames.length === 0
      ? recipesList.map((recipe) => recipe.recipeId)
      : recipeNameCondition.recipeNames;

  const unSelectedRecipes = recipesList
    .filter((recipe) => recipeNames.findIndex((name) => name === recipe.recipeId) === -1)
    .map((recipe) => recipe.recipeId);

  const [checked, setChecked] = useState<string[]>([]);
  const [left, setLeft] = useState<string[]>(unSelectedRecipes);
  const [right, setRight] = useState<string[]>(recipeNames);

  useEffect(() => {
    if (right.length === recipesList.length) {
      onChange(undefined, []);
    } else if (left.length === recipesList.length) {
      onChange(undefined, undefined);
    } else {
      onChange(undefined, right);
    }
  }, [right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const toggleHandler = (value: string) => () => {
    const currentIndex = checked.findIndex((check) => check === value);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const recipeIdOfChecked = (items: string[]) => intersection(checked, items).length;

  const toggleAllHandler = (items: string[]) => () => {
    if (recipeIdOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const checkedRightHnadler = () => {
    setRight(right.concat(leftChecked).sort(compareFunction));
    setLeft(not(left, leftChecked).sort(compareFunction));
    setChecked(not(checked, leftChecked));
  };

  const checkedLeftHandler = () => {
    setLeft(left.concat(rightChecked).sort(compareFunction));
    setRight(not(right, rightChecked).sort(compareFunction));
    setChecked(not(checked, rightChecked));
  };

  return (
    <section css={card__style}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CustomList
            title="미적용중인 Recipes"
            items={left}
            toggleAllHandler={toggleAllHandler}
            toggleHandler={toggleHandler}
            checked={checked}
            numberOfChecked={recipeIdOfChecked}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              onClick={checkedRightHnadler}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={checkedLeftHandler}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CustomList
            title="적용중인 Recipes"
            items={right}
            toggleAllHandler={toggleAllHandler}
            toggleHandler={toggleHandler}
            checked={checked}
            numberOfChecked={recipeIdOfChecked}
          />
        </Grid>
      </Grid>
      <Box css={selected__targets__box}>
        <TextField label="쿠폰가격" name="price" value={price} type="number" onChange={(e) => onChange(e)} />
        <TextField label="우선순위" name="priority" type="number" value={priority} onChange={(e) => onChange(e)} />
      </Box>
    </section>
  );
}

const card__style = css`
  input {
    width: 80px;
    margin: 5px;
    margin-bottom: 6px;
  }
  button {
    margin-bottom: 10px;
  }

  .MuiTextField-root {
    margin: 5px;
  }
`;

const selected__targets__box = css`
  margin-top: 15px;
`;

export default CouponPriceController;
