import { Benefit, BenefitType } from '@definitions/benefits';
import { ControlData, ControllerType, UserControlData, VmRecipesControlData } from '@definitions/mock';

/**
 *
 * @param controlData form에서 컨트롤할 data 입니다. controllerType 필수입니다.
 */
export const valiator = (controlData: ControlData | VmRecipesControlData) => {
  const { type } = controlData;
  switch (type) {
    case ControllerType.sticker:
    case ControllerType.coupon: {
      const { inputs } = controlData as UserControlData;
      return inputs.every((input) => input.value && typeof input.value === 'number' && input.value > 0);
    }
    case ControllerType.eventCoupon: {
      const { inputs } = controlData as UserControlData;
      return inputs.every((input) => {
        if (input.value && typeof input.value === 'number' && input.value > 0) {
          return true;
        } else if (input.value && typeof input.value === 'string') {
          return true;
        }
      });
    }
    case ControllerType.vmRecipes: {
      const { selectedBenefitType, vm, vmId } = controlData as VmRecipesControlData;
      const { vmRecipes, couponPrices } = vm;
      if (vmId === '') {
        return false;
      }

      switch (selectedBenefitType) {
        case BenefitType.price: {
          if (!vmRecipes) {
            return false;
          }
          return vmRecipes.benefits.findIndex((benefit: Benefit) => benefit.benefitType === BenefitType.price) !== -1;
        }
        case BenefitType.combination: {
          if (!vmRecipes) {
            return false;
          }
          return (
            vmRecipes.benefits.findIndex((benefit: Benefit) => benefit.benefitType === BenefitType.combination) !== -1
          );
        }
        case BenefitType.vmCouponPirce: {
          if (!couponPrices || !Object(couponPrices).hasOwnProperty('benefits')) {
            return false;
          }
          return true;
        }
        default: {
          return false;
        }
      }
    }
    default: {
      return false;
    }
  }
};

export const not = (a: any[], b: any[]) => {
  return a.filter((value) => {
    if (typeof value === 'string') {
      return b.findIndex((bItem) => value === bItem) === -1;
    } else {
      return b.findIndex((bItem) => value.id === bItem.id) === -1;
    }
  });
};

export const intersection = (a: any[], b: any[]) => {
  return a.filter((value) => {
    if (typeof value === 'string') {
      return b.findIndex((bItem) => value === bItem) !== -1;
    } else {
      return b.findIndex((bItem) => value.id === bItem.id) !== -1;
    }
  });
};

export const union = (a: any[], b: any[]) => {
  return [...a, ...not(b, a)];
};

export const compareFunction = (a: any, b: any) => {
  if (a.recipeId < b.recipeId) {
    return -1;
  } else if (a.recipeId > b.recipeId) {
    return 1;
  } else {
    return 0;
  }
};
