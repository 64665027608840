import hmacSHA256 from 'crypto-js/hmac-sha256';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
/**
 *
 * @param apiKey POS 등록을 통해 받은 고유한 접근 권한 값입니다.
 * @param posTid POS 터미널 번호
 * @param pinCode PIN 코드 (QR 인증 완료시 발급됨)
 *
 */

export const getSignature = (apiKey: string, posTid: string, pinCode: string): string => {
  const stringData = pinCode + posTid;
  const encoded = Utf8.parse(stringData);
  const hashed = hmacSHA256(encoded, apiKey);
  const signature = Base64.stringify(hashed);

  return signature;
};
