export enum MountPosition {
  keg0 = 0,
  keg1 = 1,
  keg2 = 2,
  keg3 = 3,
  keg4 = 4,
  keg5 = 5,
  keg6 = 6,
  keg7 = 7,
  keg8 = 8,
  keg9 = 9,
  pump0 = 100,
  pump1 = 101,
  pump2 = 102,
  pump3 = 103,
  pump4 = 104,
  pump5 = 105,
  pump6 = 106,
  pump7 = 107,
  pump8 = 108,
  pump9 = 109,
  water = 1001,
  hotWater = 1002,
  milk0 = 2000,
  milk1 = 2001,
  ice = 1003,
}

export const getMountPosition = (mp: number, pumpOnly = true) => {
  if (MountPosition.keg0 <= mp && mp <= MountPosition.keg3) {
    return pumpOnly ? mp + 120 : mp + 20;
  } else if (MountPosition.pump0 <= mp && mp <= MountPosition.pump8) {
    return mp + 60;
  } else if (MountPosition.milk0 <= mp && mp <= MountPosition.milk1) {
    return mp - 1860;
  } else if (mp === MountPosition.water) {
    return 0;
  } else if (mp === MountPosition.hotWater) {
    return 1;
  }

  throw new Error(`적절하지 않은 mountPosition이 들어왔습니다. mp:${mp}`);
};

export type CleaningPipe = {
  pipeNum: number;
  cleanNum: number;
  flushAmount: number;
};

export const getCleaningPipeNum = (mountPosition: number, cleaningPipes: CleaningPipe[]): number => {
  const found = cleaningPipes.find(({ pipeNum }) => mountPosition === pipeNum);

  if (found) {
    return found.cleanNum;
  }

  throw Error(`매칭되는 세척 파이프가 없습니다. 배관번호:${mountPosition}`);
};

export type Dispense = {
  // 추출 위치
  mountPosition: number;

  // 세션 아이디
  sessionId: string;

  // itemIndex (여러잔일때 몇번째 음료였는지 체크하기 위해)
  itemIndex: number;

  // 레시피 이름
  recipeName: string;

  // 주요 추출 시간
  initialTime: string; // 추출 시작 시간
  startTime: string; // 원액이 컵에 담기기 시작한 시간
  safeTime: string; // 안정화 단계 돌입 시간
  tailTime: string; // 잔여 추출
  finishTime: string;

  // 주요 추출 기간
  initialDelay: number;
  safeDuration: number;
  tailDuration: number;
  totalDuration: number;

  // 주요 추출양
  tailAmount: number;
  totalAmount: number;

  // 추출 기록 (initialTime => finishTime)
  timelime: { t: string; w: number }[];
};
