// 외부 모듈
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Segment, Dropdown, Datepicker, Button, DatepickerCalendar } from '@fluentui/react-northstar';
import { TimeStamp, VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers/index';
import { useFirestore } from 'react-redux-firebase';
import { format, getYear, getMonth } from 'app/utils/sales';
import { Admin } from '@definitions/admins';

const corpWalletLocation = ['범진전자', '조흥'];

function CorpWalletChargeHistory(): JSX.Element {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const adminsArray = Object.values(admins) as Admin[];
  const planzAdminNumArr: string[] = adminsArray.reduce((acc: string[], cur: Admin): string[] => {
    if (cur.level < 2) {
      acc.push(cur.phoneNum);
    }
    return acc;
  }, []);
  const firestore = useFirestore();
  const [selectedVmLocation, setSelectedVmLocation] = useState<string>('');
  const today = new Date();
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>(format(today));
  let locations: { name: string; startDate: TimeStamp }[] = [];
  let isLoaded = false;
  if (vms !== undefined && vms !== null && vms.length !== 0) {
    locations = vms
      .filter(({ location }) => corpWalletLocation.includes(location.name))
      .map(({ id, location: { name, startDate } }) => ({
        id,
        name,
        startDate,
      }));
    console.log(format(today));
    isLoaded = true;
  }

  const getA11ySelectionMessage = {
    onAdd: (item) => {
      setSelectedVmLocation(item);
      setStart('');
    },
  };

  return (
    <Flex>
      <Segment style={{ marginTop: '18.76px', marginLeft: '16px' }}>
        <Flex column>
          VM_ID
          <Dropdown
            loading={!isLoaded}
            items={[...locations.map(({ name }) => name), '모든장소']}
            placeholder="기기장소"
            checkable
            getA11ySelectionMessage={getA11ySelectionMessage}
            style={{ marginTop: '4px', marginBottom: '12px' }}
          />
          시작일
          <input
            id="datepicker_start"
            style={{ marginTop: '4px', marginBottom: '12px' }}
            type="date"
            value={start}
            min={
              selectedVmLocation === ''
                ? undefined
                : selectedVmLocation === '모든장소'
                ? format(
                    locations.reduce((prev, { startDate }) => {
                      return startDate.toDate() > prev ? startDate.toDate() : prev;
                    }, new Date('2019-03-29')),
                  )
                : format(
                    locations
                      .filter(({ name }) => {
                        if (name === selectedVmLocation) {
                          return true;
                        }

                        return false;
                      })[0]
                      .startDate.toDate(),
                  )
            }
            max={format(today)}
            onChange={(event) => {
              // 시작일이 수정되면 end 도 수정
              setStart(event.target.value);
            }}
          />
          마지막일
          <input
            id="datepicker_end"
            style={{ marginTop: '4px', marginBottom: '18px' }}
            type="date"
            value={end}
            max={format(today)}
            onChange={(event) => {
              setEnd(event.target.value);
            }}
          />
          <Button
            fluid
            content="다운로드"
            onClick={async (event) => {
              if (start === '' || end === '') {
                window.alert('시작일과 마지막일을 설정해주세요(기기를 수정하면 시작일이 초기화 됩니다).');

                return;
              } else if (new Date(start) > new Date(end)) {
                window.alert('시작일이 마지막일보다 클 수 없습니다.');

                return;
              } else if (
                vms.find(({ location: { name } }) => name === selectedVmLocation) === undefined &&
                selectedVmLocation !== '모든장소'
              ) {
                window.alert('조회하고자 하는 기기를 선택해주세요.');

                return;
              }
              let csv = '';
              if (selectedVmLocation === '모든장소') {
                const vms_ = vms.filter(({ location }) => corpWalletLocation.includes(location.name));
                window.alert('모든 장소 조회는 아직 구현이 안되었습니다.');
              } else if (selectedVmLocation === '범진전자') {
                const snapshot = await firestore
                  .collectionGroup('charges')
                  .where('corpName', '==', 'bumjinAdmin')
                  .where('timestamp', '>=', new Date(`${start}T00:00:00`))
                  .where('timestamp', '<=', new Date(`${end}T24:00:00`))
                  .get();

                const docs = snapshot.docs;
                for (let z = 0; z < docs.length; z++) {
                  const row = docs[z];
                  const charge = row.data();
                  const walletRef = await row.ref.parent.parent.get();
                  const wallet = walletRef.data();
                  // console.log(wallet.data());
                  csv += `${wallet.receiver},${wallet.tags},${charge.point.total},${
                    wallet.point
                  },${charge.timestamp.toDate().toLocaleTimeString('ko-KR')}\r\n`;
                }
              } else if (selectedVmLocation === '조흥') {
                const snapshot = await firestore
                  .collectionGroup('charges')
                  .where('corpName', '==', 'choheungAdmin')
                  .where('timestamp', '>=', new Date(`${start}T00:00:00`))
                  .where('timestamp', '<=', new Date(`${end}T24:00:00`))
                  .get();

                const docs = snapshot.docs;
                for (let z = 0; z < docs.length; z++) {
                  const row = docs[z];
                  const charge = row.data();
                  const walletRef = await row.ref.parent.parent.get();
                  const wallet = walletRef.data();
                  // console.log(wallet.data());
                  csv += `${wallet.receiver},${wallet.tags},${charge.point.total},${
                    wallet.point
                  },${charge.timestamp.toDate().toLocaleTimeString('ko-KR')}\r\n`;
                }
              }

              const downloadLink = document.createElement('a');
              const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
              const url = URL.createObjectURL(blob);
              downloadLink.href = url;
              downloadLink.download = `${selectedVmLocation}매출_${new Date().toDateString()}.csv`;

              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}
          />
        </Flex>
      </Segment>
    </Flex>
  );
}

export default CorpWalletChargeHistory;
