// 외부모듈
import React from 'react';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';
import { storage } from 'app/common/firebase';

// 내부모듈
import { Module, Status, Pump, Keg } from '@definitions/vm';
import { RootStateType } from '@reducers/index';
import { useState } from 'react';
import { firestore } from 'app/common/firebase';
import {
  Grid,
  Select,
  Switch,
  TextField,
  Typography,
  withStyles,
  createTheme,
  ThemeProvider,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import axios from 'axios';
import CircleProgressBar from './CircleProgressBar';
import Instructions from './Instructions';
import Dispenser from './Instructions/Dispenser';
import { PhotoResult } from './Instructions/ResultPhoto';
import { formatDate } from 'app/utils/format';
import { waitForDone } from './desktop/taskManager/TaskForm';
import Cleaning from './Instructions/Cleaning';
import { Form, InputLabel, MenuItem } from '@fluentui/react-northstar';
import { Container, ContainerStatus } from '@definitions/container';
import { MountPosition } from '@definitions/recipes';
const PROXY_IP = '43.200.132.213';
const PROXY_PORT = '3000';

type StatusProps = {
  status: Status;
  clear: () => void;
  adminConnectHandler: (id: string, status: any) => void;
  id: string;
  modules: Module[];
  ip: string;
  connectionType: string;
  containers: (Keg | Pump)[];
  dispensers: { mountPosition: number }[];
};

const sendJob = async (
  setOpen: (toggle: boolean) => void,
  connectionType: string,
  id: string,
  ip: string,
  key: string,
  job: { id: string; data: {} },
) => {
  setOpen(true);
  if (connectionType === 'socket.io') {
    const addr = `http://planz-proxy.com:3000/sendJob`;
    const params = { id, connectionType };
    let res;
    if (job.id === 'touch') {
      const data = {
        type: 'TOUCH',
        version: '0.0.1',
        requiredModules: [],
        requiredIngredients: [],
        data: null,
      };
      res = await axios.post(addr, data, { params });
    } else if (job.id === 'reboot') {
      const data = {
        type: 'REBOOT',
        version: '0.0.1',
        requiredModules: [],
        requiredIngredients: [],
        data: null,
      };
      res = await axios.post(addr, data, { params });
    } else if (job.id === 'kiosk-shutdown') {
      const data = {
        type: 'KIOSK_SHUTDOWN',
        version: '0.0.1',
        requiredModules: [],
        requiredIngredients: [],
        data: null,
      };
      res = await axios.post(addr, data, { params });
    } else if (job.id === 'restart') {
      const data = {
        type: 'RESTART',
        version: '0.0.1',
        requiredModules: [],
        requiredIngredients: [],
        data: null,
      };
      res = await axios.post(addr, data, { params });

      //pcb-reset
    } else if (job.id === 'pcb-reset') {
      const data = {
        type: 'RESET',
        version: '0.0.1',
        requiredModules: [{ name: 'pcb' }],
        requiredIngredients: [],
        data: null,
      };
      res = await axios.post(addr, data, { params });

      //im-reset
    } else if (job.id === 'im-reset') {
      const data = {
        type: 'IM_RESET',
        version: '0.0.1',
        requiredModules: [{ name: 'iceMaker' }],
        requiredIngredients: [],
        data: null,
      };
      res = await axios.post(addr, data, { params });

      // boiler-auto
    } else if (job.id === 'boiler-auto') {
      const data = {
        type: 'BOILER_AUTO',
        version: '0.0.1',
        requiredModules: [{ name: 'pcb' }],
        requiredIngredients: [],
        data: job.data.toggle,
      };
      res = await axios.post(addr, data, { params });

      //im-reset
    } else if (job.id === 'boiler-fill') {
      const data = {
        type: 'BOILER_FILL',
        version: '0.0.1',
        requiredModules: [{ name: 'pcb' }],
        requiredIngredients: [],
        data: job.data.toggle,
      };
      res = await axios.post(addr, data, { params });

      //im-reset
    } else if (job.id === 'boiler-heat') {
      const data = {
        type: 'BOILER_HEAT',
        version: '0.0.1',
        requiredModules: [{ name: 'pcb' }],
        requiredIngredients: [],
        data: job.data.toggle,
      };
      res = await axios.post(addr, data, { params });

      //im-reset
    } else if (job.id === 'dispenseDuration') {
      const data = {
        type: 'DISPENSE_ING',
        version: '0.0.1',
        requiredModules: [{ name: 'pcb' }],
        requiredIngredients: [],
        data: {
          mountPosition: job.data.mountPosition,
          pumpSpeed: 1,
          duration: job.data.duration,
          amount: 10,
        },
      };
      res = await axios.post(addr, data, { params });
    } else if (job.id === 'cleanDuration') {
      const data = {
        type: 'CLEAN',
        version: '0.0.1',
        requiredModules: [{ name: 'pcb' }],
        requiredIngredients: [],
        data: {
          mountPosition: job.data.mountPosition,
          duration: job.data.duration,
        },
      };
      res = await axios.post(addr, data, { params });
    } else if (job.id === 'dispenseCup') {
      const data = {
        type: 'DISPENSE_CUP',
        version: '0.0.1',
        requiredModules: [{ name: 'pcb' }],
        requiredIngredients: [],
        data: {
          position: job.data.position,
          candidates: [],
        },
      };
      res = await axios.post(addr, data, { params });
    } else {
      const data = {
        type: 'TOUCH',
        version: '0.0',
        requiredModules: [],
        requiredIngredients: [],
        data: null,
      };
      res = await axios.post(addr, data, { params });
    }
    setOpen(false);
    if (res.data.status === 'SUCCESS') {
      window.alert('실행에 성공했습니다.');
    } else {
      throw Error('never');
    }
  } else {
    const addr = `http://planz-proxy.com:3000/${job.id}`;
    const params = { target: ip, key, ...job.data };

    const res = await axios.get(addr, { params });

    setOpen(false);
    switch (res.data) {
      case 'ERROR':
        window.alert('에러발생\n관리자에게 문의해주세요');
        break;
      case 'GRPC_ERROR':
        window.alert('PROXY-PII 네트워크 문제가있습니다.\n관리자에게 문의해주세요.');
        break;
      case 'FAILED':
        window.alert('시도했지만, 실패했습니다.\n조금 있다 다시 실행해주세요.');
        break;
      case 'TIMEOUT':
        window.alert('PROXY로부터 응답이 돌아오지 않았습니다.\n관리자에게 문의해주세요.');
        break;
      case 'SUCCESS':
        window.alert('실행에 성공했습니다.');
        break;
      default:
        throw Error('never');
    }
  }
};
export const AntSwitch = withStyles((theme) => ({
  root: {
    width: 112,
    height: 64,
    padding: 0,
    display: 'flex',
  },
  switchBase: {
    padding: 8,
    color: theme.palette.grey[500],
    '&$checked': {
      transform: 'translateX(48px)',
      color: theme.palette.common.white,
      '& + $track': {
        opacity: 1,
        backgroundColor: theme.palette.primary.main,
        borderColor: theme.palette.primary.main,
      },
    },
  },
  thumb: {
    width: 48,
    height: 48,
    boxShadow: 'none',
  },
  track: {
    border: `1px solid ${theme.palette.grey[500]}`,
    borderRadius: 64 / 2,
    opacity: 1,
    backgroundColor: theme.palette.common.white,
  },
  checked: {},
}))(Switch);

function VmStatus({
  status,
  clear,
  adminConnectHandler,
  id,
  modules,
  ip,
  connectionType,
  containers,
  dispensers,
}: StatusProps): JSX.Element {
  const { uid } = useSelector((state: RootStateType) => state.firebase.auth);
  const admins = useSelector((state: RootStateType) => state.firestore.data['version/v3/admins']);
  const admin = status.connect.admin;
  const [open, setOpen] = useState(false);
  const [dispenseDuration, setDispenseDuration] = useState({ mountPosition: 120, duration: 0, positionName: '커피1' });
  const [cleaning, setCleaning] = useState({ mountPosition: 60, duration: 0, positionName: '커피1' });
  const [dispenseCup, setDispenseCup] = useState({ position: 0, positionName: '디스펜서1' });
  const mps = [
    { mountPosition: MountPosition.water, name: '정수', value: 0, ingredientId: '' },
    { mountPosition: MountPosition.hotWater, name: '온수', value: 1, ingredientId: '' },
    { mountPosition: MountPosition.keg0, name: '커피1', value: 120, ingredientId: '' },
    { mountPosition: MountPosition.keg1, name: '커피2', value: 121, ingredientId: '' },
    { mountPosition: MountPosition.keg2, name: '커피3', value: 122, ingredientId: '' },
    { mountPosition: MountPosition.keg3, name: '커피4', value: 123, ingredientId: '' },
    { mountPosition: MountPosition.milk0, name: '우유1', value: 140, ingredientId: '' },
    { mountPosition: MountPosition.milk1, name: '우유2', value: 141, ingredientId: '' },
    { mountPosition: MountPosition.pump0, name: '시럽1', value: 160, ingredientId: '' },
    { mountPosition: MountPosition.pump1, name: '시럽2', value: 161, ingredientId: '' },
    { mountPosition: MountPosition.pump2, name: '시럽3', value: 162, ingredientId: '' },
    { mountPosition: MountPosition.pump3, name: '시럽4', value: 163, ingredientId: '' },
    { mountPosition: MountPosition.pump4, name: '시럽5', value: 164, ingredientId: '' },
    { mountPosition: MountPosition.pump5, name: '시럽6', value: 165, ingredientId: '' },
    { mountPosition: MountPosition.pump6, name: '시럽7', value: 166, ingredientId: '' },
    { mountPosition: MountPosition.pump7, name: '시럽8', value: 167, ingredientId: '' },
    { mountPosition: MountPosition.pump8, name: '시럽9', value: 168, ingredientId: '' },
  ].map((mp) => {
    const found = containers.find((container) => container.mountPosition === mp.mountPosition);
    if (found) {
      return { ...mp, ingredientId: found.ingredientId };
    }

    return mp;
  });
  const onclikHandler = () => {
    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
    const { phoneNum, name, key } = user;
    if (admin.status) {
      adminConnectHandler(id, {
        ...status,
        connect: { ...status.connect, admin: { status: false, phoneNum: '', name: '' } },
      });
    } else {
      adminConnectHandler(id, {
        ...status,
        connect: { ...status.connect, admin: { status: true, phoneNum, name } },
      });
    }
  };
  const [dispenserOptions, setDispenserOptions] = useState(
    (() => {
      const obj: { [key: string]: 'M' | 'XL' } = {};
      const dispenserModules = modules.filter((module) => module.type === 'dispenser');
      dispenserModules.forEach((module) => (obj[module.id] = module.targetCupSize));
      return obj;
    })(),
  );

  const dispenserOptionHandler = (e: any) => {
    const { name, value } = e.target;
    setDispenserOptions({ ...dispenserOptions, [name]: value });
  };

  const setActivationDispenserModule = (moduleId: string) => {
    const confirm = window.confirm('정말적용하시겠습니까?');
    if (confirm) {
      firestore.doc(`version/v3/vms/${id}`).update({
        modules: modules.map((module) => {
          if (module.id === moduleId) {
            return { ...module, disable: module.disable === undefined ? true : !module.disable };
          } else {
            return module;
          }
        }),
      });
    }
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <>
      {open && <CircleProgressBar />}
      <div css={status__wrap}>
        <h1>에러 상태</h1>
        <div css={status__connect}>
          <h2 style={{ color: status.connect.payment ? 'blue' : 'red' }}>
            결제기 연결 상태: {`${status.connect.payment ? '정상' : '에러'}`}
          </h2>
          <h2 style={{ color: status.connect.pcb ? 'blue' : 'red' }}>
            PCB 연결 상태: {`${status.connect.pcb ? '정상' : '에러'}`}
          </h2>
        </div>
        <div css={status__hw}>
          <h2 style={{ color: status.hw.boilerTempSensor ? 'blue' : 'red' }}>
            온수탱크 온도 센서 상태: {`${status.hw.boilerTempSensor ? '정상' : '에러'}`}
          </h2>
          <h2 style={{ color: status.hw.floatingSensor ? 'blue' : 'red' }}>
            플로팅 센서 상태: {`${status.hw.floatingSensor ? '정상' : '에러'}`}
          </h2>

          {status.hw.cupDispenser.map((dispenser, index) => {
            return (
              <div key={index}>
                <h2>{index + 1}번 디스펜서</h2>
                <h3 style={{ color: dispenser.plate ? 'blue' : 'red' }}>
                  플레이트: {`${dispenser.plate ? '정상' : '에러'}`}
                </h3>
                <h3 style={{ color: dispenser.splitter ? 'blue' : 'red' }}>
                  스플리터: {`${dispenser.splitter ? '정상' : '에러'}`}
                </h3>
              </div>
            );
          })}
          <h2 style={{ color: status.hw.ir ? 'blue' : 'red' }}>근접 센서 {`${status.hw.ir ? '정상' : '에러'}`}</h2>
          <h2 style={{ color: status.sw.saga ? 'blue' : 'red' }}>saga : {`${status.sw.saga ? '정상' : '에러'}`}</h2>
        </div>
        <Button variant="contained" onClick={clear} color="secondary" css={error__init__btn}>
          에러 초기화
        </Button>
        <h1>관리자 상태</h1>
        <div css={admin__status__wrap}>
          <h2>관리자 모드</h2>
          <Typography component="div" style={{ marginRight: '20px' }}>
            <Grid component="label" container alignItems="center" spacing={1}>
              <Grid item>Off</Grid>
              <Grid item>
                <AntSwitch checked={admin.status} onChange={onclikHandler} name="checkedC" />
              </Grid>
              <Grid item>On</Grid>
            </Grid>
          </Typography>
        </div>
        <h1>디스팬서(컵) 관리</h1>
        <Dispenser
          dispensers={modules
            .filter(({ mountPosition }) => 10000 <= mountPosition && mountPosition <= 10002)
            .sort((a, b) => a.mountPosition - b.mountPosition)}
          setActivationDispenserModule={setActivationDispenserModule}
          upload={(results: PhotoResult[]) => {
            const user = admins[uid] || { phoneNum: 'x', name: 'x' };
            const { phoneNum, name, key } = user;
            const storageRef = storage.ref();
            const histRef = storageRef.child(`taskHistory/${new Date().getFullYear()}/${new Date().getMonth()}`);
            const uploads: { filename: string; name: string; type: string; url: string }[] = [];

            results.forEach((r) => {
              const filename = `fillCup-${id}-${name}-${formatDate(new Date())}`;

              histRef
                .child(filename)
                .put(r.file)
                .then((snapshot) => {
                  storage
                    .ref()
                    .child(snapshot.metadata.fullPath)
                    .getDownloadURL()
                    .then((url) => {
                      uploads.push({
                        filename,
                        name,
                        type: r.file.type,
                        url,
                      });
                    });
                });
            });
            const thRef = firestore.collection('/taskHistory/');
            waitForDone(uploads, results.length).then(() => {
              console.log('업로드 완료');
              thRef.add({
                target: id,
                authId: uid,
                authName: name,
                authPhoneNum: phoneNum,
                timestamp: new Date(),
                taskId: 'fillCup',
                results: uploads,
              });
            });
          }}
          fillCups={async () => {
            const fsRef = firestore.collection('/version/v3/vms/').doc(id);
            fsRef.update({
              modules: modules.map((md) => {
                if (md.type === 'dispenser') {
                  return { ...md, currentCupCnt: md.maxCupCapacity };
                }
                return md;
              }),
            });
          }}
        />
        {/* {[...modules]
          .sort((a, b) => (a.mountPosition || 99999) - (b.mountPosition || 99999))
          .map((module) => (
            <div css={dispenser__wrap} key={module.id}>
              {printModules(module)}
            </div>
          ))} */}

        {ip !== 'localhost' && (
          <div css={admin__pii__wrap}>
            <h1>PII 기능</h1>
            <div css={admin__status__wrap}>
              <h2>터치 재보정</h2>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    let res;
                    const answer = window.confirm(`정말 실행하시겠습니까?`);
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    await sendJob(setOpen, connectionType, id, ip, key, { id: 'touch', data: {} });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>PC 재부팅</h2>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    const answer = window.confirm(`정말 실행하시겠습니까?`);
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    await sendJob(setOpen, connectionType, id, ip, key, { id: 'reboot', data: {} });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>키오스크, PII 재시작</h2>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    const answer = window.confirm(`정말 실행하시겠습니까?`);
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    status.sw.saga;
                    const { key } = user;
                    firestore.doc(`version/v3/vms/${id}`).update({
                      status: {
                        ...status,
                        sw: {
                          saga: true,
                        },
                      },
                    });
                    await sendJob(setOpen, connectionType, id, ip, key, { id: 'restart', data: {} });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>키오스크 종료</h2>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    const answer = window.confirm(`정말 실행하시겠습니까?`);
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    await sendJob(setOpen, connectionType, id, ip, key, { id: 'kiosk-shutdown', data: {} });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>pcb 리셋</h2>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    const answer = window.confirm(`정말 실행하시겠습니까?`);
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    await sendJob(setOpen, connectionType, id, ip, key, { id: 'pcb-reset', data: {} });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>제빙기 리셋</h2>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    const answer = window.confirm(`정말 실행하시겠습니까?`);
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    await sendJob(setOpen, connectionType, id, ip, key, { id: 'im-reset', data: {} });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>온수탱크 AUTO</h2>
              <div>
                <Button
                  variant="contained"
                  size="large"
                  color={'primary'}
                  css={admin__status__btn}
                  onClick={async () => {
                    try {
                      const answer = window.confirm(`정말 실행하시겠습니까?`);
                      if (!answer) {
                        return;
                      }
                      const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                      const { key } = user;
                      await sendJob(setOpen, connectionType, id, ip, key, {
                        id: 'boiler-auto',
                        data: { toggle: true },
                      });
                    } catch (e) {
                      window.alert(e);
                    }
                  }}
                >
                  ON
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color={'secondary'}
                  css={admin__status__btn}
                  onClick={async () => {
                    try {
                      const answer = window.confirm(`정말 실행하시겠습니까?`);
                      if (!answer) {
                        return;
                      }
                      const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                      const { key } = user;
                      await sendJob(setOpen, connectionType, id, ip, key, {
                        id: 'boiler-auto',
                        data: { toggle: false },
                      });
                    } catch (e) {
                      window.alert(e);
                    }
                  }}
                >
                  OFF
                </Button>
              </div>
            </div>
            <div css={admin__status__wrap}>
              <h2>온수탱크 가열</h2>
              <div>
                <Button
                  variant="contained"
                  size="large"
                  color={'primary'}
                  css={admin__status__btn}
                  onClick={async () => {
                    try {
                      const answer = window.confirm(`정말 실행하시겠습니까?`);
                      if (!answer) {
                        return;
                      }
                      const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                      const { key } = user;
                      await sendJob(setOpen, connectionType, id, ip, key, {
                        id: 'boiler-heat',
                        data: { toggle: true },
                      });
                    } catch (e) {
                      window.alert(e);
                    }
                  }}
                >
                  ON
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color={'secondary'}
                  css={admin__status__btn}
                  onClick={async () => {
                    try {
                      const answer = window.confirm(`정말 실행하시겠습니까?`);
                      if (!answer) {
                        return;
                      }
                      const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                      const { key } = user;
                      await sendJob(setOpen, connectionType, id, ip, key, {
                        id: 'boiler-heat',
                        data: { toggle: false },
                      });
                    } catch (e) {
                      window.alert('VPC 접속을 확인해주세요.');
                    }
                  }}
                >
                  OFF
                </Button>
              </div>
            </div>
            <div css={admin__status__wrap}>
              <h2>온수탱크 물채우기</h2>
              <div>
                <Button
                  variant="contained"
                  size="large"
                  color={'primary'}
                  css={admin__status__btn}
                  onClick={async () => {
                    try {
                      const answer = window.confirm(`정말 실행하시겠습니까?`);
                      if (!answer) {
                        return;
                      }
                      const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                      const { key } = user;
                      await sendJob(setOpen, connectionType, id, ip, key, {
                        id: 'boiler-fill',
                        data: { toggle: true },
                      });
                    } catch (e) {
                      window.alert(e);
                    }
                  }}
                >
                  ON
                </Button>
                <Button
                  variant="contained"
                  size="large"
                  color={'secondary'}
                  css={admin__status__btn}
                  onClick={async () => {
                    try {
                      const answer = window.confirm(`정말 실행하시겠습니까?`);
                      if (!answer) {
                        return;
                      }
                      const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                      const { key } = user;
                      await sendJob(setOpen, connectionType, id, ip, key, {
                        id: 'boiler-fill',
                        data: { toggle: false },
                      });
                    } catch (e) {
                      window.alert('VPC 접속을 확인해주세요.');
                    }
                  }}
                >
                  OFF
                </Button>
              </div>
            </div>
            <div css={admin__status__wrap}>
              <h2>원액 추출</h2>
              <FormControl variant="outlined" style={{ width: '200px' }}>
                <Select
                  id="dispense-duration-select"
                  value={dispenseDuration.mountPosition}
                  autoWidth
                  style={{ fontSize: '18px' }}
                  MenuProps={MenuProps}
                  onChange={(event) =>
                    setDispenseDuration({
                      ...dispenseDuration,
                      mountPosition: event.target.value,
                      positionName: (mps.find((mp) => mp.value === Number(event.target.value)) || { name: '커피1' })
                        .name,
                    })
                  }
                >
                  {mps.map((mp) => (
                    <MenuItem key={mp.mountPosition} value={mp.value}>
                      {mp.name}:{mp.ingredientId || ''}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl style={{ width: '200px', height: '100%' }}>
                <TextField
                  id="dispense_duration"
                  label="추출시간(초)"
                  variant="outlined"
                  type="number"
                  style={{ fontSize: '40px' }}
                  onChange={(event) =>
                    setDispenseDuration({
                      ...dispenseDuration,
                      duration: Number(event.target.value),
                    })
                  }
                />
              </FormControl>

              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    if (dispenseDuration.duration === 0) {
                      window.alert('최소 0초 이상의 시간을 입력해 주세요.');
                      return;
                    }
                    const answer = window.confirm(
                      `추출 위치(${dispenseDuration.positionName}), 시간(${dispenseDuration.duration})\n정말 실행하시겠습니까?`,
                    );
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    console.log(dispenseDuration);
                    await sendJob(setOpen, connectionType, id, ip, key, {
                      id: 'dispenseDuration',
                      data: dispenseDuration,
                    });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>세척</h2>
              <FormControl variant="outlined" style={{ width: '200px' }}>
                <Select
                  id="cleaning-select"
                  value={cleaning.mountPosition}
                  autoWidth
                  style={{ fontSize: '18px' }}
                  MenuProps={MenuProps}
                  onChange={(event) =>
                    setCleaning({
                      ...cleaning,
                      mountPosition: event.target.value,
                      positionName:
                        Number(event.target.value) >= 60
                          ? `커피${(Number(event.target.value) % 10) + 1}`
                          : `우유${(Number(event.target.value) % 10) + 1}`,
                    })
                  }
                >
                  <MenuItem value={60}>커피1</MenuItem>
                  <MenuItem value={61}>커피2</MenuItem>
                  <MenuItem value={62}>커피3</MenuItem>
                  <MenuItem value={63}>커피4</MenuItem>
                  <MenuItem value={40}>우유1</MenuItem>
                  <MenuItem value={41}>우유2</MenuItem>
                </Select>
              </FormControl>
              <FormControl style={{ width: '200px', height: '100%' }}>
                <TextField
                  id="cleaning_duration"
                  label="세척시간(초)"
                  variant="outlined"
                  type="number"
                  style={{ fontSize: '40px' }}
                  onChange={(event) =>
                    setCleaning({
                      ...cleaning,
                      duration: Number(event.target.value),
                    })
                  }
                />
              </FormControl>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    if (cleaning.duration === 0) {
                      window.alert('최소 0초 이상의 시간을 입력해 주세요.');
                      return;
                    }
                    const answer = window.confirm(
                      `세척 위치(${cleaning.positionName}), 시간(${cleaning.duration})\n정말 실행하시겠습니까?`,
                    );
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    console.log(dispenseCup);
                    await sendJob(setOpen, connectionType, id, ip, key, {
                      id: 'cleanDuration',
                      data: cleaning,
                    });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <div css={admin__status__wrap}>
              <h2>컵토출</h2>
              <FormControl variant="outlined" style={{ width: '200px' }}>
                <Select
                  id="dispense-cup-select"
                  value={dispenseCup.position}
                  autoWidth
                  style={{ fontSize: '18px' }}
                  MenuProps={MenuProps}
                  onChange={(event) =>
                    setDispenseCup({
                      position: event.target.value,
                      positionName:
                        event.target.value === 0 ? '디스펜서1' : event.target.value === 1 ? '디스펜서2' : '디스펜서3',
                    })
                  }
                >
                  {dispensers
                    .sort((a, b) => a.mountPosition - b.mountPosition)
                    .map((dp) => (
                      <MenuItem key={dp.mountPosition} value={dp.mountPosition - 10000}>
                        디스펜서{dp.mountPosition - 10000 + 1}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button
                variant="contained"
                size="large"
                color={'primary'}
                css={admin__status__btn}
                onClick={async () => {
                  try {
                    const answer = window.confirm(`컵 토출 위치 :${dispenseCup.positionName}\n정말 실행하시겠습니까?`);
                    if (!answer) {
                      return;
                    }
                    const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                    const { key } = user;
                    console.log(dispenseCup);
                    await sendJob(setOpen, connectionType, id, ip, key, {
                      id: 'dispenseCup',
                      data: dispenseCup,
                    });
                  } catch (e) {
                    window.alert(e);
                  }
                }}
              >
                실행
              </Button>
            </div>
            <Cleaning
              upload={(results: PhotoResult[]) => {
                const user = admins[uid] || { phoneNum: 'x', name: 'x' };
                const { phoneNum, name, key } = user;
                const storageRef = storage.ref();
                const histRef = storageRef.child(`taskHistory/${new Date().getFullYear()}/${new Date().getMonth()}`);
                const uploads: { filename: string; name: string; type: string; url: string }[] = [];

                results.forEach((r) => {
                  const filename = `cleaningCheck-${id}-${name}-${formatDate(new Date())}`;

                  histRef
                    .child(filename)
                    .put(r.file)
                    .then((snapshot) => {
                      storage
                        .ref()
                        .child(snapshot.metadata.fullPath)
                        .getDownloadURL()
                        .then((url) => {
                          uploads.push({
                            filename,
                            name,
                            type: r.file.type,
                            url,
                          });
                        });
                    });
                });
                const thRef = firestore.collection('/taskHistory/');
                waitForDone(uploads, results.length).then(() => {
                  console.log('업로드 완료');
                  thRef.add({
                    target: id,
                    authId: uid,
                    authName: name,
                    authPhoneNum: phoneNum,
                    timestamp: new Date(),
                    taskId: 'cleaningCheck',
                    results: uploads,
                  });
                });
              }}
            />
          </div>
        )}
      </div>
    </>
  );
}

const status__wrap = css`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 940px;
  margin-top: 24px;
  font-family: Spoqa Han Sans;
  h1 {
    width: calc(100% - 24px);
    background-color: #666666;
    color: white;
    padding-left: 24px;
    font-weight: bold;
    font-size: 46px;
  }
`;

const status__connect = css`
  font-size: 38px;
  line-height: 1.2;
  margin-bottom: 12px;
`;
const status__hw = css`
  font-size: 38px;
  line-height: 1.2;
  div {
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

const error__init__btn = css`
  width: calc(100% - 24px);
  height: 120px;
`;

const admin__pii__wrap = css`
width: 100%;
height: '600px';
padding-bottom: : '50px';

`;

const admin__status__wrap = css`
  width: 100%;
  margin-top: 15px;
  font-size: 38px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: bold;
  }
`;

const admin__status__btn = css`
  width: 160px;
  max-height: 84px;
  border-radius: 15px;
  .MuiButton-label {
    font-size: 32px;
  }
`;

export default VmStatus;
