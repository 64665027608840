export const DEPRICATED_VMS = [
  'BETA0001',
  'BETA0002',
  'BETA0003',
  'BETA0007',
  'BETA0008',
  'BETA0009',
  'BETA0010',
  'BETA0011',
  'BETA0014',
  'BETA0015',
  'BETA0016',
  'BETA0022',
  'BETA0024',
  'BETA0020',
  'BETA0030',
  'BETA0032',
  'BETA0041',
  'BETA0046',
  'BETA0047',
  'BETA0048',
  'BETA0049',
  'BETA0050',
  'BETA0051',
  'INIT',
  'BETA9999',
  'TEST0001',
  'TEST0002',
  'TEST0003',
];
