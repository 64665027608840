//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { yellow, red } from '@material-ui/core/colors';
import { FiberNewOutlined } from '@material-ui/icons';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { RecipeType, SizeCharge } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';
import AddRecipeOrder from './AddProdPrice';
import ProdPriceInputs, { ProdPriceType } from './ProdPriceInputs';

export const MOCK_PROD_PRICES = [
  {
    recipeId: 'illyAmericano',
    priceInfo: {
      price: {
        basic: { iced: 2800 },
        concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
        sizeCharge: { iced: 900, type: SizeCharge.iced },
      },
    },
  },
];

export interface ProdPriceInfoType {
  recipeId: string;
  price: ProdPriceType;
  checked: boolean;
  beDeleted: boolean;
}

export interface ProdPriceRawProps {
  originalData: ProdPriceInfoType[];
  curData: ProdPriceInfoType;
  idx: number;
  prodPriceEditHandler: (type: string, prodPriceInfo?: ProdPriceInfoType, idx?: number) => void;
  checkHandler: (idx: number) => void;
  isLastRecipe: boolean;
  scrollToBottom: () => void;
}

export const setOptionPrice = (type: any, price: any) => {
  console.error(price);
  const recipeType = type.slice(type.indexOf('_') + 1);
  switch (recipeType) {
    case 'iced':
      return { type, iced: price.iced || 0 };
    case 'hot':
      return { type, hot: price.hot || 0 };
    case 'both':
      return { type, iced: price.iced || 0, hot: price.hot || 0 };
    case 'none':
      return { type };
    default:
      return { type };
  }
};

function ProdPriceRaw({
  originalData,
  curData,
  idx,
  prodPriceEditHandler,
  checkHandler,
  isLastRecipe,
  scrollToBottom,
}: ProdPriceRawProps) {
  const [isShowDelete, setIsShowDelete] = useState(false);
  const { recipeId, price, checked, beDeleted } = curData;
  const initId = !originalData[idx] ? undefined : originalData[idx].recipeId;
  const initPrice = !originalData[idx] ? undefined : originalData[idx].price;
  const editOnChangeHandler = (e: React.ChangeEvent<any>) => {
    const pathList = e.target.name.split('/');
    const value = Number(e.target.value);

    console.error(e.target.value);
    console.error(e.target.name);

    prodPriceEditHandler(
      'edit',
      {
        ...curData,
        price: { ...curData.price, [pathList[0]]: { ...curData.price[pathList[0]], [pathList[1]]: value } },
      },
      idx,
    );
    // prodPriceEditHandler('edit', { ...curData, recipeOrder: parseFloat(e.target.value) }, idx);
  };
  const editSelectHandler = (e: React.ChangeEvent<any>) => {
    const { sizeCharge, concnCharge } = price;
    const path = e.target.name;
    const value = e.target.value;

    console.error(e.target.value);
    console.error(e.target.name);
    const chargePrice = path === 'sizeCharge' ? sizeCharge : concnCharge;

    prodPriceEditHandler(
      'edit',
      {
        ...curData,
        price: { ...curData.price, [path]: setOptionPrice(value, chargePrice) },
      },
      idx,
    );
    // prodPriceEditHandler('edit', { ...curData, recipeOrder: parseFloat(e.target.value) }, idx);
  };

  const cancelHandler = () => {
    if (idx > originalData.length - 1) {
      prodPriceEditHandler('addCancel', curData, idx);
    } else if (beDeleted) {
      prodPriceEditHandler('deleteCancel', curData, idx);
    } else {
      prodPriceEditHandler('edit', { ...curData, price: initPrice }, idx);
    }

    setIsShowDelete(false);
  };

  useEffect(() => {
    if (idx > originalData.length - 1) {
      scrollToBottom();
    }
  }, []);

  return (
    <section css={raw__wrap(isLastRecipe)}>
      <input
        style={{ marginRight: '10px', zoom: '1.5', cursor: 'pointer' }}
        type="checkbox"
        id={idx + recipeId}
        value={recipeId}
        name=""
        checked={checked}
        onChange={() => checkHandler(idx)}
        disabled={idx > originalData.length - 1}
      />
      <div css={recipe__idx}>{idx + 1}</div>
      <div css={price__input__box}>
        <div css={price__header}>
          <label css={recipe__name} id={idx + recipeId}>
            {recipeId}
          </label>
          {initPrice !== price && !beDeleted && (
            <Button variant="contained" color="secondary" style={{ marginLeft: '15px' }} onClick={cancelHandler}>
              {idx <= originalData.length - 1 ? '되돌리기' : '생성취소'}
            </Button>
          )}
          {initId === undefined && initPrice === undefined && (
            <FiberNewOutlined style={{ marginLeft: '15px', color: yellow[700], fontSize: 50 }} fontSize="large" />
          )}
          {beDeleted && idx <= originalData.length - 1 && (
            <Button
              style={{ marginLeft: '15px' }}
              variant={isShowDelete ? 'contained' : 'outlined'}
              color="secondary"
              onClick={cancelHandler}
              // defaultValue={false}
              onMouseEnter={() => setIsShowDelete(true)}
              onMouseLeave={() => setIsShowDelete(false)}
            >
              {isShowDelete ? '삭제취소' : '삭제예정'}
            </Button>
          )}
        </div>
        {/* <input css={order__input} type="number" value={recipeOrder} onChange={(e) => onChangeHandler(e)} /> */}
        <ProdPriceInputs
          price={price}
          type={'edit'}
          editOnChangeHandler={editOnChangeHandler}
          editSelectHandler={editSelectHandler}
        />
        {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
          <p css={border__bottom(isLastRecipe)}></p>
        </div> */}
      </div>
    </section>
  );
}

export default ProdPriceRaw;

const raw__wrap = (isLastRecipe: boolean) => css`
  position: relative;
  margin-left: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 95%;
  height: 110px;
  display: flex;

  font-family: Spoqa Han Sans;
  font-size: 16px;
  margin-bottom: 15px;
`;
const recipe__idx = css`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 30px;
  height: 100%;
`;
const price__header = css`
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  /* width: 400px; */
  height: 40px;
`;
const recipe__name = css`
  display: flex;
  justify-content: start;
  align-items: center;
  /* width: 250px; */
  margin-bottom: 5px;
  height: 40px;
`;
const price__input__box = css`
  display: flex;
  flex-direction: column;
  justify-content: start;
  /* align-items: center; */
  /* width: 60px; */
  height: 100%;
  /* text-align: center; */
`;
const border__bottom = (isLastRecipe: boolean) => css`
  position: absolute;
  bottom: -10px;
  width: 5%;
  /* height: 10px; */
  border-bottom: ${!isLastRecipe && `2px solid #c5c5c5`};
  border-width: 400%;
`;
