// 외부모듈
import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import produce from 'immer';

// 내부모듈
import { RootStateType } from '@reducers/index';
import { Benefit, BenefitType, CombinationItem, CombinationTarget } from '@definitions/benefits';
import { RecipeType } from '@definitions/recipes';
import CombinationTable from './CombinationTable';
import { compareFunction, intersection, not, union } from '@utils/controlDataUtils';
import CustomList from './CustomList';
import PreViewModal from './preViewModal';

// const useStyles = makeStyles((theme: Theme) =>
//   createStyles({
//     button: {
//       margin: theme.spacing(0.5, 0),
//     },
//   }),
// );

interface CombinationControllerProps {
  onChange: any;
  data: { benefits: Benefit[] };
}

const defaultData = { benefits: [] };

function CombinationController({ onChange, data = defaultData }: CombinationControllerProps) {
  const { benefits } = data;
  const selectedBenefit = benefits.find((benefit) => benefit.benefitType === 'combination');
  const targets = selectedBenefit ? selectedBenefit.targets : [];

  const recipes: { [key: string]: RecipeType } =
    useSelector((state: RootStateType) => state.firestore.data['version/v3/recipes']) || {};
  const recipesList = Object.values(recipes)
    .map((value) => ({ id: value.recipeId, combination: value.combination, conditions: [], priority: 10 }))
    .filter((recipe) => targets.findIndex((item: any) => item.id === recipe.id) === -1)
    .sort(compareFunction);

  // const classes = useStyles();
  const [checked, setChecked] = useState<any[]>([]);
  const [left, setLeft] = useState<any[]>(recipesList);
  const [right, setRight] = useState<any[]>(targets);

  useEffect(() => {
    onChange(right);
  }, [right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const toggleHanlder = (value: any) => () => {
    const currentIndex = checked.findIndex((check) => check.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: number[]) => intersection(checked, items).length;

  const toggleAllHandler = (items: number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const checkedRightHandler = () => {
    setRight(right.concat(leftChecked).sort(compareFunction));
    setLeft(not(left, leftChecked).sort(compareFunction));
    setChecked(not(checked, leftChecked));
  };

  const checkedLeftHandler = () => {
    setLeft(left.concat(rightChecked).sort(compareFunction));
    setRight(not(right, rightChecked).sort(compareFunction));
    setChecked(not(checked, rightChecked));
  };

  const amountHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
    const nextState = produce(checked, (draft: any) => {
      const { name, value } = e.target;
      const [ingredientId, temp] = name.split('/');
      const target = draft
        .find((check: CombinationTarget) => check.id === id)
        .combination[temp].find((item: CombinationItem) => item.ingredientId === ingredientId);

      target.amount = Number(value);
    });
    setChecked(nextState);
  };

  const priorityHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => {
    const nextState = produce(checked, (draft: any) => {
      const { value } = e.target;
      const target: CombinationTarget = draft.find((check: CombinationTarget) => check.id === id);
      target.priority = Number(value);
    });
    setChecked(nextState);
  };

  return (
    <section css={card__style}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CustomList
            items={left}
            title="미적용"
            toggleAllHandler={toggleAllHandler}
            numberOfChecked={numberOfChecked}
            toggleHandler={toggleHanlder}
            checked={checked}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              onClick={checkedRightHandler}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={checkedLeftHandler}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CustomList
            items={right}
            title="적용중"
            toggleAllHandler={toggleAllHandler}
            numberOfChecked={numberOfChecked}
            toggleHandler={toggleHanlder}
            checked={checked}
          />
        </Grid>
      </Grid>
      <Box css={selected__targets__box}>
        {checked
          .filter((check) => left.find((leftItem) => check.id === leftItem.id))
          .map((check, i) => (
            <CombinationTable key={i} data={check} amountHandler={amountHandler} priorityHanlder={priorityHandler} />
          ))}
      </Box>
      <PreViewModal data={right} benefitType={BenefitType.combination} />
    </section>
  );
}

const card__style = css`
  input {
    width: 80px;
    margin: 5px;
    margin-bottom: 6px;
  }
  button {
    margin-bottom: 10px;
  }

  .MuiTextField-root {
    margin: 5px;
  }
`;

const selected__targets__box = css`
  margin-top: 15px;
`;

export default CombinationController;
