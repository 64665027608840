// api.ts
import axios from 'axios';
import { Opr } from './types';

// 실제 API 엔드포인트로 변경하세요.
export const fetchOprData = async (year: number, month: number): Promise<Opr[]> => {
  // 예를 들어, `/api/opr-data?year=2023&month=10` 형태로 호출
  // 여기서는 Mock 데이터를 반환합니다.
  const addr = `http://planz-proxy.com:3000/getOprs`;
  const params = { key: 'planz!3245', start: '2024-10-14', end: '2024-10-15' };
  let result = [];
  const res = await axios.get(addr, { params });
  return res.data;
};
