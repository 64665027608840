// OprCalendar.tsx
import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import { fetchOprData } from './api';
import { Opr } from './types';

interface DailyOpr {
  [key: number]: {
    mainTotal: number;
    totalTotal: number;
    count: number;
  };
}

const OprCalendar: React.FC = () => {
  const [value, setValue] = useState<Date>(new Date());
  const [oprData, setOprData] = useState<DailyOpr>({});
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchAndProcessData = async () => {
      setLoading(true);
      const year = value.getFullYear();
      const month = value.getMonth();
      const data: Opr[] = await fetchOprData(year, month);
      const dailyOpr: DailyOpr = {};

      data.forEach((item) => {
        const date = new Date(item.timeStamp).getDate();
        if (!dailyOpr[date]) {
          dailyOpr[date] = { mainTotal: 0, totalTotal: 0, count: 0 };
        }

        const mainRatios = item.vms.map((opr) => opr.opr_main.ratio);
        const totalRatios = item.vms.map((opr) => opr.opr_total.ratio);

        const dailyMainRatio = mainRatios.reduce((acc, curr) => acc + curr, 0) / mainRatios.length;
        const dailyTotalRatio = totalRatios.reduce((acc, curr) => acc + curr, 0) / totalRatios.length;

        dailyOpr[date].mainTotal += dailyMainRatio;
        dailyOpr[date].totalTotal += dailyTotalRatio;
        dailyOpr[date].count += 1;
      });

      setOprData(dailyOpr);
      setLoading(false);
    };

    fetchAndProcessData();
  }, [value]);

  return (
    <div>
      <h2>운영율 달력</h2>
      {loading ? (
        <p>로딩 중...</p>
      ) : (
        <Calendar
          //   onChange={(date: Date) => setValue(date)}
          value={value}
          tileContent={({ date, view }) => {
            if (view === 'month') {
              const day = date.getDate();
              if (oprData[day]) {
                const avgMain = (oprData[day].mainTotal / oprData[day].count) * 100;
                const avgTotal = (oprData[day].totalTotal / oprData[day].count) * 100;
                return (
                  <div style={{ textAlign: 'center', marginTop: 5 }}>
                    <span style={{ fontSize: '0.8em' }}>핵심: {avgMain.toFixed(2)}%</span>
                    <br />
                    <span style={{ fontSize: '0.8em' }}>완벽: {avgTotal.toFixed(2)}%</span>
                  </div>
                );
              }
            }
            return null;
          }}
        />
      )}
    </div>
  );
};

export default OprCalendar;
