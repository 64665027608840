// 외부 모듈
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Segment, Dropdown, Datepicker, Button, DatepickerCalendar } from '@fluentui/react-northstar';
import { TimeStamp, VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers/index';
import { useFirestore } from 'react-redux-firebase';
import { getSalesInfo, format, getYear, getMonth } from 'app/utils/sales';
import { Admin } from '@definitions/admins';

interface SalesProps {
  admin: Admin;
}
function Sales({ admin }: SalesProps): JSX.Element {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const adminsArray = Object.values(admins) as Admin[];
  const planzAdminNumArr: string[] = adminsArray.reduce((acc: string[], cur: Admin): string[] => {
    if (cur.level < 2) {
      acc.push(cur.phoneNum);
    }
    return acc;
  }, []);
  const firestore = useFirestore();
  const [selectedVmLocation, setSelectedVmLocation] = useState<string>('');
  const today = new Date();
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>(format(today));
  let locations: { name: string; startDate: TimeStamp }[] = [];
  let isLoaded = false;
  if (vms !== undefined && vms !== null && vms.length !== 0) {
    locations = vms
      .filter(({ location }) => location.type !== '테스트')
      .filter(({ id }) => (admin.type === 'FRANCHISEE' ? admin.vms && admin.vms.includes(id) : true))
      .map(({ id, location: { name, startDate } }) => ({
        id,
        name,
        startDate,
      }));
    if (admin.level === 0) {
      locations = [
        ...locations,
        { name: '모든장소', startDate: { nanoseconds: 1, seconds: 2, toDate: () => new Date('2019-03-29') } },
      ];
    }
    console.log(locations.map((l) => l.name));
    isLoaded = true;
  }

  const getA11ySelectionMessage = {
    onAdd: (item) => {
      setSelectedVmLocation(item);
      setStart('');
    },
  };

  const onUpdateDate = {
    onAdd: (item) => console.log(item),
  };
  let csv = '';
  // const processBatch = async (start, end, vms_, vi, lastDoc, currentYear, currentMonth) => {
  //   const batchSize = 500; // 배치 크기 설정

  //   const endYear = getYear(end);
  //   const endMonth = getMonth(end);

  //   let query = firestore
  //     .collection(
  //       `version/v3/vms/${vms_[vi].id}/orders/${currentYear}/${currentMonth < 10 ? `0${currentMonth}` : currentMonth}`,
  //     )
  //     .where('timeStamp', '>=', new Date(`${start}T00:00:00`))
  //     .where('timeStamp', '<=', new Date(`${end}T24:00:00`))
  //     .orderBy('timeStamp')
  //     .limit(batchSize);

  //   if (lastDoc) {
  //     query = query.startAfter(lastDoc);
  //   }

  //   const querySnapshot = await query.get();
  //   console.log('downloaded');

  //   querySnapshot.forEach((row) => {
  //     const order = row.data();
  //     csv += getSalesInfo(order, vms_[vi].location.name, planzAdminNumArr);
  //   });

  //   const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

  //   // 다음 월로 넘어가기
  //   if (currentYear === endYear && currentMonth === endMonth) {
  //     return null; // 처리 완료
  //   }

  //   currentMonth++;
  //   if (currentMonth > 12) {
  //     currentMonth = 1;
  //     currentYear++;
  //   }

  //   return { lastVisible, currentYear, currentMonth };
  // };

  // const executeBatchUpdates = async (start, end, vms_) => {
  //   for (let vi = 0; vi < vms_.length; vi++) {
  //     let lastDoc = null;
  //     const startYear = getYear(start);
  //     const startMonth = getMonth(start);
  //     let currentYear = startYear;
  //     let currentMonth = startMonth;
  //     let finished = false;

  //     while (!finished) {
  //       const result = await processBatch(start, end, vms_, vi, lastDoc, currentYear, currentMonth);
  //       if (!result) {
  //         finished = true;
  //       } else {
  //         lastDoc = result.lastVisible;
  //         currentYear = result.currentYear;
  //         currentMonth = result.currentMonth;
  //       }
  //     }
  //   }
  // };

  return (
    <Flex>
      <Segment style={{ marginTop: '18.76px', marginLeft: '16px' }}>
        <Flex column>
          VM_ID
          <Dropdown
            loading={!isLoaded}
            items={locations.map(({ name }) => name)}
            placeholder="기기장소"
            checkable
            getA11ySelectionMessage={getA11ySelectionMessage}
            style={{ marginTop: '4px', marginBottom: '12px' }}
          />
          시작일
          <input
            id="datepicker_start"
            style={{ marginTop: '4px', marginBottom: '12px' }}
            type="date"
            value={start}
            min={
              selectedVmLocation === ''
                ? undefined
                : selectedVmLocation === '모든장소'
                ? format(
                    locations.reduce((prev, { name, startDate }) => {
                      console.log(name, ': ', startDate);
                      return startDate.toDate() > prev ? startDate.toDate() : prev;
                    }, new Date('2019-03-29')),
                  )
                : format(
                    locations
                      .filter(({ name }) => {
                        if (name === selectedVmLocation) {
                          return true;
                        }

                        return false;
                      })[0]
                      .startDate.toDate(),
                  )
            }
            max={format(today)}
            onChange={(event) => {
              // 시작일이 수정되면 end 도 수정
              setStart(event.target.value);
            }}
          />
          마지막일
          <input
            id="datepicker_end"
            style={{ marginTop: '4px', marginBottom: '18px' }}
            type="date"
            value={end}
            max={format(today)}
            onChange={(event) => {
              setEnd(event.target.value);
            }}
          />
          <Button
            fluid
            content="다운로드"
            onClick={async (event) => {
              // let query_ = firestore.collection('version/v3/vms/BETA0027/orders/2024/01').limit(1);
              // let cnt = 0;

              // let lastDoc = null; // 이전 쿼리의 마지막 문서 저장
              // let finished = false;

              // while (!finished) {
              //   const batch = firestore.batch();
              //   if (lastDoc) {
              //     query_ = query_.startAfter(lastDoc);
              //   }
              //   const orders_ = await query_.get();
              //   orders_.forEach((row) => {
              //     console.log(row.id);
              //     const order = row.data();
              //     console.log(order.session.history);

              //     batch.update(row.ref, {
              //       session: {
              //         ...order.session,
              //         history: order.session.history.map((hist) => {
              //           if (hist.action === 'SIGN_IN_SUCCESS') {
              //             return {
              //               ...hist,
              //               payload: {
              //                 ...hist.payload,
              //                 userData: {
              //                   ...hist.payload.userData,
              //                   userCouponList: [],
              //                   userSickerList: [],
              //                 },
              //               },
              //             };
              //           }

              //           return hist;
              //         }),
              //       },
              //     });
              //   });
              //   batch.commit();
              //   const lastVisible = orders_.docs[orders_.docs.length - 1];

              //   if (orders_.docs.length < 1) {
              //     // 쿼리가 더 이상 결과를 가져오지 못하는 경우(배치 크기보다 작음) 종료합니다.
              //     finished = true;
              //   } else {
              //     console.log('다운로드 수: ', 1);
              //     // 아직 처리되지 않은 데이터가 있는 경우 이전 쿼리의 마지막 위치에서 다음 쿼리를 시작합니다.
              //     lastDoc = lastVisible;
              //   }
              // }

              // batch.commit();
              // console.error(cnt);
              // return;

              /**
               * 2023 크리스마스, 날씨 관련 업데이트 위해 임시코드 작성
               */
              // vms.forEach((vm) => {
              //   firestore.doc(`version/v3/vms/${vm.id}`).update({
              //     location: {
              //       ...vm.location,
              //       // connectionType: 'socket.io',
              //       // isWeatherEffectVisible: true,
              //       // isChristmasEffectVisible: true,
              //     },
              //   });
              // });

              /**
               * 2024 모듈에 스티머 추가
               */
              // vms.forEach((vm) => {
              //   if (
              //     vm.id === 'BETA0031' ||
              //     vm.id === 'BETA0033' ||
              //     vm.id === 'BETA0034' ||
              //     vm.id === 'BETA0035' ||
              //     vm.id === 'BETA0036' ||
              //     vm.id === 'BETA0037' ||
              //     vm.id === 'BETA0040' ||
              //     vm.id === 'BETA0041' ||
              //     vm.id === 'BETA0042' ||
              //     vm.id === 'BETA0043' ||
              //     vm.id === 'BETA0044' ||
              //     vm.id === 'BETA0045' ||
              //     vm.id === 'BETA0050' ||
              //     vm.id === 'BETA0052' ||
              //     vm.id === 'BETA0053' ||
              //     vm.id === 'BETA0054' ||
              //     vm.id === 'INIT' ||
              //     vm.id === 'BETA0055'
              //   ) {
              //     firestore.doc(`version/v3/vms/${vm.id}`).update({
              //       modules: [...vm.modules, { type: 'milkSteamer' }],
              //     });
              //   }
              // });

              if (start === '' || end === '') {
                window.alert('시작일과 마지막일을 설정해주세요(기기를 수정하면 시작일이 초기화 됩니다).');

                return;
              } else if (new Date(start) > new Date(end)) {
                window.alert('시작일이 마지막일보다 클 수 없습니다.');

                return;
              } else if (
                vms.find(({ location: { name } }) => name === selectedVmLocation) === undefined &&
                selectedVmLocation !== '모든장소'
              ) {
                window.alert('조회하고자 하는 기기를 선택해주세요.');

                return;
              }

              const vms_ =
                selectedVmLocation === '모든장소'
                  ? vms.filter(
                      (vm) =>
                        vm.id !== 'TEST0000' && vm.id !== 'TEST0001' && vm.id !== 'TEST0002' && vm.id !== 'TEST0003',
                    )
                  : vms.filter(({ location: { name } }) => name === selectedVmLocation);

              // for (let yi = getYear(start); yi <= getYear(end); yi++) {
              //   for (let mi = getMonth(start); mi <= getMonth(end); mi++) {
              //     const snapshot = await firestore
              //       .collection(`version/v3/vms/${vmId}/orders/${yi}/${mi < 10 ? `0${mi}` : mi}`)
              //       .where('timeStamp', '>=', new Date(`${start}T00:00:00`))
              //       .where('timeStamp', '<=', new Date(`${end}T24:00:00`))
              //       .get();
              //     snapshot.forEach((row) => {
              //       const order = row.data();
              //       csv += getSalesInfo(order, selectedVmLocation, planzAdminNumArr);
              //     });
              //   }
              // }
              csv = '';
              const csvs: string[] = [];
              const batchSize = 100; // 한 번에 읽어올 데이터 개수 설정
              console.log('시작: ', vms_[0].id);
              console.log('start: ', start, 'end: ', end);
              console.log('start: ', getYear(start), 'end: ', getYear(end));

              for (let yi = getYear(start); yi <= getYear(end); yi++) {
                const endMonth = yi < getYear(end) ? 12 : getMonth(end); // 현재 연도가 마지막 연도보다 작으면 모든 월을 처리하고, 그렇지 않으면 마지막 월까지 처리합니다.
                const startMonth = yi > getYear(start) ? 1 : getMonth(start); // 현재 연도가 시작 연도보다 크면 1월부터 시작하고, 그렇지 않으면 시작 월부터 시작합니다.

                for (let mi = startMonth; mi <= endMonth; mi++) {
                  for (let vi = 0; vi < vms_.length; vi++) {
                    let lastDoc = null; // 이전 쿼리의 마지막 문서 저장
                    let finished = false;
                    console.log(`${yi}-${mi}`);
                    while (!finished) {
                      let query = firestore
                        .collection(`version/v3/vms/${vms_[vi].id}/orders/${yi}/${mi < 10 ? `0${mi}` : mi}`)
                        .where('timeStamp', '>=', new Date(`${start}T00:00:00`))
                        .where('timeStamp', '<=', new Date(`${end}T24:00:00`))
                        .orderBy('timeStamp')
                        .limit(batchSize);

                      if (lastDoc) {
                        query = query.startAfter(lastDoc);
                      }
                      console.log('vmId: ', vms_[vi].id);

                      const snapshot = await query.get();
                      console.log('downloaded: ', snapshot.docs.length);
                      snapshot.forEach((row) => {
                        const order = row.data();
                        console.log(order);
                        csvs.push(getSalesInfo(order, vms_[vi].location.name, planzAdminNumArr));
                      });

                      // 이전 쿼리의 마지막 문서를 가져옵니다.
                      const lastVisible = snapshot.docs[snapshot.docs.length - 1];

                      if (snapshot.docs.length < batchSize) {
                        // 쿼리가 더 이상 결과를 가져오지 못하는 경우(배치 크기보다 작음) 종료합니다.
                        finished = true;
                      } else {
                        console.log('다운로드 수: ', batchSize);
                        // 아직 처리되지 않은 데이터가 있는 경우 이전 쿼리의 마지막 위치에서 다음 쿼리를 시작합니다.
                        lastDoc = lastVisible;
                      }
                    }
                  }
                }
              }

              const downloadLink = document.createElement('a');
              const blob = new Blob([csvs.join('')], { type: 'text/csv;charset=utf-8' });
              const url = URL.createObjectURL(blob);
              downloadLink.href = url;
              downloadLink.download = `${selectedVmLocation}매출_${new Date().toDateString()}.csv`;

              document.body.appendChild(downloadLink);
              downloadLink.click();
              document.body.removeChild(downloadLink);
            }}
          />
        </Flex>
      </Segment>
    </Flex>
  );
}

export default Sales;
