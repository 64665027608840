import React, { useCallback, useState } from 'react';
import { css } from '@emotion/core';
import { useFirestore } from 'react-redux-firebase';
import Input from '@material-ui/core/Input';

// 내부모듈
import UserInfo from './UserInfo';
import UserController from './UserController';
import { userFormData } from 'app/mock/userFormData';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/index';

function UserForm() {
  const firestore = useFirestore();
  const [phoneNum, setPhoneNum] = useState('');
  const [user, setUser] = useState<any>();
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  if (!admins || !uid) {
    return null;
  }
  if (admins[uid].level > 1) {
    return <div>접근권한 없음</div>;
  }

  const phoneNumInputHanlder = useCallback((e) => {
    const {
      target: { value },
    } = e;
    setPhoneNum(value);
    if (value.length === 11) {
      firestore
        .doc(`version/v3/users/${value}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUser(doc.data());
          } else {
            setUser(undefined);
          }
        });
    } else {
      setUser(undefined);
    }
  }, []);

  const userRefreshHandler = useCallback(() => {
    if (phoneNum.length === 11) {
      firestore
        .doc(`version/v3/users/${phoneNum}`)
        .get()
        .then((doc) => {
          if (doc.exists) {
            setUser(doc.data());
          } else {
            setUser(undefined);
          }
        });
    } else {
      setUser(undefined);
    }
  }, [phoneNum]);

  return (
    <section css={user__control__wrap}>
      <div css={user__input__box}>
        <Input
          onChange={phoneNumInputHanlder}
          id="standard-basic"
          defaultValue="010"
          type="number"
          style={{ width: '70%', height: '100px', fontSize: '32px' }}
        ></Input>
      </div>
      <div css={user__info__box}>
        {phoneNum.length === 11 &&
          (user ? (
            <UserInfo user={user} userRefreshHandler={userRefreshHandler} />
          ) : (
            <h2 css={unknow__user__text}>미가입 고객입니다.</h2>
          ))}
        {user &&
          userFormData.map((formData, i: number) => <UserController key={i} formData={formData} phoneNum={phoneNum} />)}
      </div>
    </section>
  );
}

const user__control__wrap = css`
  width: 100vw;
  height: calc(100vh - 360px);
  font-family: Spoqa Han Sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 24px;
`;

const user__input__box = css`
  height: 15vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
const user__info__box = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const unknow__user__text = css`
  font-size: 32px;
  font-weight: bold;
`;

export default UserForm;
