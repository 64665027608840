// 외부모듈
import React from 'react';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Divider from '@material-ui/core/Divider';
import Checkbox from '@material-ui/core/Checkbox';
import List from '@material-ui/core/List';
import { css } from '@emotion/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

interface CustomListProps {
  title: React.ReactNode;
  items: any[];
  toggleAllHandler: any;
  numberOfChecked: any;
  toggleHandler: any;
  checked: any[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    cardHeader: {
      padding: theme.spacing(1, 2),
    },
    list: {
      width: 300,
      height: 230,
      backgroundColor: theme.palette.background.paper,
      overflow: 'auto',
    },
  }),
);

function CustomList({ title, items, toggleAllHandler, toggleHandler, numberOfChecked, checked }: CustomListProps) {
  const classes = useStyles();
  return (
    <Card css={card__style}>
      <CardHeader
        className={classes.cardHeader}
        avatar={
          <Checkbox
            onClick={toggleAllHandler(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} selected`}
      />
      <Divider />
      <List className={classes.list} dense component="div" role="list">
        {items.map((value: any, i: number) => {
          const labelId = `transfer-list-all-item-${value}-label`;

          return (
            <ListItem key={i} role="listitem" button onClick={toggleHandler(value)}>
              <ListItemIcon>
                <Checkbox
                  checked={
                    checked.findIndex((check) => {
                      if (typeof check === 'string') {
                        return check === value;
                      } else {
                        return check.id === value.id;
                      }
                    }) !== -1
                  }
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              <ListItemText id={labelId} primary={value.recipeId || value.id || value || 'error'} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
    </Card>
  );
}

const card__style = css`
  input {
    width: 80px;
    margin: 5px;
    margin-bottom: 6px;
  }

  .MuiTextField-root {
    margin: 5px;
  }
`;

export default CustomList;
