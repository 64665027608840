// 외부모듈
import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { useFirestore } from 'react-redux-firebase';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoffee } from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';

// 내부모듈
import OrderRow from './OrderRow';
import { getRefundDate } from '../utils/refund/refundController';

// 타입
import { DocumentData } from '@firebase/firestore-types';
import { RootStateType } from '@reducers';
import { VendingMachine } from '@definitions/vm';
import { DEPRICATED_VMS } from '@constants/vm';

function FindOrderHistory() {
  const firestore = useFirestore();

  // const [vmList, setVmList] = useState<string[]>([]);
  const [selectedVm, setSelectedVm] = useState('');
  const [selectedDate, setSelectedDate] = useState('');
  const [tableData, setTableData] = useState<DocumentData[]>([]);
  const [refundedOrders, setRefundedOrders] = useState<DocumentData>([]);
  const [numOfItems, setNumOfItems] = useState(0);
  const [numOfRefundesItems, setNumOfRefundesItems] = useState({ success: 0, failed: 0, pending: 0 });
  const [refundOpen, setRefundOpen] = useState(false);
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  const isRootAdmin = admins[uid].level === 0;
  const isOperatingAdmin = admins[uid].level === 1;
  const isStoreManager = admins[uid].level === 2;
  if (!admins || !uid) {
    return null;
  }

  const vmList = vms
    .filter((vm) => {
      const { id } = vm;
      if (DEPRICATED_VMS.includes(id)) {
        return false;
      }
      if (isRootAdmin) {
        //rootAdmin은 전체 vm 셀렉 가능하게끔한다
        return true;
      } else if (isOperatingAdmin) {
        //Operating Admin은 전체 test vm 제외 셀렉 가능하게끔한다
        return !id.includes('TEST') && !id.includes('INIT');
      } else if (isStoreManager) {
        // 점주들은 자신이 관리하는 기기만 확인할 수 있어야함.
        return admins[uid].vms.includes(id);
      }
    })
    .map((vm) => ({ id: vm.id, location: vm.location.name }));

  useEffect(() => {
    // getVmList를 통해 유효한 vmList를 가져와서 dropdown에서 클릭할 수 있게 함
    if (selectedVm === '' || selectedDate === '') {
      return;
    }
    // date를 지정하면 해당 날의 0시와 23시59분 데이터를 가져와서 넣어줌
    const startTimestamp = new Date(selectedDate).setHours(0, 0, 0, 0);
    const endTimestamp = new Date(selectedDate).setHours(23, 59, 59, 999);

    // 해당 타켓시간대의 order를 import 함
    const importFirebase = async () => {
      let targetOrder = [];
      const refundedOrder = [];
      const currentYear = new Date(startTimestamp).getFullYear();
      const currentMonth =
        new Date(startTimestamp).getMonth() + 1 < 10
          ? `0${new Date(startTimestamp).getMonth() + 1}`
          : `${new Date(startTimestamp).getMonth() + 1}`;

      const { refundEndDate } = getRefundDate(new Date(startTimestamp), new Date(endTimestamp));

      const thisMonthTargetOrder = await firestore
        .collection(`/version/v3/vms/${selectedVm}/orders/${currentYear}/${currentMonth}`)
        .where('timeStamp', '>', new Date(startTimestamp))
        .where('timeStamp', '<', new Date(endTimestamp))
        .get();
      const thisMonthRefundedOrder = await firestore
        .collection(`/version/v3/vms/${selectedVm}/refunds`)
        .where('timeStamp', '>', new Date(startTimestamp))
        .where('timeStamp', '<', new Date(refundEndDate))
        .get();

      targetOrder = thisMonthTargetOrder.docs.map((doc) => ({ ...doc.data(), _id: doc.id }));
      const refundedOrderData = thisMonthRefundedOrder.docs.map((doc) => doc.data());
      refundedOrderData.forEach((rOrder) => {
        targetOrder.forEach((tOrder) => {
          if (!(tOrder.type >= 90) && rOrder.sessionId === tOrder.sessionId) {
            refundedOrder.push(rOrder);
          }
        });
      });
      setRefundedOrders(refundedOrderData);
      setTableData(targetOrder);
      const totalBevNum = targetOrder.reduce((a, b) => a + b.order.items.length, 0);
      const refundedBebNum = refundedOrder.reduce(
        (a, b) => {
          if (b.type === 99) {
            return { ...a, success: a.success + b.order.items.length };
          } else if (b.type === 98) {
            return { ...a, pending: a.pending + b.order.items.length };
          } else if (b.type === 97) {
            return { ...a, failed: a.failed + b.order.items.length };
          }
          return a;
        },
        { success: 0, failed: 0, pending: 0 },
      );

      setNumOfItems(totalBevNum);
      setNumOfRefundesItems(refundedBebNum);
    };
    importFirebase();
  }, [selectedDate, selectedVm, refundOpen]);

  const handleVmSelect = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedVm(event.target.value as string);
  };

  const setDateHandler = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedDate(event.target.value as string);
  };

  return (
    <section css={find__order__history__wrap}>
      <div css={input__box}>
        <FormControl style={{ width: '90%' }}>
          <InputLabel style={{ fontSize: '30px' }}>VM 명</InputLabel>
          <Select style={{ fontSize: 30 }} value={selectedVm} onChange={handleVmSelect}>
            {vmList.map((vm, idx) => (
              <MenuItem style={{ fontSize: 30, marginTop: '10px' }} key={idx} value={vm.id}>
                {vm.id} ({vm.location})
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <form css={date__picker} noValidate>
          <TextField
            style={{ width: '100%', fontSize: '30px' }}
            label="주문날짜"
            type="date"
            InputProps={{ style: { fontSize: 30 } }}
            InputLabelProps={{
              shrink: true,
              style: { fontSize: 30 },
            }}
            onChange={setDateHandler}
          />
        </form>
        <div css={total__bev__num}>
          총{' '}
          {numOfItems > 0 &&
            numOfItems - numOfRefundesItems.success - numOfRefundesItems.failed - numOfRefundesItems.pending}
          <FontAwesomeIcon icon={faCoffee} /> 판매 되었습니다
          {numOfRefundesItems.success > 0 && `/ 환불 ${numOfRefundesItems.success} `}
          {numOfRefundesItems.pending > 0 && `/ 처리중 ${numOfRefundesItems.pending} `}
          {numOfRefundesItems.failed > 0 && `/ 환불실패 ${numOfRefundesItems.failed} `}
        </div>
        <TableContainer style={{ width: '90%', fontSize: '30px' }} component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell />
                <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>시간</TableCell>
                <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>메뉴</TableCell>
                <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }} align="right">
                  상태
                </TableCell>
                <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }} align="right">
                  전화번호
                </TableCell>
                <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }} align="right">
                  결제금액
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, idx) => (
                <OrderRow
                  key={idx}
                  row={row}
                  setRefundOpen={setRefundOpen}
                  refundOpen={refundOpen}
                  refundedOrders={refundedOrders}
                  selectedVm={selectedVm}
                />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </section>
  );
}

const find__order__history__wrap = css`
  width: 100vw;
  height: calc(100vh - 360px);
  font-family: Spoqa Han Sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 24px;
  background-color: #f8f7f5;
  overflow: scroll;
`;

const total__bev__num = css`
  font-family: Spoqa Han Sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 32px;
  margin-bottom: 20px;
`;
const input__box = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 46px;
  margin-top: 100px;
`;

const date__picker = css`
  display: 'flex';
  flex-wrap: 'wrap';
  margin-top: 30px;
  width: 90%;
  margin-bottom: 50px;
`;
export default FindOrderHistory;
