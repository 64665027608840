export enum ChargeType {
  corp = 'CORP_WEB',
  admin = 'ADMIN_WEB',
  cardKiosk = 'CARD_KIOSK',
  paycoKisok = 'PAYCO_KIOSK',
  franchisee = 'FRANCHISEE',
}

export interface Charge {
  point: {
    bonus: number;
    discount: number;
    total: number;
    price: number;
  };
  timestamp: Date;
}

export interface CorpWebCharge extends Charge {
  type: ChargeType.corp;
  corpAdminId: string;
  corpName: string;
}

export interface AdminWebCharge extends Charge {
  type: ChargeType.admin;
  adminId: string;
}

export interface FranchiseeCharge extends Charge {
  type: ChargeType.franchisee;
  adminId: string;
}
