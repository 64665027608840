import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers';
import { Container, ContainerStatus } from '@definitions/container';
import { Avatar, Card, Flex, Text } from '@fluentui/react-northstar';
import Input from '@material-ui/core/Input';
import { firestore } from 'app/common/firebase';
import { MountPosition } from '@definitions/recipes';
import { format } from 'app/utils/sales';
import { orderObj } from 'app/utils/orderHistory/getOrderHistoryObj';

function ContainerCheck(): JSX.Element {
  // const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const ingredients = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);

  const [selectedContainerId, setSelectedContainerId] = useState('00');
  const [containerHisotry, setContainerHistory] = useState([]);
  const containers: Container[] = useSelector(
    ({ firestore: { ordered } }: RootStateType) => ordered['version/v3/containers'],
  );

  const containerHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setSelectedContainerId(event.target.value);
  };

  const getSales = async (vmId: string, year: string, month: number) => {
    console.log(`vmId: ${vmId}, year:${year}, month:${month}`);
    const snapshot = await firestore
      .collection(`version/v3/vms/${vmId}/orders/${year}/${month < 10 ? `0${month}` : month}`)
      .get();

    let membershiptCnt = 0;

    console.log(`length: ${snapshot.size}, membershipCnt: ${membershiptCnt}`);
    snapshot.forEach((row) => {
      const order = row.data();
      const docId = row.id;
      const { session } = order;
      const curVesrion: string = (session && session.version) || '0.0.0';
      const result = orderObj[curVesrion](order);
      // 시간,장소, 승인방법, 승인번호,카드번호, 메뉴, 옵션,가격,총가격, 쿠폰사용여부,쿠폰사용갯수, admin번호주문
      const {
        timeStamp,
        items,
        paymentWayUserId,
        isCouponUsed,
        phoneNum,
        infoForRefunds,
        paymentWay,
        totalPrice,
        usedCouponNum,
        companyName,
        couponPrice,
      } = result;
      if (phoneNum !== '미가입주문') {
        membershiptCnt++;
        const recipeNames = items.reduce((names, { recipeName }) => {
          return `${names}${recipeName}, `;
        }, '');
        console.log(`id: ${docId}, ${phoneNum}: ${recipeNames}`);
      }
    });
    console.log(` length: ${snapshot.size}, membershipCnt: ${membershiptCnt}`);
  };

  useEffect(() => {
    getSales('BETA0001', '2021', 7).then(() => console.log('done'));
  }, []);

  // useEffect(() => {
  //   const getContainerHistory = async (containerId: string) => {
  //     const snapshot = await firestore.collection(`/version/v3/containers/${containerId}/history/`).get();
  //     const history = snapshot.docs.map((doc) => doc.exists && doc.data());
  //     const [a, b] = history.sort((left, right) => right.timeStamp - left.timeStamp);
  //     if (true) {
  //       const orderHistorySnapshot = await firestore
  //         .collection(`/version/v3/vms/${a.mount}/orders/2021/06/`)
  //         .where('timeStamp', '>', new Date('2021-06-04T07:25'))
  //         // .where('timeStamp', '>', new Date('2021-06-08T12:56'))
  //         .where('timeStamp', '<', new Date('2021-06-10T19:23'))
  //         // .where('timeStamp', '>', new Date('2021-06-11T06:08'))
  //         // .where('timeStamp', '<', new Date('2021-06-08T12:57'))
  //         .get();

  //       const orderHistory = orderHistorySnapshot.docs.map((doc) => doc.exists && doc.data());
  //       let amount = 0;
  //       let cnt = 0;
  //       orderHistory.map((history) => {
  //         const { order, timeStamp } = history;

  //         if (order) {
  //           order.items.forEach((item) => {
  //             // if (
  //             //   item.recipeName === '카페라떼' ||
  //             //   item.recipeName === '할리데이모카' ||
  //             //   item.recipeName === '바닐라라떼' ||
  //             //   item.recipeName === '바닐라빈우유' ||
  //             //   item.recipeName === '리치초콜릿'
  //             // ) {
  //             //   cnt++;
  //             // }

  //             item.combination.filter((comb) => {
  //               if (comb.ingredientId === 'milk' && comb.mountPosition === MountPosition.milk1) {
  //                 // console.log(comb);
  //                 // console.log(timeStamp.toDate());
  //                 if (order.payment.status.msg.successMsg) {
  //                   amount += comb.amount;
  //                   cnt++;
  //                 }
  //               }
  //             });
  //             // item.customCombination &&
  //             //   item.customCombination.forEach((ccomb) => {
  //             //     if (ccomb.ingredientId === 'milk' && ccomb.mountPosition === MountPosition.milk1) {
  //             //       // console.log(comb);
  //             //       console.log(timeStamp.toDate());

  //             //       amount += ccomb.amount;
  //             //       cnt++;
  //             //     }
  //             //   });
  //           });
  //         }
  //       });
  //       console.log(amount);
  //       console.log(cnt);
  //     }

  //     setContainerHistory(history);
  //   };

  //   const isFound = containers.find(({ id }) => id === selectedContainerId) !== undefined;
  //   if (isFound) {
  //     getContainerHistory(selectedContainerId);
  //   } else {
  //     setContainerHistory([]);
  //   }
  // }, [selectedContainerId]);

  return (
    <div css={container__wrap}>
      <h1>컨테이너 ID로 검색(개발중)</h1>
      {/* <Input
        onChange={containerHandler}
        id="standard-basic"
        defaultValue="00"
        type="string"
        style={{
          width: '600px',
          height: '80px',
          fontSize: '46px',
          backgroundColor: '#FFFFFF',
          marginLeft: '36px',
        }}
        inputProps={{ maxLength: 13 }}
      /> */}

      {/* {containers
        .filter((container) => {
          return container.id === selectedContainerId;
        })
        .map((container) => {
          // const snapshot = await firestore.collection(`/version/v3/containers/${container.id}/history/`).get();
          // snapshot.docs.map((doc) => doc.exists && console.log(doc.data()));
          if (container.status === ContainerStatus.empty) {
            return <div>비워져 있는 케그입니다.</div>;
          } else if (container.status === ContainerStatus.deprecated) {
            return <div>삭제 처리된 케그입니다.</div>;
          }
          return (
            <Card size="medium" key={container.id}>
              <Card.Header style={{ marginBottom: '15px' }} key={container.id} fitted>
                <Flex gap="gap.small">
                  <Avatar
                    image={ingredients[container.ingredientId].image}
                    label="ingredients"
                    name={container.ingredientId}
                    status={container.amount < 500 ? (container.amount < 300 ? 'error' : 'warning') : 'success'}
                  />
                  <Flex column>
                    <Text content={container.ingredientId} size="medium" weight="semibold" />
                    <Text
                      color={container.amount < 500 ? (container.amount < 300 ? 'red' : 'orange') : 'black'}
                      content={`재고 : ${container.amount}ml`}
                    />
                  </Flex>
                </Flex>
              </Card.Header>
              {containerHisotry
                .sort((left, right) => left.timeStamp - right.timeStamp)
                .map((history) => {
                  return <div key={history.timeStamp}>{history.type}</div>;
                })}
            </Card>
          );
        })} */}
    </div>
  );
}

const container__wrap = css`
  display: flex;
  flex-direction: column;
`;
export default ContainerCheck;
