/** @jsx jsx */
import { css, jsx, keyframes } from '@emotion/core';
import waveHigh from '@resources/backgrounds/wave-b-2.svg';
import waveMidHigh from '@resources/backgrounds/wave-b-1.svg';
import waveMidLow from '@resources/backgrounds/wave-a-2.svg';
import waveLow from '@resources/backgrounds/wave-a-1.svg';

function BackgroundWave(): JSX.Element {
  return (
    <div css={container}>
      <svg css={waveUpper} width="6400" height="607" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="Pattern" x="0" y="0" width="0.25" height="1">
            <image x="0" y="0" width="1600" height="607" xlinkHref={waveHigh} />
          </pattern>
        </defs>
        <rect fill="url(#Pattern)" width="6400" height="607" />
      </svg>
      <svg css={waveMidUpper} width="6400" height="607" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="Pattern" x="0" y="0" width="0.25" height="1">
            <image x="0" y="0" width="1600" height="607" xlinkHref={waveMidHigh} />
          </pattern>
        </defs>
        <rect fill="url(#Pattern)" width="6400" height="607" />
      </svg>
      <svg css={waveMidLower} width="6400" height="284" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="Pattern" x="0" y="0" width="0.25" height="1">
            <image x="0" y="0" width="1600" height="284" xlinkHref={waveMidLow} />
          </pattern>
        </defs>
        <rect fill="url(#Pattern)" width="6400" height="284" />
      </svg>
      <svg css={waveLower} width="6400" height="284" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <pattern id="Pattern" x="0" y="0" width="0.25" height="1">
            <image x="0" y="0" width="1600" height="284" xlinkHref={waveLow} />
          </pattern>
        </defs>
        <rect fill="url(#Pattern)" width="6400" height="284" />
      </svg>
    </div>
  );
}

const container = css`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background-color: #f8f7f5;
  overflow: hidden;
  z-index: -1;
`;

const moveWave = keyframes`
    0% {
        transform: translateX(0) translateZ(-1px) translateY(40px);
    }
    25% {
        transform: translateX(400px) translateZ(-1px) translateY(0);
    }
    50% {
        transform: translateX(800px) translateZ(-1px) translateY(40px);
    }
    75% {
        transform: translateX(1200px) translateZ(-1px) translateY(0);
    }
    100% {
        transform: translateX(1600px) translateZ(-1px) translateY(40px);
    }
`;

const moveWaveOpposed = keyframes`
    0% {
        transform: translateX(0) translateZ(-1px) translateY(0);
    }
    25% {
        transform: translateX(400px) translateZ(-1px) translateY(80px);
    }
    50% {
        transform: translateX(800px) translateZ(-1px) translateY(0px);
    }
    75% {
        transform: translateX(1200px) translateZ(-1px) translateY(80px);
    }
    100% {
        transform: translateX(1600px) translateZ(-1px) translateY(0px);
    }
`;

const waveLower = css`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
  animation: ${moveWave} 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  mix-blend-mode: multiply;
  will-change: transform;
`;

const waveMidLower = css`
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: -1;
  animation: ${moveWaveOpposed} 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  mix-blend-mode: multiply;
  will-change: transform;
`;
const waveMidUpper = css`
  position: absolute;
  bottom: -130px;
  right: 0px;
  z-index: -1;
  animation: ${moveWave} 18s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  mix-blend-mode: multiply;
  will-change: transform;
`;
const waveUpper = css`
  position: absolute;
  bottom: -130px;
  right: 0px;
  z-index: -1;
  animation: ${moveWaveOpposed} 22s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
  mix-blend-mode: multiply;
  will-change: transform;
`;

// const waveLower = css`
//   background: url(${waveLow}) repeat-x;
//   mix-blend-mode: multiply;
//   position: absolute;
//   bottom: 0px;
//   left: 0px;
//   width: 6400px;
//   height: 284px;
//   animation: ${moveWave} 30s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
// `;

// const waveMidLower = css`
//   background: url(${waveMid}) repeat-x;
//   mix-blend-mode: multiply;
//   position: absolute;
//   bottom: 0px;
//   left: -10px;
//   width: 6400px;
//   height: 284px;
//   animation: ${moveWaveOpposed} 20s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
// `;

// const waveMidUpper = css`
//   background: url(${waveHigh}) repeat-x;
//   position: absolute;
//   mix-blend-mode: multiply;
//   bottom: -130px;
//   left: 0px;
//   width: 6400px;
//   height: 607px;
//   animation: ${moveWave} 18s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
//   opacity: 1;
// `;

// const waveUpper = css`
//   background: url(${waveHigh}) repeat-x;
//   position: absolute;
//   mix-blend-mode: multiply;
//   bottom: -120px;
//   left: -180px;
//   width: 6400px;
//   height: 607px;
//   animation: ${moveWaveOpposed} 22s cubic-bezier(0.36, 0.45, 0.63, 0.53) infinite;
//   opacity: 1;
// `;

export default BackgroundWave;
