/* eslint-disable curly */
// 외부모듈
import React, { useRef, useState } from 'react';
import { css } from '@emotion/core';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Autocomplete from '@material-ui/lab/Autocomplete';

interface SearchBarProps {
  data: { [key: string]: any };
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => void;
  onChange?: (e: React.ChangeEvent<any>, value: string) => void;
}

function SearchBar({ data, onChange, onClick }: SearchBarProps) {
  if (!data) {
    return null;
  }

  const inputRef = useRef(null);
  const dataList = Object.keys(data);
  const [searchData, setSearchData] = useState('');
  const isCreate = searchData !== '' && !data[searchData];

  const onChangeHandler = (e: React.ChangeEvent<any>, value: string) => {
    setSearchData(value);

    if (typeof onChange === 'function') onChange(e, value);
  };

  const onClickHandler = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (typeof onClick === 'function') {
      onClick(e, searchData);
    }
  };

  return (
    <section css={search__wrap}>
      <Autocomplete
        id="combo-box-demo"
        options={dataList}
        // freeSolo
        value={searchData === '' ? null : searchData}
        getOptionLabel={(data) => data}
        noOptionsText="해당 값이 존재하지 않습니다."
        style={{ width: '35vw' }}
        onInputChange={(e, value) => onChangeHandler(e, value)}
        renderInput={(params) => (
          <TextField
            {...params}
            label="생성/관리 할 이름 또는 Vm을 입력해주세요"
            inputRef={(input) => {
              inputRef.current = input;
            }}
          />
        )}
      />
      {isCreate && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px', marginLeft: '15px' }}
          onClick={onClickHandler}
        >
          생성
        </Button>
      )}
    </section>
  );
}

const search__wrap = css`
  margin-top: 30px;
  display: flex;
`;

export default SearchBar;
