import { AUTH_ACTIONS, COUPON_ACTIONS, SessionHistory } from '@definitions/session';
import {
  isNonPaymentOrder,
  isAdminOrder,
  getFinalPrice,
  isCouponMembersSession,
  getSuccessNumber,
  getCardNumber,
  getCompanyNameFromSession,
  getCouponPrice,
} from '../getSessionInfo';

export const get111Order = (obj: any) => {
  const { order, steps, timeStamp, session } = obj;
  const { items } = order;
  const { payment } = order;
  const { way: paymentWay } = payment;

  let signInNumber;
  let membersCouponNum;

  const isSignInMembersOrder =
    session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS).length > 0;
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isCouponMembersOrder = isCouponMembersSession(session);

  const finalPrice = !isNonPaymentSession && !isAdminSession && getFinalPrice(session, isCouponMembersOrder);
  const totalPrice = isNonPaymentSession ? (isAdminSession ? 'admin' : 0) : finalPrice;

  if (isCouponMembersOrder) {
    membersCouponNum = session.history.filter(
      (history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON,
    )[0].payload.selectedCouponIdList.length;
  }

  if (isSignInMembersOrder) {
    signInNumber = session.history.filter(
      (history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS,
    )[0].payload.userData.phoneNum;
  }
  const companyName = getCompanyNameFromSession(session);
  const isCouponUsed = isCouponMembersOrder ? '사용O' : '사용X';
  const usedCouponNum = isCouponMembersOrder ? membersCouponNum : 0;
  const infoForRefunds = getSuccessNumber(session, payment);
  const paymentWayUserId = getCardNumber(session, payment);
  const phoneNum = isAdminSession ? '관리자주문' : !isSignInMembersOrder ? '미가입주문' : signInNumber;
  const couponPrice = getCouponPrice(session);

  return {
    items,
    steps,
    timeStamp,
    infoForRefunds,
    paymentWayUserId,
    isCouponUsed,
    totalPrice,
    usedCouponNum,
    phoneNum,
    paymentWay,
    companyName,
    couponPrice,
  };
};
