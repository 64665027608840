import { css } from '@emotion/core';
import { Button, Dialog, DialogTitle } from '@material-ui/core';
import React from 'react';

export interface SimpleDialogProps {
  open: boolean;
  content: string;
  onClose: (response: string) => void;
}

export default function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, open, content } = props;

  const handleClose = () => {
    onClose('CANCEL');
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>{content}</DialogTitle>
      <div css={dialog__bottom}>
        <Button variant="outlined" onClick={() => onClose('CANCEL')}>
          취소
        </Button>
        <Button variant="contained" onClick={() => onClose('RESUME')}>
          계속
        </Button>
      </div>
    </Dialog>
  );
}

const dialog__bottom = css`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
`;
