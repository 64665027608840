// 내부모듈
import { Admin } from '@definitions/admins';
import { Charge, ChargeType } from '@definitions/charge';

export const createChargeData = (auth: Admin, uid: string, point: number) => {
  const common: Charge = {
    point: {
      bonus: 0,
      discount: 0,
      total: point,
      price: point,
    },
    timestamp: new Date(),
  };

  switch (auth.type) {
    case 'CORP':
      return { ...common, type: ChargeType.corp, corpAdminId: uid, corpName: auth.name };
    case 'PLANZ':
      return { ...common, type: ChargeType.admin, adminId: uid, adminName: auth.name };
    case 'FRANCHISEE':
      return { ...common, type: ChargeType.corp, adminId: uid, adminName: auth.name };
    default:
      return { ...common, type: 'unknow', adminId: uid, adminName: auth.name };
  }
};
