//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send } from '@material-ui/icons';
import { Alert, AlertTitle, Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { RecipeType } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';
import RecipesOrderControl from './RecipeOrderControl';
import { RecipeOrder } from './RecipeOrderRaw';
import SelectVm from './SelectVm';
import { firestore } from 'app/common/firebase';
import Progress from './Progress';
import ConfirmModal from './confirmModal';

export function getAvailableRecipes(curVm: string, recipes: RecipeType[]) {
  const vmRecipes = recipes.filter(
    ({ targetLocations }) => !targetLocations || (targetLocations && targetLocations.includes(curVm)),
  );

  return vmRecipes;
}

function RecipesOrder() {
  const vms: VendingMachine[] = useSelector((state: RootStateType) => state.firestore.data['version/v3/vms']);
  const recipes = useSelector((state: RootStateType) => state.firestore.data['version/v3/recipes']);
  const [curVm, setCurVm] = useState('');
  const [vmRecipes, setVmRecipes] = useState<RecipeType[]>([]);
  const [vmRecipeOrder, setVmRecipeOrder] = useState<RecipeOrder[]>([]);
  const [editedRecipeOrder, setEditedRecipeOrder] = useState<RecipeOrder[]>([]);
  const [progress, setProgress] = useState(false);
  const [success, setSuccess] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);

  const onClickHandler = (vm: string) => {
    setCurVm(vm);
  };

  const initHandler = () => {
    setCurVm('');
    setVmRecipeOrder([]);
    setEditedRecipeOrder([]);
    setVmRecipes([]);
  };

  const orderEditHandler = (type: string, recipeOrderInfo?: RecipeOrder, idx?: number) => {
    if (type === 'edit') {
      //recipeOrder 수정했을때
      setEditedRecipeOrder((prevState) => {
        return prevState.map((el, curIndex) => {
          if (curIndex === idx) {
            return recipeOrderInfo;
          } else {
            return el;
          }
        });
      });
    } else if (type === 'deleteCancel') {
      //recipeOrder 삭제 등록을 취소할때

      setEditedRecipeOrder((prevState) => {
        return prevState.map((el, curIndex) => {
          if (curIndex === idx) {
            return { ...el, beDeleted: false };
          } else {
            return el;
          }
        });
      });
    } else if (type === 'delete') {
      // recipeOrder 삭제등록 할때

      setEditedRecipeOrder((prevState) => {
        return prevState.map((el) => {
          if (el.checked) {
            return { ...el, checked: false, beDeleted: true };
          } else {
            return el;
          }
        });
      });
    } else if (type === 'addCancel') {
      setEditedRecipeOrder((prevState) => prevState.filter((_, index) => index !== idx));
    }
  };

  const checkHandler = (idx: number) => {
    setEditedRecipeOrder((prevState) => {
      return prevState.map((el, curIndex) => {
        if (curIndex === idx) {
          return { ...el, checked: !el.checked };
        } else {
          return el;
        }
      });
    });
  };
  const allCheckHandler = () => {
    setEditedRecipeOrder((prevState) => {
      const checked = prevState.findIndex(({ checked }, curIndex) => {
        if (curIndex > vmRecipeOrder.length - 1) {
          return false;
        } else {
          return !checked;
        }
      });
      return prevState.map((el, curIndex) => {
        if (vmRecipeOrder.length - 1 >= curIndex) {
          if (checked === -1) {
            return { ...el, checked: false };
          } else {
            return { ...el, checked: true };
          }
        } else {
          return el;
        }
      });
    });
  };

  const orderAddHandler = (recipeOrderInfo: RecipeOrder, idx: number) => {
    setEditedRecipeOrder((prevState) => [...prevState, recipeOrderInfo]);
  };

  const submitHandler = async () => {
    setProgress(true);
    const result = Object();
    editedRecipeOrder.forEach(({ recipeName, recipeOrder, beDeleted }) => {
      if (!beDeleted) {
        result[recipeName] = recipeOrder;
      }
    });

    const vmRef = firestore.doc(`/version/v3/vms/${curVm}`);
    const vmDoc = await vmRef.get();
    const curVmInfo = vmDoc.data();

    await vmRef.update({ recipeOrder: result });

    const sortedRecipeOrders = editedRecipeOrder
      .sort((recipeA: RecipeOrder, recipeB: RecipeOrder) => {
        return recipeA.recipeOrder - recipeB.recipeOrder;
      })
      .filter(({ beDeleted }) => !beDeleted)
      .map((el) => ({ ...el, checked: false, beDeleted: false }));

    setVmRecipeOrder(sortedRecipeOrders);
    setEditedRecipeOrder(sortedRecipeOrders);

    setSuccess(true);
  };

  const successInitHandler = () => {
    setSuccess(false);
    setProgress(false);
  };

  const confirmOpenHandler = () => {
    const emptyData = editedRecipeOrder.findIndex(({ recipeName, recipeOrder }) => {
      return isNaN(recipeOrder);
    });
    if (emptyData !== -1) {
      setOpenAlert(true);
    } else {
      setOpenConfirm(true);
    }
  };

  const confirmCloseHandler = () => {
    setOpenConfirm(false);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenAlert(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [openAlert]);

  useEffect(() => {
    const recipeOrders = curVm === '' ? [] : vms[curVm].recipeOrder;
    const availableRecipes =
      curVm === '' || recipes.length < 1 ? [] : getAvailableRecipes(curVm, Object.values(recipes));

    setVmRecipes(availableRecipes);
    const recipeOrderInfo = Object.entries(recipeOrders).map((el) => {
      return {
        recipeName: el[0],
        recipeOrder: el[1],
        checked: false,
        beDeleted: false,
      };
    }) as RecipeOrder[];

    const sortedRecipeOrders = recipeOrderInfo.sort((recipeA: RecipeOrder, recipeB: RecipeOrder) => {
      return recipeA.recipeOrder - recipeB.recipeOrder;
    });
    setVmRecipeOrder(sortedRecipeOrders);
    setEditedRecipeOrder(sortedRecipeOrders);
  }, [curVm]);

  return (
    <div css={container}>
      <div css={header}>
        <h1>레시피 순서 변경</h1>
      </div>

      {openAlert && (
        <Alert variant="filled" onClose={() => setOpenAlert(false)} css={alert} severity="error">
          <AlertTitle>Warning</AlertTitle>
          빈칸을 모두 채워주세요!
        </Alert>
      )}
      <ConfirmModal openConfirm={openConfirm} closeHandler={confirmCloseHandler} submitHandler={submitHandler} />
      <Progress progress={progress} success={success} successInitHandler={successInitHandler} />
      <div style={{ display: 'flex', width: '820px', justifyContent: 'space-between' }}>
        <SelectVm data={vms} onClickHandler={onClickHandler} initHandler={initHandler} />
        <Button
          style={{ width: '200px', height: '40px', marginLeft: '20px', marginTop: '38px' }}
          variant="contained"
          color="primary"
          startIcon={<Send />}
          onClick={confirmOpenHandler}
          disabled={vmRecipeOrder.length < 1 && editedRecipeOrder.length < 1}
        >
          변경사항 적용
        </Button>
      </div>
      <RecipesOrderControl
        originalData={vmRecipeOrder}
        editedData={editedRecipeOrder}
        orderEditHandler={orderEditHandler}
        orderAddHandler={orderAddHandler}
        submitHandler={submitHandler}
        checkHandler={checkHandler}
        allCheckHandler={allCheckHandler}
        vmRecipes={vmRecipes}
      />
    </div>
  );
}
export default RecipesOrder;

const container = css`
  padding: 10px 30px;
  width: 100%;
  height: 90vh;
`;

const header = css`
  display: flex;
  align-items: center;
`;
const alert = css`
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateX(-50%);
  transform: translateY(-50%);
  width: 300px;
  height: 80px;
  z-index: 999;
  /* display: flex;
  align-items: center; */
`;
