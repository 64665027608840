import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TextField from '@material-ui/core/TextField';
import { CombinationTarget } from '@definitions/benefits';
import css from '@emotion/css';

interface CombinationTableProps {
  data: CombinationTarget;
  amountHandler: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => void;
  priorityHanlder: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, id: string) => void;
}

function CombinationTable({ data, amountHandler, priorityHanlder }: CombinationTableProps) {
  const { combination, id, priority } = data;
  const { iced, hot } = combination;

  return (
    <div css={combination__table__wrap}>
      <h1 css={combination__recipe__name}>{id}</h1>
      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Temp</TableCell>
              <TableCell>IngredientId</TableCell>
              <TableCell align="center">Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {iced &&
              iced.map((row, i) => (
                <TableRow key={i}>
                  <TableCell style={{ fontWeight: 'bold', color: '#2F3F9F' }}>Iced</TableCell>
                  <TableCell component="th" scope="row">
                    {row.ingredientId}
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      name={`${row.ingredientId}/iced`}
                      value={row.amount}
                      onChange={(e) => amountHandler(e, id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
            {hot &&
              hot.map((row, i) => (
                <TableRow key={i}>
                  <TableCell style={{ fontWeight: 'bold', color: 'red' }}>Hot</TableCell>
                  <TableCell component="th" scope="row">
                    {row.ingredientId}
                  </TableCell>
                  <TableCell align="center">
                    <TextField
                      name={`${row.ingredientId}/hot`}
                      value={row.amount}
                      onChange={(e) => amountHandler(e, id)}
                    />
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div css={combination__priority__input}>
        <TextField name="priority" label="우선순위" value={priority} onChange={(e) => priorityHanlder(e, id)} />
      </div>
    </div>
  );
}

const combination__table__wrap = css`
  margin-top: 20px;
`;

const combination__recipe__name = css`
  font-size: 18px;
  font-weight: bold;
`;

const combination__priority__input = css`
  margin-top: 10px;
`;
export default CombinationTable;
