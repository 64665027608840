/* eslint-disable curly */
// 외부모듈
import React, { useState } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

// 내부모듈
import VMControl from './VMControl';
import MembersControl from './MembersControl';
import { ControlType } from '@constants/benefits';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

function BenefitControl() {
  const [tabsIndex, setTabsIndex] = useState(ControlType.vmControl);

  const handleChangeIndex = (event: React.ChangeEvent<any>, newValue: ControlType) => {
    setTabsIndex(newValue);
  };

  return (
    <div>
      <Tabs value={tabsIndex} onChange={handleChangeIndex} indicatorColor="primary" textColor="primary">
        <Tab label="Vm" />
        <Tab label="Members" />
      </Tabs>
      <TabPanel value={tabsIndex} index={ControlType.vmControl}>
        <VMControl />
      </TabPanel>
      <TabPanel value={tabsIndex} index={ControlType.membersControl}>
        <MembersControl />
      </TabPanel>
    </div>
  );
}

export default React.memo(BenefitControl);
