// 외부모듈
import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import { Box } from '@material-ui/core';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';

// 내부모듈
import { RootStateType } from '@reducers/index';
import { Benefit, BenefitType } from '@definitions/benefits';
import { RecipeType } from '@definitions/recipes';
import PriceInputs from './PriceInputs';
import { compareFunction, intersection, not, union } from '@utils/controlDataUtils';
import CustomList from './CustomList';
import PreViewModal from './preViewModal';

interface PriceControllerProps {
  onChange: any;
  data: { benefits: Benefit[] };
  controlType?: 'VM' | 'MEMBERS';
}

const defaultData = { benefits: [] };

export default function PriceController({ onChange, data = defaultData, controlType }: PriceControllerProps) {
  const { benefits } = data;

  const selectedBenefit = benefits.find((benefit) => benefit.benefitType === 'price');
  const targets = selectedBenefit ? selectedBenefit.targets : [];

  const recipes: { [key: string]: RecipeType } =
    useSelector((state: RootStateType) => state.firestore.data['version/v3/recipes']) || {};
  const recipesList = Object.values(recipes)
    .map((value) => ({ id: value.recipeId, price: value.price, conditions: [], priority: 10 }))
    .filter((recipe) => targets.findIndex((item: any) => item.id === recipe.id) === -1)
    .sort(compareFunction);

  const [checked, setChecked] = useState<any[]>([]);
  const [left, setLeft] = useState<any[]>(recipesList);
  const [right, setRight] = useState<any[]>(targets);

  useEffect(() => {
    onChange(right);
  }, [right]);

  const leftChecked = intersection(checked, left);
  const rightChecked = intersection(checked, right);

  const toggleHandler = (value: any) => () => {
    const currentIndex = checked.findIndex((check) => check.id === value.id);
    const newChecked = [...checked];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    setChecked(newChecked);
  };

  const numberOfChecked = (items: number[]) => intersection(checked, items).length;

  const toggleAllHandler = (items: number[]) => () => {
    if (numberOfChecked(items) === items.length) {
      setChecked(not(checked, items));
    } else {
      setChecked(union(checked, items));
    }
  };

  const checkedRightHandler = () => {
    setRight(right.concat(leftChecked).sort(compareFunction));
    setLeft(not(left, leftChecked).sort(compareFunction));
    setChecked(not(checked, leftChecked));
  };

  const checkedLeftHandler = () => {
    setLeft(left.concat(rightChecked).sort(compareFunction));
    setRight(not(right, rightChecked).sort(compareFunction));
    setChecked(not(checked, rightChecked));
  };

  const setTypePriceForm = (type: any, price: any) => {
    const recipeType = type.slice(type.indexOf('_') + 1);
    switch (recipeType) {
      case 'iced':
        return { type, iced: price.iced || 0 };
      case 'hot':
        return { type, hot: price.hot || 0 };
      case 'both':
        return { type, iced: price.iced || 0, hot: price.hot || 0 };
      case 'none':
        return { type };
      default:
        return { type };
    }
  };

  const setConditionForm = (type: string) => {
    switch (type) {
      case 'termDiscountByGroup':
        return [{ type: 'termDiscountByGroup', count: 1, termOfDay: 1 }];
      default:
        return [];
    }
  };

  const priceInputHandler = (id: string, e: React.ChangeEvent<{ value: unknown; name: string }>) => {
    const pathDivide = e.target.name.split('/');
    if (pathDivide.length === 1) {
      const updateChecked = checked.map((check) => {
        if (check.id === id) {
          return {
            ...check,
            // 삼항연산자로 데이터 체킹이 좋은 방법은 아님... 사가쪽에서 데이터 돌면서 체크해야할듯..
            [e.target.name]: pathDivide[0] === 'priority' ? Number(e.target.value) : e.target.value,
          };
        } else {
          return check;
        }
      });
      setChecked(updateChecked);
    } else {
      const updateChecked = checked.map((check) => {
        if (check.id === id) {
          if (pathDivide[0] === 'condition') {
            return {
              ...check,
              conditions: [{ ...check.conditions[0], [pathDivide[1]]: Number(e.target.value) }],
            };
          } else {
            return {
              ...check,
              price: {
                ...check.price,
                [pathDivide[0]]: { ...check.price[pathDivide[0]], [pathDivide[1]]: Number(e.target.value) },
              },
            };
          }
        } else {
          return check;
        }
      });
      setChecked(updateChecked);
    }
  };

  // 이벤트 위임으로 개편해야 합니다!!
  const chargeSelectHandler = (id: string, e: React.ChangeEvent<{ value: unknown; name?: string | undefined }>) => {
    const { name, value } = e.target;
    const updateChecked = checked.map((check) => {
      if (check.id === id && name) {
        if (name === 'sizeCharge' || name === 'concnCharge') {
          return {
            ...check,
            price: { ...check.price, [name]: setTypePriceForm(value, check.price) },
          };
        } else if (name === 'condition') {
          return { ...check, conditions: setConditionForm(value as string) };
        }
      } else {
        return check;
      }
    });
    setChecked(updateChecked);
  };

  return (
    <section css={card__style}>
      <Grid container spacing={2} alignItems="center">
        <Grid item>
          <CustomList
            items={left}
            title="적용가능한 Recipes"
            toggleAllHandler={toggleAllHandler}
            numberOfChecked={numberOfChecked}
            toggleHandler={toggleHandler}
            checked={checked}
          />
        </Grid>
        <Grid item>
          <Grid container direction="column" alignItems="center">
            <Button
              variant="outlined"
              size="small"
              onClick={checkedRightHandler}
              disabled={leftChecked.length === 0}
              aria-label="move selected right"
            >
              &gt;
            </Button>
            <Button
              variant="outlined"
              size="small"
              onClick={checkedLeftHandler}
              disabled={rightChecked.length === 0}
              aria-label="move selected left"
            >
              &lt;
            </Button>
          </Grid>
        </Grid>
        <Grid item>
          <CustomList
            items={right}
            title="적용중인 Recipes"
            toggleAllHandler={toggleAllHandler}
            numberOfChecked={numberOfChecked}
            toggleHandler={toggleHandler}
            checked={checked}
          />
        </Grid>
      </Grid>
      <Box css={selected__targets__box}>
        {checked
          .filter((check) => left.find((leftItem) => check.id === leftItem.id))
          .map((check, i) => (
            <PriceInputs
              target={check}
              key={i}
              toggleHandler={toggleHandler}
              inputHandler={priceInputHandler}
              selectHandler={chargeSelectHandler}
              checkedRightHandler={checkedRightHandler}
              controlType={controlType}
            />
          ))}
      </Box>
      <PreViewModal data={right} benefitType={BenefitType.price} />
    </section>
  );
}

const card__style = css`
  input {
    width: 80px;
    margin: 5px;
    margin-bottom: 6px;
  }
  button {
    margin-bottom: 10px;
  }
  .MuiTextField-root {
    margin: 5px;
  }
`;

const selected__targets__box = css`
  margin-top: 15px;
`;
