// 외부 모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import Input from '@material-ui/core/Input';
import { useFirestore } from 'react-redux-firebase';

// 내부 모듈
import ContainerItem from '@components/ContainerItem';
import {
  Container,
  ContainerHistoryType,
  ContainerHistoryVersion,
  ContainerStatus,
  ContainerType,
} from '@definitions/container';
import { RootStateType } from '@reducers/index';
import { hasDuplicates } from 'app/utils/arr';

function Containers(): JSX.Element {
  const [containerId, setContainerId] = useState<string>('00977-');
  const [containerType, setContainerType] = useState<ContainerType>(ContainerType.keg);
  const [selectedContainer, setSelectedContainer] = useState('00');
  const containers: Container[] = useSelector(
    ({ firestore: { ordered } }: RootStateType) => ordered['version/v3/containers'],
  );
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  const isRootAdmin = admins[uid].level === 0;
  const isOperatingAdmin = admins[uid].level === 1;
  const isPartner = admins[uid].level === 2 && admins[uid].vms;

  const containerHandler = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    console.log(event.target.value);
    setSelectedContainer(event.target.value);
  };
  // 파이어스토어
  const firestore = useFirestore();

  if (admins[uid] === undefined) {
    return <ul css={containers__wrap} />;
  }

  const onChangeContainerId = (id: string) => {
    setContainerId(id);
  };

  const onClickAddContainer = (isPrivate: boolean) => {
    if (containerId.length > 13) {
      window.alert('컨테이너 아이디는 총 13자리이하 여야 합니다.');

      return;
    }

    if (isNaN(Number(containerId.slice(6)))) {
      window.alert("'-' 뒤의 값은 모두 숫자여야 합니다.");

      return;
    }

    if (containers.filter((container) => container.id === containerId).length !== 0) {
      window.alert('이미 존재하는 컨테이너 아이디 입니다.');

      return;
    }

    const batch = firestore.batch();
    const ref = firestore.collection('version/v3/containers/').doc(containerId);
    if (isPrivate) {
      batch.set(ref, {
        type: containerType,
        status: ContainerStatus.empty,
        target: admins[uid].vms,
      });
    } else {
      batch.set(ref, {
        type: containerType,
        status: ContainerStatus.empty,
      });
    }

    batch.set(ref.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.create,
      timeStamp: new Date(),
      version: ContainerHistoryVersion,
    });
    batch.commit();

    window.alert(`${containerId}가 추가되었습니다.`);
    // 추가된 containerid만 바로보이게끔 설정함
    setSelectedContainer(containerId);
  };

  return (
    <ul css={containers__wrap}>
      <li css={container__add}>
        <div>
          <h1>컨테이너 타입</h1>
          <div css={container__add__type}>
            <input
              type="radio"
              id="keg"
              name="containerType"
              value="keg"
              onClick={() => {
                setContainerType(ContainerType.keg);
                setContainerId('00977-');
              }}
              defaultChecked
            />
            <label htmlFor="keg">커피</label>

            <input
              type="radio"
              id="bottle"
              name="containerType"
              onClick={() => {
                setContainerType(ContainerType.bottle);
                setContainerId('00855-');
              }}
              value="bottle"
            />
            <label htmlFor="bottle">시럽/우유</label>
          </div>
          <div>
            <h1>컨테이너 ID</h1>
            <div css={container__add__id}>
              <input
                type="text"
                name="ingredientId"
                value={containerId}
                maxLength={13}
                onChange={(e) => onChangeContainerId(e.target.value)}
              />
            </div>
          </div>
        </div>

        <button
          css={container__add__button}
          onClick={() => {
            onClickAddContainer(isPartner);
          }}
        >
          추가하기
        </button>
      </li>
      <li>
        <div css={containers__search__wrap}>
          <h1>컨테이너 ID로 검색</h1>
          <Input
            onChange={containerHandler}
            id="standard-basic"
            defaultValue="00"
            type="string"
            style={{
              width: '600px',
              height: '80px',
              fontSize: '46px',
              backgroundColor: '#FFFFFF',
              marginLeft: '36px',
            }}
            inputProps={{ maxLength: 13 }}
          />
        </div>
      </li>
      {containers
        .filter((container) => {
          if (isRootAdmin) {
            return container.id.includes(selectedContainer);
          } else if (isOperatingAdmin) {
            return (
              container.id.includes(selectedContainer) &&
              !container.id.includes('TESTK') &&
              !container.id.includes('TESTB')
            );
          }
          if (container.target) {
            const isDuplicated = hasDuplicates([...container.target, ...admins[uid].vms]);
            if (isDuplicated) {
              // return true;
              return true;
            }
          }
          if (container.status === 'allocated') {
            if (admins[uid].vms.includes(container.allocation)) {
              // return true;
              return container.id.includes(selectedContainer);
            }
          }
          return false;
        })
        .filter((container) => container.status !== ContainerStatus.deprecated)
        .map((container) => {
          return (
            <ContainerItem key={container.id} container={container} isPlanzAdmin={isRootAdmin || isOperatingAdmin} />
          );
        })}
    </ul>
  );
}

const containers__wrap = css`
  width: 100vw;
  height: calc(100vh - 360px);
  scrollbar-width: none;
  box-sizing: border-box;
  overflow-y: auto; /* 세로 스크롤 적용 */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Edge */
  }
`;

const containers__search__wrap = css`
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fce1ac;
  font-family: Spoqa Han Sans;

  h1 {
    font-size: 46px;
    letter-spacing: -0.3px;
    margin-bottom: 8px;
    margin-left: 24px;
  }
  input,
  label {
    margin-left: 24px;
  }
`;

const container__add = css`
  width: 100%;
  height: 480px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fce1ac;
  font-family: Spoqa Han Sans;

  div {
    margin-left: 24px;
    margin-bottom: 12px;
    h1 {
      font-size: 46px;
      letter-spacing: -0.3px;
      margin-bottom: 8px;
    }
  }
`;

const container__add__id = css`
  margin-left: 0px;
  input {
    font-size: 46px;
    width: 600px;
    height: 80px;
  }
`;

const container__add__type = css`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 0px;
  input {
    width: 80px;
    height: 40px;
  }
  label {
    font-size: 32px;
    letter-spacing: -0.3px;
  }
`;

const container__add__button = css`
  width: calc(100% - 48px);
  height: 100px;
  border: none;
  border-radius: 10px;
  background-color: #b67d56;
  color: white;
  font-size: 38px;
  align-self: center;
`;

export default Containers;
