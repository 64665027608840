import React, { useState, useEffect, useCallback } from 'react';
import TaskList from '@components/desktop/taskManager/TaskList';
import TaskForm from '@components/desktop/taskManager/TaskForm';
import { Button, Container, Typography, Box } from '@material-ui/core';
import { useFirestore } from 'react-redux-firebase';

// const TaskManagerPage = ['LIST', 'CREATE', 'UPDATE'];
enum TaskManagerPage {
  list = 'LIST',
  create = 'CREATE',
  edit = 'UPDATE',
}
export interface Task {
  id: string;
  name: string;
  tags: string;
  visible: boolean;
  urls: { name: string; type: string; url: string }[];
  author: string;
  createDate: Date;
  latestEditedDate: Date;
}

const emptyForm = {
  id: '',
  name: '',
  tags: '',
  visible: true,
  urls: [],
  author: '',
  createDate: new Date(),
  latestEditedDate: new Date(),
};

function TaskManager(): JSX.Element {
  const [curPage, setCurPage] = useState(TaskManagerPage.list);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [selectedTask, setSelectedTask] = useState<Task | null>(null);
  const [formData, setFormData] = useState<Task>(emptyForm);
  const firestore = useFirestore();
  useEffect(() => {
    firestore
      .collection('/tasks')
      .get()
      .then((snapshot) => {
        const tasks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Task[];
        setTasks(tasks);
      });
  }, []);

  const onEdit = (task: Task): void => {
    setSelectedTask(task);
    setFormData(task);
    setCurPage(TaskManagerPage.edit);
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData((prev) => ({ ...prev, [name]: checked }));
    } else {
      setFormData((prev) => ({ ...prev, [name]: value }));
    }
  };

  const onRemove = (task: Task): void => {
    console.log(task);
    const answer = window.confirm('정말 삭제 하시겠습니까?');
    if (!answer) {
      return;
    }

    console.log(task.id);
    firestore
      .doc(`/tasks/${task.id}`)
      .delete()
      .then(() => {
        setTasks(tasks.filter(({ id }) => task.id !== id));
      });

    // 서버에 새로운 task로 업데이트
  };

  const onSubmit = (task: Task) => {
    onUpdateTask(task);

    setCurPage(TaskManagerPage.list);
  };

  const onUpdateTask = (task: Task) => {
    const found = tasks.find(({ id }) => id === task.id);
    if (found) {
      console.log(task);
      setTasks(tasks.map((t) => (t.id === task.id ? task : t)));
    } else {
      setTasks([task, ...tasks]);
    }
  };

  return (
    <Container maxWidth="md" style={{ marginTop: '19px' }}>
      <Box mb={5}>
        <Typography variant="h4" gutterBottom>
          태스크 관리 페이지
        </Typography>
        {curPage === TaskManagerPage.list ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => {
              setFormData(emptyForm);
              setCurPage(TaskManagerPage.create);
            }}
          >
            새로운 태스크 생성
          </Button>
        ) : (
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              const answer = window.confirm('저장하지 않은 정보는 날아갑니다. 정말로 뒤로 돌아가시겠습니까?');
              if (!answer) {
                return;
              }

              setCurPage(TaskManagerPage.list);
            }}
          >
            뒤로 돌아가기
          </Button>
        )}
      </Box>

      {curPage === TaskManagerPage.list && <TaskList tasks={tasks} onEdit={onEdit} onRemove={onRemove} />}
      {(curPage === TaskManagerPage.create || curPage === TaskManagerPage.edit) && (
        <TaskForm formData={formData} handleChange={handleChange} onSubmit={onSubmit} />
      )}
    </Container>
  );
}

export default TaskManager;
