// 외부 모듈
import React from 'react';
import { css } from '@emotion/core';
import { Avatar, Card, Flex, Text } from '@fluentui/react-northstar';

// 내부 모듈
import { VendingMachine } from '@definitions/vm';
import { getColorForExpirationDate, getStatusColorForAmount, getColorForAmount } from '@utils/getStatusColor';

type VmAmountCardProps = {
  item: VendingMachine;
  ingredients: any;
};

function VMamountCardMobile({ item, ingredients }: VmAmountCardProps) {
  const {
    kegs,
    pumps,
    id,
    location: { name },
  } = item;
  const validKegs = kegs.filter((keg) => keg.kegId !== undefined);
  const validPumps = pumps.filter((pump) => pump.bottleId !== undefined);

  return (
    <div css={ingredient__wrap}>
      <Card style={{ width: '90vw', marginBottom: '50px' }}>
        <Text content={`${id} : ${name}`} style={{ fontSize: '52px' }} weight="semibold" />
        <Text content={`KEGS`} style={{ fontSize: '44px' }} weight="semibold" />
        {validKegs.map((keg, idx) => (
          <Card.Header style={{ marginBottom: '15px' }} key={idx} fitted>
            <Flex gap="gap.medium">
              <Avatar
                style={{ width: '100px', height: '100px' }}
                image={ingredients[keg.ingredientId].image}
                label="ingredients"
                name={keg.ingredientId}
                status={{
                  color: getStatusColorForAmount(keg),
                  size: 'larger',
                }}
              />
              <Flex column>
                <Text style={{ fontSize: '40px' }} content={keg.ingredientId} size="large" weight="semibold" />
                <Text color={getColorForAmount(keg)} content={`재고 : ${keg.amount}ml`} style={{ fontSize: '38px' }} />
                <Text
                  content={`유통기한 :${
                    keg.expirationDate === undefined ? 'N/A' : keg.expirationDate.toDate().toDateString()
                  } `}
                  style={{ fontSize: '38px' }}
                  color={getColorForExpirationDate(keg)}
                />
              </Flex>
            </Flex>
          </Card.Header>
        ))}
        <Text content={`PUMPS`} style={{ fontSize: '44px' }} weight="semibold" />
        {validPumps.map((pump, idx) => (
          <Card.Header style={{ marginBottom: '15px' }} key={idx} fitted>
            <Flex gap="gap.medium">
              <Avatar
                style={{ width: '100px', height: '100px' }}
                image={ingredients[pump.ingredientId].image}
                label="ingredients"
                name={pump.ingredientId}
                status={{
                  color: getStatusColorForAmount(pump),
                  size: 'larger',
                }}
              />
              <Flex column>
                <Text style={{ fontSize: '40px' }} content={pump.ingredientId} size="large" weight="semibold" />
                <Text
                  color={getColorForAmount(pump)}
                  content={`재고 : ${pump.amount}ml`}
                  style={{ fontSize: '38px' }}
                />
                <Text
                  content={`유통기한 :${
                    pump.expirationDate === undefined ? 'N/A' : pump.expirationDate.toDate().toDateString()
                  } `}
                  style={{ fontSize: '38px' }}
                  color={getColorForExpirationDate(pump)}
                />
              </Flex>
            </Flex>
          </Card.Header>
        ))}
      </Card>
    </div>
  );
}

const ingredient__wrap = css`
  display: flex;
  justify-content: center;
`;

export default VMamountCardMobile;
