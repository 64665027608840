import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { Task } from './desktop/taskManager';
import { useFirestore } from 'react-redux-firebase';

function Tasks(): JSX.Element {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [viewType, setViewType] = useState<'list' | 'detail'>('list');
  const [selectedTask, setSelectedTask] = useState<Task>();
  const [outerWidth, setOuterWidth] = useState<number>(0);
  const [outerHeight, setOutherHeight] = useState<number>(0);
  const firestore = useFirestore();
  useEffect(() => {
    setOuterWidth(window.outerWidth);
    setOutherHeight(window.outerHeight);
    // api 호출
    firestore
      .collection('/tasks')
      .get()
      .then((snapshot) => {
        const tasks = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() })) as Task[];
        setTasks(tasks);
      });
  }, []);

  return (
    <div css={tasks__wrap}>
      {/* List View Type */}
      {viewType === 'list' && (
        <>
          <h2 style={{ fontSize: '6vw' }}>Task List</h2>
          <div style={{ display: 'flex', marginTop: '4vw', gap: '2.5vw', flexDirection: 'column', fontSize: '4vw' }}>
            {tasks.map((task, index) => {
              if (!task.visible) {
                return null;
              }
              return (
                <li
                  onClick={() => {
                    setViewType('detail');
                    setSelectedTask(task);
                  }}
                  style={{
                    backgroundColor: '#9995952e',
                    borderRadius: '2vw',
                    border: '#00000047 solid 2px',
                    padding: '2.5vw',
                  }}
                  key={task.id}
                >
                  {task.name}
                </li>
              );
            })}
          </div>
        </>
      )}

      {/* Detail View Type */}
      {viewType === 'detail' && selectedTask && (
        <>
          <h2 style={{ fontSize: '6vw' }}>
            <p
              style={{
                display: 'flex',
                backgroundColor: '#9995952e',
                borderRadius: '2vw',
                border: '#00000047 solid 2px',
                width: '30%',
                fontSize: '4vw',
                padding: '1.5vw',
                justifyContent: 'center',
                marginBottom: '2.5vw',
              }}
              onClick={() => {
                setViewType('list');
              }}
            >
              뒤로가기
            </p>
            {selectedTask.name}
          </h2>
          <iframe
            css={iframe__wrap}
            style={{ width: outerWidth, height: outerHeight }}
            src={selectedTask.urls.find(({ type }) => type === 'text/html').url}
          />
        </>
      )}
    </div>
  );
}

const tasks__wrap = css`
  margin: 5vw;
  width: 100vw;
  height: calc(100vh - 360px);
  list-style-type: none;
  &::-webkit-scrollbar {
    width: 0; /* Y축 스크롤바의 너비를 0으로 설정 */
  }
`;

const iframe__wrap = css`
  margin-left: -5vw;
  margin-top: 2.5vw;
  transform: scale(2.5); /* 화면 크기를 2.5배로 확대 */
  transform-origin: 0 0; /* 변환의 기준점을 상단 왼쪽으로 설정 */
`;
export default Tasks;
