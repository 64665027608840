import { SH_TYPE } from '@definitions/session';
import { Item } from './order';

export enum REFUND_ACTIONS {
  WALLET_REFUND = 'WALLET_REFUND',
  CREDIT_CARD_REFUND = 'CREDIT_CARD_REFUND',
  PAYCO_BARCODE_REFUND = 'PAYCO_BARCODE_REFUND',
  NON_PAYMENT_REFUND = 'NON_PAYMENT_REFUND',
  BENEFIT_NON_PAYMENT_REFUND = 'BENEFIT_NON_PAYMENT_REFUND',
  COUPON_REFUND = 'COUPON_REFUND',
}

export interface WalletRefundSuccess {
  type: SH_TYPE.WALLET_AUTH;
  action: REFUND_ACTIONS.WALLET_REFUND;
  payload: {
    refundItem: Item[];
  };
  timeStamp: Date;
}

export interface CreditCardRefund {
  type: SH_TYPE.PAYMENT;
  action: REFUND_ACTIONS.CREDIT_CARD_REFUND;
  payload: {
    refundItem: Item[];
  };
  timeStamp: Date;
}

export interface PaycoBarcodeRefund {
  type: SH_TYPE.PAYMENT;
  action: REFUND_ACTIONS.PAYCO_BARCODE_REFUND;
  payload: {
    refundItem: Item[];
  };
  timeStamp: Date;
}

export interface NonPaymentRefund {
  type: SH_TYPE.PAYMENT;
  action: REFUND_ACTIONS.NON_PAYMENT_REFUND;
  payload: {
    refundItem: Item[];
  };
  timeStamp: Date;
}

export interface BenefitNonPaymentRefund {
  type: SH_TYPE.PAYMENT;
  action: REFUND_ACTIONS.BENEFIT_NON_PAYMENT_REFUND;
  payload: {
    refundItem: Item[];
  };
  timeStamp: Date;
}

export interface CouponRefund {
  type: SH_TYPE.PAYMENT;
  action: REFUND_ACTIONS.COUPON_REFUND;
  payload: {
    refundItem: Item[];
  };
  timeStamp: Date;
}

export type WalletRefund =
  | WalletRefundSuccess
  | CreditCardRefund
  | PaycoBarcodeRefund
  | NonPaymentRefund
  | BenefitNonPaymentRefund
  | CouponRefund;
