import {
  AuthSignOut,
  AUTH_ACTIONS,
  AuthAdminSignInSuccess,
  AuthAdminSignOut,
  PAYMENT_ACTIONS,
  COUPON_ACTIONS,
  QUEUE_ACTIONS,
  SessionHistory,
  ORDER_ACTIONS,
  SH_TYPE,
  CUSTOM_RECIPE_ACTIONS,
  SetCustomRecipeOrder,
  Session,
  SetWalletBalancePayment,
  SetWalletPaymentResult,
  CouponUse,
} from '@definitions/session';
import { PaymentType, PaymentWay } from '@definitions/payment';
import { WalletUserSignInSuccess, WALLET_ACTIONS, WalletSelected } from '@definitions/walletAuth';
import { latest } from 'immer/dist/internal';
import { REFUND_ACTIONS, WalletRefundSuccess } from '@definitions/walletRefund';

export const isAdminOrder = (session) => {
  const getAdminSignOutHistory = session.history.filter(
    (historySession) => historySession.action === AUTH_ACTIONS.ADMIN_SIGN_OUT,
  );

  const getAdminSignInHistory = session.history.filter(
    (historySession) => historySession.action === AUTH_ACTIONS.ADMIN_SIGN_IN_SUCCESS,
  );

  const adminSignOutLength = getAdminSignOutHistory.length;
  const adminSignInLength = getAdminSignInHistory.length;

  if (adminSignInLength > adminSignOutLength) {
    return true;
  } else {
    return false;
  }
};

export const getAdminSignInSuccessInfo = (session) => {
  const getAdminSignInHistory = session.history.filter(
    (historySession) => historySession.action === AUTH_ACTIONS.ADMIN_SIGN_IN_SUCCESS,
  );

  const isAdminSession = isAdminOrder(session);

  const latestAdminSignIn = getAdminSignInHistory[getAdminSignInHistory.length - 1] as AuthAdminSignInSuccess;
  return isAdminSession && latestAdminSignIn.payload !== undefined
    ? latestAdminSignIn.payload
    : { phoneNum: '', name: '' };
};

export const isNonPaymentOrder = (session) => {
  if (isAdminOrder(session)) {
    return true;
  }
  if (session.version === '1.1.1' || session.version === '1.1.0') {
    const getSignOutHistory = session.history.filter(
      (historySession) => historySession.action === AUTH_ACTIONS.SIGN_OUT,
    );

    const latestSignOutHistory = getSignOutHistory[getSignOutHistory.length - 1] as AuthSignOut;
    const signOutType = latestSignOutHistory.payload.paymentType;
    if (signOutType === PaymentType.none) {
      return true;
    }
    return false;
  } else if (session.version >= '1.2.1') {
    const getPaymentDetailHistory = session.history.filter(
      (historySession) => historySession.action === PAYMENT_ACTIONS.SET_PAYMENT_SUMMARY,
    );
    if (getPaymentDetailHistory.length === 0) {
      return true;
    } else {
      return false;
    }
  }
};

export const getFinalPrice = (session, isCouponMembersOrder: boolean) => {
  let totalPrice;
  let itemTotalPrice;
  if (session.version >= '1.2.1') {
    const itemDetail = session.history.filter((history: SessionHistory) => history.action === ORDER_ACTIONS.SET_ITEMS);
    const latestItemDetail = itemDetail[itemDetail.length - 1].payload.items;

    itemTotalPrice = latestItemDetail.reduce(
      (acc, cur) => acc + (cur.price.basic + cur.price.sizeCharge + cur.price.concnCharge),
      0,
    );
  } else if (session.version < '1.2.1') {
    const itemDetail = session.history.filter(
      (history: SessionHistory) => history.action === PAYMENT_ACTIONS.SET_PAYMENT,
    );
    const lastItemDetail = itemDetail[itemDetail.length - 1].payload.items;
    itemTotalPrice = lastItemDetail.reduce((acc, cur) => acc + cur.price, 0);
  }

  if (isCouponMembersOrder) {
    const totalCouponPrice = session.history.filter(
      (history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON,
    )[0].payload.totalCouponPrice;
    totalPrice = itemTotalPrice - totalCouponPrice;
  } else {
    totalPrice = itemTotalPrice;
  }
  return totalPrice;
};

export const isCouponMembersSession = (session) => {
  if (
    session.history.filter((history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON).length > 0 &&
    session.history.filter((history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON)[0].payload
      .selectedCouponIdList.length > 0
  ) {
    return true;
  } else {
    return false;
  }
};

export const getSuccessNumber = (session, payment) => {
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isTest = session.vmId === 'TEST0000';
  if (isAdminSession || isNonPaymentSession || isTest) {
    return 'N/A';
  } else {
    if (payment.way === PaymentWay.card) {
      return payment.status.result.successMsg.successNumber;
    } else {
      return payment.paycoStatus.msg.successMsg.tradeRequestNo;
    }
  }
};

export const getCardNumber = (session, payment) => {
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isTest = session.vmId === 'TEST0000';

  if (isAdminSession || isNonPaymentSession || isTest) {
    return 'N/A';
  } else {
    if (payment.way === PaymentWay.card) {
      return payment.status.result.successMsg.cardNumber;
    } else {
      return 'PAYCO';
    }
  }
};

export const getInfoForRefunds = (session, walletsignedInInfo: WalletUserSignInSuccess) => {
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isTest = session.vmId === 'TEST0000';
  if (walletsignedInInfo) {
    return walletsignedInInfo.payload.selectedWallet.name;
  }
  if (isAdminSession || isNonPaymentSession) {
    return 'N/A';
  }

  const infoForRefunds = session.history.filter(
    (historySession: SessionHistory) => historySession.action === PAYMENT_ACTIONS.SET_PAYMENT_SUMMARY,
  )[0].payload.infoForRefunds;
  return infoForRefunds;
};

export const getPaymentWayUserId = (session) => {
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isTest = session.vmId === 'TEST0000';
  if (isAdminSession || isNonPaymentSession) {
    return 'N/A';
  }
  const paymentWayUserId = session.history.filter(
    (historySession: SessionHistory) => historySession.action === PAYMENT_ACTIONS.SET_PAYMENT_SUMMARY,
  )[0].payload.paymentWayUserId;
  return paymentWayUserId;
};

export const getPaymentWay = (session) => {
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isTest = session.vmId === 'TEST0000';
  const isWallet = getWalletSigInSuccessInfo(session).length > 0;
  if (isWallet) {
    return PaymentWay.wallet;
  }
  if (isAdminSession || isNonPaymentSession) {
    return 'N/A';
  }
  const paymentWay = session.history.filter(
    (historySession: SessionHistory) => historySession.action === PAYMENT_ACTIONS.SET_PAYMENT_SUMMARY,
  )[0].payload.paymentWay;
  return paymentWay;
};

const getItemLength = (session) => {
  const setItemHistory = session.history.find((history: SessionHistory) => history.action === ORDER_ACTIONS.SET_ITEMS);
  return setItemHistory ? setItemHistory.payload.items.length : 0;
};

export const getStepsFromSession = (session) => {
  const queueStepHistory = session.history.find(
    (history: SessionHistory) => history.action === QUEUE_ACTIONS.SET_STEPS,
  );
  const itemLength = getItemLength(session);
  return queueStepHistory ? queueStepHistory.payload.steps : Array.from({ length: itemLength }, () => 'READY');
};

export const getItemsFromSession = (session) => {
  const itemsHistory = session.history.filter((history: SessionHistory, idx: number) => {
    return history.action === ORDER_ACTIONS.SET_ITEMS || history.action === PAYMENT_ACTIONS.SET_WALLET_BALANCE_PAYMENT;
  });
  let getLatestItemHistory = null;
  if (itemsHistory.length === 0) {
    const customRecipeOrderHistory: SetCustomRecipeOrder[] = session.history.filter(
      (history: SessionHistory) => history.action === CUSTOM_RECIPE_ACTIONS.SET_ORDER,
    );
    if (customRecipeOrderHistory.length === 0) {
      return [];
    }
    getLatestItemHistory = customRecipeOrderHistory[customRecipeOrderHistory.length - 1].payload.items;
  } else {
    getLatestItemHistory = itemsHistory[itemsHistory.length - 1].payload.items;
  }

  const cupDispenserHistories = session.history.filter(
    (history: SessionHistory) => history.action === ORDER_ACTIONS.SET_CUP_DISPENSER_NUM,
  );

  const cupDispenserHistory =
    cupDispenserHistories.length > 0 ? cupDispenserHistories[0].payload.cupDispenser : undefined;

  const ItemsWithCupDispenserInfo = getLatestItemHistory.map((item, idx) => ({
    ...item,
    cupDispenser: cupDispenserHistory ? cupDispenserHistory[idx].dispenserNum : 'x',
  }));

  return ItemsWithCupDispenserInfo;
};

export const getCompanyNameFromSession = (session) => {
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isTest = session.vmId === 'TEST0000';
  const getSignInHistory = session.history.filter(
    (historySession) => historySession.action === AUTH_ACTIONS.SIGN_IN_SUCCESS,
  );

  // sign up detail history로 가입할때 회사명을 기입하는 내용까지 확인하게 하기 위함
  const getSignUpDetailHistory = session.history.filter(
    (historySession) => historySession.action === AUTH_ACTIONS.ADD_SIGN_UP_DETAIL,
  );

  // 지갑 결제의 경우, tags의 company를 참조 할 수 있으면 참조,
  const walletSignInInfo = session.history.filter((history) => history.action === WALLET_ACTIONS.USER_SIGN_IN_SUCCESS);
  const isWalletPayment = walletSignInInfo.length > 0;

  let companyName;

  if (
    isAdminSession ||
    isTest ||
    (getSignInHistory.length === 0 && getSignUpDetailHistory.length === 0 && !isWalletPayment)
  ) {
    return 'N/A';
  } else if (getSignUpDetailHistory.length > 0) {
    const latestSignUpHistory = getSignUpDetailHistory[getSignUpDetailHistory.length - 1].payload.userData;
    companyName = latestSignUpHistory.company;
    return companyName;
  } else if (isWalletPayment) {
    const tags = walletSignInInfo[walletSignInInfo.length - 1].payload.selectedWallet.tags;

    // console.log(tags);
    if (!tags) {
      // 일반 지갑 사용
      if (getSignInHistory.length === 0) {
        return 'N/A';
      }

      const latestSignInHistory = getSignInHistory[getSignInHistory.length - 1].payload.userData;
      companyName = latestSignInHistory.company || 'N/A';
      return companyName;
    } else {
      return tags
        .split('&')
        .map((tag) => {
          const [key, value] = tag.split('=');
          return { key, value };
        })
        .filter(({ key }) => key === 'company' || key === 'team')[0].value;
    }

    return 'N/A';
  } else {
    const latestSignInHistory = getSignInHistory[getSignInHistory.length - 1].payload.userData;
    companyName = latestSignInHistory.company || 'N/A';
    return companyName;
  }
};

export const getCouponPrice = (session) => {
  if (isCouponMembersSession(session)) {
    const getCouponSession = session.history.filter(
      (historySession) => historySession.action === COUPON_ACTIONS.SET_COUPON_PRICE,
    );
    const latestCouponPrice = getCouponSession[getCouponSession.length - 1].payload.couponPrice;
    return latestCouponPrice;
  } else {
    return '쿠폰주문아님';
  }
};

export const getMembersId = (session) => {
  const signedInInfo = session.history.find((sh: SessionHistory) => sh.action === AUTH_ACTIONS.SIGN_IN_SUCCESS);
  if (signedInInfo && signedInInfo.payload.membersInfo) {
    return signedInInfo.payload.membersInfo.id;
  }
  return 'originalMembers';
};

export const getOrderedWallet = (session: Session) => {
  const { history } = session;
  const selectedWalletHistory = history.filter((sh: SessionHistory) => sh.action === WALLET_ACTIONS.WALLET_SELECTED);

  const latestSelectedWallet = selectedWalletHistory[selectedWalletHistory.length - 1] as WalletSelected;

  return latestSelectedWallet.payload;
};
export const getWalletSigInSuccessInfo = (session: Session) => {
  const { history } = session;
  const signInSuccessWalletHistory = history.filter(
    (sh: SessionHistory) => sh.type === SH_TYPE.WALLET_AUTH && sh.action === WALLET_ACTIONS.USER_SIGN_IN_SUCCESS,
  ) as WalletUserSignInSuccess[];

  return signInSuccessWalletHistory;
};
export const getRefundedItems = (session: Session) => {
  const { history } = session;
  const refundWalletHistory = history.filter((sh: SessionHistory) => sh.action === REFUND_ACTIONS.WALLET_REFUND);

  const refundItemInfo = refundWalletHistory[refundWalletHistory.length - 1] as WalletRefundSuccess;

  return refundItemInfo.payload.refundItem;
};

export const getWalletBalancePayment = (session: Session) => {
  const walletBalancePaymentHistory = session.history.filter(
    (historySession) => historySession.action === PAYMENT_ACTIONS.SET_WALLET_BALANCE_PAYMENT,
  );

  if (walletBalancePaymentHistory.length === 0) {
    return undefined;
  }

  const walletBalancePaymentInfo = walletBalancePaymentHistory[
    walletBalancePaymentHistory.length - 1
  ] as SetWalletBalancePayment;

  return walletBalancePaymentInfo;
};

export const getWalletPaymentResult = (session: Session) => {
  const walletPaymentResultInfo = session.history.filter(
    (historySession) => historySession.action === PAYMENT_ACTIONS.SET_WALLET_PAYMENT_RESULT,
  );

  if (walletPaymentResultInfo.length === 0) {
    return undefined;
  }

  const walletBalancePaymentInfo = walletPaymentResultInfo[
    walletPaymentResultInfo.length - 1
  ] as SetWalletPaymentResult;

  return walletBalancePaymentInfo;
};

export const getUseCoupons = (session: Session) => {
  const { history } = session;
  const useCouponHistories = history.filter((sh: SessionHistory) => sh.action === COUPON_ACTIONS.USE_COUPON);

  const useCouponHistory = useCouponHistories[useCouponHistories.length - 1] as CouponUse;

  return useCouponHistory;
};
