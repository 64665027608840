// 내부 모듈
import { CombinationObjectType, CustomCombinationObjectType, RecipeCategory } from '@definitions/recipes';

// 타입
import { Payment } from '@definitions/payment';

export enum OptionLabel {
  Btype = 'H/I',
  Size = '사이즈',
  Concn = '진하기',
  Syrup = '설탕시럽',
}

// 옵션 라벨 (Btype: 음료타입(Iced/Hot), Size: 사이즈(M, XL), Concn: 진하기(Weak, Normal, Strong), Syrup: 시럽(None, Sweet, Sweeter))
export type SelectedOptions = {
  [OptionLabel.Btype]: string;
  [OptionLabel.Size]: string;
  [OptionLabel.Concn]: string;
  [OptionLabel.Syrup]: string;
};

// 상품 타입
export type Item = {
  id: string;
  recipeId: string;
  recipeName: string;
  recipeImage: string;
  recipeImageAltText: string;
  selectedOptions: SelectedOptions;
  category: RecipeCategory;
  price: {
    basic: number;
    sizeCharge: number;
    concnCharge: number;
  };
  combination: CombinationObjectType[];
  publishStickerNum: number;
};

export interface CustomItem extends Item {
  customCombination: CustomCombinationObjectType[];
}

// GA: 구글 애널리틱스 관련 타입
// type GA = {}

export enum OrderStatus {
  ordering,
  ordered,
}

export type Ordering = {
  status: OrderStatus.ordering;
  orderCnt: number;
};

export type Ordered = {
  status: OrderStatus.ordered;
  id: string;
  orderCnt: number;
  items: Item[];
  payment: Payment;
};

// 주문 타입: 고객은 복수개의 상품을 주문할 수 있다.
export type Order = Ordering | Ordered;
export type CustomOrder = {
  status: OrderStatus.ordered;
  id: string;
  orderCnt: number;
  items: CustomItem[];
  payment: Payment;
};
