import React from 'react';
import { css } from '@emotion/core';

function VmState(): JSX.Element {
  return <div css={vmState__wrap}>기기 상태 화면(준비중)</div>;
}
const vmState__wrap = css`
  background-color: white;
`;

export default VmState;
