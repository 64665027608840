// 외부 모듈
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

// 타입
import { QueueStep } from '@definitions/queue';
import { Item, OptionLabel } from '@definitions/order';
import { CustomCombinationObjectType } from '@definitions/recipes';
import { orderObj } from 'app/utils/orderHistory/getOrderHistoryObj';
import { getWalletSigInSuccessInfo } from 'app/utils/getSessionInfo';
import { WalletUserSignInSuccess } from '@definitions/walletAuth';
import RefundAlert from './modal/RefundAlert';
import RefundController, { getRefundItems } from '../utils/refund/refundController';
import { Button, Link, TableFooter } from '@material-ui/core';
import { formatDateToKorean, formatTimeToKorean } from 'app/utils/format';

function UserOrderRow(props: any) {
  const [refundedItems, setRefundedItems] = useState<Item[]>([]);
  const [refundTargetItems, setRefundTargetItems] = useState<Item[]>([]);
  const { row, setRefundOpen, refundOpen, refundedOrders, vms } = props;
  const { session, type, sessionId, vmId } = row;
  const [open, setOpen] = useState(false);
  const [refundOpenId, setRefundOpenId] = useState('');
  const curVesrion: string = (session && session.version) || '0.0.0';

  const result = orderObj[curVesrion](row);
  const {
    items,
    steps,
    timeStamp,
    isCouponUsed,
    totalPrice,
    usedCouponNum,
    phoneNum,
    infoForRefunds,
    paymentWayUserId,
    paymentWay,
  } = result;

  const walletsignedInInfoList: WalletUserSignInSuccess[] = getWalletSigInSuccessInfo(session);

  const refundButtonHandler = async () => {
    // const orderedWallet = getOrderedWallet(session);
    // await walletRefundController(refundTargetItems, orderedWallet, session, timeStamp);
    // await setRefundOpen(false);

    const refundController = new RefundController(session, refundTargetItems, result);
    await refundController.refund();
    setRefundOpen(false);
  };

  const isAdmin = phoneNum === '관리자주문';

  // 변수명부터 잘못됨...
  const isCoupon = isCouponUsed === '사용O' && totalPrice === 0;

  const handleClick = (text: string) => {
    // 클립보드에 text 복사
    navigator.clipboard
      .writeText(text)
      .then(() => {
        alert('클립보드에 복사되었습니다!');
      })
      .catch((err) => {
        console.error('복사 실패: ', err);
      });
  };

  //
  useEffect(() => {
    if (refundedOrders.length > 0) {
      const refundList = getRefundItems(session, refundedOrders);
      setRefundedItems(refundList);
    }
  }, [refundOpen, refundedOrders.length, sessionId]);

  useEffect(() => {
    setRefundOpenId('');
  }, [open]);

  return (
    <>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => {
              setOpen(!open);
              setRefundOpen(false);
              setRefundOpenId('');
            }}
          >
            {open ? <FontAwesomeIcon icon={faChevronUp} /> : <FontAwesomeIcon icon={faChevronDown} />}
          </IconButton>
        </TableCell>
        <TableCell style={{ fontSize: '26px' }} component="th" scope="row">
          {formatTimeToKorean(new Date(timeStamp.seconds * 1000))}
        </TableCell>
        <TableCell style={{ fontSize: '26px' }} component="th" scope="row">
          {(vms.find((vm) => vm.id === vmId) || { location: { name: '없음' } }).location.name}
        </TableCell>
        <TableCell style={{ fontSize: '26px', wordBreak: 'break-all' }} component="th" scope="row">
          {refundedItems &&
            items.map((item, idx) => {
              const isRefunded =
                refundedItems.findIndex(({ id }) => {
                  return id !== undefined && item.id !== undefined && id === item.id;
                }) > -1;
              return (
                <span style={{ textDecoration: !(type >= 90) && isRefunded ? 'line-through' : 'none' }} key={idx}>
                  {item.recipeName}
                  {items.length - 1 > idx && ','}
                </span>
              );
            })}
        </TableCell>
        <TableCell style={{ fontSize: '18px' }} align="right">
          {steps.map((item, idx) => {
            if (item === QueueStep.success) {
              return <FontAwesomeIcon key={idx} icon={faCheckCircle} style={{ color: 'green', marginRight: '2px' }} />;
            } else if (item === QueueStep.failed) {
              return <FontAwesomeIcon key={idx} icon={faCheckCircle} style={{ color: 'red', marginRight: '2px' }} />;
            } else {
              return <FontAwesomeIcon key={idx} icon={faCheckCircle} style={{ color: 'orange', marginRight: '2px' }} />;
            }
          })}
        </TableCell>
        <TableCell style={{ fontSize: type === 99 ? '16px' : '22px' }} align="right">
          {totalPrice}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                상세내역
              </Typography>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>승인번호</TableCell>
                    <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>카드번호</TableCell>
                    <TableCell style={{ fontSize: '22px', fontWeight: 'bold' }} align="center">
                      쿠폰사용여부
                    </TableCell>
                    <TableCell style={{ fontSize: '22px', fontWeight: 'bold' }} align="center">
                      쿠폰사용갯수
                    </TableCell>
                    <TableCell style={{ fontSize: '22px', fontWeight: 'bold' }} align="center">
                      영수증
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell style={{ fontSize: '24px' }} component="th" scope="row">
                      {infoForRefunds}
                    </TableCell>
                    <TableCell style={{ fontSize: '24px' }}>{paymentWayUserId}</TableCell>
                    <TableCell style={{ fontSize: '20px' }} align="center">
                      {isCouponUsed}
                    </TableCell>
                    <TableCell style={{ fontSize: '20px' }} align="center">
                      {usedCouponNum}
                    </TableCell>
                    <TableCell style={{ fontSize: '20px' }} align="center">
                      <button
                        css={receipt__btn}
                        onClick={() => {
                          const rowId = session.history
                            .filter((h) => h.action === 'ADD_DOCUMENT_TO_FB' && h.type === 'ADD_DOCUMENT')
                            .map((h) => h.payload.id)
                            .reduce((acc, cur) => cur, '');

                          const date = new Date(row.timeStamp.seconds * 1000);
                          const year = date.getFullYear();
                          const month = date.getMonth() + 1;
                          if (rowId) {
                            navigator.clipboard
                              .writeText(
                                `https://planz-coffee.com/receipt/?id=${rowId}&vm=${row.vmId}&year=${year}&month=${
                                  month > 9 ? month : `0${month}`
                                }`,
                              )
                              .then(() => {
                                window.alert('영수증 링크가 복사되었습니다!');
                              })
                              .catch((err) => {
                                console.error('Failed to copy text: ', err);
                              });
                          } else {
                            window.alert('영수증 링크가 복사에 실패했습니다.');
                          }
                        }}
                      >
                        복사
                      </button>
                    </TableCell>
                  </TableRow>
                </TableBody>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>메뉴</TableCell>
                    <TableCell style={{ fontSize: '24px', fontWeight: 'bold' }}>옵션</TableCell>
                    <TableCell style={{ fontSize: '24px', fontWeight: 'bold', position: 'relative' }} align="center">
                      가격
                      {!isAdmin && type !== 99 && (
                        <button
                          css={refund__btn}
                          disabled={refundedItems.length >= items.length}
                          onClick={() => {
                            const remainItem = items.filter(
                              (item: Item) => refundedItems.findIndex(({ id }) => id === item.id) < 0,
                            );
                            setRefundOpen(true);
                            setRefundTargetItems(remainItem);
                            setRefundOpenId(sessionId);
                          }}
                        >
                          전체환불
                        </button>
                      )}
                    </TableCell>
                    <TableCell style={{ fontSize: '20px', fontWeight: 'bold' }} align="center">
                      컵 디스펜서
                    </TableCell>
                    <TableCell style={{ fontSize: '20px', fontWeight: 'bold' }} align="center">
                      상태
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody style={{ position: 'relative' }}>
                  {refundOpenId === sessionId && refundOpen && (
                    <RefundAlert
                      items={items}
                      refundTargetItems={refundTargetItems}
                      setRefundOpen={setRefundOpen}
                      setRefundOpenId={setRefundOpenId}
                      refundButtonHandler={refundButtonHandler}
                    />
                  )}
                  {items.map((item, idx: number) => {
                    const { selectedOptions, recipeName, price } = item;
                    const { basic, concnCharge, sizeCharge } = price;
                    const itemPrice = basic + concnCharge + sizeCharge;
                    const beforeIdx = 0;
                    const isRefunded =
                      refundedItems.findIndex(({ id }) => {
                        return id === item.id;
                      }) > -1;
                    const cupDispenserNum =
                      curVesrion >= '1.2.1'
                        ? item.cupDispenser
                        : item.customCombination &&
                          item.customCombination.filter(
                            (item: CustomCombinationObjectType) => item.ingredientId === 'cup',
                          )[0].metric;

                    return (
                      <TableRow key={idx}>
                        <TableCell
                          style={{
                            fontSize: '20px',
                          }}
                        >
                          {recipeName}
                        </TableCell>
                        <TableCell style={{ fontSize: '20px' }}>
                          {selectedOptions[OptionLabel.Btype]}, {selectedOptions[OptionLabel.Size]},
                          {selectedOptions[OptionLabel.Concn]}
                        </TableCell>
                        <TableCell style={{ fontSize: '20px', position: 'relative' }} align="center">
                          {itemPrice}
                          {!isAdmin && type !== 99 && (walletsignedInInfoList.length > 0 || paymentWay === 'N/A') && (
                            <button
                              css={refund__btn}
                              disabled={isRefunded}
                              onClick={() => {
                                setRefundOpen(true);
                                setRefundTargetItems([].concat(item));
                                setRefundOpenId(sessionId);
                              }}
                            >
                              환불
                            </button>
                          )}
                        </TableCell>
                        <TableCell style={{ fontSize: '20px' }} align="center">
                          {cupDispenserNum}
                        </TableCell>
                        <TableCell style={{ fontSize: '20px' }} align="center">
                          {steps[idx] === QueueStep.success ? (
                            <FontAwesomeIcon
                              key={idx}
                              icon={faCheckCircle}
                              style={{ color: 'green', marginRight: '2px' }}
                            />
                          ) : steps[idx] === QueueStep.failed ? (
                            <FontAwesomeIcon
                              key={idx}
                              icon={faCheckCircle}
                              style={{ color: 'red', marginRight: '2px' }}
                            />
                          ) : (
                            <FontAwesomeIcon
                              key={idx}
                              icon={faCheckCircle}
                              style={{ color: 'orange', marginRight: '2px' }}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default UserOrderRow;

const table__style = css`
  border-bottom: 'unset';
`;

const refund__btn = css`
  position: absolute;
  right: 0px;
  top: 50%;
  height: 30px;
  transform: translateY(-50%);
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #d49c76;
  font-family: Spoqa Han Sans;
  color: white;
  font-size: 14px;

  &:active {
    background-color: #b67d56;
  }

  &:disabled {
    background-color: #b2b1b0;
    opacity: 0.4;
  }
`;

const receipt__btn = css`
  height: 30px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #d49c76;
  font-family: Spoqa Han Sans;
  color: white;
  font-size: 14px;

  &:active {
    background-color: #b67d56;
  }

  &:disabled {
    background-color: #b2b1b0;
    opacity: 0.4;
  }
`;
