// 외부모듈
import { call, delay, put, select, take, takeEvery } from '@redux-saga/core/effects';

// 내부모듈
import { controlForm, ControllerActions } from '@reducers/formController';
import { RootStateType } from '../reducers/index';
import { ControllerType, CreateMembersData, MembersControlData, VmRecipesControlData } from '@definitions/mock';
import { firestore, firebase } from 'app/common/firebase/index';
import { createExpirationDate } from '@utils/createExpirationDate';
import { valiator } from '@utils/controlDataUtils';
import { endProcess, ProcessState, startProcess, setProcessingPercent } from '@reducers/process';
import { BenefitType } from '@definitions/benefits';

function* controller(action: ControllerActions) {
  const { payload } = action;
  const { type } = payload;
  switch (type) {
    case ControllerType.sticker:
    case ControllerType.eventCoupon:
    case ControllerType.coupon: {
      if (!valiator(payload)) {
        return alert('잘못된 값입니다. 다시 확인해주세요');
      }
      const { type, phoneNum, inputs } = payload as any;
      const auth = firebase.auth();
      const createCnt = inputs[0].value as number;

      if (type === ControllerType.sticker) {
        for (let i = 1; i <= createCnt; i++) {
          yield delay(1000);
          yield put(setProcessingPercent(Math.round((i / createCnt) * 100)));
          yield firestore.collection(`version/v3/users/${phoneNum}/stickers`).add({
            createdDate: new Date(),
            expirationDate: createExpirationDate(365),
            isExpired: false,
            location: 'adminWeb',
            usedDate: null,
            publisher: auth.currentUser ? auth.currentUser.email : 'admin',
          });
        }
      } else if (type === ControllerType.coupon) {
        for (let i = 1; i <= createCnt; i++) {
          yield delay(1000);
          yield put(setProcessingPercent(Math.round((i / createCnt) * 100)));
          yield firestore.collection(`version/v3/users/${phoneNum}/coupons`).add({
            createdDate: new Date(),
            expirationDate: createExpirationDate(365),
            isExpired: false,
            titleEng: 'membershipCoupon',
            titleKor: '멤버십쿠폰',
            usedDate: null,
            type: 'membershipCoupon',
            publisher: auth.currentUser ? auth.currentUser.email : 'admin',
          });
        }
      } else if (type === ControllerType.eventCoupon) {
        const createCnt = inputs[0].value as number;
        const titleKor = inputs[1].value as string;
        const price = inputs[2].value as number;
        for (let i = 1; i <= createCnt; i++) {
          yield delay(1000);
          yield put(setProcessingPercent(Math.round((i / createCnt) * 100)));
          yield firestore.collection(`version/v3/users/${phoneNum}/coupons`).add({
            createdDate: new Date(),
            expirationDate: createExpirationDate(365),
            isExpired: false,
            titleEng: 'membershipCoupon',
            titleKor,
            price,
            usedDate: null,
            type: 'eventCoupon',
            publisher: auth.currentUser ? auth.currentUser.email : 'admin',
          });
        }
      }

      break;
    }
    case ControllerType.vmRecipes: {
      const { vm, selectedBenefitType, vmId } = action.payload as VmRecipesControlData;
      switch (selectedBenefitType) {
        case BenefitType.price:
        case BenefitType.combination: {
          if (!valiator(action.payload)) {
            return alert('잘못되었습니다!');
          }
          const { vmRecipes } = vm;
          yield firestore.doc(`version/v3/vms/${vmId}`).update({ vmRecipes });

          break;
        }
        case BenefitType.vmCouponPirce: {
          if (!valiator(action.payload)) {
            return alert('잘못되었습니다!');
          }
          const { couponPrices } = vm;
          yield firestore.doc(`version/v3/vms/${vmId}`).update({ couponPrices });
          break;
        }
        default: {
          return alert('vmRecipes 에서 컨트롤할수 없는 benefitType 입니다.');
        }
      }
      break;
    }

    case ControllerType.members: {
      const { members } = action.payload as MembersControlData;
      yield firestore.doc(`version/v3/members/${members.id}`).update({ benefits: members.benefits });

      break;
    }

    case ControllerType.createMembers: {
      const { members } = action.payload as CreateMembersData;
      yield firestore.doc(`version/v3/members/${members.id}`).set(members);
      break;
    }

    default: {
      return null;
    }
  }
  return null;
}

function* processAlert(): Generator<any, any, any> {
  const process: ProcessState = yield select((state: RootStateType) => state.process);
  const isProcessing = process.isProcessing === true;

  if (isProcessing) {
    return alert('작업이 진행중입니다. 잠시만 기다려주세요');
  } else {
    return null;
  }
}

export function* controllerSaga(): Generator<any, void, any> {
  yield takeEvery(controlForm.type, processAlert);

  while (true) {
    const action = yield take(controlForm.type);
    yield put(startProcess());
    yield call(controller, action);
    yield put(endProcess());
    yield put(setProcessingPercent(0));
    window.location.reload();
  }
}
