// 외부모듈
import React from 'react';
import { css } from '@emotion/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { BENEFIT_TYPE } from '@constants/benefits';

interface BenefitTypeSelectProps {
  onChange: (e: React.ChangeEvent<{ value: unknown }>) => void;
  benefitTypes?: string[];
  value: string;
}

// 현재 관리하는 benefit type 은 price, combination, vmCouponPrice 입니다.
function BenefitTypeSelect({ onChange, value, benefitTypes }: BenefitTypeSelectProps) {
  return (
    <div css={select__wrap}>
      <FormControl>
        <InputLabel id="demo-simple-select-label">BenefitType</InputLabel>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          css={select__input}
          onChange={(e) => onChange(e)}
          value={value}
        >
          {benefitTypes
            ? benefitTypes.map((type, i) => (
                <MenuItem value={type} key={i}>
                  {type}
                </MenuItem>
              ))
            : BENEFIT_TYPE.map((type, i) => (
                <MenuItem value={type} key={i}>
                  {type}
                </MenuItem>
              ))}
        </Select>
      </FormControl>
    </div>
  );
}

const select__wrap = css`
  margin: 10px 0px 30px 0px;
`;

const select__input = css`
  width: 150px;
`;

export default BenefitTypeSelect;
