/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable curly */
// 외부모듈
import React, { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, Fab, Modal, TextField } from '@material-ui/core';
import { css } from '@emotion/core';
import { v4 as uuidv4 } from 'uuid';
import { firestore } from 'app/common/firebase';
import { CorpGiftWallet } from '@definitions/wallet';
import { Admin } from '@definitions/admins';
import { createWalletData } from './utils/createWalletData';

interface CreateWalletProps {
  handleLoading: (bol: boolean) => void;
  setReload: () => void;
  auth: Admin;
}

interface CreateInputProps {
  index: number;
  inputId: string;
  handleRemoveWalletInput: (index: string) => void;
  handleChangeInput: (inputId: string, e: any) => void;
  inputList: { id: string; phoneNumber: string; inputId: string }[];
}

function CreateInput({ index, inputId, handleRemoveWalletInput, handleChangeInput, inputList }: CreateInputProps) {
  return (
    <div css={input__container} onChange={(e) => handleChangeInput(inputId, e)}>
      <TextField
        label="아이디"
        name="id"
        style={{ margin: '0px 15px' }}
        helperText={index === 0 ? '중복되지 않는 값' : null}
        variant="outlined"
        size="small"
      />
      <TextField
        label="전화번호"
        name="phoneNumber"
        style={{ margin: '0px 15px' }}
        type="number"
        helperText={index === 0 ? '- 을 제외한 전화번호' : null}
        variant="outlined"
        size="small"
      />
      <Button onClick={() => handleRemoveWalletInput(inputId)}>
        <DeleteIcon className="remove-btn" />
      </Button>
    </div>
  );
}

function CreateWallet({ handleLoading, setReload, auth }: CreateWalletProps) {
  const batch = firestore.batch();
  const [open, setOpen] = useState(false);
  const [inputList, setInputList] = useState([{ inputId: uuidv4(), id: '', phoneNumber: '' }]);

  useEffect(() => {
    setInputList([{ inputId: uuidv4(), id: '', phoneNumber: '' }]);
  }, [open === false]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAddWalletInput = () => setInputList([...inputList, { inputId: uuidv4(), id: '', phoneNumber: '' }]);
  const handleRemoveWalletInput = (inputId: string) =>
    setInputList(inputList.filter((input) => input.inputId !== inputId));
  const handleChangeInput = (inputId: string, e: any) => {
    const { name, value } = e.target;
    setInputList(
      inputList.map((input) => {
        if (input.inputId === inputId) return { ...input, [name]: value };
        else return input;
      }),
    );
  };

  const createWallet = () => {
    handleLoading(true);
    inputList.map((input) => {
      const ref = firestore.collection('version/v3/wallets').doc();
      const walletData: CorpGiftWallet = createWalletData(input, auth, ref.id);

      batch.set(ref, walletData);
    });
    setOpen(false);
    batch.commit().then(() => setReload());
  };

  return (
    <div css={container}>
      <Fab color="primary" aria-label="add" onClick={handleOpen} style={{ marginRight: '10px', marginBottom: '10px' }}>
        <AddIcon />
      </Fab>
      <Modal open={open} onClose={handleClose} style={{ zIndex: 10000 }}>
        <form css={modal__container}>
          <h1>지갑 생성하기</h1>
          {inputList.map((input, i) => (
            <CreateInput
              inputList={inputList}
              key={input.inputId}
              index={i}
              inputId={input.inputId}
              handleRemoveWalletInput={handleRemoveWalletInput}
              handleChangeInput={handleChangeInput}
            />
          ))}
          <div css={add__wallet__btn__container}>
            <Button onClick={handleAddWalletInput}>
              <AddIcon />
            </Button>
          </div>
          <div css={apply__wallet__btn__container}>
            <Button onClick={createWallet}>등록</Button>
          </div>
        </form>
      </Modal>
    </div>
  );
}

const container = css`
  z-index: 10000;
`;

const modal__container = css`
  position: absolute;
  background-color: white;
  border-radius: 15px;
  padding: 20px;
  top: 30%;
  left: 30%;
`;

const input__container = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px 0px;

  input {
    margin: 0px 15px;
  }
`;

const add__wallet__btn__container = css`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const apply__wallet__btn__container = css`
  display: flex;
  justify-content: flex-end;
`;

export default CreateWallet;
