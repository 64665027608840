// 외부모듈
import { createAction, createReducer } from '@reduxjs/toolkit';

// 타입
import { CustomPayloadAction } from '@definitions/redux';

// 액션
export const completeInitSaga = createAction('@COMPLETE_INIT_SAGA');

// 리듀서
const initialState = false;

const sagaInit = createReducer(initialState, {
  [completeInitSaga.type]: () => {
    return true;
  },
});

export default sagaInit;

export type SagaInitActions = CustomPayloadAction<'@COMPLETE_INIT_SAGA'>;
