// 외부 모듈
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Flex, Segment, Dropdown, Button, Table, MenuButton } from '@fluentui/react-northstar';
import { TimeStamp, VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers/index';
import { useFirestore } from 'react-redux-firebase';
import { getSalesInfo, format, getYear, getMonth } from 'app/utils/sales';

import { gridNestedBehavior, gridCellWithFocusableElementBehavior } from '@fluentui/accessibility';
import { getSignature } from 'app/utils/signature';
import axios from 'axios';

// 페이코 request header option
const PAYCO_REQUEST_OPTION = {
  headers: {
    'Content-Type': 'application/json',
  },
  validateStatus(status: number) {
    // 상태 코드가 500 이상일 경우 거부. 나머지(500보다 작은)는 허용.
    return true;
  },
};
const URI = 'https://dongle.payco.com/';
const QR_PAYMENT_CANCEL = 'payment/v1/cancel/simple';

const parseToDate = (tradeDatetime: string) =>
  `${tradeDatetime.slice(0, 4)}-${tradeDatetime.slice(4, 6)}-${tradeDatetime.slice(6, 8)} ${tradeDatetime.slice(
    8,
    10,
  )}:${tradeDatetime.slice(10, 12)} `;

/**
 * 페이코에서는 productName이 1개일 경우에는 해당 아이템 이름, 2개 이상일 경우에는
 * 단순히 개수로 구분하는 것이 아니라 종류의 개수를 외 n건 식으로 표현해야함. 이를 만들어주는 함수.
 *
 * @param items 장바구니에 담겨있는 음료들
 */
function getPaycoProductName(items: Item[]) {
  if (items.length === 1) {
    return items[0].recipeName;
  }

  const names = new Set();
  items.forEach((item) => {
    names.add(item.recipeName);
  });

  if (names.size === 1) {
    return items[0].recipeName;
  }

  return `${items[0].recipeName}외 ${names.size - 1}건`;
}

function PaycoPayments(): JSX.Element {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const firestore = useFirestore();
  const [selectedVmLocation, setSelectedVmLocation] = useState<string>('');
  const today = new Date();
  const [start, setStart] = useState<string>('');
  const [end, setEnd] = useState<string>(format(today));
  const [rowsPlain, setRowsPlain] = useState<any[]>([]);
  let locations: { name: string; startDate: TimeStamp }[] = [];
  let isLoaded = false;
  if (vms !== undefined && vms !== null && vms.length !== 0) {
    locations = vms
      // .filter(({ location }) => location.type !== '테스트')
      .map(({ id, location: { name, startDate } }) => ({
        id,
        name,
        startDate,
      }));
    console.log(format(today));
    isLoaded = true;
  }

  const getA11ySelectionMessage = {
    onAdd: (item) => {
      setSelectedVmLocation(item);
      setStart('');
    },
  };

  const onUpdateDate = {
    onAdd: (item) => console.log(item),
  };

  return (
    <Flex column>
      <Segment style={{ marginTop: '18.76px', marginLeft: '16px' }}>
        <Flex column>
          VM_ID
          <Dropdown
            loading={!isLoaded}
            items={locations.map(({ name }) => name)}
            placeholder="기기장소"
            checkable
            getA11ySelectionMessage={getA11ySelectionMessage}
            style={{ marginTop: '4px', marginBottom: '12px' }}
          />
          시작일
          <input
            id="datepicker_start"
            style={{ marginTop: '4px', marginBottom: '12px' }}
            type="date"
            value={start}
            min={
              selectedVmLocation === ''
                ? undefined
                : format(
                    locations
                      .filter(({ name }) => {
                        if (name === selectedVmLocation) {
                          return true;
                        }

                        return false;
                      })[0]
                      .startDate.toDate(),
                  )
            }
            max={format(today)}
            onChange={(event) => {
              // 시작일이 수정되면 end 도 수정
              setStart(event.target.value);
            }}
          />
          마지막일
          <input
            id="datepicker_end"
            style={{ marginTop: '4px', marginBottom: '18px' }}
            type="date"
            value={end}
            max={format(today)}
            onChange={(event) => {
              setEnd(event.target.value);
            }}
          />
          <Button
            fluid
            content="조회"
            onClick={async (event) => {
              setRowsPlain([]);
              let index = 1;
              if (start === '' || end === '') {
                window.alert('시작일과 마지막일을 설정해주세요(기기를 수정하면 시작일이 초기화 됩니다).');

                return;
              } else if (new Date(start) > new Date(end)) {
                window.alert('시작일이 마지막일보다 클 수 없습니다.');

                return;
              } else if (vms.find(({ location: { name } }) => name === selectedVmLocation) === undefined) {
                window.alert('조회하고자 하는 기기를 선택해주세요.');

                return;
              }

              const vmId = vms.find(({ location: { name } }) => name === selectedVmLocation).id;
              const vmConfig = vms.find(({ location: { name } }) => name === selectedVmLocation).configs;
              for (let yi = getYear(start); yi <= getYear(end); yi++) {
                for (let mi = getMonth(start); mi <= getMonth(end); mi++) {
                  console.log(yi);
                  console.log(mi);
                  const snapshot = await firestore
                    .collection(`version/v3/vms/${vmId}/orders/${yi}/${mi < 10 ? `0${mi}` : mi}`)
                    .where('order.payment.way', 'in', ['PAYCO_BARCODE', 'PAYCO_QR', 'PAYCO'])
                    .where('timeStamp', '>=', new Date(`${start}T00:00:00+09:00`))
                    .where('timeStamp', '<=', new Date(`${end}T24:00:00+09:00`))
                    .get();

                  const rows = [];
                  const sum = { point: 0, coupon: 0, card: 0 };

                  snapshot.forEach((row) => {
                    const { order } = row.data();

                    const isNonPaymentOrCouponOnlyPayment = !order.payment.status.msg.successMsg;
                    if (isNonPaymentOrCouponOnlyPayment) {
                      return;
                    }
                    const {
                      approvalResultList,
                      totalTaxableAmount,
                      totalVatAmount,
                      tradeDatetime,
                      tradeNo,
                      tradeRequestNo,
                      pinCode,
                    } = order.payment.status.msg.successMsg;

                    const way = order.payment.way;
                    const isCanceledOrder = !!order.payment.status.msg.cancelMsg;

                    // payco방식의 결제를 했지만, 쿠폰으로만 결재 한 경우.
                    if (!approvalResultList) {
                      return;
                    }
                    const { items } = order;
                    const cancelContent = isCanceledOrder
                      ? {
                          content: (
                            <Button disabled tabIndex={-1} title="canceled">
                              취소완료
                            </Button>
                          ),
                        }
                      : {
                          content: (
                            <Button tabIndex={-1} title="cancel">
                              취소
                            </Button>
                          ),
                          truncateContent: true,
                          accessibility: gridCellWithFocusableElementBehavior,
                          onClick: (e) => {
                            const cancelBody = {
                              signature: getSignature(vmConfig.paycoInfo.apiKey, vmConfig.paycoInfo.posTid, pinCode),
                              tradeRequestNo,
                              deviceAuthType: way === 'PAYCO_QR' || way === 'PAYCO' ? 'QR' : 'BAR',
                              tradeNo,
                              serviceType: 'PAYCO',
                              registrationNumber: vmConfig.paycoInfo.registrationNumber,
                              posTid: vmConfig.paycoInfo.posTid,
                              vanCorpCode: 'SMTR',
                              vanPosTid: vmConfig.paycoInfo.vanPosTid,
                              totalAmount: totalTaxableAmount + totalVatAmount,
                              approvalNo: approvalResultList[0].approvalNo,
                              approvalDatetime: approvalResultList[0].approvalDatetime,
                              approvalAmount: approvalResultList[0].approvalAmount,
                              pinCode,
                              extras: {
                                posDevCorpName: 'PLANZ',
                                posSolutionName: 'PLANZ KIOSK',
                                posSolutionVersion: 'ver 1.0.0',
                                merchantCode: 'SPOS',
                              },
                            };
                            console.log(cancelBody);
                            axios
                              .post(`/api/${QR_PAYMENT_CANCEL}`, JSON.stringify(cancelBody), PAYCO_REQUEST_OPTION)
                              .then((msg) => {
                                console.log(msg);
                                if (msg.status === 200) {
                                  if (msg.data.resultCode === 0) {
                                    firestore
                                      .collection(`version/v3/vms/${vmId}/orders/${yi}/${mi < 10 ? `0${mi}` : mi}`)
                                      .doc(row.id)
                                      .update({
                                        ['order.payment.status.msg.cancelMsg']: msg.data.result,
                                      });
                                    alert(msg.data.sourceMessage);
                                  } else {
                                    alert(msg.data.message);
                                  }
                                } else {
                                  alert(msg.statusText);
                                }
                              })
                              .catch((error) => {
                                alert(error);
                              });
                            e.stopPropagation();
                          },
                        };
                    const approvalList = [{ type: 'PAYCO 포인트' }, { type: '페이코 쿠폰' }, { type: '신용카드' }].map(
                      ({ type }) => {
                        const result = approvalResultList.find((result) => result.paymentMethodName === type);
                        if (result !== undefined) {
                          const amount = Number(result.approvalAmount);
                          if (type === 'PAYCO 포인트') {
                            sum.point += amount;
                          } else if (type === '페이코 쿠폰') {
                            sum.coupon += amount;
                          } else {
                            sum.card += amount;
                          }

                          return {
                            type,
                            content: result.approvalAmount,
                            key:
                              type === 'PAYCO 포인트'
                                ? `${index}-6`
                                : type === '페이코 쿠폰'
                                ? `${index}-7`
                                : `${index}-8`,
                            id:
                              type === 'PAYCO 포인트'
                                ? `paycoPointAmount-${index}`
                                : type === '페이코 쿠폰'
                                ? `paycoCouponAmount-${index}`
                                : `paycoCreditCardAmount-${index}`,
                          };
                        }

                        return {
                          type,
                          key:
                            type === 'PAYCO 포인트'
                              ? `${index}-6`
                              : type === '페이코 쿠폰'
                              ? `${index}-7`
                              : `${index}-8`,
                        };
                      },
                    );

                    rows.push({
                      key: index,
                      items: [
                        { content: `${index}`, key: `${index}-1` },
                        { content: parseToDate(tradeDatetime), key: `${index}-2`, id: `datetime-${index}` },
                        { content: tradeNo, key: `${index}-3`, id: `tradeNo-${index}` },
                        { content: getPaycoProductName(items), key: `${index}-4`, id: `productName-${index}` },
                        { content: totalTaxableAmount + totalVatAmount, key: `${index}-5`, id: `totalAmount-${index}` },
                        ...approvalList.map(({ content, key, id }) => {
                          if (id === undefined) {
                            return { key };
                          }

                          return { content, key, id };
                        }),
                        { key: `${index}-9`, ...cancelContent },
                      ],
                    });
                    index++;
                  });
                  setRowsPlain([
                    ...rows,
                    {
                      key: index,
                      items: [
                        { content: '총합', key: `${index}-1` },
                        { key: `${index}-2` },
                        { key: `${index}-3` },
                        { key: `${index}-4` },
                        { content: `${sum.point + sum.card + sum.coupon}`, key: `${index}-5` },
                        { content: `${sum.point}`, key: `${index}-6` },
                        { content: `${sum.coupon}`, key: `${index}-7` },
                        { content: `${sum.card}`, key: `${index}-8` },
                        { key: `${index}-9` },
                      ],
                    },
                  ]);
                }
              }
            }}
          />
        </Flex>
      </Segment>
      <Table
        variables={{
          cellContentOverflow: 'none',
        }}
        header={header}
        rows={rowsPlain}
        aria-label="Nested navigation"
        accessibility={gridNestedBehavior}
      />
    </Flex>
  );
}

export default PaycoPayments;

function handleRowClick(index) {
  alert(`OnClick on the row ${index} executed.`);
}

const header = {
  key: 'header',
  items: [
    {
      content: '',
      key: 'index',
    },
    {
      content: '날짜',
      key: 'datetime',
    },
    {
      content: '거래번호',
      key: 'tradeNo',
    },
    {
      content: '상품명',
      key: 'productName',
    },
    {
      content: '상품 총결제액',
      key: 'totalAmount',
    },
    {
      content: 'PAYCO 포인트 승인금액',
      key: 'paycoPointAmount',
    },
    {
      content: '페이코 쿠폰 승인금액',
      key: 'paycoCouponAmount',
    },
    {
      content: '신용카드 승인금액',
      key: 'paycoCreditCardAmount',
    },
    {
      content: '',
      key: 'cancel',
    },
  ],
};
