import React, { useState } from 'react';
import { css } from '@emotion/core';

interface ProgressBarProps {
  initialStep?: number;
  onComplete: () => void;
  onExtract: (amount: number, isClean: boolean) => void;
}

const CIP: React.FC<ProgressBarProps> = ({ initialStep = 0, onComplete, onExtract }: ProgressBarProps) => {
  const [step, setStep] = useState(initialStep);
  const progressWidth = ((step % 7) / 6) * 100;
  const [amount0, setAmount0] = useState(150);
  const [amount1, setAmount1] = useState(150);
  const [amount2, setAmount2] = useState(150);
  const [amount3, setAmount3] = useState(120);
  const [amount4, setAmount4] = useState(30);

  return (
    <div style={{ width: '100%', height: '100%', position: 'relative' }}>
      {/* Background ProgressBar (Red) */}
      <div
        style={{ position: 'absolute', bottom: 0, left: 0, height: '16px', width: '100%', backgroundColor: '#666666' }}
      />

      {/* Foreground ProgressBar (Blue) */}
      <div
        style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          height: '16px',
          width: `${progressWidth}%`,
          backgroundColor: '#3f51b5',
          transition: 'width 0.5s ease-in-out',
        }}
      />

      {/* Current Step */}
      <div
        style={{
          width: 'calc(100% - 10px)',
          height: 'calc(100% - 20px)',
          position: 'absolute',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          bottom: '20px',
          left: '10px',
        }}
      >
        {step === 0 && (
          <button
            css={cip__button}
            onClick={() => {
              const result = window.confirm('CIP를 진행하시겠습니까?');
              if (!result) {
                return;
              }
              setStep((prev) => prev + 1);
            }}
          >
            CIP 시작
          </button>
        )}
        {step === 1 && (
          <div>
            <div css={buttons__wrap}>
              <h3 style={{ lineHeight: '70px', marginRight: '8px', marginBottom: 0 }}>1.미온수</h3>
              <input
                style={{ width: '35%' }}
                type="number"
                defaultValue={150}
                onChange={(e) => setAmount0(Number(e.target.value))}
              />
            </div>

            <div css={buttons__wrap}>
              <button css={skip__buton} onClick={() => setStep((prev) => prev + 1)}>
                스킵
              </button>
              <button
                css={extract__button}
                onClick={async () => {
                  const result = window.confirm('미온수 추출을 진행하시겠습니까? 컵이 꽂혀있는지 확인하세요!');
                  if (!result) {
                    return;
                  }
                  onExtract(amount0, false);
                  setStep((prev) => prev + 1);
                }}
              >
                추출
              </button>
            </div>
          </div>
        )}
        {step === 2 && (
          <div>
            <div css={buttons__wrap}>
              <h3 style={{ lineHeight: '70px', marginRight: '8px', marginBottom: 0 }}>2.과탄산 용해액</h3>
              <input
                style={{ width: '35%' }}
                type="number"
                defaultValue={150}
                onChange={(e) => setAmount1(Number(e.target.value))}
              />
            </div>

            <div css={buttons__wrap}>
              <button css={skip__buton} onClick={() => setStep((prev) => prev + 1)}>
                스킵
              </button>
              <button
                css={extract__button}
                onClick={async () => {
                  const result = window.confirm('과탄산 용해액 추출을 진행하시겠습니까? 컵이 꽂혀있는지 확인하세요!');
                  if (!result) {
                    return;
                  }
                  onExtract(amount1, false);
                  setStep((prev) => prev + 1);
                }}
              >
                추출
              </button>
            </div>
          </div>
        )}
        {step === 3 && (
          <div>
            <div>
              <div css={buttons__wrap}>
                <h3 style={{ lineHeight: '70px', marginRight: '8px', marginBottom: 0 }}>3.미온수</h3>
                <input
                  style={{ width: '35%' }}
                  type="number"
                  defaultValue={150}
                  onChange={(e) => setAmount2(Number(e.target.value))}
                />
              </div>

              <div css={buttons__wrap}>
                <button css={skip__buton} onClick={() => setStep((prev) => prev + 1)}>
                  스킵
                </button>
                <button
                  css={extract__button}
                  onClick={() => {
                    const result = window.confirm('미온수 추출을 진행하시겠습니까? 컵이 꽂혀있는지 확인하세요!');
                    if (!result) {
                      return;
                    }
                    onExtract(amount2, false);
                    setStep((prev) => prev + 1);
                  }}
                >
                  추출
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 4 && (
          <div>
            <div>
              <div css={buttons__wrap}>
                <h3 style={{ lineHeight: '70px', marginRight: '8px', marginBottom: 0 }}>4.원액밀기</h3>
                <input
                  style={{ width: '35%' }}
                  type="number"
                  defaultValue={120}
                  onChange={(e) => setAmount3(Number(e.target.value))}
                />
              </div>

              <div css={buttons__wrap}>
                <button css={skip__buton} onClick={() => setStep((prev) => prev + 1)}>
                  스킵
                </button>
                <button
                  css={extract__button}
                  onClick={() => {
                    const result = window.confirm('원액 추출을 진행하시겠습니까? 컵이 꽂혀있는지 확인하세요!');
                    if (!result) {
                      return;
                    }
                    onExtract(amount3, false);
                    setStep((prev) => prev + 1);
                  }}
                >
                  시작
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 5 && (
          <div>
            <div>
              <div css={buttons__wrap}>
                <h3 style={{ lineHeight: '70px', marginRight: '8px', marginBottom: 0 }}>5.세척</h3>
                <input
                  style={{ width: '35%' }}
                  type="number"
                  defaultValue={30}
                  onChange={(e) => setAmount4(Number(e.target.value))}
                />
              </div>

              <div css={buttons__wrap}>
                <button css={skip__buton} onClick={() => setStep((prev) => prev + 1)}>
                  스킵
                </button>
                <button
                  css={extract__button}
                  onClick={() => {
                    const result = window.confirm('세척을 진행하시겠습니까? 마지막 작업입니다!');
                    if (!result) {
                      return;
                    }
                    onExtract(amount4, true);
                  }}
                >
                  시작
                </button>
              </div>
            </div>
          </div>
        )}
        {step === 6 && (
          <button
            css={cip__button}
            onClick={() => {
              onComplete();
            }}
          >
            완료
          </button>
        )}
      </div>
    </div>
  );
};

const cip__button = css`
  width: 70%;
  height: 62px !important;
  font-size: 32px;
  background-color: #3f51b5;
`;

const buttons__wrap = css`
  height: 70px !important;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justyfy-content: space-between;
  line-height: 40%;
  margin-top: 12px;
`;

const skip__buton = css`
  width: 35%;
  height: 62px !important;
  font-size: 24px;
  background-color: #f50057;
  marigin-right: 12px;
`;

const extract__button = css`
  width: 35%;
  height: 62px !important;
  font-size: 24px;
  background-color: #3f51b5;
`;

export default CIP;
