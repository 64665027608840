import { useDispatch } from 'react-redux';
import { openBottomSheet, Option, closeBottomSheet } from '../reducers/bottomSheet';
import { useCallback, useEffect, useState } from 'react';
import BottomSheetSelect from '@components/common/BottomSheetSelect';

// import { BottomSheetSelect } from '../common/BottomSheetSelect';

export default function useBottomSheetSelect(title: string) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);
  const [resolve, setResolve] = useState<((value: Option | null) => void) | null>(null);

  const openSelect = useCallback((options: Option[]): Promise<Option | null> => {
    setOptions(options);
    setOpen(true);

    return new Promise((res) => {
      setResolve(() => res);
    });
  }, []);

  const handleClose = useCallback(() => {
    setOpen(false);
    if (resolve) resolve(null);
  }, [resolve]);

  const handleSelect = useCallback(
    (option: Option) => {
      setOpen(false);
      if (resolve) resolve(option);
    },
    [resolve],
  );

  const BottomSheetComponent = (
    <BottomSheetSelect open={open} options={options} onClose={handleClose} onSelect={handleSelect} title={title} />
  );

  return {
    openSelect,
    BottomSheetComponent,
  };
}
