import { AUTH_ACTIONS, COUPON_ACTIONS, PAYMENT_ACTIONS, SessionHistory } from '@definitions/session';
import { PaymentWay } from '@definitions/payment';
import { getCompanyNameFromSession } from '../getSessionInfo';

export const get000Order = (obj: any) => {
  const { order, steps, timeStamp, session } = obj;
  const vmId = session && session.vmId;
  const { items } = order;
  const { payment, membership } = order;
  const isMembersOrder = membership === undefined;
  let isSignInMembersOrder;
  let isSignUpMembersOrder;
  let signInNumber;
  let membersFinalPrice;
  let isCouponMembersOrder;
  let membersCouponNum;
  let isFinalPriceSession;
  let companyName;
  let couponPrice;
  const couponPriceObj = {
    BETA0001: 1800,
    BETA0002: 1800,
    BETA0007: 1900,
    BETA0008: 1800,
    BETA0009: 1800,
    BETA0010: 1800,
    BETA0011: 1000,
    BETA0012: 1400,
    BETA0013: 1800,
    BETA0014: 1800,
    BETA0015: 1900,
  };

  if (isMembersOrder) {
    isSignInMembersOrder =
      session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS).length > 0;
    isSignUpMembersOrder =
      session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_REQUEST).length > 0;
    isFinalPriceSession =
      session.history.filter((history: SessionHistory) => history.action === PAYMENT_ACTIONS.SET_FINAL_PAYMENT).length >
      0;
    membersFinalPrice =
      session.history.length === 1 ||
      !order.payment.status ||
      (order.payment.status.result === undefined && order.payment.status.msg.successMsg === undefined)
        ? isFinalPriceSession
          ? session.history.filter((history: SessionHistory) => history.action === PAYMENT_ACTIONS.SET_FINAL_PAYMENT)[0]
              .payload.finalPrice
          : 'admin'
        : session.history.filter((history: SessionHistory) => history.action === PAYMENT_ACTIONS.SET_FINAL_PAYMENT)[0]
            .payload.finalPrice;

    isCouponMembersOrder =
      session.history.filter((history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON).length > 0 &&
      session.history.filter((history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON)[0].payload
        .selectedCouponIdList.length > 0
        ? true
        : false;

    if (isCouponMembersOrder) {
      membersCouponNum = session.history.filter(
        (history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON,
      )[0].payload.selectedCouponIdList.length;
    }

    if (isSignInMembersOrder) {
      signInNumber = session.history.filter(
        (history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS,
      )[0].payload.userData.phoneNum;
    } else if (isSignUpMembersOrder) {
      signInNumber = session.history.filter(
        (history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_REQUEST,
      )[0].payload.userData.phoneNum;
    }

    companyName = getCompanyNameFromSession(session);
    couponPrice = isCouponMembersOrder
      ? getCompanyNameFromSession(session) === 'N/A'
        ? couponPriceObj[vmId]
        : 1000
      : '쿠폰주문아님';
  }

  const isMembershipOrder = membership !== undefined && membership.status === 1;
  const isSkipMembershipOrder = membership !== undefined && membership.status !== 1;

  const isCouponUsed = isMembersOrder
    ? isCouponMembersOrder
      ? '사용O'
      : '사용X'
    : membership.selectedCouponIdList !== undefined &&
      membership.selectedCouponIdList !== null &&
      membership.selectedCouponIdList.length > 0
    ? '사용O'
    : '사용X';

  couponPrice =
    isMembershipOrder &&
    membership.selectedCouponIdList !== undefined &&
    membership.selectedCouponIdList !== null &&
    membership.selectedCouponIdList.length > 0
      ? couponPriceObj['BETA0013']
      : '쿠폰주문아님';

  const usedCouponNum = isMembersOrder
    ? membersCouponNum
    : membership.selectedCouponIdList !== undefined && membership.selectedCouponIdList !== null
    ? membership.selectedCouponIdList.length
    : 0;

  const totalPrice = isMembersOrder
    ? membersFinalPrice
    : !order.payment.status || payment.status.result === undefined
    ? membership.selectedCouponIdList !== undefined && membership.selectedCouponIdList !== null
      ? 0
      : 'admin'
    : payment.status.result.successMsg.total * 1;

  const infoForRefunds =
    (isMembersOrder && session.history.length === 1) ||
    !order.payment.status ||
    (payment.status.result === undefined && payment.status.msg.successMsg === undefined)
      ? 'N/A'
      : payment.way === PaymentWay.card || payment.way === undefined
      ? payment.status.result.successMsg.successNumber
      : payment.status.msg.successMsg.tradeRequestNo;

  const paymentWayUserId =
    (isMembersOrder && session.history.length === 1) ||
    !order.payment.status ||
    (payment.status.result === undefined && payment.status.msg.successMsg === undefined)
      ? 'N/A'
      : payment.way === PaymentWay.card || payment.way === undefined
      ? payment.status.result.successMsg.cardNumber
      : `PAYCO`;

  const paymentWay = payment.way === undefined ? (infoForRefunds.length > 6 ? 'CREDIT_CARD' : 'N/A') : payment.way;

  const phoneNum =
    isSkipMembershipOrder || (isMembersOrder && !isSignInMembersOrder && !isSignUpMembersOrder)
      ? '미가입주문'
      : isMembershipOrder
      ? membership.phoneNum
      : signInNumber;

  return {
    items,
    steps,
    timeStamp,
    infoForRefunds,
    paymentWayUserId,
    isCouponUsed,
    totalPrice,
    usedCouponNum,
    phoneNum,
    paymentWay,
    companyName,
    couponPrice,
  };
};
