// 외부모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { BenefitType } from '@definitions/benefits';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

interface PreViewModalProps {
  data: any;
  benefitType: BenefitType;
}

export default function PreViewModal({ data, benefitType }: PreViewModalProps) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const printBody = () => {
    if (data.length === 0) {
      return <div>...notData</div>;
    }
    switch (benefitType) {
      case BenefitType.combination: {
        return data.map((data: any, i: number) => {
          const { combination, id, priority } = data;
          const { iced, hot } = combination;
          return (
            <div css={combination__table__wrap} key={i}>
              <h1 css={font__bold}>{id}</h1>
              <TableContainer component={Paper}>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <TableCell>Temp</TableCell>
                      <TableCell>IngredientId</TableCell>
                      <TableCell align="center">Amount</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {iced &&
                      iced.map((row: any, i: number) => (
                        <TableRow key={i}>
                          <TableCell style={{ fontWeight: 'bold', color: '#2F3F9F' }}>Iced</TableCell>
                          <TableCell component="th" scope="row">
                            {row.ingredientId}
                          </TableCell>
                          <TableCell align="center">{row.amount}</TableCell>
                        </TableRow>
                      ))}
                    {hot &&
                      hot.map((row: any, i: number) => (
                        <TableRow key={i}>
                          <TableCell style={{ fontWeight: 'bold', color: 'red' }}>Hot</TableCell>
                          <TableCell component="th" scope="row">
                            {row.ingredientId}
                          </TableCell>
                          <TableCell align="center">{row.amount}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div css={font__bold}>{`우선순위 : ${priority}`}</div>
            </div>
          );
        });
      }
      case BenefitType.price: {
        return (
          <TableContainer component={Paper}>
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell align="right">price(basic)</TableCell>
                  <TableCell align="right">price(concn)</TableCell>
                  <TableCell align="right">price(size)</TableCell>
                  <TableCell align="right">priority</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row: any, i: number) => (
                  <TableRow key={i}>
                    <TableCell component="th" scope="row">
                      {row.id}
                    </TableCell>
                    <TableCell align="right">{`iced:${row.price.basic.iced} hot:${row.price.basic.hot}`}</TableCell>
                    <TableCell align="right">{`iced:${row.price.concnCharge.iced || 0} hot:${
                      row.price.concnCharge.hot || 0
                    }`}</TableCell>
                    <TableCell align="right">{`iced:${row.price.sizeCharge.iced || 0} hot:${
                      row.price.sizeCharge.hot || 0
                    }`}</TableCell>
                    <TableCell align="right">{row.priority}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        );
      }
      default:
        return <div>....</div>;
    }
  };

  return (
    <>
      <div css={modal__btn}>
        <Button onClick={handleOpen} variant="contained" color="primary">
          미리보기
        </Button>
      </div>
      <Dialog open={open} onClose={handleClose} scroll="paper">
        <DialogTitle>미리보기</DialogTitle>
        <DialogContent>{printBody()}</DialogContent>
      </Dialog>
    </>
  );
}

const modal__btn = css`
  position: absolute;
  top: 110px;
  right: 180px;
`;

const combination__table__wrap = css`
  margin-top: 20px;
`;

const font__bold = css`
  font-size: 18px;
  font-weight: bold;
  margin: 5px;
`;
