import { BenefitType, Members } from './benefits';

export enum InputType {
  number = 'number',
  string = 'string',
  button = 'button',
  email = 'email',
  radio = 'radio',
}
interface Input {
  placeholder: string;
  name: string;
  value: null | number | string;
  inputType: InputType;
}

export enum ControllerType {
  'sticker',
  'coupon',
  'eventCoupon',
  'createUser',
  'vmRecipes',
  'members',
  'createMembers',
}
export interface UserControlData {
  type: ControllerType.coupon | ControllerType.sticker | ControllerType.eventCoupon;
  title: string;
  buttonTitle: string;
  phoneNum: string;
  inputs: Input[];
  disabled?: boolean;
}
export interface UserControlData {
  type: ControllerType.coupon | ControllerType.sticker | ControllerType.eventCoupon;
  title: string;
  buttonTitle: string;
  phoneNum: string;
  inputs: Input[];
  disabled?: boolean;
}

export interface VmRecipesControlData {
  type: ControllerType.vmRecipes;
  selectedBenefitType: BenefitType;
  vmId: string;
  vm: any;
}

export interface MembersControlData {
  type: ControllerType.members;
  selectedBenefitType: BenefitType;
  members: Members;
}

export interface CreateMembersData {
  type: ControllerType.createMembers;
  members: Members;
}

export type ControlData = UserControlData | VmRecipesControlData | MembersControlData | CreateMembersData;
