// 외부모듈
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleUp } from '@fortawesome/free-solid-svg-icons';

interface HeaderProps {
  isVisibleArrowBtn: boolean;
  moveToMainTop: () => void;
}

function Header({ isVisibleArrowBtn, moveToMainTop }: HeaderProps) {
  return (
    <header css={header__wrap}>
      <div>PLANZ</div>
      {isVisibleArrowBtn && (
        <div css={arrow__btn} onClick={moveToMainTop}>
          <FontAwesomeIcon size="2x" icon={faArrowAltCircleUp} />
        </div>
      )}
    </header>
  );
}

const header__wrap = css`
  height: 160px;
  width: 100vw;
  background-color: white;
  font-family: Times Sans Serif;
  font-size: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  border-bottom: 1px solid #666666;
`;

const arrow__btn = css`
  position: absolute;
  right: 20px;
  color: #d49c76;
`;

export default Header;
