// 외부모듈
import { useEffect, useRef } from 'react';
import { useHistory } from 'react-router-dom';

interface PathScrollValues {
  [key: string]: number;
}

interface Location {
  pathname: string;
  state: { scroll: number };
}

function useScrollMemory(mainEl: React.RefObject<HTMLElement>) {
  const pathScrollValues = useRef<PathScrollValues>({});
  const history = useHistory();
  const prevPath = useRef(history.location.pathname);

  useEffect(() => {
    const { push, replace } = history;

    history.push = (path: string) => {
      push(path, { scroll: mainEl.current ? mainEl.current.scrollTop : 0 });
    };

    history.replace = (path: string) => {
      push(path, { scroll: mainEl.current ? mainEl.current.scrollTop : 0 });
    };

    const unregister = history.listen((location: any) => {
      const { pathname, state }: Location = location;
      pathScrollValues.current = { ...pathScrollValues.current, [prevPath.current]: state.scroll };
      setTimeout(() => {
        // 스크롤이 생기는 dom에 scroll 값을 가져와야 합니다.  -최다빈

        if (mainEl.current) {
          mainEl.current.scrollTo(0, pathScrollValues.current[pathname] || 0);
        } else {
          window.scrollTo(0, 0);
        }
        prevPath.current = pathname;
      }, 50);
    });

    // Unregister listener when component unmounts.
    return () => {
      unregister();
    };
  }, [history]);
}

export default useScrollMemory;
