// 외부모듈
import React from 'react';
import { css } from '@emotion/core';
import LinearProgress from '@material-ui/core/LinearProgress';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/index';
import { animated, useSpring } from 'react-spring';

function ProcessModal() {
  const process = useSelector((state: RootStateType) => state.process);
  const { processingPercent, isProcessing } = process;
  const styles = useSpring({ opacity: isProcessing ? 1 : 0 });
  if (!isProcessing) {
    return null;
  }

  return (
    <animated.div css={process__modal__wrap} style={styles}>
      <p>요청한 작업을 수행중입니다.</p>
      <div>
        <LinearProgress variant="determinate" value={processingPercent} style={{ height: '20px', margin: '10px' }} />
        <p> {`${processingPercent} % `}</p>
      </div>
    </animated.div>
  );
}

const process__modal__wrap = css`
  position: absolute;
  top: 0px;
  right: 0px;
  border: 1px solid gray;
  border-radius: 25px;
  width: 40%;
  height: 230px;
  z-index: 10;
  background-color: rgba(89, 100, 106, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  font-family: Spoqa Han Sans;
  font-size: 20px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  color: white;
`;

export default ProcessModal;
