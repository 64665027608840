import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useFirebase, useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';

// 타입 정의
import { RootStateType } from '@reducers/index';
import {
  Container,
  ContainerHistoryType,
  ContainerHistoryVersion,
  ContainerStatus,
  ContainerType,
} from '@definitions/container';
import { Ingredient, IngredientType } from '@definitions/ingredient';
import { VendingMachine } from '@definitions/vm';
import ExpirationDateModal from './modal/ExpirationDateModal';
import { createExpirationDate } from '@utils/createExpirationDate';
import { getDateAfterNDays, getN } from 'app/utils/format';

const getTypeNameInKor = (containerType: string) => {
  switch (containerType) {
    case 'keg':
      return '커피용';
    case 'bottle':
      return '시럽/우유용';
    default:
      return '?';
  }
};

const getStatusInKor = (status: string) => {
  switch (status) {
    case 'empty':
      return '비워짐';
    case 'filled':
      return '할당 대기';
    case 'mounted':
      return '장착됨';
    case 'allocated':
      return '장착 대기';
    default:
      return '?';
  }
};

type ContainerItemProps = {
  container: Container;
  isPlanzAdmin: boolean;
};

type ContainerItemFilledProps = {
  id: string;
  type: string;
  status: string;
  ingredientId: string;
  src: string;
  amount: number;
  clear: (type: string) => void;
  allocate: (allocation: string) => void;
  filterValidVms: VendingMachine[];
};

type ContainerItemAllocatedProps = {
  id: string;
  type: string;
  status: string;
  ingredientId: string;
  src: string;
  amount: number;
  deallocate: () => void;
  isPlanzAdmin: boolean;
  allocation: string;
};

type ContainerItemMountedProps = {
  id: string;
  type: string;
  status: string;
  ingredientId: string;
  src: string;
  amount: number;
  mount: string;
};

type ContainerItemEmptyProps = {
  id: string;
  type: string;
  status: string;
  deprecate: () => void;
  fill: (ingredientId: string, amount: number, expirationDate: Date) => void;
  ingredientsArray: { id: string; titleKor: string }[];
};

function ContainerItemEmpty({ id, type, status, fill, deprecate, ingredientsArray }: ContainerItemEmptyProps) {
  const [ingredientId, setIngredientId] = useState<string>('');
  const [amount, setAmount] = useState<number>(0);
  const [open, setOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState<Date>(
    createExpirationDate(ingredientId ? getN(ingredientId) : 60),
  );

  const fillHandler = () => {
    setOpen(true);
  };

  const expirationDateHandler = (expirationDate: string) => {
    setExpirationDate(new Date(expirationDate));
  };

  const clickHandler = () => {
    setOpen(false);
    fill(ingredientId, amount, expirationDate);
  };

  const onChangeIngredientId = (ingredientId: string) => {
    setIngredientId(ingredientId);
    setExpirationDate(new Date(createExpirationDate(getN(ingredientId))));
  };

  const onChangeAmount = (amount: string) => {
    const amountNum = Number(amount);
    if (!isNaN(amountNum)) {
      setAmount(amountNum);
    }
  };

  return (
    <>
      <ExpirationDateModal
        open={open}
        onClose={() => setOpen(false)}
        onChange={expirationDateHandler}
        onClick={clickHandler}
        defaultDate={getDateAfterNDays(getN(ingredientId))}
      />
      <li key={id} css={container__item(status === ContainerStatus.filled)}>
        <div css={container__item__upper}>
          <div css={container__item__left}>
            <div css={container__item__left__upper} style={{ margin: 0 }}>
              <h1 style={{ margin: 0 }}>{id}</h1>
              <div>
                <p>
                  컨테이너 타입: <span style={{ fontWeight: 'bold' }}>{getTypeNameInKor(type)}</span>
                </p>
                <p>
                  컨테이너 상태: <span style={{ fontWeight: 'bold' }}>{getStatusInKor(status)}</span>
                </p>
              </div>
            </div>
            <div css={container__item__left__lower}>
              <div css={container__item__left__lower__body}>
                <label>
                  담을 재료 ID:
                  <select name="ingredientId" onChange={(e) => onChangeIngredientId(e.target.value)}>
                    <option value="">아래 재료중 선택해주세요</option>
                    {ingredientsArray.map((ingredient, idx) => (
                      <option key={idx} value={ingredient.id}>
                        {ingredient.titleKor}
                      </option>
                    ))}
                  </select>
                </label>
                <label>
                  담을 재료의 양 :
                  <input type="number" name="amount" size={20} onChange={(e) => onChangeAmount(e.target.value)} />
                </label>
              </div>
            </div>
          </div>

          <div css={container__item__right}>
            <button onClick={fillHandler}>주입</button>
            <button onClick={deprecate}>삭제</button>
          </div>
        </div>
      </li>
    </>
  );
}

function ContainerItemMounted({ id, type, ingredientId, src, status, amount, mount }: ContainerItemMountedProps) {
  return (
    <li key={id} css={container__item(status === ContainerStatus.mounted)}>
      <div css={container__item__upper}>
        <div css={container__item__left}>
          <div css={container__item__left__upper}>
            <h1>{id}</h1>
            <div>
              <p>
                컨테이너 타입: <span style={{ fontWeight: 'bold' }}>{getTypeNameInKor(type)}</span>
              </p>
              <p>
                컨테이너 상태: <span style={{ fontWeight: 'bold' }}>{getStatusInKor(status)}</span>
              </p>
            </div>
          </div>

          <div css={container__item__left__lower}>
            <img src={src} />

            <div css={container__item__left__lower__body}>
              <h2>담긴 재료: {ingredientId}</h2>
              <h2>할당 위치: {mount}</h2>
              <h2>담긴 양: {amount}ml</h2>
            </div>
          </div>
        </div>

        <div css={container__item__right}>
          <button
            onClick={() => {
              const selBox = document.createElement('textarea');
              selBox.style.position = 'fixed';
              selBox.style.left = '0';
              selBox.style.top = '0';
              selBox.style.opacity = '0';
              selBox.value = id;

              document.body.appendChild(selBox);
              selBox.focus();
              selBox.select();

              document.execCommand('copy');
              document.body.removeChild(selBox);
              window.alert('컨테이너 아이디가 복사되었습니다.');
            }}
          >
            복사
          </button>
        </div>
      </div>
    </li>
  );
}

function ContainerItemAllocated({
  id,
  type,
  status,
  ingredientId,
  allocation,
  src,
  amount,
  deallocate,
  isPlanzAdmin,
}: ContainerItemAllocatedProps) {
  return (
    <li key={id} css={container__item(status === ContainerStatus.allocated)}>
      <div css={container__item__upper}>
        <div css={container__item__left}>
          <div css={container__item__left__upper}>
            <h1>{id}</h1>
            <div>
              <p>
                컨테이너 타입: <span style={{ fontWeight: 'bold' }}>{getTypeNameInKor(type)}</span>
              </p>
              <p>
                컨테이너 상태: <span style={{ fontWeight: 'bold' }}>{getStatusInKor(status)}</span>
              </p>
            </div>
          </div>

          <div css={container__item__left__lower}>
            <img src={src} />

            <div css={container__item__left__lower__body}>
              <h2>담긴 재료: {ingredientId}</h2>
              <h2>할당 위치: {allocation}</h2>
              <h2>담긴 양: {amount}ml</h2>
            </div>
          </div>
        </div>

        <div css={container__item__right}>
          <button
            onClick={() => {
              const selBox = document.createElement('textarea');
              selBox.style.position = 'fixed';
              selBox.style.left = '0';
              selBox.style.top = '0';
              selBox.style.opacity = '0';
              selBox.value = id;

              document.body.appendChild(selBox);
              selBox.focus();
              selBox.select();

              document.execCommand('copy');
              document.body.removeChild(selBox);
              window.alert('컨테이너 아이디가 복사되었습니다.');
            }}
          >
            복사
          </button>
          {isPlanzAdmin && (
            <button
              onClick={() => {
                deallocate();
              }}
            >
              해제
            </button>
          )}
        </div>
      </div>
    </li>
  );
}

function ContainerItemFilled({
  id,
  type,
  ingredientId,
  src,
  amount,
  status,
  clear,
  allocate,
  filterValidVms,
}: ContainerItemFilledProps) {
  const [allocation, setAllocation] = useState<string>('');
  const onChangeAllocation = (vmId: string) => {
    setAllocation(vmId);
  };

  return (
    <li key={id} css={container__item(status === ContainerStatus.filled)} style={{ height: '680px' }}>
      <div css={container__item__upper}>
        <div css={container__item__left}>
          <div css={container__item__left__upper}>
            <h1>{id}</h1>
            <div>
              <p>
                컨테이너 타입: <span style={{ fontWeight: 'bold' }}>{getTypeNameInKor(type)}</span>
              </p>
              <p>
                컨테이너 상태: <span style={{ fontWeight: 'bold' }}>{getStatusInKor(status)}</span>
              </p>
            </div>
          </div>

          <div css={container__item__left__lower}>
            <img src={src} />

            <div css={container__item__left__lower__body}>
              <h2>담긴 재료: {ingredientId}</h2>
              <h2>담긴 양: {amount}ml</h2>
            </div>
          </div>
        </div>

        <div css={container__item__right}>
          <button
            onClick={() => {
              const selBox = document.createElement('textarea');
              selBox.style.position = 'fixed';
              selBox.style.left = '0';
              selBox.style.top = '0';
              selBox.style.opacity = '0';
              selBox.value = id;

              document.body.appendChild(selBox);
              selBox.focus();
              selBox.select();

              document.execCommand('copy');
              document.body.removeChild(selBox);
              window.alert('컨테이너 아이디가 복사되었습니다.');
            }}
          >
            복사
          </button>
          <button
            onClick={() => {
              clear(type);
            }}
          >
            비우기
          </button>
        </div>
      </div>
      <div css={container__item__lower}>
        <div css={container__item__lower__left}>
          <div
            style={{ width: '100%', display: 'flex', flexDirection: 'row', overflowX: 'hidden', paddingLeft: '32px' }}
          >
            <label style={{ marginRight: '24px' }}>할당할 장소:</label>
            <select style={{ width: '60%' }} name="amount" onChange={(e) => onChangeAllocation(e.target.value)}>
              <option value="">아래 vm중 선택해주세요</option>
              {filterValidVms.map((vm, idx) => (
                <option key={idx} value={vm.id}>
                  {vm.location.name}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div css={container__item__right}>
          <button
            onClick={() => {
              allocate(allocation);
            }}
          >
            할당
          </button>
        </div>
      </div>
    </li>
  );
}

function ContainerItem({ container, isPlanzAdmin }: ContainerItemProps): JSX.Element {
  const firestore = useFirestore();
  const ingredients = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  const filterValidVms = vms.reduce((acc: VendingMachine[], cur) => {
    if (admins[uid].level === 0) {
      acc.push(cur);
    } else if (admins[uid].level === 1 && !cur.id.includes('TEST') && !cur.id.includes('INIT')) {
      acc.push(cur);
    } else if (admins[uid].level === 2 && admins[uid].vms.includes(cur.id)) {
      acc.push(cur);
    }
    return acc;
  }, []);

  const ingredientsArray = Object.keys(ingredients).map((key) => ({ id: key, titleKor: ingredients[key].titleKor }));
  const fill = (ingredientId: string, amount: number, expirationDate: Date) => {
    if (ingredients[ingredientId] === undefined) {
      window.alert('잘못된 재료 아이디 입니다.');
      return;
    }
    const { type } = container;
    if (type === ContainerType.bottle && ingredients[ingredientId].type === IngredientType.craft) {
      window.alert('병 타입 컨테이너에는 크래프트 음료를 넣을 수 없습니다.');

      return;
    } else if (type === ContainerType.keg && ingredients[ingredientId].type === IngredientType.syrup) {
      window.alert('케그 타입 컨테이너에는 시럽 음료를 넣을 수 없습니다.');

      return;
    }

    if (!(expirationDate instanceof Date)) {
      window.alert('expirationDate 데이터 형식이 잘못되었습니다.');
      return;
    } else if (new Date() >= expirationDate) {
      window.alert('expirationDate은 현재시간보다 작을수 없습니다.');
      return;
    }

    const batch = firestore.batch();
    const ref = firestore.collection('version/v3/containers/').doc(container.id);

    batch.update(ref, {
      status: 'filled',
      ingredientId,
      amount,
      fillDate: new Date(),
      expirationDate,
    });

    batch.set(ref.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.fill,
      ingredientId,
      amount,
      timeStamp: new Date(),
      expirationDate,
      version: ContainerHistoryVersion,
    });

    batch.commit();
  };

  const deprecate = () => {
    const batch = firestore.batch();
    const ref = firestore.collection('version/v3/containers/').doc(container.id);

    const isConfirm = window.confirm(`${container.id}를 정말 삭제하시겠습니까?`);
    if (isConfirm) {
      batch.update(ref, { status: ContainerStatus.deprecated, id: container.id });
      batch.set(ref.collection('history').doc(), {
        auth: admins[uid],
        type: ContainerHistoryType.deprecate,
        timeStamp: new Date(),
        version: ContainerHistoryVersion,
      });

      batch.commit();
    } else {
      return;
    }
  };

  const clear = (type: string) => {
    const batch = firestore.batch();
    const ref = firestore.collection('version/v3/containers/').doc(container.id);

    batch.set(ref, {
      status: 'empty',
      type,
    });
    batch.set(ref.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.clear,
      timeStamp: new Date(),
      version: ContainerHistoryVersion,
    });
    batch.commit();
  };

  const allocate = (allocation: string) => {
    if (allocation.length !== 8) {
      window.alert('벤딩머신 아이디의 길이는 8입니다.');

      return;
    }
    if (allocation.slice(0, 4) !== 'BETA' && allocation.slice(0, 4) !== 'TEST') {
      window.alert('벤딩머신 아이디는 BETA/TEST로 시작합니다.');

      return;
    }
    if (isNaN(Number(allocation.slice(4)))) {
      window.alert('벤딩머신 아이디 나머지 4자리는 반드시 숫자형태이어야 합니다.');

      return;
    }

    if (vms.filter((vm) => vm.id === allocation).length === 0) {
      window.alert('존재하지 않는 VM ID입니다.');

      return;
    }

    const batch = firestore.batch();
    const ref = firestore.collection('version/v3/containers/').doc(container.id);

    batch.update(ref, {
      status: 'allocated',
      allocation,
      allocationDate: new Date(),
    });
    batch.set(ref.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.allocate,
      vmId: allocation,
      timeStamp: new Date(),
      version: ContainerHistoryVersion,
    });
    batch.commit();
  };

  const deallocate = () => {
    const batch = firestore.batch();
    const ref = firestore.collection('version/v3/containers/').doc(container.id);

    batch.update(ref, {
      status: 'filled',
      allocation: null,
    });
    batch.set(ref.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.deallocate,
      timeStamp: new Date(),
      version: ContainerHistoryVersion,
    });
    batch.commit();
  };

  if (container.status === ContainerStatus.filled) {
    return (
      <ContainerItemFilled
        id={container.id}
        type={container.type}
        status={container.status}
        ingredientId={ingredients[container.ingredientId].titleKor}
        src={ingredients[container.ingredientId] ? ingredients[container.ingredientId].image : ''}
        amount={container.amount}
        clear={clear}
        allocate={allocate}
        filterValidVms={filterValidVms}
      />
    );
  } else if (container.status === ContainerStatus.allocated) {
    const vm = vms.find(({ id }) => id === container.allocation) || { location: { name: container.allocation } };
    return (
      <ContainerItemAllocated
        id={container.id}
        type={container.type}
        status={container.status}
        ingredientId={ingredients[container.ingredientId].titleKor}
        src={ingredients[container.ingredientId] ? ingredients[container.ingredientId].image : ''}
        amount={container.amount}
        deallocate={deallocate}
        isPlanzAdmin={isPlanzAdmin}
        allocation={vm.location.name}
      />
    );
  } else if (container.status === ContainerStatus.mounted) {
    const vm = vms.find(({ id }) => id === container.mount) || { location: { name: container.allocation } };
    return (
      <ContainerItemMounted
        id={container.id}
        type={container.type}
        status={container.status}
        ingredientId={ingredients[container.ingredientId].titleKor}
        src={ingredients[container.ingredientId] ? ingredients[container.ingredientId].image : ''}
        amount={container.amount}
        mount={vm.location.name}
      />
    );
  } else {
    return (
      <ContainerItemEmpty
        ingredientsArray={ingredientsArray}
        id={container.id}
        type={container.type}
        status={container.status}
        fill={fill}
        deprecate={deprecate}
      />
    );
  }
}

const container__item = (isFilled: boolean) => css`
  width: 100vw;
  height: ${isFilled ? '580px' : '520px'};
  display: flex;
  flex-direction: column;
  background-color: #f8f7f5;
  /* padding-left: 24px;
  padding-right: 24px; */
  box-sizing: border-box;
  border-top: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
  font-family: Spoqa Han Sans;
`;

const container__item__upper = css`
  width: 100vw;
  height: 550px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-size: 36px;
  box-sizing: border-box;
`;

const container__item__lower = css`
  height: 164px;
  width: 100vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  box-sizing: border-box;
  border-top: 1px solid #dddddd;
  font-size: 42px;
  letter-spacing: -0.3px;
`;

const container__item__lower__left = css`
  flex: 4;
  display: flex;
  height: 100%;
  flex-direction: row;
  align-items: center;
  border-right: 1px dashed #cccccc;
  label {
    margin-right: 12px;
  }
  input {
    width: 50%;
    height: 40px;
    font-size: 28px;
    padding-left: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 5px 6px 0 rgba(141, 85, 37, 0.3);
  }
`;

const container__item__left = css`
  flex: 4;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-left: 24px;
  border-right: 1px dashed #cccccc;
`;
const container__item__left__upper = css`
  width: 100%;
  display: flex;
  flex: 1.2;
  flex-direction: column;
  align-items: flex-start;
  // margin-top: 36px;
  padding-bottom: 24px;
  margin-bottom: 24px;
  border-bottom: 1px dashed #cccccc;
  h1 {
    font-weight: bold;
    font-size: 54px;
    margin-bottom: 12px;
    letter-spacing: -0.3px;
  }

  h3 {
    font-size: 36px;
    font-weight: bold;
    letter-spacing: -0.3px;
    span {
      font-size: 36px;
      font-weight: normal;
      margin-right: 16px;
    }
  }
`;

const container__item__left__lower = css`
  display: flex;
  flex: 2;
  flex-direction: row;
  img {
    flex: 1;
    width: 160px;
    height: 200px;
    margin-right: 24px;
  }
`;

const container__item__left__lower__body = css`
  display: flex;
  flex: 5;
  flex-direction: column;
  justify-content: center;
  h2 {
    font-size: 42px;
    letter-spacing: -0.3px;
    margin-bottom: 24px;
  }
  label {
    font-size: 42px;
    letter-spacing: -0.3px;
    margin-bottom: 24px;
    input {
      width: 100%;
      height: 60px;
      font-size: 32px;
      padding-left: 12px;
      border: none;
      border-radius: 4px;
      box-shadow: 0 5px 6px 0 rgba(141, 85, 37, 0.3);
    }
    select {
      width: 100%;
      height: 60px;
      font-size: 32px;
      padding-left: 12px;
      border: none;
      border-radius: 4px;
      box-shadow: 0 5px 6px 0 rgba(141, 85, 37, 0.3);
    }
  }
`;

const container__item__right = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  button {
    width: 128px;
    height: 84px;
    border: none;
    border-radius: 10px;
    background-color: #b67d56;
    color: white;
    font-size: 32px;
  }
`;
export default ContainerItem;
