// 외부 모듈
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLeaf,
  faCube,
  faTools,
  faUserCog,
  faThList,
  faColumns,
  faPhone,
  faAngry,
} from '@fortawesome/free-solid-svg-icons';
import { useFirestoreConnect } from 'react-redux-firebase';
import { Route, Switch, useHistory } from 'react-router';

// 내부모듈
import FindOrderHistory from './FindOrderHistory';
import UserForm from './UserForm';
import Ingredients from './Ingredients';
import Containers from './Containers';
import Tasks from './Tasks';
import VendingMachines from './VendingMachines';
import Header from './Header';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/index';
import CircleProgressBar from './CircleProgressBar';
import useScrollMemory from 'app/hooks/useScrollMemory';
import Dashboard from './Dashboard';
import CSnumber from './CSnumber';
// import GlobalBottomSheet from './common/BottomSheetSelect';

interface Tab {
  id: string;
  icon: JSX.Element;
  url: string;
  isSelected: boolean;
}

function Main(this: any) {
  useFirestoreConnect(() => [
    { collection: 'version/v3/ingredients' },
    { collection: 'version/v3/containers' },
    { collection: 'version/v3/admins' },
    { collection: 'version/v3/vms' },
    { collection: 'version/v3/recipes' },
    { collection: 'version/v3/members' },
  ]);

  const history = useHistory();
  const {
    location: { pathname },
  } = history;
  const { sagaInit } = useSelector((state: RootStateType) => state);

  // 최상단으로 올라가는 버튼 랜더링 여부를 표시하는 플래그 상태값 입니다. -최다빈
  const [isVisibleArrowBtn, setIsVisibleArrowBtn] = useState(false);
  const mainEl = useRef<HTMLElement>(null);

  useScrollMemory(mainEl);

  const [tabs, setTabs] = useState([
    {
      id: 'Ingredients',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faLeaf} />,
      url: '/Ingredients',
      isSelected: '/Ingredients' === pathname,
    },
    {
      id: 'Containers',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faCube} />,
      url: '/Containers',
      isSelected: '/Containers' === pathname,
    },
    {
      id: 'VendingMachines',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faTools} />,
      url: '/VendingMachines',
      isSelected: '/VendingMachines' === pathname,
    },
    {
      id: 'User',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faUserCog} />,
      url: '/UserForm',
      isSelected: '/UserForm' === pathname,
    },
    {
      id: 'Tasks',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faAngry} />,
      url: '/Tasks',
      isSelected: '/Tasks' === pathname,
    },
    {
      id: 'OrderHistory',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faThList} />,
      url: '/OrderHistory',
      isSelected: '/OrderHistory' === pathname,
    },
    {
      id: 'Dashboard',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faColumns} />,
      url: '/Dashboard',
      isSelected: '/Dashboard' === pathname,
    },
    {
      id: 'CSnumber',
      icon: <FontAwesomeIcon css={main__footer__tab__icon} size="5x" icon={faPhone} />,
      url: '/CSnumber',
      isSelected: '/CSnumber' === pathname,
    },
  ]);
  const onClickTab = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, selectedTab: Tab) => {
    e.preventDefault();
    setTabs(
      tabs.map((tab) => {
        if (tab.id === selectedTab.id) {
          return { ...selectedTab, isSelected: true };
        } else {
          return { ...tab, isSelected: false };
        }
      }),
    );
    history.push(selectedTab.url);
  };

  // 스크롤시 최상단으로 이동할수 있는 버튼을 보여줄지 말지 판단하는 함수입니다. - 최다빈
  const mainScrollHanlder = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    if (mainEl.current) {
      const { currentTarget } = e;
      if (currentTarget.scrollTop > 0 && !isVisibleArrowBtn) {
        setIsVisibleArrowBtn(true);
      } else if (currentTarget.scrollTop === 0) {
        setIsVisibleArrowBtn(false);
      }
    }
  };
  // 위로가기 버튼 클릭시 맨 상단으로 이동하는 함수
  const moveToMainTop = () => {
    if (mainEl.current) {
      mainEl.current.scrollTo(0, 0);
    }
  };

  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);

  // if (admins && uid && admins[uid].type === 'CORP') {
  //   return <div>권한없음</div>;
  // }
  return (
    <div css={main__wrap}>
      <Header isVisibleArrowBtn={isVisibleArrowBtn} moveToMainTop={moveToMainTop} />
      {sagaInit ? (
        admins[uid].type === 'CORP' ? (
          <div>권한없음</div>
        ) : (
          <>
            <main onScroll={(e) => mainScrollHanlder(e)} ref={mainEl}>
              <Switch>
                <Route exact path="/" component={Ingredients} />
                <Route path="/Ingredients" component={Ingredients} />
                <Route path="/Containers" component={Containers} />
                <Route path="/VendingMachines" component={VendingMachines} />
                <Route path="/UserForm" component={UserForm} />
                <Route path="/Tasks" component={Tasks} />
                <Route path="/OrderHistory" component={FindOrderHistory} />
                <Route path="/Dashboard" component={Dashboard} />
                <Route path="/CSnumber" component={CSnumber} />
              </Switch>
            </main>
            <nav>
              {tabs
                .filter((tab) =>
                  admins[uid].level === 2 ? tab.id !== 'CSnumber' && tab.id !== 'Dashboard' && tab.id !== 'User' : tab,
                )
                .map((tab) => (
                  <div css={main__footer__tab(tab.isSelected)} onClick={(e) => onClickTab(e, tab)} key={tab.id}>
                    {tab.icon}
                    {tab.id}
                  </div>
                ))}
            </nav>
          </>
        )
      ) : (
        <CircleProgressBar />
      )}
      {/* <GlobalBottomSheet /> */}
    </div>
  );
}

const main__wrap = css`
  width: 100vw;
  height: 100vh;
  font-family: Open Sans;
  display: flex;
  flex-direction: column;
  main {
    display: flex;
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
  }
  nav {
    height: 200px;
    width: 100vw;
    background-color: white;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    border-top: solid 1px #666666;
    z-index: 1;
    overflow-x: scroll;
  }
`;

const main__footer__tab = (isSelected: boolean) => css`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${isSelected ? '#d49c76' : '#666666'};
  font-size: 24px;
  margin-left: 10px;
`;

const main__footer__tab__icon = css`
  width: 75px;
  height: 75px;
  margin-bottom: 18px;
`;
export default Main;
