// 외부 모듈
import React, { useEffect, useState } from 'react';
import { css, Global } from '@emotion/core';
import SiginIn from '@containers/SignIn';

// 내부 모듈
import '../global.css';

// Times Font
import timesSansSerifReg from '@resources/fonts/times_sans_serif_reg.ttf';

// Spoqa Fonts
import spoqaHanSansBold from '@resources/fonts/spoqa_han_sans_bold.ttf';
import spoqaHanSansReg from '@resources/fonts/spoqa_han_sans_reg.ttf';
import spoqaHanSansLight from '@resources/fonts/spoqa_han_sans_light.ttf';

// Open Sans Fonts
import openSansBold from '@resources/fonts/open_sans_bold.ttf';
import openSansReg from '@resources/fonts/open_sans_reg.ttf';
import openSansLight from '@resources/fonts/open_sans_light.ttf';
import { useFirebase } from 'react-redux-firebase';
import { User } from 'firebase';
import Main from '@containers/Main';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/index';

const globalStyles = css`
  .App {
    @font-face {
      font-family: 'Times Sans Serif';
      src: url(${timesSansSerifReg}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
    }
    @font-face {
      font-family: 'Spoqa Han Sans';
      src: url(${spoqaHanSansReg}) format('truetype');
    }
    @font-face {
      font-family: 'Spoqa Han Sans';
      src: url(${spoqaHanSansBold}) format('truetype');
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
    }
    @font-face {
      font-family: 'Spoqa Han Sans';
      src: url(${spoqaHanSansLight}) format('truetype');
      font-weight: 100;
      font-style: normal;
      font-stretch: normal;
    }
    @font-face {
      font-family: 'Open Sans';
      src: url(${openSansReg}) format('truetype');
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
    }
    @font-face {
      font-family: 'Open Sans';
      src: url(${openSansBold}) format('truetype');
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
    }
    @font-face {
      font-family: 'Open Sans';
      src: url(${openSansLight}) format('truetype');
      font-weight: 100;
      font-style: normal;
      font-stretch: normal;
    }
  }
`;

export default function App() {
  const firebase = useFirebase();
  const [user, setUser] = useState<User>();
  const { sagaInit } = useSelector((state: RootStateType) => state);

  const { isLoaded, isEmpty } = useSelector(({ firebase: { auth } }: RootStateType) => auth);
  // const ingredients = user && useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);
  // const containers = user && useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/containers']);
  // const vms = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/vms']);

  const auth = firebase.auth();
  useEffect(() => {
    /**
     * When the App component mounts, we need to listen for any authentication state changes
     * in Firebase.
     * Once subscribed, the 'user' parameter will either be null (logged out) or an Object (logged in)
     */
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        setUser(user);
      }
    });
    /**
     * When the App component unmounts, we need to stop listening for any authentication state changes
     * in Firebase.
     */

    return function cleanUp() {
      unsubscribe();
    };
  });

  // if (!ingredients || !containers || !vms) {
  //   return <div>splash</div>;
  // }

  return (
    <div>
      <Global styles={globalStyles} />
      {isLoaded ? !isEmpty ? <Main /> : <SiginIn /> : <div>splash</div>}
    </div>
  );
}
