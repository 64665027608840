import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import TextField from '@material-ui/core/TextField';
import Fade from '@material-ui/core/Fade';
import Button from '@material-ui/core/Button';
import { getDateAfterNDays } from 'app/utils/format';

interface ExpirationDateModalProps {
  open: boolean;
  onClose: () => void;
  onChange?: (expirationDate: string) => void;
  onClick?: () => void;
  defaultDate: string;
}

function ExpirationDateModal({ open, onClose, onChange, onClick, defaultDate }: ExpirationDateModalProps) {
  const dateHandler = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    if (onChange) {
      const expirationDate = `${e.target.value}T23:59:59`;
      onChange(expirationDate);
    }
  };

  return (
    <div css={shelf__life__modal__wrap}>
      <Modal
        open={open}
        onClose={onClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
        css={modal__wrap}
      >
        <Fade in={open}>
          <div css={fade__wrap}>
            <h2 css={text__bold}>유통기간을 설정해주세요</h2>
            <TextField
              style={{ width: '100%', fontSize: '38px' }}
              label="유통기간"
              type="date"
              InputProps={{ style: { fontSize: '38px' } }}
              InputLabelProps={{
                shrink: true,
                style: { fontSize: 30 },
              }}
              onChange={(e) => dateHandler(e)}
              defaultValue={defaultDate}
            />
            <button onClick={onClick} css={btn__style}>
              적용
            </button>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const shelf__life__modal__wrap = css`
  position: absolute;
  top: 0px;
  left: 0px;
`;

const modal__wrap = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const text__bold = css`
  font-size: 38px;
  font-weight: bold;
`;

const fade__wrap = css`
  width: 70vw;
  height: 300px;
  background-color: #fff;
  border: '2px solid #000';
  text-align: center;
  margin: 0 auto;
  font-size: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
`;

const btn__style = css`
  width: 100px;
  height: 70px;
  font-size: 32px;
  background-color: #b67d56;
  color: #fff;
  border: none;
  border-radius: 5px;
  outline: 0;
`;

export default ExpirationDateModal;
