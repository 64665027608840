import React, { useState } from 'react';
import { css } from '@emotion/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import VmStatus from './Status';
import VmPolicies from './VmPolicies';
import VmPaymentWays from './VmPaymentWays';
import { Keg, Module, PaymentWay, Policies, Pump, Status, VendingMachine } from '@definitions/vm';
import { Instructions } from './Instructions/Instructions';
import { Container } from '@definitions/container';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface VmTabsProps {
  id: string;
  ip: string;
  connectionType: string;
  policies: Policies;
  status: Status;
  paymentWays: PaymentWay[];
  clear: (id: string) => void;
  policiesHandler: (id: string, policies: Policies) => void;
  paymentWaysHandler: (id: string, paymentWays: PaymentWay[]) => void;
  adminConnectHandler: (id: string, status: any) => void;
  modules: Module[];
  containers: (Keg | Pump)[];
  dispensers: { mountPosition: number }[];
}

// 탬 패널
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

function VmTabs({
  id,
  ip,
  connectionType,
  policies,
  status,
  paymentWays,
  clear,
  policiesHandler,
  paymentWaysHandler,
  adminConnectHandler,
  modules,
  containers,
  dispensers,
}: VmTabsProps) {
  const [tabIdx, setTabIdx] = useState(0);

  const tabHandler = (event: React.ChangeEvent<{}>, newValue: number) => {
    setTabIdx(newValue);
  };

  return (
    <div css={tabs__wrap}>
      <Tabs value={tabIdx} onChange={tabHandler} indicatorColor="primary" textColor="primary">
        <Tab label="Status" />
        <Tab label="Policies" />
        <Tab label="PaymentWays" />
      </Tabs>
      <TabPanel value={tabIdx} index={0}>
        <VmStatus
          status={status}
          clear={() => clear(id)}
          adminConnectHandler={adminConnectHandler}
          id={id}
          modules={modules}
          ip={ip}
          connectionType={connectionType}
          containers={containers}
          dispensers={dispensers}
        />
      </TabPanel>
      <TabPanel value={tabIdx} index={1}>
        <VmPolicies policies={policies} policiesHandler={policiesHandler} id={id} />
      </TabPanel>
      <TabPanel value={tabIdx} index={2}>
        <VmPaymentWays paymentWays={paymentWays} paymentWaysHandler={paymentWaysHandler} id={id} />
      </TabPanel>
    </div>
  );
}

const tabs__wrap = css`
  width: 100%;
  height: 200px;

  .MuiTab-textColorPrimary {
    color: black;
    background-color: #fff;
    margin-right: 5px;
  }
`;

export default VmTabs;
