// 외부모듈
import React from 'react';
import { css } from '@emotion/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Backdrop } from '@material-ui/core';

function CircleProgressBar() {
  return (
    <div css={circle__progress__bar__wrap}>
      <Backdrop open={true}>
        <CircularProgress style={{ width: '100px', height: '100px' }} />
      </Backdrop>
    </div>
  );
}

const circle__progress__bar__wrap = css`
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0px;
  justify-content: center;
  align-self: center;
  z-index: 2;
`;

export default CircleProgressBar;
