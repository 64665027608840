//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send } from '@material-ui/icons';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';

interface SelectVmProps {
  data: { [key: string]: any };
  onClickHandler: (vm: string) => void;
  initHandler: () => void;
}

function SelectVm({ data, onClickHandler, initHandler }: SelectVmProps) {
  const [curVm, setCurVm] = useState('');
  const vmList = Object.keys(data);

  const onChangeHandler = (e: React.ChangeEvent<any>, value: string) => {
    setCurVm(value);
    initHandler();
  };

  useEffect(() => {
    initHandler();
  }, [curVm]);

  return (
    <section css={select__wrap}>
      <Autocomplete
        options={vmList}
        style={{ width: '500px' }}
        noOptionsText="해당 값이 존재하지 않습니다."
        onInputChange={(e, value) => onChangeHandler(e, value)}
        renderInput={(params) => <TextField {...params} label="관리 할 이름 또는 Vm을 입력해주세요" />}
      />
      {curVm !== '' && (
        <Button
          variant="contained"
          color="primary"
          style={{ marginTop: '10px', marginLeft: '15px' }}
          onClick={() => onClickHandler(curVm)}
        >
          관리
        </Button>
      )}
    </section>
  );
}

export default SelectVm;

const select__wrap = css`
  margin-top: 30px;
  display: flex;
`;
