/* eslint-disable curly */

// 외부모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { TextField, Typography, Button } from '@material-ui/core';
import { GridToolbarContainer, useGridSlotComponentProps } from '@material-ui/data-grid';

// 내부모듈
import { firestore } from 'app/common/firebase';
import { CorpGiftWallet } from '@definitions/wallet';
import { Admin } from '@definitions/admins';
import { createChargeData } from './utils/createChargeData';
import { getCorpGiftWallets } from '.';

interface WalletToolbarProps {
  wallets: CorpGiftWallet[];
  setReload: () => void;
  handleLoading: (bol: boolean) => void;
  auth: Admin;
  uid: string;
}

function WalletToolbar({ wallets, setReload, handleLoading, auth, uid }: WalletToolbarProps) {
  const { state } = useGridSlotComponentProps();
  const selectedWalletNum = state.selection.length;

  const [addPoint, setAddPoint] = useState(0);

  const handlePointAddInput = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { value } = e.target;
    setAddPoint(Number(value));
  };

  const slicerDefaultOption = {
    per: 200,
  };

  function slicer<T>(list: T[], options = slicerDefaultOption): T[][] {
    const { per } = options;
    return list.reduce((acc, curr, i) => {
      if (i % per === 0) {
        acc.push([]);
      }
      acc[acc.length - 1].push(curr);
      return acc;
    }, [] as T[][]);
  }

  const handlePointAddSubmit = async () => {
    if (typeof addPoint !== 'number' || addPoint <= 0 || addPoint > 1000000) {
      window.alert('충전할 수 있는 금액의 범위는 1원 부터 1,000,000 입니다.');
      return;
    } else {
      const confirm = window.confirm(`${state.selection.length}개의 지갑에 ${addPoint} 포인트를 충전하시겠습니까?`);

      if (confirm) {
        handleLoading(true);
        const seletedWallets = wallets.filter((wallet) => state.selection.includes(wallet.walletId));
        const dimensionalWallets = slicer(seletedWallets);

        for (const wallets of dimensionalWallets) {
          const batch = firestore.batch();

          wallets.map((wallet) => {
            batch.update(firestore.doc(`version/v3/wallets/${wallet.walletId}`), { point: wallet.point + addPoint });
            batch.set(
              firestore.collection(`version/v3/wallets/${wallet.walletId}/charges`).doc(),
              createChargeData(auth, uid, addPoint),
            );
          });

          await batch.commit();
        }

        setReload();
      }
    }
  };

  const handleResetPoint = async () => {
    const confirm = window.confirm(`${state.selection.length}개의 지갑 포인트를 초기화 하시겠습니까?`);

    if (confirm) {
      handleLoading(true);
      const seletedWallets = wallets.filter((wallet) => state.selection.includes(wallet.walletId));
      const dimensionalWallets = slicer(seletedWallets);

      for (const wallets of dimensionalWallets) {
        const batch = firestore.batch();
        wallets.map((wallet) => {
          batch.update(firestore.doc(`version/v3/wallets/${wallet.walletId}`), { point: 0 });
          batch.set(
            firestore.collection(`version/v3/wallets/${wallet.walletId}/charges`).doc(),
            createChargeData(auth, uid, addPoint),
          );
        });

        await batch.commit();
      }

      setReload();
    }
  };

  const handleRemoveWallet = async () => {
    if (state.selection.length === 0) return;
    else {
      const confirm = window.confirm(`${state.selection.length}개의 지갑을 삭제하시겠습니까?`);

      if (confirm) {
        handleLoading(true);

        const walletsRef = await getCorpGiftWallets(auth.vms);
        const seletedWalletsRef = walletsRef.docs.filter((walletDoc) => state.selection.includes(walletDoc.id));
        const dimensionalWallets = slicer(seletedWalletsRef, { per: 50 });

        for (const walletsRef of dimensionalWallets) {
          const batch = firestore.batch();

          await Promise.all(
            walletsRef.map(async (walletDoc) => {
              const v3Ref = walletDoc.ref.parent.parent;
              if (v3Ref) {
                batch.set(v3Ref.collection('removeWallets').doc(walletDoc.id), { ...walletDoc.data() });
                const chargesRef = await walletDoc.ref.collection('charges').get();

                chargesRef.docs.map((chargeDoc) => {
                  batch.set(
                    v3Ref.collection('removeWallets').doc(walletDoc.id).collection('charges').doc(chargeDoc.id),
                    {
                      ...chargeDoc.data(),
                    },
                  );
                  batch.delete(chargeDoc.ref);
                });

                batch.delete(walletDoc.ref);
              }
            }),
          );

          await batch.commit();
        }

        setReload();
      }
    }
  };

  return selectedWalletNum > 0 ? (
    <GridToolbarContainer css={tooltip__container}>
      <Typography>{selectedWalletNum}개의 지갑이 선택되었습니다.</Typography>
      <form css={tooltip__form}>
        <TextField
          type="number"
          style={{ margin: '0px 15px', width: '200px' }}
          onChange={(e) => handlePointAddInput(e)}
          placeholder="충전할 포인트를 입력하세요"
          error={addPoint <= 0 || typeof addPoint !== 'number'}
          helperText={addPoint <= 0 || typeof addPoint !== 'number' ? '포인트는 양수만 충전 가능합니다' : null}
        />
        <Button className="addPointBtn" variant="contained" onClick={handlePointAddSubmit} color="primary">
          선택된 지갑 일괄 충전하기
        </Button>
      </form>
      <Button variant="contained" onClick={handleRemoveWallet} color="secondary">
        선택된 지갑 일괄 삭제
      </Button>
      <Button variant="contained" onClick={handleResetPoint} color="secondary" style={{ marginLeft: '15px' }}>
        선택된 지갑 포인트 초기화
      </Button>
    </GridToolbarContainer>
  ) : (
    <GridToolbarContainer>
      <Typography>선택된 지갑이 없습니다.</Typography>
    </GridToolbarContainer>
  );
}

export default WalletToolbar;

const tooltip__container = css`
  height: 100px;
`;

const tooltip__form = css`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 15px;
`;
