// 외부모듈
import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/core';

// 외부모듈
import { NumberOfDiscounts, PriceTarget, TermDiscountByGroup } from '@definitions/benefits';
import { ConcnCharge, SizeCharge } from '@definitions/recipes';

interface PriceInputsPros {
  toggleHandler: any;
  inputHandler: any;
  selectHandler: any;
  checkedRightHandler: any;
  target: PriceTarget;
  controlType?: 'VM' | 'MEMBERS';
}

function PriceInputs({
  toggleHandler,
  inputHandler,
  selectHandler,
  checkedRightHandler,
  target,
  controlType,
}: PriceInputsPros) {
  const { id, price, priority, conditions } = target;
  const { basic, concnCharge, sizeCharge } = price;
  const condition = conditions[0] as TermDiscountByGroup | NumberOfDiscounts | undefined;
  const isAvailableCondition = controlType === 'MEMBERS';

  return (
    <div css={input__controller__wrap}>
      <div>
        <span>{id}</span>
        <Button
          color="secondary"
          startIcon={<FontAwesomeIcon size="1x" icon={faTrash} />}
          onClick={toggleHandler(target)}
        ></Button>
      </div>
      <TextField label="기본(Iced)" name="basic/iced" value={basic.iced || 0} onChange={(e) => inputHandler(id, e)} />
      <TextField label="기본(Hot)" name="basic/hot" value={basic.hot || 0} onChange={(e) => inputHandler(id, e)} />
      <FormControl margin="normal">
        <InputLabel style={{ top: '-12px' }}>SizeType</InputLabel>
        <Select
          style={{ width: '90px' }}
          name="sizeCharge"
          onChange={(e) => selectHandler(id, e)}
          value={sizeCharge.type || 'size_none'}
        >
          <MenuItem value="size_both">Both</MenuItem>
          <MenuItem value="size_iced">IcedOnly</MenuItem>
          <MenuItem value="size_hot">HotOnly</MenuItem>
          <MenuItem value="size_none">None</MenuItem>
        </Select>
      </FormControl>
      {(sizeCharge.type === SizeCharge.iced || sizeCharge.type === SizeCharge.both) && (
        <TextField
          label="Iced"
          name="sizeCharge/iced"
          value={sizeCharge.iced || 0}
          onChange={(e) => inputHandler(id, e)}
        />
      )}
      {(sizeCharge.type === SizeCharge.hot || sizeCharge.type === SizeCharge.both) && (
        <TextField
          type="number"
          label="Hot"
          name="sizeCharge/hot"
          value={sizeCharge.hot || 0}
          onChange={(e) => inputHandler(id, e)}
        />
      )}
      <FormControl margin="normal">
        <InputLabel style={{ top: '-12px' }}>ConcnType</InputLabel>
        <Select
          style={{ width: '100px' }}
          name="concnCharge"
          onChange={(e) => selectHandler(id, e)}
          value={concnCharge.type || 'concn_none'}
        >
          <MenuItem value={'concn_both'}>Both</MenuItem>
          <MenuItem value="concn_iced">IcedOnly</MenuItem>
          <MenuItem value="concn_hot">HotOnly</MenuItem>
          <MenuItem value="concn_none">None</MenuItem>
        </Select>
      </FormControl>
      {(concnCharge.type === ConcnCharge.iced || concnCharge.type === ConcnCharge.both) && (
        <TextField
          label="Iced"
          name="concnCharge/iced"
          value={concnCharge.iced || 0}
          onChange={(e) => inputHandler(id, e)}
        />
      )}
      {(concnCharge.type === ConcnCharge.hot || concnCharge.type === ConcnCharge.both) && (
        <TextField
          label="Hot"
          name="concnCharge/hot"
          value={concnCharge.hot || 0}
          onChange={(e) => inputHandler(id, e)}
        />
      )}
      {isAvailableCondition && (
        <FormControl margin="normal">
          <InputLabel style={{ top: '-12px' }}>Condition(선택)</InputLabel>
          <Select
            style={{ width: '100px' }}
            name="condition"
            onChange={(e) => selectHandler(id, e)}
            value={condition ? condition.type : 'condition/none'}
          >
            <MenuItem value="termDiscountByGroup">termDiscountByGroup</MenuItem>
            <MenuItem value="condition/none">None</MenuItem>
          </Select>
          {condition && (
            <TextField
              label="Count"
              name="condition/count"
              value={condition ? condition.count : 1}
              onChange={(e) => inputHandler(id, e)}
            />
          )}
          {condition && (
            <TextField
              label="termOfDay"
              name="condition/termOfDay"
              value={condition ? condition.termOfDay : 1}
              onChange={(e) => inputHandler(id, e)}
            />
          )}
        </FormControl>
      )}
      <TextField
        label="priority"
        name="priority"
        type="number"
        value={Number(priority) || 0}
        onChange={(e) => inputHandler(id, e)}
      />
      <Button variant="contained" color="primary" style={{ marginTop: '15px' }} onClick={checkedRightHandler}>
        추가
      </Button>
    </div>
  );
}

const input__controller__wrap = css`
  width: 100vw;
  white-space: nowrap;
`;

export default PriceInputs;
