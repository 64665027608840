export enum RecipeCategory {
  original = 'Planz',
  roastery = 'Roastery',
}

export enum RoastingPoint {
  light = 0,
  medium = 1,
  mediumDark = 2,
  dark = 3,
  veryDark = 4,
}

export enum ConcnCharge {
  none = 'concn_none',
  iced = 'concn_iced',
  hot = 'concn_hot',
  both = 'concn_both',
}

export enum SizeCharge {
  none = 'size_none',
  iced = 'size_iced',
  hot = 'size_hot',
  both = 'size_both',
}

export enum MountPosition {
  keg0 = 0,
  keg1 = 1,
  keg2 = 2,
  keg3 = 3,
  keg4 = 4,
  keg5 = 5,
  keg6 = 6,
  keg7 = 7,
  keg8 = 8,
  keg9 = 9,
  pump0 = 100,
  pump1 = 101,
  pump2 = 102,
  pump3 = 103,
  pump4 = 104,
  pump5 = 105,
  pump6 = 106,
  pump7 = 107,
  pump8 = 108,
  pump9 = 109,
  water = 1001,
  hotWater = 1002,
  milk0 = 2000,
  milk1 = 2001,
  ice = 1003,
}

export enum Metric {
  ml = 1,
  l = 1000,
  g = 1,
  kg = 1000,
  sec = 1,
}

export interface ConditionType {
  syrupAdjustable: boolean;
}

export enum IngredientTypes {
  craft = 'craft',
  syrup = 'syrup',
  etc = 'etc',
}

export interface IngredientType {
  id: string;
  image: string;
  titleKor: string;
  type: IngredientTypes;
}

export type CombinationObjectType = {
  startAt: number;
  ingredientId: string;
  mountPosition: MountPosition;
  amount: number;
  metric: Metric;
};

export interface CustomCombinationObjectType extends CombinationObjectType {
  isComplete: boolean;
  startTime?: Date;
  endTime?: Date;
}

export enum IngredientId {
  ice = 'ice',
  hotWater = 'hotWater',
  milk = 'milk',
}

export interface IcedCombination {
  iced: CombinationObjectType[];
}

export interface HotCombination {
  hot: CombinationObjectType[];
}

interface BothCombination {
  iced: CombinationObjectType[];
  hot: CombinationObjectType[];
}

export enum TagType {
  new = 'NEW',
  best = 'BEST',
  event = 'EVENT',
  soldout = 'SOLD OUT',
}

export enum BeverageGroup {
  coffee = 'COFFEE',
  nonCoffee = 'NON-COFFEE',
}

export enum BeverageType {
  icedOnly = 'ICED_ONLY',
  hotOnly = 'HOT_ONLY',
  both = 'ICED_HOT_BOTH',
}

export interface OriginalCoffeeRecipeDetail {
  name: string;
  image: string;
  simpleInfo: string;
  tagType: TagType;
  beverageGroup: BeverageGroup.coffee;
  summary: string;
  roastingPoint: RoastingPoint;
  idleImage: string;
  mainImage: string;
}

export interface OriginalNonCoffeeRecipeDetail {
  name: string;
  image: string;
  simpleInfo: string;
  tagType: TagType;
  beverageGroup: BeverageGroup.nonCoffee;
  summary: string;
  idleImage: string;
  mainImage: string;
}

export type OriginalRecipeDetail = OriginalCoffeeRecipeDetail | OriginalNonCoffeeRecipeDetail;

export interface OriginalrecipeItemType {
  id: string;
  name: string;
  image: string;
  imageAltText: string;
  simpleInfo: string;
  price: number;
  tagType?: TagType;
  isSelected: boolean;
  isSoldout: boolean;
  category: RecipeCategory;
}

// Roastery 타입
export interface RoasteryRecipeDetail {
  name: string;
  logoImage: string;
  beanImage: string;
  roasteryImage: string;
  idleImage: string;
  video: string;
  beanSimpleName: string;
  beanFullName: string;
  instar: InstarDetailType;
  flavorTags: string[];
  address: AddressDetailType;
  summary: string;
}
export interface AddressDetailType {
  lat: number;
  lon: number;
  addressStr: string;
}
export interface InstarDetailType {
  id: string;
  totalLikes: string;
  totalHashTags: string;
  totalFollowers: string;
  images: string[];
}
// mockRoasteryRecipeList 을 통해 나온 리스트
export interface RoasteryRecipeItemType extends RoasteryRecipeDetail {
  id: string;
  isSoldout: boolean;
  price: number;
}

interface SizeNone {
  type: SizeCharge.none;
}

interface SizeIced {
  type: SizeCharge.iced;
  iced: number;
}

interface SizeHot {
  type: SizeCharge.hot;
  hot: number;
}

interface SizeBoth {
  type: SizeCharge.both;
  iced: number;
  hot: number;
}

export type SizeChargeType = SizeNone | SizeIced | SizeHot | SizeBoth;
/*
  concentration(진하기) 조절 타입
  총 4가지 타입이 존재
  none: 진하기 조절이 안되는 타입
  iced: 얼음 음료만 진하기 조절이 가능한 타입
  hot: 뜨거운 음료만 진하기 조절이 가능한 타입
  both: 얼음, 뜨거운 음료 둘다 진하기 조절이 가능한 타입
**/
interface ConcnNone {
  type: ConcnCharge.none;
}

interface ConcnHot {
  type: ConcnCharge.hot;
  hot: number;
}

interface ConcnIced {
  type: ConcnCharge.iced;
  iced: number;
}

interface ConcnBoth {
  type: ConcnCharge.both;
  iced: number;
  hot: number;
}

export type ConcnChargeType = ConcnNone | ConcnHot | ConcnIced | ConcnBoth;

/*
  Price(가격) 조절 타입
  음료는 Iced만 되거나, Hot만 되거나, 둘다되는 경우가 존재(둘다 안되는 경우는 없음)
**/
interface IcedPriceType {
  basic: {
    iced: number;
  };
  sizeCharge: SizeChargeType;
  concnCharge: ConcnChargeType;
}

export interface HotPriceType {
  basic: {
    hot: number;
  };
  sizeCharge: SizeChargeType;
  concnCharge: ConcnChargeType;
}

export interface BothPriceType {
  basic: {
    iced: number;
    hot: number;
  };
  sizeCharge: SizeChargeType;
  concnCharge: ConcnChargeType;
}

export interface IcedCustomCombination {
  iced_m: CustomCombinationObjectType[];
  iced_xl?: CustomCombinationObjectType[];
}
export interface HotCustomCombination {
  hot_m: CustomCombinationObjectType[];
}
export interface BothCustomCombination {
  iced_m: CustomCombinationObjectType[];
  iced_xl?: CustomCombinationObjectType[];
  hot_m: CustomCombinationObjectType[];
}

export type PriceType = BothPriceType | IcedPriceType | HotPriceType;
type DetailType = OriginalRecipeDetail | RoasteryRecipeDetail;

interface IceOnlyOriginalRecipeType {
  id: string;
  category: RecipeCategory.original;
  isSoldout: boolean;
  price: IcedPriceType;
  beverageType: BeverageType.icedOnly;
  detail: OriginalRecipeDetail;
  condition: ConditionType;
  combination: IcedCombination;
  customCombination: IcedCustomCombination;
  recipeId: string;
}
interface IceOnlyRoasteryRecipeType {
  id: string;
  category: RecipeCategory.roastery;
  isSoldout: boolean;
  price: IcedPriceType;
  beverageType: BeverageType.icedOnly;
  detail: RoasteryRecipeDetail;
  condition: ConditionType;
  combination: IcedCombination;
  customCombination: IcedCustomCombination;
  recipeId: string;
}

interface HotOnlyOriginalRecipeType {
  id: string;
  category: RecipeCategory.original;
  isSoldout: boolean;
  price: HotPriceType;
  beverageType: BeverageType.hotOnly;
  detail: OriginalRecipeDetail;
  condition: ConditionType;
  combination: HotCombination;
  customCombination: HotCustomCombination;
  recipeId: string;
}

interface HotOnlyRoasteryRecipeType {
  id: string;
  category: RecipeCategory.roastery;
  isSoldout: boolean;
  price: HotPriceType;
  beverageType: BeverageType.hotOnly;
  detail: RoasteryRecipeDetail;
  condition: ConditionType;
  combination: HotCombination;
  customCombination: HotCustomCombination;
  recipeId: string;
}

interface BothOriginalRecipeType {
  id: string;
  category: RecipeCategory.original;
  isSoldout: boolean;
  price: BothPriceType;
  beverageType: BeverageType.both;
  detail: OriginalRecipeDetail;
  condition: ConditionType;
  combination: BothCombination;
  customCombination?: BothCustomCombination;
  recipeId: string;
}

interface BothRoasteryRecipeType {
  id: string;
  category: RecipeCategory.roastery;
  isSoldout: boolean;
  price: BothPriceType;
  beverageType: BeverageType.both;
  detail: RoasteryRecipeDetail;
  condition: ConditionType;
  combination: BothCombination;
  customCombination?: BothCustomCombination;
  recipeId: string;
}

interface Targetable {
  targetLocations: string[];
}

export type RecipeListOrder = { [recipeId: string]: number };
export type OriginalRecipeType = IceOnlyOriginalRecipeType | HotOnlyOriginalRecipeType | BothOriginalRecipeType;
export type RoasteryRecipeType = IceOnlyRoasteryRecipeType | HotOnlyRoasteryRecipeType | BothRoasteryRecipeType;
export type RecipeType = (OriginalRecipeType | RoasteryRecipeType) & Targetable;
