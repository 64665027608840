// 외부모듈
import React from 'react';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';

// 외부모듈
import { PaymentWay } from '@definitions/vm';

interface VmPaymentWaysProps {
  paymentWays: PaymentWay[];
  paymentWaysHandler: (id: string, paymentWays: PaymentWay[]) => void;
  id: string;
}

function VmPaymentWays({ paymentWays, paymentWaysHandler, id }: VmPaymentWaysProps) {
  const apply = (title: string) => {
    const updateData = paymentWays.map((way) => {
      if (way.title === title) {
        return { ...way, available: !way.available };
      } else {
        return way;
      }
    });

    const isAllFalse = updateData.filter((way) => way.available).length === 0;

    if (isAllFalse) {
      return alert('모든 결제수단을 disable 할수 없습니다.');
    }

    paymentWaysHandler(id, updateData);
  };

  return (
    <div css={payment__ways__wrap}>
      {paymentWays.map((way, i: number) => (
        <div key={i} css={payment__way}>
          <h2>{`${way.titleKR}(${way.title})`}</h2>
          <Button variant="contained" onClick={() => apply(way.title)} color={way.available ? 'secondary' : 'primary'}>
            {way.available ? '해제' : '적용'}
          </Button>
        </div>
      ))}
    </div>
  );
}

const payment__ways__wrap = css`
  padding: 10px;
  h2 {
    font-size: 32px;
    font-weight: bold;
  }

  button {
    width: 130px;
    max-height: 80px;
  }
`;

const payment__way = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0px;
`;

export default VmPaymentWays;
