import { ControllerType, InputType, UserControlData } from '@definitions/mock';

// 스티커 발급 컨드롤러 비활성화합니다. 스티커에 id가 생겨서 종류가 다양해졌기 때문에 기존에 발급 로직은 맞지 않습니다.
export const userFormData: UserControlData[] = [
  {
    type: ControllerType.sticker,
    title: '스티커 발급',
    buttonTitle: '발급',
    inputs: [{ name: 'stickerCnt', placeholder: '발급 갯수를 입력하세요', value: 1, inputType: InputType.number }],
    phoneNum: '',
    disabled: true,
  },
  {
    type: ControllerType.coupon,
    title: '쿠폰 발급',
    buttonTitle: '발급',
    phoneNum: '',
    inputs: [{ name: 'couponCnt', placeholder: '발급 갯수를 입력하세요', value: 1, inputType: InputType.number }],
  },
  {
    type: ControllerType.eventCoupon,
    title: '특별 쿠폰 발급',
    buttonTitle: '발급',
    phoneNum: '',
    inputs: [
      { name: 'couponCnt', placeholder: '발급 갯수를 입력하세요', value: 1, inputType: InputType.number },
      { name: 'couponTitle', placeholder: '쿠폰 이름을 입력하세요', value: '환불 쿠폰', inputType: InputType.string },
      { name: 'couponPrice', placeholder: '쿠폰 가격을 입력하세요', value: 1500, inputType: InputType.number },
    ],
  },
];
