import { eventChannel } from 'redux-saga';
import { fork, call, take } from 'redux-saga/effects';

function fsPoller() {
  return eventChannel((emit) => {
    const fpv = setInterval(() => {
      emit('firestore polling check');
    }, 1000 * 10 * 60);

    // The subscriber must return an unsubscribe function
    return () => {
      clearInterval(fpv);
    };
  });
}

function* poller() {
  const channel = yield call(fsPoller);
  while (true) {
    try {
      const result = yield take(channel);
      console.log(result);
    } catch (error) {
      console.error('fs poller');
    }
  }
}
export function* fsSaga() {
  // process.env.VM_ID 사용해야함
  // 초기 딜레이

  yield fork(poller);
}
