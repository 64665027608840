import React from 'react';
import { TableCell, Tooltip, TableCellProps } from '@material-ui/core';

// TableCellProps를 확장하여 새로운 interface를 생성합니다.
interface TooltipTableCellProps extends TableCellProps {
  tooltipText: string;
  children: any;
}

const TooltipTableCell: React.FC<TooltipTableCellProps> = ({
  tooltipText,
  children,
  ...cellProps
}: TooltipTableCellProps) => {
  return (
    <TableCell {...cellProps}>
      <Tooltip title={tooltipText}>
        <div>{children}</div>
      </Tooltip>
    </TableCell>
  );
};

export default TooltipTableCell;
