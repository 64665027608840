import React, { useState } from 'react';
import { css } from '@emotion/core';
import { Button, Chip, FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers/index';
import { CombinationObjectType, RecipeType } from '@definitions/recipes';
import { DataGrid, GridRowsProp, GridSelectionModel } from '@material-ui/data-grid';
import SimpleDialog from './SimpleDialog';
import { pickValuesFromColorScheme } from '@fluentui/react-northstar';
import { useFirestore } from 'react-redux-firebase';

const toMainIngrdnt = (combination) =>
  Object.keys(combination)
    .reduce((prev, cur) => {
      const mainIngredientFilter = (ingredientId: string) =>
        ingredientId !== 'water' && ingredientId !== 'hotWater' && ingredientId !== 'ice' && ingredientId !== 'milk';
      (combination[cur] as CombinationObjectType[]).forEach((comb) => {
        const isMainIng = mainIngredientFilter(comb.ingredientId);

        if (isMainIng) {
          const isExist = prev.findIndex((id) => id === comb.ingredientId) > -1;
          if (!isExist) {
            prev.push(comb.ingredientId);
          }
        }
      });
      return prev;
    }, [] as string[])
    .toString();

function VmAvailableRecipes() {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const recipes: RecipeType[] = useSelector(
    ({ firestore: { ordered } }: RootStateType) => ordered['version/v3/recipes'],
  );
  const firestore = useFirestore();
  const [vmId, setVmId] = useState('');
  const [recipeId, setRecipeId] = useState('');
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);
  const [open, setOpen] = useState(false);
  const [alertContent, setAlertConent] = useState('');
  const addTargetOnRecipe = (recipeId: string, vmId: string) => {
    const recipe = recipes.find(({ id }) => id === recipeId);
    if (recipe) {
      if (recipe.targetLocations) {
        firestore.doc(`/version/v3/recipes/${recipeId}`).set(
          {
            targetLocations: [...recipe.targetLocations, vmId],
          },
          { merge: true },
        );
      } else {
        firestore.doc(`/version/v3/recipes/${recipeId}`).set(
          {
            targetLocations: [vmId],
          },
          { merge: true },
        );
      }

      setRecipeId('');
    } else {
      console.error('없는 레시피입니다.');
    }
  };
  const removeTargetOnRecipe = (recipeIds: string[], vmId: string) => {
    recipeIds.forEach((recipeId) => {
      const recipe = recipes.find(({ id }) => id === recipeId);
      if (recipe) {
        firestore.doc(`/version/v3/recipes/${recipeId}`).set(
          {
            targetLocations: [...recipe.targetLocations.filter((target) => target !== vmId)],
          },
          { merge: true },
        );
        setSelectionModel([]);
      } else {
        console.error('없는 레시피입니다.');
      }
    });
  };
  const onClickAdd = (allAvailableRecipe: string[], vmId: string, recipeId: string) => {
    if (allAvailableRecipe.includes(recipeId)) {
      setAlertConent(
        '전체 적용되어 있는 레시피 입니다. 계속할 경우 기존에 적용되어있던 장소에서 해당 레시피를 반드시 추가해줘야합니다. 계속하시겠습니까?',
      );
      setOpen(true);
    } else {
      addTargetOnRecipe(recipeId, vmId);
    }
  };
  const targetAvailableRecipes: GridRowsProp = recipes
    .filter(
      (recipe) => recipe.targetLocations && recipe.targetLocations.findIndex((location) => location === vmId) !== -1,
    )
    .map((recipe) => ({
      id: recipe.id,
      col1: recipe.detail.name,
      col2: toMainIngrdnt(recipe.combination),
    }))
    .sort((a, b) => {
      return a.col1.localeCompare(b.col1);
    });

  const allAvailableRecipes: GridRowsProp = recipes
    .filter((recipe) => !recipe.targetLocations)
    .map((recipe) => ({
      id: recipe.id,
      col1: recipe.detail.name,
      col2: toMainIngrdnt(recipe.combination),
    }))
    .sort((a, b) => {
      return a.col1.localeCompare(b.col1);
    });

  return (
    <>
      <SimpleDialog
        content={alertContent}
        open={open}
        onClose={(response: string) => {
          setOpen(false);
          if (response === 'CANCEL') {
            setRecipeId('');
          } else if (response === 'RESUME') {
            // 추가 작업
            addTargetOnRecipe(recipeId, vmId);
          }
        }}
      />
      <h1>장소별 레시피 관리</h1>
      <div>
        <FormControl css={{ width: '500px' }}>
          <InputLabel id="vm-select-label">장소 선택</InputLabel>
          <Select
            labelId="vm-select-label"
            id="vm-select"
            value={vmId}
            label="vmId"
            onChange={(event) => setVmId(event.target.value as string)}
          >
            {vms
              .filter((vm) => vm.id[0] === 'B') // INIT, TEST 제외
              .map((vm) => (
                <MenuItem key={vm.id} value={vm.id}>
                  {vm.location.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </div>

      <div css={recipes__body}>
        <div css={recipes__body__top}>
          <h3>
            <span style={{ color: 'red' }}>{`${vmId}`}</span> 적용 레시피
          </h3>
          {vmId !== '' && (
            <div css={recipes__body__target__add}>
              <FormControl css={{ width: '500px' }}>
                <InputLabel id="vm-select-label">추가할 레시피 선택</InputLabel>
                <Select
                  labelId="vm-select-label"
                  id="recipe-select"
                  value={recipeId}
                  label="recipe"
                  onChange={(event) => setRecipeId(event.target.value as string)}
                >
                  {recipes
                    .filter((recipe) => !targetAvailableRecipes.find((tr) => tr.id === recipe.id))
                    .sort((a, b) => {
                      return a.detail.name.localeCompare(b.detail.name);
                    })
                    .map((recipe) => (
                      <MenuItem key={recipe.id} value={recipe.id}>
                        {`${recipe.detail.name} (${toMainIngrdnt(recipe.combination)})`}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              <Button
                css={css`
                  margin-left: 12px;
                `}
                color="primary"
                variant="outlined"
                onClick={() =>
                  onClickAdd(
                    allAvailableRecipes.map((value) => value.id),
                    vmId,
                    recipeId,
                  )
                }
              >
                추가하기
              </Button>
            </div>
          )}
          {selectionModel.length > 0 && (
            <div
              css={css`
                margin-bottom: 12px;
              `}
            >
              {selectionModel.map((model) => {
                const recipe = recipes.find((recipe) => recipe.id === model) || { detail: { name: 'never' } };
                return <Chip key={model} label={recipe.detail.name} />;
              })}

              <Button
                css={css`
                  margin-left: 12px;
                `}
                variant="outlined"
                color="secondary"
                onClick={() => removeTargetOnRecipe(selectionModel, vmId)}
              >
                삭제
              </Button>
            </div>
          )}
          <div css={recipes__body__target__list}>
            <DataGrid
              css={css`
                height: 700px;
              `}
              checkboxSelection={true}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionModel(newSelectionModel);
                console.log(newSelectionModel);
              }}
              selectionModel={selectionModel}
              rows={targetAvailableRecipes}
              columns={[
                { field: 'col1', headerName: '레시피 이름', width: 200 },
                { field: 'col2', headerName: '레시피 주요 재료', width: 400 },
              ]}
            />
          </div>
        </div>
        <div css={recipes__body__bottom}>
          <h3>전체 적용 레시피</h3>
          <DataGrid
            rows={allAvailableRecipes}
            columns={[
              { field: 'col1', headerName: '레시피 이름', width: 200 },
              { field: 'col2', headerName: '레시피 주요 재료', width: 400 },
            ]}
          />
        </div>
      </div>
    </>
  );
}

const recipes__body = css`
  margin-top: 80px;
`;

const recipes__body__top = css`
  height: 860px;
  width: 700px;
  h3 {
    font-weight: bold;
    font-size: 20px;
  }
`;

const recipes__body__target__add = css`
  width: 700px;
  height: 60px;
  margin-bottom: 12px;
  padding-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const recipes__body__target__list = css`
  height: 700px;
`;

const recipes__body__bottom = css`
  height: 700px;
  width: 700px;
  margin-top: 40px;
  h3 {
    font-weight: bold;
    font-size: 20px;
  }
`;

export default VmAvailableRecipes;
