/* eslint-disable @typescript-eslint/no-var-requires */
/* eslint-disable prefer-template */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable lines-between-class-members */
// 외부모듈
import axios, { AxiosError, AxiosResponse } from 'axios';
import * as CryptoJs from 'crypto-js';
const cleanDeep = require('clean-deep');
import { cloneDeep } from 'lodash';
// 내부모듈

import { firebase, firestore } from 'app/common/firebase';
import { getOrderedWallet, getUseCoupons, getWalletBalancePayment, getWalletPaymentResult } from '../getSessionInfo';
import key from '../../keys/key.json';

// 타입
import { REFUND_ACTIONS } from '@definitions/walletRefund';
import { Item, Order } from '@definitions/order';
import { PaymentWay } from '@definitions/payment';
import { Wallet } from '@definitions/wallet';
import { QueueStep } from '@definitions/queue';
import { TimeStamp } from '@definitions/vm';
import { Session, SessionHistory } from '@definitions/session';
import Slack from '../slack';

const PLANZ_SITE_URL = 'https://planz-coffee.com/creditCardRefund';

export interface OrderedInfo {
  order: Order;
  session: Session;
  sessionId: string;
  steps: QueueStep[];
  timeStamp: TimeStamp;
  type: number;
  vmId: string;
}

interface DataType {
  items: Item[];
  steps: string[];
  timeStamp: any;
  isCouponUsed: '사용X' | '사용O';
  totalPrice: number | false;
  usedCouponNum: number;
  phoneNum: string;
  infoForRefunds: any;
  paymentWayUserId: string;
  paymentWay: PaymentWay;
}

interface CardRefundData {
  paymentWay: PaymentWay.card;
  payload: { phoneNum: string; infoForRefunds: string; timeStamp: TimeStamp; totalPrice: number | false };
}

interface PaycoBarcodeRefundData {
  paymentWay: PaymentWay.paycoBarcode;
  payload: { vmId: string; order: any; phoneNum: string };
}

interface WalletRefundData {
  paymentWay: PaymentWay.wallet;
  payload: { wallet: Wallet; refundPrice: number };
}

interface NonPaymentRefundData {
  paymentWay: 'NON_PAYMENT';
  payload: { phoneNum: string };
}

interface BenefitNonPaymnetRefundData {
  paymentWay: 'BENEFIT_NON_PAYMENT';
  payload: { phoneNum: string };
}

interface UnknownRefundData {
  paymentWay: 'UNKNOWN';
}

interface CouponRefundData {
  paymentWay: 'COUPON';
  payload: { selectedCouponIdList: string[]; totalCouponPrice: number; phoneNum: string };
}

type RefundIterable =
  | CardRefundData
  | PaycoBarcodeRefundData
  | WalletRefundData
  | NonPaymentRefundData
  | UnknownRefundData
  | BenefitNonPaymnetRefundData
  | CouponRefundData;

interface RefundStateType {
  refundedOrder: OrderedInfo;
  data: DataType;
  refundItems: Item[];
  refundIterable: RefundIterable[];
}

interface Refs {
  refOrder: undefined | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  refRefund: undefined | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  refUserOrder: undefined | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
  refWalletCharge: undefined | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>;
}

class RefundState {
  private state: RefundStateType;
  refs: Refs;
  session: Session;

  constructor(state: RefundStateType) {
    this.state = state;
    this.session = cloneDeep(state.refundedOrder.session);
    this.refs = { refOrder: undefined, refRefund: undefined, refWalletCharge: undefined, refUserOrder: undefined };
  }

  _createRefundOrderHistory() {
    const { refundedOrder } = this.state;

    return {
      ...refundedOrder,
      type: 98,
      timeStamp: new Date(),
    };
  }

  async _writeToWalletCharges(curWallet: Wallet) {
    const { ownerId: ownerPhoneNumber, walletId } = curWallet;
    const { refundItems } = this.state;

    const refWallet = firestore.collection(`/version/v3/wallets/${walletId}/charges`);
    const date = new Date();

    let totalRefundedPoint = 0;

    const itemsInfo = refundItems.map(({ price }) => {
      const { sizeCharge, basic, concnCharge } = price;

      const refundedPoint = sizeCharge + basic + concnCharge;
      totalRefundedPoint += refundedPoint;
      return {
        bonusPoint: 0,
        coupon: 0,
        discount: 0,
        name: '지갑 환불',
        point: refundedPoint,
        price: 0,
      };
    });

    const refundInfo = {
      charge: {
        items: itemsInfo,
        totalBonusPoint: 0,
        totalCoupon: 0,
        totalDiscount: 0,
        totalPoint: totalRefundedPoint,
        totalPrice: 0,
      },
      payment: {},
      timeStamp: date,
      wallet: {
        ownerId: ownerPhoneNumber,
      },
    };

    const refWalletCharge = await refWallet.add(refundInfo);

    return { refWalletCharge };
  }

  async _writeToVmOrdersAndRefunds() {
    const date = new Date();
    const currentYear = date.getFullYear();
    const currentMonth = date.getMonth() + 1 < 10 ? `0${date.getMonth() + 1}` : `${date.getMonth() + 1}`;
    const refOrders = firestore.collection(
      `/version/v3/vms/${this.session.vmId}/orders/${currentYear}/${currentMonth}`,
    );
    const refRefunds = firestore.collection(`/version/v3/vms/${this.session.vmId}/refunds`);

    let refundOrderHistory = this._createRefundOrderHistory();
    refundOrderHistory = cleanDeep(refundOrderHistory, {
      NaNValues: true,
      nullValues: false,
      emptyArrays: false,
      emptyObjects: false,
    });

    const refOrder = await refOrders.add(refundOrderHistory);
    const refRefund = await refRefunds.add(refundOrderHistory);

    return { refOrder, refRefund };
  }

  async _writeToUserRefunds() {
    const { phoneNum } = this.state.data;
    const refUserOrders = firestore.collection(`/version/v3/users/${phoneNum}/refunds`);
    const refundOrderHistory = this._createRefundOrderHistory();

    const refUserOrder = await refUserOrders.add(refundOrderHistory);

    return { refUserOrder };
  }

  async init() {
    const { refundIterable, data } = this.state;
    const { phoneNum } = data;

    const isSignIn = phoneNum !== '' && phoneNum !== '미가입주문';
    const refundWallets = refundIterable.filter((iter) => iter.paymentWay === PaymentWay.wallet);
    const isIncludeWalletRefund = refundWallets.length > 0;

    const { refOrder, refRefund } = await this._writeToVmOrdersAndRefunds();
    this.refs = { ...this.refs, refOrder, refRefund };

    if (isSignIn) {
      const { refUserOrder } = await this._writeToUserRefunds();
      this.refs = { ...this.refs, refUserOrder };
    }

    if (isIncludeWalletRefund) {
      const wallet = getOrderedWallet(this.session);
      const { refWalletCharge } = await this._writeToWalletCharges(wallet);
      this.refs = { ...this.refs, refWalletCharge };
    }
  }

  addHistory(refund: RefundIterable) {
    const refundActionTable: { [key: string]: string } = {
      CREDIT_CARD: REFUND_ACTIONS.CREDIT_CARD_REFUND,
      WALLET: REFUND_ACTIONS.WALLET_REFUND,
      PAYCO_BARCODE: REFUND_ACTIONS.PAYCO_BARCODE_REFUND,
      NON_PAYMENT: REFUND_ACTIONS.NON_PAYMENT_REFUND,
      BENEFIT_NON_PAYMENT: REFUND_ACTIONS.BENEFIT_NON_PAYMENT_REFUND,
      COUPON: REFUND_ACTIONS.COUPON_REFUND,
    };

    const refundAction = {
      action: refundActionTable[refund.paymentWay],
      payload: {
        refundItem: this.state.refundItems,
      },
      timeStamp: new Date(),
    };

    //@ts-ignore
    this.session.history.push(refundAction);
  }

  async commit() {
    const { refundedOrder, refundItems } = this.state;
    const isRefundCreditCard = this.state.refundIterable.filter((i) => i.paymentWay === PaymentWay.card).length > 0;

    Object.entries(this.refs).forEach(([_, path]) => {
      if (path) {
        firestore.doc(path.path).update({
          ...refundedOrder,
          type: isRefundCreditCard ? 98 : 99,
          session: { ...refundedOrder.session, history: this.session.history },
          steps: new Array(refundItems.length).fill('SUCCESS'),
          timeStamp: new Date(),
        });
      }
    });
  }
}

export default class RefundController {
  session: Session;
  data: DataType;
  private refundedOrder: OrderedInfo | any;
  private refundItems: Item[];

  constructor(session: Session, refundItems: Item[], data: any) {
    this.session = session;
    this.refundItems = refundItems;
    this.data = data;
  }

  async _getRefundedOrder() {
    const { vmId, id: curSessionId } = this.session;
    const orderedDate = this.data.timeStamp.toDate();
    const year = orderedDate.getFullYear();
    const month = ('0' + (orderedDate.getMonth() + 1)).slice(-2);

    const refOrderHistory = await firestore
      .collection(`/version/v3/vms/${vmId}/orders/${year}/${month}`)
      .where('sessionId', '==', curSessionId)
      .where('type', '==', 4)
      .get();

    const orderHistorysData = refOrderHistory.docs.map((doc) => doc.data());

    this.refundedOrder = orderHistorysData[0] as OrderedInfo;
  }

  _getCurWallet() {
    return getOrderedWallet(this.session);
  }

  _getTotalRefundPrice() {
    return this.refundItems.reduce((total, item) => {
      const { basic, concnCharge, sizeCharge } = item.price;
      return (total += basic + concnCharge + sizeCharge);
    }, 0);
  }

  async _refundWallet(refund: WalletRefundData) {
    const { wallet, refundPrice } = refund.payload;
    const refWallet = firestore.doc(`/version/v3/wallets/${wallet.walletId}`);
    const walletData = await refWallet.get();

    //@ts-ignore
    const point = walletData.data().point || 0;
    await refWallet.update({ point: point + refundPrice });
  }

  async _refundCreditCard(refund: CardRefundData, refs: Refs) {
    if (!refs.refOrder && !refs.refRefund) {
      alert('신용카드 환불중 path가 존재하지 않습니다.');
      return;
    }

    const { phoneNum, infoForRefunds, timeStamp, totalPrice } = refund.payload;
    const isUser = phoneNum !== '' && phoneNum !== '미가입주문';

    let to = 'mjkim@dreampos.com';

    const isBaseEmail = confirm(`기본(${to})로 전송하시겠습니까?`);

    if (!isBaseEmail) {
      const newEmail = prompt('환불 요청할 메일을 적어주세요');

      if (newEmail && typeof newEmail === 'string') {
        to = newEmail;
      } else {
        return;
      }
    }

    //@ts-ignore
    const vo = refs.refOrder.path;
    //@ts-ignore
    const vr = refs.refRefund.path;
    let ur = null;
    if (isUser && refs.refUserOrder) {
      ur = refs.refUserOrder.path;
    }

    const EMAIL_API_URL = 'https://us-central1-planz-6761f.cloudfunctions.net/email';
    const WEBHOOK_API_URL = 'https://us-central1-planz-6761f.cloudfunctions.net/webhook';

    const subject = '플랜즈커피 환불 요청';
    const query = `vo=${vo}&vr=${vr}&ur=${ur}&vm=${this.session.vmId}&p=${isUser ? phoneNum : null}`;
    const encryptSuccessQuery = CryptoJs.AES.encrypt(`${query}&status=1`, key.refundDataSecretKey).toString();
    const encryptFailedQuery = CryptoJs.AES.encrypt(`${query}&status=0`, key.refundDataSecretKey).toString();
    const currentUser = firebase.auth().currentUser;
    const html = `
    <div style="font-size: 14px">
        <p>안녕하세요 PlanzCoffee 입니다. 다음 거래에 대한 환불을 요청합니다. <br />
        <br />
        <br />
        ------------------------------------------------ <br />
        승인번호 : ${infoForRefunds} <br />
        승인날짜 : ${timeStamp.toDate().toString()} <br />
        거래금액 : ${totalPrice} <br />
        담당자 : ${currentUser ? currentUser.email : 'planzCoffee'}  <br />
        ------------------------------------------------ <br />
        </p>
        <div>
            <a href="${PLANZ_SITE_URL}/?data=${encryptSuccessQuery}">
                <button style="font-weight: bold; color: blue; width: 100px; height: 30px;">환불승인</button>
            </a>
            <a href="${PLANZ_SITE_URL}/?data=${encryptFailedQuery}">
                <button style="font-weight: bold; color: red; width: 100px; height: 30px;">환불실패</button>
            </a>
        </div>
    </div>
    `;

    // @ts-ignore
    const token = await currentUser.getIdToken();

    const slackMessage = {
      title: '환불이메일이 발송되었습니다.',
      text: `
      ------------------
      to : ${to}
      vmOrdersPath : ${vo}
      vmRefundsPath : ${vr}
      ${isUser ? `userRefundsPath: ${ur}` : ''}
      time: ${this.refundedOrder.timeStamp.toDate()}
      `,
      color: Slack.StatusColors.success,
    };

    const body = { to, subject, html };

    await axios.post(EMAIL_API_URL, body, { headers: { Authorization: `Bearer ${token}` } });
    await axios.post(WEBHOOK_API_URL, {
      type: 'slack',
      attachments: slackMessage,
      url: Slack.Channels.refund,
    });
  }

  async _refundPaycoBarcode(refund: PaycoBarcodeRefundData) {
    const { vmId, order } = refund.payload;
    const { payment } = order;
    const sellerOrderNum = payment.paycoStatus.msg.successMsg.tradeRequestNo;

    const isTest = vmId.slice(0, 4) === 'TEST';
    const api = isTest
      ? 'https://us-central1-planz-6761f.cloudfunctions.net/paycoRefundTest'
      : 'https://us-central1-planz-6761f.cloudfunctions.net/paycoRefund';

    //@ts-ignore
    const res: AxiosResponse<{ result: boolean; message?: string }> = await axios
      .post(api, { sellerOrderNum })
      .catch((err: AxiosError) => {
        alert(err.message);
      });

    if (!res.data.result) {
      alert(`환불실패원인 : ${res.data.message} `);
    }
  }

  async _refundNonPayment() {
    console.log('비결제 환불!');
  }

  async _refundCoupon(refund: CouponRefundData) {
    const { phoneNum, selectedCouponIdList } = refund.payload;

    for (const id of selectedCouponIdList) {
      await firestore.doc(`version/v3/users/${phoneNum}/coupons/${id}`).update({ usedDate: null });
    }
  }

  /**
   * 환불은 크게 6가지 종류입니다.
   * 1. 신용카드
   * 2. 페이코
   * 3. 지갑
   * 4. 비결제 모드
   * 5. 실제 가격이 0원
   */

  //  1. 신용카드
  //  2. 쿠폰 잔액결제x
  //  3. 쿠폰 잔액결제o
  //  4. 지갑결제 온리포인트
  //  5. 지갑결제 포인트 + 잔액결제 (페이먼트웨이는 지갑으로됨)
  //  7. 멤버스 혜택 0원
  //  8. 비결제

  _refundGenerator() {
    const iterable: RefundIterable[] = [];
    const { paymentWay, phoneNum, isCouponUsed } = this.data;
    const isPayment = this.refundItems.every((item) => item.price.basic > 0);
    const walletBalancePayment = getWalletBalancePayment(this.session);
    const isAdmin = phoneNum === '관리자주문';

    if (isAdmin) {
      iterable.push({ paymentWay: 'UNKNOWN' });
      return iterable;
    }

    //  1. 신용카드
    if (isPayment && paymentWay === PaymentWay.card) {
      const { phoneNum, infoForRefunds, timeStamp, totalPrice } = this.data;

      iterable.push({
        paymentWay: PaymentWay.card,
        payload: { phoneNum, infoForRefunds, timeStamp, totalPrice },
      });
    }

    // 페이코
    if (isPayment && paymentWay === PaymentWay.paycoBarcode) {
      const { vmId, order } = this.refundedOrder;
      const { phoneNum } = this.data;

      iterable.push({ paymentWay: PaymentWay.paycoBarcode, payload: { vmId, order, phoneNum } });
    }

    // 지갑
    if (isPayment && paymentWay === PaymentWay.wallet) {
      let refundPrice = this._getTotalRefundPrice();

      if (walletBalancePayment) {
        refundPrice -= walletBalancePayment.payload.balance;
      }

      iterable.push({ paymentWay: PaymentWay.wallet, payload: { wallet: this._getCurWallet(), refundPrice } });
    }

    // 지갑 + 잔액결제시 (현재는 무조건 신용카드이지만 추후 결제수단에 따른 분기 필요)
    if (isPayment && walletBalancePayment) {
      const walletPaymentResult = getWalletPaymentResult(this.session);

      if (walletPaymentResult) {
        const totalPrice = walletBalancePayment.payload.balance;
        iterable.push({
          paymentWay: PaymentWay.wallet,
          payload: { wallet: this._getCurWallet(), refundPrice: totalPrice },
        });
      } else {
        iterable.push({ paymentWay: 'UNKNOWN' });
      }
    }

    // 비결제
    if (isPayment && paymentWay === PaymentWay['N/A'] && isCouponUsed === '사용X') {
      iterable.push({ paymentWay: 'NON_PAYMENT', payload: { phoneNum } });
    }

    // 멤버스 베네핏이 0 원
    if (!isPayment && paymentWay === PaymentWay['N/A'] && isCouponUsed === '사용X') {
      iterable.push({ paymentWay: 'BENEFIT_NON_PAYMENT', payload: { phoneNum } });
    }

    // 쿠폰
    if (isCouponUsed === '사용O') {
      const useCouponHistory = getUseCoupons(this.session);
      iterable.push({ paymentWay: 'COUPON', payload: { ...useCouponHistory.payload, phoneNum: this.data.phoneNum } });
    }

    return iterable;
  }

  async refund() {
    await this._getRefundedOrder();

    const refundIterable = this._refundGenerator();
    const refundState = new RefundState({
      refundIterable,
      refundedOrder: this.refundedOrder,
      refundItems: this.refundItems,
      data: this.data,
    });

    await refundState.init();

    if (refundIterable.find((i) => i.paymentWay === 'UNKNOWN')) {
      return alert('결제방법에 unknown 방법이 있어 환불을 수행하지 못했습니다.');
    }

    for (const refund of refundIterable) {
      refundState.addHistory(refund);
      switch (refund.paymentWay) {
        case PaymentWay.card:
          await this._refundCreditCard(refund, refundState.refs);
          break;
        case PaymentWay.wallet:
          await this._refundWallet(refund);
          break;
        case PaymentWay.paycoBarcode:
          await this._refundPaycoBarcode(refund);
          break;
        // 환불 기능이 동일해서 일단 통합했습니다
        case 'BENEFIT_NON_PAYMENT':
        case 'NON_PAYMENT':
          await this._refundNonPayment();
          break;
        case 'COUPON':
          await this._refundCoupon(refund);
          break;
        default:
          alert('현재 해당 결제방법은 환불이 불가능 합니다.');
      }
    }

    await refundState.commit();
  }
}

export const getRefundItems = (session: Session, refundedOrders: OrderedInfo[]) => {
  const { id: curSessionId } = session;

  const refundedItems: Item[] = [];
  refundedOrders
    .filter((session) => session.sessionId === curSessionId)
    .forEach(({ session: session }) => {
      session.history.forEach((sh: SessionHistory) => {
        if (
          sh.action === REFUND_ACTIONS.WALLET_REFUND ||
          sh.action === REFUND_ACTIONS.CREDIT_CARD_REFUND ||
          sh.action === REFUND_ACTIONS.PAYCO_BARCODE_REFUND ||
          sh.action === REFUND_ACTIONS.COUPON_REFUND ||
          sh.action === REFUND_ACTIONS.NON_PAYMENT_REFUND ||
          sh.action === REFUND_ACTIONS.BENEFIT_NON_PAYMENT_REFUND
        ) {
          const {
            payload: { refundItem },
          } = sh;
          refundItem.forEach((item: Item) => {
            refundedItems.push(item);
          });
        }
      });
    });

  return refundedItems;
};

export function getRefundDate(curStartDate: Date, curEndDate: Date) {
  let curMonth = curStartDate.getMonth();
  let curYear = curStartDate.getFullYear();
  let refundStartDate = curStartDate.setMonth(curMonth - 1);
  if (curMonth === 0) {
    refundStartDate = curStartDate.setFullYear(curYear - 1);
  }

  curMonth = curEndDate.getMonth();
  curYear = curEndDate.getFullYear();
  let refundEndDate = curEndDate.setMonth(curMonth + 1);
  if (curMonth === 11) {
    refundEndDate = curEndDate.setFullYear(curYear + 1);
  }

  return { refundStartDate, refundEndDate };
}
