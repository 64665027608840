import { get000Order } from './ver0.0.0Order';
import { get100Order } from './ver1.0.0Order';
import { get110Order } from './ver1.1.0Order';
import { get111Order } from './ver1.1.1Order';
import { get121Order } from './ver1.2.1Order';
import { get122Order } from './ver1.2.2Order';
import { get123Order } from './ver1.2.3Order';
import { get124Order } from './ver1.2.4Order';

export const orderObj = {
  '0.0.0': get000Order,
  '1.0.0': get100Order,
  '1.1.0': get110Order,
  '1.1.1': get111Order,
  '1.2.1': get121Order,
  '1.2.2': get122Order,
  '1.2.3': get123Order,
  '1.2.4': get124Order,
};
