// 외보모듈
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';
import Button from '@material-ui/core/Button';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

// 내부모듈
import { Policies } from '@definitions/vm';
import { BeverageType } from '@definitions/recipes';

interface VmPoliciesProps {
  policies: Policies;
  policiesHandler: (id: string, policies: Policies) => void;
  id: string;
}

function VmPolicies({ policies, policiesHandler, id }: VmPoliciesProps) {
  const { beverageType, defaultBeverageType, membership, payment } = policies;
  const [selectedBeverageType, setSelectedBeverageType] = useState<BeverageType>(beverageType);
  const [selectedDefaultBeverageType, setSelectedDefaultBeverageType] = useState(defaultBeverageType);

  useEffect(() => {
    setSelectedBeverageType(policies.beverageType);
    setSelectedDefaultBeverageType(policies.defaultBeverageType);
  }, [policies]);

  const beverageTypeHandler = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const type = e.target.value as BeverageType;
    setSelectedBeverageType(type);
  };

  const defaultBeveragetTypeHandler = (
    e: React.ChangeEvent<{
      name?: string | undefined;
      value: unknown;
    }>,
  ) => {
    const type = e.target.value as 'ICED' | 'HOT';
    setSelectedDefaultBeverageType(type);
  };

  const apply = (type: 'beverageType' | 'defaultBeverageType' | 'membership' | 'payment') => {
    switch (type) {
      case 'beverageType': {
        policiesHandler(id, { ...policies, beverageType: selectedBeverageType });
        break;
      }
      case 'defaultBeverageType': {
        policiesHandler(id, { ...policies, defaultBeverageType: selectedDefaultBeverageType });
        break;
      }
      case 'membership': {
        policiesHandler(id, { ...policies, membership: !membership });
        break;
      }
      case 'payment': {
        policiesHandler(id, { ...policies, payment: !payment });
        break;
      }
      default: {
        alert('일치하는 policy 형식이 없습니다.');
      }
    }
  };

  return (
    <div css={vm__policies__wrap}>
      <div css={vm__policies__row}>
        <h2>BeverageType</h2>
        <Select
          css={policies__select}
          value={selectedBeverageType || 'ICED_HOT_BOTH'}
          onChange={(e) => beverageTypeHandler(e)}
        >
          <MenuItem value="ICED_HOT_BOTH">ICED_HOT_BOTH</MenuItem>
          <MenuItem value="ICED_ONLY">ICED_ONLY</MenuItem>
          <MenuItem value="HOT_ONLY">HOT_ONLY</MenuItem>
        </Select>
        <Button variant="contained" color="primary" css={policies__btn} onClick={() => apply('beverageType')}>
          적용
        </Button>
      </div>
      <div css={vm__policies__row}>
        <h2>DefaultBeverageType</h2>
        <Select
          css={policies__select}
          value={selectedDefaultBeverageType || 'ICED'}
          onChange={(e) => defaultBeveragetTypeHandler(e)}
        >
          <MenuItem value="ICED">ICED</MenuItem>
          <MenuItem value="HOT">HOT</MenuItem>
        </Select>
        <Button variant="contained" color="primary" css={policies__btn} onClick={() => apply('defaultBeverageType')}>
          적용
        </Button>
      </div>
      <div css={vm__policies__row}>
        <h2>Membership</h2>
        <Button
          variant="contained"
          color={membership ? 'secondary' : 'primary'}
          css={policies__btn}
          onClick={() => apply('membership')}
        >
          {membership ? '해제' : '적용'}
        </Button>
      </div>
      <div css={vm__policies__row}>
        <h2>Payment</h2>
        <Button
          variant="contained"
          color={payment ? 'secondary' : 'primary'}
          css={policies__btn}
          onClick={() => apply('payment')}
        >
          {payment ? '해제' : '적용'}
        </Button>
      </div>
    </div>
  );
}

const vm__policies__wrap = css`
  margin-top: 15px;
  font-size: 32px;
  padding-bottom: 20px;

  h2 {
    font-weight: bold;
  }
`;

const vm__policies__row = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 5px;
  margin-bottom: 35px;
`;

const policies__select = css`
  width: 350px;
  height: 84px;
  .MuiSelect-select.MuiSelect-select {
    font-size: 32px;
  }
`;

const policies__btn = css`
  width: 120px;
  max-height: 84px;
  border-radius: 15px;
  .MuiButton-label {
    font-size: 32px;
  }
`;

export default VmPolicies;
