// 외부 모듈
import React from 'react';
import ReactDOM from 'react-dom';

// 내부 모듈
import Root from '@containers/Root';

ReactDOM.render(<Root />, document.getElementById('root'));

// service wokrer가 navigator에 있으면, window가 로드될때, service worker를 등록한다.
if ('serviceWorker' in navigator && process.env.NODE_ENV === 'production') {
  window.addEventListener('load', () => {
    // '/dist/service-worker.js' 파일 위치는 webpack.dev.{BUILD_TYPE}.config.js 의 GenerateSW에 정의 되어있음
    navigator.serviceWorker.register('service-worker.js').catch((registrationError) => {
      console.log('SW registration failed: ', registrationError);
    });
  });
}
