export const omit = (obj: any, omitKey: string) => {
  return omitKeys(obj, [omitKey]);
};

export const omitKeys = (obj: any, omitKeys: string[]) => {
  return Object.keys(obj).reduce((result: any, key: string) => {
    if (!omitKeys.includes(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};
