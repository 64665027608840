import React from 'react';
import { Drawer, List, ListItem, ListItemText, Button, Typography, makeStyles } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../reducers/index';
import { closeBottomSheet, Option } from '../../reducers/bottomSheet';

// const GlobalBottomSheet = () => {
//   const dispatch = useDispatch();
//   const { open, options, title } = useSelector((state: RootStateType) => state.bottomSheet);

//   const handleClose = () => {
//     dispatch(closeBottomSheet());
//   };

//   const handleSelect = (option: Option) => {
//     dispatch(closeBottomSheet());
//     // 여기서 선택된 옵션 처리를 위한 별도의 콜백을 설정할 수도 있습니다.
//   };

//   return (
//     <BottomSheetSelect open={open} options={options} title={title} onClose={handleClose} onSelect={handleSelect} />
//   );
// };

interface BottomSheetSelectProps {
  open: boolean;
  options: Option[];
  onClose: () => void;
  onSelect: (option: Option) => void;
  title?: string;
}

const useStyles = makeStyles((theme) => ({
  listItem: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  listItemText: {
    '& .MuiListItemText-primary': {
      fontSize: '5rem',
      fontWeight: 500,
    },
  },
  cancelButton: {
    padding: theme.spacing(2),
    fontSize: '2rem',
  },
}));

const BottomSheetSelect: React.FC<BottomSheetSelectProps> = ({ open, options, onClose, onSelect, title }) => {
  const classes = useStyles();

  const handleSelect = (option: Option) => {
    onSelect(option);
    window.dispatchEvent(new CustomEvent('bottom-sheet-select', { detail: option }));
  };

  return (
    <Drawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          borderTopLeftRadius: 12,
          borderTopRightRadius: 12,
          maxHeight: '80vh',
        },
      }}
    >
      {title && (
        <Typography variant="h6" style={{ padding: 16, fontWeight: 'bold' }}>
          {title}
        </Typography>
      )}
      <List style={{ overflowY: 'auto' }}>
        {options.map((option) => (
          <ListItem button key={option.value} className={classes.listItem} onClick={() => handleSelect(option)}>
            <ListItemText primary={option.label} className={classes.listItemText} />
          </ListItem>
        ))}
      </List>
      <Button onClick={onClose} className={classes.cancelButton}>
        취소
      </Button>
    </Drawer>
  );
};

export default BottomSheetSelect;
