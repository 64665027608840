// 외부 모듈
import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

// 내부 모듈
import { CustomPayloadAction } from '@definitions/redux';

export const selectVm = createAction('@SELECT_VM', (vm: string) => ({
  payload: vm,
}));

const initialState = {
  selectedVm: '',
};

const vm = createReducer(initialState, {
  [selectVm.type]: (state, action: PayloadAction<string>) => ({ ...state, selectedVm: action.payload }),
});

export default vm;

export type VmActions = CustomPayloadAction<'@SELECT_VM', string>;
