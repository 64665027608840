import { Button, Dialog, IconButton, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';

import AddPhotoAlternateIcon from '@material-ui/icons/AddPhotoAlternate';
import { css } from '@emotion/core';

const useStyles = makeStyles({
  imageContainer: {
    width: '300px',
    height: '300px',
    border: '1px solid gray',
    position: 'relative',
    display: 'inline-block',
    margin: '10px',
  },
  iconContainer: {
    display: 'flex',
    justifyContent: 'center', // 가로 중앙 정렬
    alignItems: 'center', // 세로 중앙 정렬
    height: '100%', // container의 높이 설정
  },
  input: {
    display: 'none',
    border: '2px dashed',
  },
  button: {
    marginBottom: '24px',
    width: 'calc(100% - 24px)',
    height: '80px',
    fontSize: '42px',
  },
});

interface Example {
  name: string;
  url: string;
  caption?: string;
}

export interface PhotoResult {
  name: string;
  url: string;
  file: File;
}

interface ResultPhotoProps {
  close: () => void;
  title: string;
  subTitle: string;
  examples: Example[];
  onSubmit: (results: PhotoResult[], length: number) => void;
  onSkip?: () => void;
}

function ResultPhoto({ title, subTitle, close, examples, onSubmit, onSkip }: ResultPhotoProps) {
  const classes = useStyles();
  const [zoomedImage, setZoomedImage] = useState<null | string>(null);
  const [resultImages, setResultImages] = useState<PhotoResult[]>([]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>, name: string) => {
    const file = e.target.files ? e.target.files[0] : null;
    const reader = new FileReader();

    reader.onloadend = () => {
      if (typeof reader.result === 'string') {
        const isExist = resultImages.find((image) => image.name === name);
        const results = isExist
          ? resultImages.map((img) => (img.name === name ? { ...img, url: reader.result as string, file } : img))
          : [...resultImages, { name, url: reader.result, file }];

        setResultImages(results);
      }
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div css={resultPhoto__wrap} style={{ padding: '20px', textAlign: 'center' }}>
      <h2>{title}</h2>
      <h4>{subTitle}</h4>
      <div css={examples__wrap}>
        {examples.map((example, index) => {
          const { name, url, caption } = example;
          const result = resultImages.find((result) => result.name === example.name);
          return (
            <div key={example.url} css={example__wrap}>
              <h1>{`${index + 1}. ${name}`}</h1>
              <div css={example__images}>
                <div className={classes.imageContainer} onClick={() => setZoomedImage(url)}>
                  <img src={url} alt="대표 이미지" width="100%" height="100%" />
                  <p>{`<예시 사진>`}</p>
                </div>
                <div className={classes.imageContainer}>
                  {result ? (
                    <img src={result.url} alt="업로드한 이미지" width="100%" height="100%" />
                  ) : (
                    <div className={classes.iconContainer}>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                        capture="camera" // 카메라 열기
                        onChange={(e) => handleImageChange(e, name)}
                      />
                      <label style={{ margin: 'auto' }} htmlFor="icon-button-file">
                        <IconButton color="default" component="span">
                          <AddPhotoAlternateIcon style={{ fontSize: 100 }} />
                        </IconButton>
                      </label>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </div>

      {onSkip && (
        <Button variant="contained" className={classes.button} color="secondary" onClick={() => onSkip()}>
          스킵
        </Button>
      )}

      <Button
        variant="contained"
        className={classes.button}
        color="primary"
        onClick={() => onSubmit(resultImages, examples.length)}
      >
        저장
      </Button>
      <Button variant="contained" className={classes.button} color="secondary" onClick={() => close()}>
        닫기
      </Button>
      {/* 확대된 이미지 보기 */}
      <Dialog open={!!zoomedImage} onClose={() => setZoomedImage(null)} maxWidth="md" fullWidth>
        <img src={zoomedImage} alt="확대된 이미지" style={{ width: '100%', height: 'auto' }} />
      </Dialog>
    </div>
  );
}
const resultPhoto__wrap = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  font-size: 28px;
  h2 {
    font-weight: bold;
    font-size: 46px;
    margin-bottom: 12px;
  }
  h3 {
    width: 80%;
    line-height: 1.2;
    font-size: 36px;
  }
`;
const examples__wrap = css`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  margin-bottom: 48px;
`;

const example__wrap = css`
  display: flex;
  flex-direction: column;
  h1 {
    font-weight: bold;
    font-size: 28px;
    margin-top: 8px;
  }
`;

const example__images = css`
  display: flex;
  flex-direction: row;
  margin-bottom: 32px;
`;
export default ResultPhoto;
