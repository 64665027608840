// 외부모듈
import React, { useEffect, useRef, useState } from 'react';
import { css } from '@emotion/core';
import { Item } from '@definitions/order';

interface RefundAlertProps {
  items: Item[];
  refundTargetItems: Item[];
  setRefundOpen: (value: React.SetStateAction<boolean>) => void;
  setRefundOpenId: (value: React.SetStateAction<string>) => void;
  refundButtonHandler: () => void;
}

function RefundAlert({
  items,
  refundTargetItems,
  setRefundOpen,
  setRefundOpenId,
  refundButtonHandler,
}: RefundAlertProps) {
  const [loading, setLoading] = useState(false);
  const [refundBlock, setRefundBlock] = useState(false);
  const divRef = useRef(null);

  useEffect(() => {
    [...items, ...refundTargetItems].forEach((item) => {
      if (item.id === undefined) {
        setRefundBlock(true);
      }
    });

    return () => {
      setRefundBlock(false);
    };
  }, []);

  useEffect(() => {
    return () => {
      setRefundOpenId('');
    };
  }, []);
  const handleClickOutside = (event) => {
    if (divRef && !divRef.current.contains(event.target)) {
      setRefundOpen(false);
    } else {
      setRefundOpen(true);
    }
  };
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <div ref={divRef} css={refund__alert__wrap}>
      {loading ? (
        <div>환불 진행중...</div>
      ) : (
        <div>
          {refundBlock
            ? '환불진행이 불가능 합니다'
            : refundTargetItems.length >= items.length
            ? '전체음료'
            : refundTargetItems.map(({ recipeName }, idx) => {
                return (
                  <div key={idx}>
                    {recipeName} <br />
                  </div>
                );
              })}
          {!refundBlock && '를 환불하시겠습니까?'}
        </div>
      )}

      {!refundBlock && !loading ? (
        <div css={button__wrap}>
          <button
            css={button}
            onClick={() => {
              setLoading(true);
              refundButtonHandler();
            }}
          >
            네
          </button>
          <button
            css={button}
            onClick={() => {
              setRefundOpen(false);
              setRefundOpenId('');
            }}
          >
            아니오
          </button>
        </div>
      ) : (
        <div css={button__wrap}>
          <button
            css={button}
            onClick={() => {
              setRefundOpen(false);
              setRefundOpenId('');
            }}
          >
            닫기
          </button>
        </div>
      )}
    </div>
  );
}

const refund__alert__wrap = css`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 50%;
  bottom: 20%;
  transform: translateX(-50%);
  width: 35vw;
  padding-top: 15px;
  padding-bottom: 15px;
  /* height: 10vh; */
  border-radius: 1vw;
  border: 2px solid #b67d56;
  outline: none;
  background-color: white;
  font-family: Spoqa Han Sans;
  font-size: 20px;
  z-index: 999;
`;
const button__wrap = css`
  width: 80%;
  height: 50%;
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
`;
const button = css`
  width: 40%;
  height: 80%;
  border-radius: 0.5vw;
  border: none;
  outline: none;

  background-color: #d49c76;
  color: white;
`;

export default RefundAlert;
