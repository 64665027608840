/* eslint-disable curly */
// 외부모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import Button from '@material-ui/core/Button';

// 내부모듈
import { RootStateType } from '@reducers/index';
import SearchBar from './SearchBar';
import BenefitTypeSelect from './benefitTypeSelect';
import PriceController from './PriceController';
import {
  Benefit,
  BenefitType,
  CombinationBenefit,
  CombinationTarget,
  Condition,
  CouponPriceBenefit,
  CouponPriceTarget,
  Target,
} from '@definitions/benefits';
import CouponPriceController from './CouponPriceController';
import CombinationController from './CombinationController';
import { controlForm } from '@reducers/formController';
import { ControllerType } from '@definitions/mock';

export const defaultCouponPrices = {
  id: 'vmCouponPrices',
  benefits: [
    {
      benefitType: 'couponPrice',
      targets: [
        {
          id: 'originalMembershipCoupon',
          price: 1400,
          priority: 10,
          conditions: [{ type: 'recipe', recipeNames: [] }],
        },
      ],
    },
  ],
};

interface ControlPageProps {
  vm: any;
  vmHandler: (vm: any) => void;
  selectHanlder: (e: React.ChangeEvent<{ value: unknown }>) => void;
  selectedBenefitType: string;
}

function ControlPage({ vm, vmHandler, selectHanlder, selectedBenefitType }: ControlPageProps) {
  const bennefitTypes = ['price', 'combination', 'vmCouponPrice'];

  const printBenefitTypePage = () => {
    switch (selectedBenefitType) {
      case 'price': {
        const vmRecipesHandler = (value: Target[]) => {
          const { vmRecipes } = vm;
          if (vmRecipes) {
            if (vmRecipes.benefits.findIndex((benefit: Benefit) => benefit.benefitType === BenefitType.price) === -1) {
              const updateVmRecips = {
                ...vmRecipes,
                benefits: [...vmRecipes.benefits, { benefitType: 'price', targets: value }],
              };
              vmHandler({ ...vm, vmRecipes: updateVmRecips });
            } else {
              const updateVmRecipes = {
                ...vmRecipes,
                benefits: vmRecipes.benefits.map((benefit: Benefit) => {
                  if (benefit.benefitType === selectedBenefitType) {
                    return { ...benefit, targets: value };
                  } else {
                    return benefit;
                  }
                }),
              };
              vmHandler({ ...vm, vmRecipes: updateVmRecipes });
            }
          } else {
            const updateVmRecips = { id: 'vmRecips', benefits: [{ benefitType: 'price', targets: value }] };
            vmHandler({ ...vm, vmRecipes: updateVmRecips });
          }
        };

        return (
          <div>
            <PriceController data={vm.vmRecipes} onChange={vmRecipesHandler} controlType={'VM'} />
          </div>
        );
      }
      case 'vmCouponPrice': {
        const { couponPrices } = vm;
        // 기존 legacy 데이터에 대한 방어 코드입니다.
        if (!Object(couponPrices).hasOwnProperty('benefits') || !couponPrices) {
          vmHandler({ ...vm, couponPrices: defaultCouponPrices });
        }

        const vmCouponPriceHandler = (
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          recipeNames: string[],
        ) => {
          const nextState = produce(vm, (draft: any) => {
            if (draft.couponPrices && Object(couponPrices).hasOwnProperty('benefits')) {
              if (e) {
                const { name, value } = e.target;
                const couponPriceTarget = draft.couponPrices.benefits
                  .find((benefit: CouponPriceBenefit) => benefit.benefitType === 'couponPrice')
                  .targets.find((target: CouponPriceTarget) => target.id === 'originalMembershipCoupon');
                couponPriceTarget[name] = Number(value);
              } else if (recipeNames) {
                const recipeCondition = draft.couponPrices.benefits
                  .find((benefit: CouponPriceBenefit) => benefit.benefitType === 'couponPrice')
                  .targets.find((target: CouponPriceTarget) => target.id === 'originalMembershipCoupon')
                  .conditions.find((condition: Condition) => condition.type === 'recipe');
                recipeCondition.recipeNames = recipeNames;
              } else {
                const recipeCondition = draft.couponPrices.benefits
                  .find((benefit: CouponPriceBenefit) => benefit.benefitType === 'couponPrice')
                  .targets.find((target: CouponPriceTarget) => target.id === 'originalMembershipCoupon')
                  .conditions.find((condition: Condition) => condition.type === 'recipe');
                recipeCondition.recipeNames = ['allDisable'];
              }
            } else {
              const newTarget = {
                id: 'originalMembershipCoupon',
                price: 1400,
                priority: 10,
                conditions: [{ type: 'recipe', recipeNames: [] }],
              };
              const newCouponPrices = {
                id: 'vmCouponPrices',
                benefits: [{ BenefitType: 'couponPrice', targets: newTarget }],
              };
              draft.couponPrices = newCouponPrices;
            }
          });
          vmHandler(nextState);
        };

        // coupon 한 종류만 컨트롤하는 코드입니다.
        const { benefits } = couponPrices;
        const couponPriceBenefit = benefits.find(
          (benefit: Benefit) => benefit.benefitType === BenefitType.couponPrice,
        ) as CouponPriceBenefit;

        if (!couponPriceBenefit) return null;

        const target = couponPriceBenefit.targets.find((target: Target) => target.id === 'originalMembershipCoupon');

        if (!target) return null;

        return (
          <div>
            <CouponPriceController data={target} onChange={vmCouponPriceHandler} />
          </div>
        );
      }
      case 'combination':
        const { vmRecipes } = vm;

        const vmCombinationHandler = (targets: CombinationTarget[]) => {
          const nextState = produce(vm, (draft: any) => {
            if (draft.vmRecipes) {
              const combinationBenefitIdx: number = draft.vmRecipes.benefits.findIndex(
                (benefit: Benefit) => benefit.benefitType === BenefitType.combination,
              );
              if (combinationBenefitIdx !== -1) {
                draft.vmRecipes.benefits[combinationBenefitIdx].targets = targets;
              } else {
                const newCombinationBenefit: CombinationBenefit = { benefitType: BenefitType.combination, targets };
                draft.vmRecipes.benefits.push(newCombinationBenefit);
              }
            } else {
              const newCombinationBenefit: CombinationBenefit = { benefitType: BenefitType.combination, targets };
              const vmRecipes = { id: 'vmRecipes', benefits: [newCombinationBenefit] };
              draft.vmRecipes = vmRecipes;
            }
          });
          vmHandler(nextState);
        };

        return (
          <div>
            <CombinationController data={vmRecipes} onChange={vmCombinationHandler} />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div>
      <BenefitTypeSelect onChange={selectHanlder} value={selectedBenefitType} benefitTypes={bennefitTypes} />
      {printBenefitTypePage()}
    </div>
  );
}

function VMControl() {
  const vms = useSelector((state: RootStateType) => state.firestore.data['version/v3/vms']);
  const [vmId, setVmId] = useState('');
  const [selectedBenefitType, setSelectedBenefitType] = useState<BenefitType | ''>('');
  const [vm, setVm] = useState<any>();
  const dispatch = useDispatch();

  const selectHanlder = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBenefitType(e.target.value as BenefitType);
  };

  const searchHandler = (e: React.ChangeEvent<any>, vmId: string) => {
    const vm = vms[vmId];
    if (vm) {
      const { vmRecipes, couponPrices } = vm;
      setVmId(vmId);
      setVm({ vmRecipes, couponPrices });
    } else {
      setVm(undefined);
    }
  };

  const vmHandler = (vm: any) => {
    setVm(vm);
  };

  // 새로운 vm benefit을 생성하는 함수입니다. (미구현)
  const createVMBenefit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, value: string) => {
    alert('미구현!!');
  };

  // benefit 을 적용하는 핸들러 함수 입니다.
  const applyBtn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedBenefitType === '') {
      return null;
    }
    dispatch(controlForm({ type: ControllerType.vmRecipes, selectedBenefitType, vmId, vm }));
  };

  return (
    <form css={vm__recipe__control__wrap} onSubmit={(e) => applyBtn(e)}>
      <SearchBar data={vms} onChange={searchHandler} onClick={createVMBenefit} />
      {vm && (
        <>
          <ControlPage
            vm={vm}
            vmHandler={vmHandler}
            selectedBenefitType={selectedBenefitType}
            selectHanlder={selectHanlder}
          />
          <div css={apply__btn}>
            <Button type="submit" variant="contained" color="secondary">
              적용
            </Button>
          </div>
        </>
      )}
    </form>
  );
}

const vm__recipe__control__wrap = css``;
const apply__btn = css`
  width: 100px;
  height: 50px;
  position: absolute;
  top: 110px;
  right: 50px;
`;

export default VMControl;
