// 외부모듈
import React from 'react';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/storage';
import { hot } from 'react-hot-loader';

import { Provider } from 'react-redux';
import { ReactReduxFirebaseProvider } from 'react-redux-firebase';
import { createFirestoreInstance } from 'redux-firestore';
import { Provider as FluentProvider, teamsTheme } from '@fluentui/react-northstar';

// 내부모듈
import App from '@containers/App';
import { firebaseConfig } from '@configs/firebaseConfigs/firebaseConfig';
import { rrfConfig } from '@configs/firebaseConfigs/reactReduxFirebaseConfig';
import { configureStore } from 'app/store/configureStore';
import { BrowserRouter } from 'react-router-dom';

function Root(): JSX.Element {
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  // 로컬 테스트
  // firebase.firestore().settings({ host: 'localhost:8080', ssl: false });

  const store = configureStore();

  const rrfProps = {
    firebase,
    config: rrfConfig,
    dispatch: store.dispatch,
    createFirestoreInstance,
  };

  return (
    <Provider store={store}>
      <FluentProvider theme={teamsTheme}>
        <ReactReduxFirebaseProvider {...rrfProps}>
          {/* <React.StrictMode> */}
          <BrowserRouter>
            <App />
            {/* </React.StrictMode> */}
          </BrowserRouter>
        </ReactReduxFirebaseProvider>
      </FluentProvider>
    </Provider>
  );
}

export default hot(module)(Root);
