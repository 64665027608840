//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { yellow, red } from '@material-ui/core/colors';
import { FiberNewOutlined } from '@material-ui/icons';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { RecipeType } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';
import AddRecipeOrder from './AddRecipeOrder';

export interface RecipeOrder {
  recipeName: string;
  recipeOrder: number;
  checked: boolean;
  beDeleted: boolean;
}

export interface RecipeOrderRawProps {
  originalData: RecipeOrder[];
  curData: RecipeOrder;
  idx: number;
  orderEditHandler: (type: string, recipeOrderInfo?: RecipeOrder, idx?: number) => void;
  checkHandler: (idx: number) => void;
  isLastRecipe: boolean;
  scrollToBottom: () => void;
}

function RecipeOrderRaw({
  originalData,
  curData,
  idx,
  orderEditHandler,
  checkHandler,
  isLastRecipe,
  scrollToBottom,
}: RecipeOrderRawProps) {
  const [isShowDelete, setIsShowDelete] = useState(false);
  const { recipeName, recipeOrder, checked, beDeleted } = curData;
  const initName = !originalData[idx] ? undefined : originalData[idx].recipeName;
  const initOrder = !originalData[idx] ? undefined : originalData[idx].recipeOrder;

  const onChangeHandler = (e: React.ChangeEvent<any>) => {
    orderEditHandler('edit', { ...curData, recipeOrder: parseFloat(e.target.value) }, idx);
  };

  const cancelHandler = () => {
    if (idx > originalData.length - 1) {
      orderEditHandler('addCancel', curData, idx);
    } else if (beDeleted) {
      orderEditHandler('deleteCancel', curData, idx);
    } else {
      orderEditHandler('edit', { ...curData, recipeOrder: initOrder }, idx);
    }

    setIsShowDelete(false);
  };

  useEffect(() => {
    if (idx > originalData.length - 1) {
      scrollToBottom();
    }
  }, []);

  return (
    <section css={raw__wrap(isLastRecipe)}>
      <input
        style={{ marginRight: '10px', zoom: '1.5', cursor: 'pointer' }}
        type="checkbox"
        id={idx + recipeName}
        value={recipeName}
        name=""
        checked={checked}
        onChange={() => checkHandler(idx)}
        disabled={idx > originalData.length - 1}
      />
      <div css={recipe__idx}>{idx + 1}</div>
      <label htmlFor={idx + recipeName} css={recipe__name} id={idx + recipeName}>
        {recipeName}
      </label>
      <input css={order__input} type="number" value={recipeOrder} onChange={(e) => onChangeHandler(e)} />
      {initOrder !== recipeOrder && !beDeleted && (
        <Button variant="contained" color="secondary" style={{ marginLeft: '15px' }} onClick={cancelHandler}>
          {idx <= originalData.length - 1 ? '되돌리기' : '생성취소'}
        </Button>
      )}
      {initName === undefined && initOrder === undefined && (
        <FiberNewOutlined style={{ marginLeft: '15px', color: yellow[700], fontSize: 50 }} fontSize="large" />
      )}
      {beDeleted && idx <= originalData.length - 1 && (
        <Button
          style={{ marginLeft: '80px' }}
          variant={isShowDelete ? 'contained' : 'outlined'}
          color="secondary"
          onClick={cancelHandler}
          // defaultValue={false}
          onMouseEnter={() => setIsShowDelete(true)}
          onMouseLeave={() => setIsShowDelete(false)}
        >
          {isShowDelete ? '삭제취소' : '삭제예정'}
        </Button>
      )}
    </section>
  );
}

export default RecipeOrderRaw;

const raw__wrap = (isLastRecipe: boolean) => css`
  position: relative;
  margin-left: 10px;
  display: flex;
  justify-content: start;
  align-items: center;
  width: 540px;
  height: 50px;
  display: flex;
  border-bottom: ${!isLastRecipe && `1px solid #acacac`};
  font-family: Spoqa Han Sans;
  font-size: 16px;
`;
const recipe__idx = css`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 30px;
  height: 100%;
`;
const recipe__name = css`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 250px;
  height: 80%;
`;
const order__input = css`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 60px;
  height: 70%;
  text-align: center;
`;
