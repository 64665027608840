// 내부모듈
import { BothPriceType } from './recipes';

export enum BenefitType {
  price = 'price',
  couponPrice = 'couponPrice',
  sticker = 'sticker',
  combination = 'combination',
  vmCouponPirce = 'vmCouponPrice',
  stickerType = 'stickerType',
}

export interface RecipeCondition {
  type: string;
  recipeNames: string[];
}

export interface TermDiscountByGroup {
  count: number;
  termOfDay: number;
  type: 'termDiscountByGroup';
}

export interface NumberOfDiscounts {
  count: number;
  termOfDay: number;
  type: 'numberOfDiscounts';
}

export type Condition = RecipeCondition | TermDiscountByGroup | NumberOfDiscounts;

export interface CommonTarget {
  id: string;
  priority: number;
  conditions: Condition[];
}

export interface PriceTarget extends CommonTarget {
  price: BothPriceType;
}

export interface CouponPriceTarget extends CommonTarget {
  price: number;
}

export interface StickerTarget extends CommonTarget {
  publishStickerNum: number;
}

export interface CombinationItem {
  amount: number;
  ingredientId: string;
  metric: number;
  startAt: number;
}

export interface Combination {
  iced?: CombinationItem[];
  hot?: CombinationItem[];
}

export interface CombinationTarget extends CommonTarget {
  combination: Combination;
}

export type Target = PriceTarget | CouponPriceTarget | StickerTarget;

export interface PriceBenefit {
  targets: PriceTarget[];
  benefitType: BenefitType.price;
}

export interface CouponPriceBenefit {
  targets: CouponPriceTarget[];
  benefitType: BenefitType.couponPrice;
}

export interface StickerBenefit {
  targets: StickerTarget[];
  benefitType: BenefitType.sticker;
}

export interface CombinationBenefit {
  targets: CombinationTarget[];
  benefitType: BenefitType.combination;
}

export interface StickerTypeBenefit {
  benefitType: BenefitType.stickerType;
  targets: CommonTarget[];
}

export type Benefit = PriceBenefit | CouponPriceBenefit | StickerBenefit | CombinationBenefit;

export interface Members {
  additionalCustomerInfo: any[];
  benefits: Benefit[];
  id: string;
  name: string;
}
