// 외부 모듈
import React from 'react';
import { css } from '@emotion/core';
import { MountedContainer } from '@definitions/container';
import { RootStateType } from '@reducers';
import { useSelector } from 'react-redux';

// 내부 모듈
import VmAmountCard from './VmAmountCard';
import VmDashboardTable from './VmDashboardTable';
import { VendingMachine } from '@definitions/vm';

// 상수
import { DEPRICATED_VMS } from '@constants/vm';
import { MountPosition } from '@definitions/recipes';

export const excludeVmFilter = (vm: VendingMachine) => !DEPRICATED_VMS.includes(vm.id);

const download = (vms: VendingMachine[], ingredients: { [key: string]: Ingredient }) => {
  const result = vms.map((vm) => {
    const id = vm.id;
    const name = vm.location.name;
    const pumps = vm.pumps.filter(
      (pump) => MountPosition.pump0 <= pump.mountPosition && pump.mountPosition <= MountPosition.pump9,
    );
    const milks = vm.pumps.filter(
      (pump) => MountPosition.milk0 <= pump.mountPosition && pump.mountPosition <= MountPosition.milk1,
    );
    const kegs = vm.kegs.filter(
      (keg) => MountPosition.keg0 <= keg.mountPosition && keg.mountPosition <= MountPosition.keg3,
    );
    return `${id},${name},${pumps.reduce((prev, cur, index) => {
      let ingList = prev;

      if (cur.mountDate && cur.ingredientId) {
        ingList += ingredients[cur.ingredientId].titleKor;
      }

      if (index !== pumps.length - 1) {
        ingList += ',';
      }

      return ingList;
    }, '')},${milks.reduce((prev, cur, index) => {
      let ingList = prev;

      if (cur.mountDate && cur.ingredientId) {
        ingList += ingredients[cur.ingredientId].titleKor;
      }

      if (index !== milks.length - 1) {
        ingList += ',';
      }

      return ingList;
    }, '')},${kegs.reduce((prev, cur, index) => {
      let ingList = prev;

      if (cur.mountDate && cur.ingredientId) {
        ingList += ingredients[cur.ingredientId].titleKor;
      }

      if (index !== kegs.length - 1) {
        ingList += ',';
      }

      return ingList;
    }, '')}\n`;
  });
  const HEADER =
    'VM_ID,장소이름,펌프1,펌프2,펌프3,펌프4,펌프5,펌프6,펌프7,펌프8,펌프9,우유1,우유2,커피1,커피2,커피3,커피4\n';
  const downloadLink = document.createElement('a');
  const blob = new Blob([HEADER + result.reduce((prev, cur) => prev + cur, '')], { type: 'text/csv;charset=utf-8' });
  const url = URL.createObjectURL(blob);
  downloadLink.href = url;
  downloadLink.download = `장소별재료장착현황_${new Date().toDateString()}.csv`;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

function VmDashboard(): JSX.Element {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const ingredients = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);

  // dashboard에는 test,init,사용하지않는 vm은 빼고 보여준다
  const filterValidVms = vms.filter(excludeVmFilter);

  return (
    <div css={dashboard__wrap}>
      <button onClick={() => download(filterValidVms, ingredients)}>장소별 재료 장착현황 다운로드받기</button>
      <div css={card__wrap}>
        {filterValidVms.map((item, idx) => (
          <VmAmountCard key={idx} item={item} ingredients={ingredients} />
        ))}
      </div>

      <div css={table__wrap}>
        <VmDashboardTable vms={filterValidVms} ingredients={ingredients} />
      </div>
    </div>
  );
}

export default VmDashboard;

const dashboard__wrap = css``;
const card__wrap = css`
  display: flex;
  flex-wrap: wrap;
  margin: -16px 0 0 -16px;
  width: calc(100% + 16px);
  > * {
    margin: 16px 0 0 16px;
  }
`;

const table__wrap = css`
  margin-top: 30px;
  margin-bottom: 30px;
`;
