import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';
import 'firebase/performance';
import { firebaseConfig } from '@configs/firebaseConfigs/firebaseConfig';

//import { firebaseConfig } from '../../../configs/firebaseConfigs/firebaseConfig';

if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}

const firestore = firebase.firestore();
const storage = firebase.storage();

export { storage, firestore, firebase };
