// 외부 모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { Button, Input, Dialog } from '@fluentui/react-northstar';

// 내부 모듈
import {
  BeverageType,
  RecipeCategory,
  BeverageGroup,
  RoastingPoint,
  TagType,
  ConcnCharge,
  SizeCharge,
  RecipeType,
  OriginalRecipeDetail,
} from '@definitions/recipes';
import { RootStateType } from '@reducers';
import { omit } from '@utils/omitObject';

function AddNewRecipeAndIngredient(): JSX.Element {
  const firestore = useFirestore();
  const ingredients = useSelector((state: RootStateType) => state.firestore.data['version/v3/ingredients']);
  const [ingredient, setIngredient] = useState({ image: '', titleEng: '', titleKor: '', type: '' });
  const [recipe, setRecipe] = useState({
    beverageType: '',
    category: '',
    isSoldout: false,
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: '',
      idleImage: '',
      mainImage: '',
      tagType: '',
      image: '',
      summary: '',
      simpleInfo: '',
      name: '',
    },
    price: {
      basic: {},
      concnCharge: {},
      sizeCharge: {},
    },
    recipeId: '',
    combination: {},
  });

  const ingredientsArray = Object.keys(ingredients).concat(['ice', 'water', 'hotWater']);
  const beverageTypeArray = [BeverageType.both, BeverageType.icedOnly, BeverageType.hotOnly];
  const categoryArray = [RecipeCategory.original];
  const syrupAdjustableArray = ['시럽추가가능', '시럽추가불가능'];
  const beverageGroupArray = [BeverageGroup.coffee, BeverageGroup.nonCoffee];
  const roastingPointArray = [
    RoastingPoint.light,
    RoastingPoint.medium,
    RoastingPoint.mediumDark,
    RoastingPoint.dark,
    RoastingPoint.veryDark,
  ];
  const tagTypeArray = [TagType.new, TagType.best, TagType.event, TagType.soldout];
  const [iceCombinationInput, setIceCombinationInput] = useState<Array<{ [key: string]: number | string }>>([
    {
      metric: 1,
      startAt: 0,
    },
  ]);

  const [hotCombinationInput, setHotCombinationInput] = useState<Array<{ [key: string]: number | string }>>([
    { metric: 1, startAt: 0 },
  ]);
  const [basicPrice, setBasicPrice] = useState<{ [key: string]: number }>({});
  const [concnCharge, setConcnCharge] = useState<{ [key: string]: number | string }>({ type: ConcnCharge.none });
  const [sizeCharge, setSizeCharge] = useState<{ [key: string]: number | string }>({ type: SizeCharge.none });
  const [detail, setDetail] = useState<{ [key: string]: string | number }>({});
  const priceHandler = (event: React.SyntheticEvent<HTMLElement, Event>, type: string): void => {
    const { name, value } = event.target as HTMLInputElement;
    if (type === 'basic') {
      if (Number(value) === 0) {
        for (const prop in basicPrice) {
          if (Number(basicPrice[prop]) < 10) {
            setBasicPrice(omit(basicPrice, prop));
          }
        }
      } else {
        setBasicPrice({ ...basicPrice, [name]: Number(value) });
      }
    }
    if (type === 'concnCharge') {
      if (Number(value) === 0) {
        let isHotDeleted = false;
        let isIcedDeleted = false;
        for (const prop in concnCharge) {
          if (Number(concnCharge[prop]) < 10) {
            prop === 'iced' ? (isIcedDeleted = true) : (isHotDeleted = true);
            const filteredObj = omit(concnCharge, prop);
            if (Object.values(filteredObj).includes(ConcnCharge.both) && isHotDeleted) {
              setConcnCharge({ ...filteredObj, type: ConcnCharge.iced });
            } else if (Object.values(concnCharge).includes(ConcnCharge.both) && isIcedDeleted) {
              setConcnCharge({ ...filteredObj, type: ConcnCharge.hot });
            } else {
              setConcnCharge({ type: ConcnCharge.none });
            }
          }
        }
      } else {
        if (name === 'hot') {
          if (concnCharge.type === ConcnCharge.none || concnCharge.type === ConcnCharge.hot) {
            setConcnCharge({ ...concnCharge, [name]: Number(value), type: ConcnCharge.hot });
          } else {
            setConcnCharge({ ...concnCharge, [name]: Number(value), type: ConcnCharge.both });
          }
        }
        if (name === 'iced') {
          if (concnCharge.type === ConcnCharge.none || concnCharge.type === ConcnCharge.iced) {
            setConcnCharge({ ...concnCharge, [name]: Number(value), type: ConcnCharge.iced });
          } else {
            setConcnCharge({ ...concnCharge, [name]: Number(value), type: ConcnCharge.both });
          }
        }
      }
    }

    if (type === 'sizeCharge') {
      if (Number(value) === 0) {
        for (const prop in sizeCharge) {
          if (Number(sizeCharge[prop]) < 10) {
            const filteredSizeCharge = omit(sizeCharge, prop);
            setSizeCharge({ ...filteredSizeCharge, type: SizeCharge.none });
          }
        }
      } else {
        setSizeCharge({ ...sizeCharge, [name]: Number(value), type: SizeCharge.iced });
      }
    }
  };

  const handleRecipeCombination = (
    event: React.ChangeEvent<HTMLSelectElement> | React.ChangeEvent<HTMLInputElement>,
    index: number,
    type: string,
  ) => {
    const { name, value } = event.target;
    if (type === 'iced') {
      const list = [...iceCombinationInput];
      list[index][name] = name === 'amount' ? parseFloat(value) : value;
      setIceCombinationInput(list);
      return;
    }
    const list = [...hotCombinationInput];
    list[index][name] = name === 'amount' ? parseFloat(value) : value;
    setHotCombinationInput(list);
  };
  const combinationRemoveHanlder = (index: number, type: string) => {
    if (type === 'iced') {
      const combinationList = [...iceCombinationInput];
      combinationList.splice(index, 1);
      setIceCombinationInput(combinationList);
      return;
    }
    const combinationList = [...hotCombinationInput];
    combinationList.splice(index, 1);
    setHotCombinationInput(combinationList);
  };

  const combinationAddHandler = (type: string) => {
    if (type === 'iced') {
      setIceCombinationInput([...iceCombinationInput, { amount: 0, ingredientId: '', metric: 1, startAt: 0 }]);
      return;
    }
    setHotCombinationInput([...hotCombinationInput, { amount: 0, ingredientId: '', metric: 1, startAt: 0 }]);
  };

  const handleDetail = (event: React.ChangeEvent<{ name: string; value: string }> | React.SyntheticEvent): void => {
    const { name, value } = event.target as HTMLInputElement | HTMLSelectElement;
    if (name === 'roastingPoint') {
      setDetail({ ...detail, [name]: parseFloat(value) });
    } else if (name === 'tagType' && value === '') {
      setDetail(omit(detail, 'tagType'));
    } else {
      setDetail({ ...detail, [name]: value });
    }
  };

  const handleIngredient = (event: React.SyntheticEvent): void => {
    const { name, value } = event.target as HTMLInputElement;
    setIngredient({ ...ingredient, [name]: value });
  };

  const addRecipe = () => {
    setRecipe({
      ...recipe,
      detail,
      price: { basic: basicPrice, concnCharge, sizeCharge },
    });
  };

  const confirmRecipe = async () => {
    const { isSoldout, beverageType, condition, detail, combination, price, recipeId } = recipe as RecipeType;
    const { beverageGroup, image, idleImage, mainImage, name, simpleInfo, summary } = detail as OriginalRecipeDetail;
    const { basic, concnCharge, sizeCharge } = price;
    const { type } = concnCharge || sizeCharge;
    const fieldValueArray = [
      isSoldout,
      beverageType,
      condition,
      detail,
      combination,
      price,
      recipeId,
      beverageGroup,
      idleImage,
      image,
      mainImage,
      name,
      simpleInfo,
      summary,
      basic,
      type,
    ];

    if (fieldValueArray.filter((item) => item === undefined).length > 0) {
      return alert(`필요한 요소가 다 채워져있는지 확인하세요`);
    }
    await firestore.collection('/version/v3/recipes').add(recipe);
    await firestore
      .collection('/version/v3/recipes')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          if (doc.data().recipeId === recipe.recipeId) {
            window.location.reload(true);
          }
        });
      });
  };

  const addIngredient = async () => {
    await firestore.collection('/version/v3/ingredients').doc(`${ingredient.titleEng}`).set({
      image: ingredient.image,
      titleKor: ingredient.titleKor,
      type: ingredient.type,
    });
    const ingredientRef = firestore.collection('/version/v3/ingredients').doc(`${ingredient.titleEng}`);
    ingredientRef
      .get()
      .then((doc) => {
        if (doc.exists) {
          window.location.reload(true);
        } else {
          console.error('등록에 실패하였습니다.');
        }
      })
      .catch((err) => {
        console.error('ingredient등록이 addIngredient함수에서 아예 실행되지 않았음', err.message);
      });
  };

  const handleRecipe = (event: React.SyntheticEvent | React.ChangeEvent<HTMLSelectElement>): void => {
    const { name, value } = event.target as HTMLInputElement | HTMLSelectElement;
    const syrupBooleanValue = {
      시럽추가가능: true,
      시럽추가불가능: false,
    };
    if (value === '시럽추가가능' || value === '시럽추가불가능') {
      setRecipe({ ...recipe, condition: { syrupAdjustable: syrupBooleanValue[value] } });
    } else {
      setRecipe({ ...recipe, [name]: value });
    }
  };

  const setCombinationHandler = () => {
    if (iceCombinationInput.length > 1 && hotCombinationInput.length === 1) {
      setRecipe({ ...recipe, combination: { iced: iceCombinationInput } });
    } else if (iceCombinationInput.length === 1 && hotCombinationInput.length > 1) {
      setRecipe({ ...recipe, combination: { hot: hotCombinationInput } });
    } else {
      setRecipe({ ...recipe, combination: { hot: hotCombinationInput, iced: iceCombinationInput } });
    }
    alert('combination이 확정되었습니다');
  };

  // firebase 레시피 추가
  return (
    <>
      <a
        href="https://console.firebase.google.com/u/0/project/planz-6761f/storage/planz-6761f.appspot.com/files~2Fv3~2Forder"
        target="_blank"
        rel="noopener noreferrer"
      >
        새로운 음료이미지, 메인이미지, ingredient 이미지는 해당 링크를 클릭하여 업로드
      </a>
      <div css={add__wrap}>
        <div css={add__recipe__wrap}>
          <div css={add__ingredient__header}>
            <h1>recipe 추가</h1>
            <h2>새로운 레시피 추가 시 필요한 내용을 담아서 추가합니다</h2>
          </div>

          <h1>detail</h1>
          <label>
            레시피id (메뉴영문명)
            <Input name="recipeId" placeholder="예시:brewedCoffee" onChange={handleRecipe} />
          </label>
          <label>
            beverageType
            <select name="beverageType" onChange={handleRecipe}>
              <option value="">아래 옵션 중 선택하세요</option>
              {beverageTypeArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>

          <label>
            category
            <select name="category" onChange={handleRecipe}>
              <option value="">아래 옵션 중 선택하세요</option>
              {categoryArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
          <label>
            시럽적용여부
            <select name="syrupAdjustable" onChange={handleRecipe}>
              <option value="">아래 옵션 중 선택하세요</option>
              {syrupAdjustableArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
          <label>
            커피류/음료류 선택
            <select name="beverageGroup" onChange={handleDetail}>
              <option value="">아래 옵션 중 선택하세요</option>
              {beverageGroupArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>

          <label>
            음료 idle 이미지 링크 추가
            <Input name="idleImage" fluid placeholder="idle이미지 링크" onChange={handleDetail} />
          </label>
          <label>
            음료 이미지 링크 추가
            <Input name="image" fluid placeholder="음료 이미지 링크" onChange={handleDetail} />
          </label>
          <label>
            음료 메인이미지 링크 추가
            <Input name="mainImage" fluid placeholder="메인 이미지 링크" onChange={handleDetail} />
          </label>
          <label>
            음료명(한글)
            <Input name="name" placeholder="음료명(한글)" onChange={handleDetail} />
          </label>
          {detail.beverageGroup === BeverageGroup.coffee && (
            <>
              <label>
                로스팅포인트(0: light, 1 : medium, 2:mediumDark, 3:dark, 4: veryDark)
                <select name="roastingPoint" onChange={handleDetail}>
                  <option value="">아래 roastingPoint 옵션 중 선택하세요</option>
                  {roastingPointArray.map((item, index) => (
                    <option key={index} value={index}>
                      {index}
                    </option>
                  ))}
                </select>
              </label>
            </>
          )}
          <label>
            음료 simpleInfo
            <Input fluid name="simpleInfo" placeholder="예시:바닐라향이 감미로운 라떼" onChange={handleDetail} />
          </label>
          <label>
            음료 summary
            <Input
              name="summary"
              fluid
              placeholder="예시:고메 바닐라빈 시럽의 감미로운 향이 부드럽게 어우러진 바닐라 라떼 음료"
              onChange={handleDetail}
            />
          </label>
          <label>
            tagType (옵션)
            <select name="tagType" onChange={handleDetail}>
              <option value="">아래 태그 옵션 중 선택하세요</option>
              {tagTypeArray.map((item, index) => (
                <option key={index} value={item}>
                  {item}
                </option>
              ))}
            </select>
          </label>
          <div css={combination__wrap}>
            <h1>combination</h1>
            <>
              {recipe.beverageType !== BeverageType.hotOnly && (
                <div css={ice__combination__wrap}>
                  <span>iced : </span>
                  {iceCombinationInput.map((combination, idx) => (
                    <>
                      <div key={idx}>
                        <select name="ingredientId" onChange={(e) => handleRecipeCombination(e, idx, 'iced')}>
                          <option value="">아래 ingredients 중 선택하세요</option>
                          {ingredientsArray.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>

                        <input
                          name="amount"
                          type="text"
                          placeholder="amount"
                          onChange={(e) => handleRecipeCombination(e, idx, 'iced')}
                        />
                        <span>
                          {iceCombinationInput.length !== 1 && (
                            <Button
                              content="Remove"
                              size="small"
                              css={add__remove__btn}
                              onClick={() => combinationRemoveHanlder(idx, 'iced')}
                            />
                          )}
                          {iceCombinationInput.length - 1 === idx && (
                            <Button
                              content="Add"
                              size="small"
                              css={add__remove__btn}
                              onClick={() => combinationAddHandler('iced')}
                            />
                          )}
                        </span>
                      </div>
                    </>
                  ))}
                </div>
              )}
              {recipe.beverageType !== BeverageType.icedOnly && (
                <>
                  <span>hot : </span>

                  {hotCombinationInput.map((combination, idx) => (
                    <>
                      <div key={idx}>
                        <select name="ingredientId" onChange={(e) => handleRecipeCombination(e, idx, 'hot')}>
                          <option value="">아래 ingredients 중 선택하세요</option>
                          {ingredientsArray.map((item, index) => (
                            <option key={index} value={item}>
                              {item}
                            </option>
                          ))}
                        </select>

                        <input
                          name="amount"
                          type="number"
                          placeholder="amount"
                          onChange={(e) => handleRecipeCombination(e, idx, 'hot')}
                        />
                        <span>
                          {hotCombinationInput.length !== 1 && (
                            <Button
                              content="Remove"
                              size="small"
                              onClick={() => combinationRemoveHanlder(idx, 'hot')}
                            />
                          )}
                          {hotCombinationInput.length - 1 === idx && (
                            <Button content="add" size="small" onClick={() => combinationAddHandler('hot')} />
                          )}
                        </span>
                      </div>
                    </>
                  ))}
                </>
              )}

              <Button onClick={() => setCombinationHandler()} content="combination 확정" />
            </>
          </div>
          <h1>price</h1>
          {recipe.beverageType !== BeverageType.icedOnly && (
            <>
              <label>
                hot음료 가격
                <Input
                  name="hot"
                  type="number"
                  placeholder="hot m사이즈 가격"
                  onChange={(event) => priceHandler(event, 'basic')}
                />
              </label>
              <label>
                hot 음료 진하기 가격(옵션)
                <Input
                  name="hot"
                  type="number"
                  placeholder="hot 진하기 가격"
                  onChange={(event) => priceHandler(event, 'concnCharge')}
                />
              </label>
            </>
          )}
          {recipe.beverageType !== BeverageType.hotOnly && (
            <>
              <label>
                iced 음료 가격
                <Input
                  name="iced"
                  type="number"
                  placeholder="iced m사이즈 가격"
                  onChange={(event) => priceHandler(event, 'basic')}
                />
              </label>
              <label>
                iced 음료 진하기 가격(옵션)
                <Input
                  name="iced"
                  type="number"
                  placeholder="iced  진하기 가격"
                  onChange={(event) => priceHandler(event, 'concnCharge')}
                />
              </label>

              <label>
                iced 음료 xl사이즈 가격(옵션)
                <Input
                  name="iced"
                  type="number"
                  placeholder="iced  xl사이즈 가격"
                  onChange={(event) => priceHandler(event, 'sizeCharge')}
                />
              </label>
            </>
          )}

          <Dialog
            backdrop={true}
            cancelButton="취소"
            confirmButton="확인"
            style={{ whiteSpace: 'pre-line', overflow: `scroll` }}
            onConfirm={() => confirmRecipe()}
            content={`
            beverageType : ${recipe.beverageType},
            category : ${recipe.category},
            시럽적용여부 : ${recipe.condition.syrupAdjustable},
            커피류/음료류:${recipe.detail.beverageGroup}, 
            idle이미지링크 : ${recipe.detail.idleImage},

            음료이미지링크 : ${recipe.detail.image},

            메인이미지링크 : ${recipe.detail.mainImage},
            소개문구 : ${recipe.detail.simpleInfo},
            소개글 : ${recipe.detail.summary},
            태그명 : ${recipe.detail.tagType},
            레시피 영문명 : ${recipe.recipeId},
            레시피 한글명 : ${recipe.detail.name},
            음료가격 : 
            ${JSON.stringify(recipe.price.basic)},
            진하기가격 : 
            ${JSON.stringify(recipe.price.concnCharge)},
            사이즈업가격 : 
            ${JSON.stringify(recipe.price.sizeCharge)},
            레시피 combination: 
            ${JSON.stringify(recipe.combination)}
              `}
            header="아래 recipe 추가 세부사항이 맞으면 확인을 눌러주세요 확인을 누르면 정상적으로 추가됩니다"
            trigger={<Button css={add__recipe__btn} onClick={() => addRecipe()} content="레시피 추가" />}
          />
        </div>
        <div css={add__ingredient__wrap}>
          <div css={add__ingredient__header}>
            <h1>Ingredient 추가</h1>
            <h2>새로운 레시피 추가 시, 신규 ingredient추가가 필요할때 아래를 입력하여 추가합니다</h2>
          </div>
          <label>
            원액이름(영문)
            <Input name="titleEng" placeholder="원액영문명" onChange={handleIngredient} />
          </label>
          <label>
            원액이름(한글)
            <Input name="titleKor" placeholder="원액한글명" onChange={handleIngredient} />
          </label>
          <label>
            원액타입
            <Input name="type" placeholder="원액타입" onChange={handleIngredient} />
          </label>
          <label>
            이미지링크
            <Input name="image" placeholder="이미지링크" onChange={handleIngredient} />
          </label>
          <Dialog
            backdrop={true}
            cancelButton="취소"
            confirmButton="확인"
            onConfirm={() => addIngredient()}
            style={{ whiteSpace: 'pre-line' }}
            content={`원액영문명 : ${ingredient.titleEng}, 
            원액한글명 : ${ingredient.titleKor}, 
            원액타입 : ${ingredient.type}, 
            이미지링크 : ${ingredient.image}`}
            header="아래 ingredient추가 세부사항이 맞으면 확인을 눌러주세요 확인을 누르면 정상적으로 추가됩니다"
            trigger={<Button content="원액 추가" />}
          />
        </div>
      </div>
    </>
  );
}

const add__wrap = css`
  display: flex;
  flex-direction: row;
  input,
  select {
    margin-left: 10px;
  }
`;

const add__ingredient__header = css`
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
  h1 {
    font-size: 30px;
  }
  h2 {
    font-size: 20px;
    color: grey;
  }
`;
const add__recipe__btn = css`
  margin-bottom: 20px;
`;
const combination__wrap = css`
  display: flex;
  flex-direction: column;
  span {
    font-weight: bold;
  }
`;
const add__recipe__wrap = css`
  display: flex;
  flex-direction: column;
  max-width: 500px;

  select {
    width: 300px;
  }
  label {
    font-weight: bold;
    margin-bottom: 15px;
  }
`;
const add__ingredient__wrap = css`
  background-color: white;
  display: flex;
  flex-direction: column;

  label {
    font-weight: bold;
    margin-bottom: 15px;
  }
`;
const add__remove__btn = css``;

const ice__combination__wrap = css`
  margin-bottom: 10px;
`;
export default AddNewRecipeAndIngredient;
