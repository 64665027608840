// 외부 모듈
import React, { useRef, useState, useEffect } from 'react';
import Chart from 'chart.js/auto';

// 타입
import { Temp } from '@definitions/monitoring';

interface TempChartProps {
  data: { temp: Temp; timeStamp: string }[];
}
const TempChart = ({ data }: TempChartProps) => {
  const boilerChartRef = useRef(null);
  const boilerChartInstance = useRef(null);
  const refrChartRef = useRef(null);
  const refrChartInstance = useRef(null);

  useEffect(() => {
    if (boilerChartRef.current && refrChartRef.current && data) {
      // 기존 차트 인스턴스 파괴
      if (boilerChartInstance.current) {
        boilerChartInstance.current.destroy();
      }
      if (refrChartInstance.current) {
        refrChartInstance.current.destroy();
      }

      const timestamps = data.map((item) => item.timeStamp);

      // boiler
      const currentBoilerTemps = data.map((item) => item.temp.boilerTemp);
      const targetBoilerTemps = data.map((item) => item.temp.boilerTargetTemp);
      // refr
      const currentRefrTemps = data.map((item) => item.temp.refrTemp);
      const targetRefrTemps = data.map((item) => item.temp.refrTargetTemp);

      boilerChartInstance.current = new Chart(boilerChartRef.current, {
        type: 'line',
        data: {
          labels: timestamps,
          datasets: [
            {
              label: '보일러 실제 온도',
              data: currentBoilerTemps,
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
            },
            {
              label: '설정 온도',
              data: targetBoilerTemps,
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 2,
            },
          ],
        },
        options: {
          // 차트 옵션 설정
        },
      });

      refrChartInstance.current = new Chart(refrChartRef.current, {
        type: 'line',
        data: {
          labels: timestamps,
          datasets: [
            {
              label: '냉장고 실제 온도',
              data: currentRefrTemps,
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 2,
            },
            {
              label: '설정 온도',
              data: targetRefrTemps,
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 2,
            },
          ],
        },
        options: {
          // 차트 옵션 설정
        },
      });

      // 컴포넌트 언마운트 시 차트 인스턴스 파괴
      return () => {
        if (boilerChartInstance.current) {
          boilerChartInstance.current.destroy();
        }
        if (refrChartInstance.current) {
          refrChartInstance.current.destroy();
        }
      };
    }
  }, [data]);

  return (
    <div>
      <h2>Temperature Chart</h2>
      <canvas ref={boilerChartRef}></canvas>
      <canvas ref={refrChartRef}></canvas>
    </div>
  );
};

export default TempChart;
