// 외부 모듈
import React from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';

// 내부 모듈
import VmAmountCardMobile from './VmAmountCardMobile';
import { VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers';

function Dashboard() {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const ingredients = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/ingredients']);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  if (!admins || !uid) {
    return null;
  }
  // dashboard에는 test,init,사용하지않는 vm은 빼고 보여준다
  const filterValidVms = vms.filter(
    (vm) =>
      !vm.id.includes('TEST') &&
      !vm.id.includes('INIT') &&
      !vm.id.includes('BETA0002') &&
      !vm.id.includes('BETA0009') &&
      !vm.id.includes('BETA0001') &&
      !vm.id.includes('BETA0011') &&
      !vm.id.includes('BETA0020') &&
      !vm.id.includes('BETA0032') &&
      !vm.id.includes('BETA0050'),
  );
  if (admins[uid].level > 1) {
    return <div>접근권한 없음</div>;
  }

  return (
    <div css={dashboard__wrap}>
      <div css={card__wrap}>
        {filterValidVms.map((item, idx) => (
          <VmAmountCardMobile key={idx} item={item} ingredients={ingredients} />
        ))}
      </div>
    </div>
  );
}

export default Dashboard;

const dashboard__wrap = css`
  width: 100vw;
  height: calc(100vh - 360px);
  font-family: Spoqa Han Sans;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  font-size: 24px;
`;
const card__wrap = css`
  width: 100vw;
`;
