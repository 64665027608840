// 외부모듈
import { call, delay, put, select } from '@redux-saga/core/effects';

// 내부모듈
import { completeInitSaga } from '@reducers/init';

export function* initSaga(): Generator<any, any, any> {
  function* checkFirestoreOrderd(): Generator<any, any, any> {
    const firesotreOrderd = yield select((state) => state.firestore.ordered);
    if (Object.keys(firesotreOrderd).length > 1) {
      return null;
    }
    yield delay(1000);
    yield call(checkFirestoreOrderd);
  }

  yield call(checkFirestoreOrderd);
  yield put(completeInitSaga());
}
