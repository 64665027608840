import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import MonitoringTable from './MonitoringTable';
import axios from 'axios';
import { Monitoring } from '@definitions/monitoring';
import { DEPRICATED_VMS } from '@constants/vm';

function extractNumber(jsString: string) {
  // Assuming the non-numeric part is always 'BETA'
  const prefixLength = 'BETA'.length;

  // Extracting the numeric part from the string
  const numberPart = jsString.substring(prefixLength);

  // Converting the extracted part to a number
  return Number(numberPart);
}

const excludeVmFilter = (vmId: string) => !DEPRICATED_VMS.includes(vmId);

function MonitoringControl(): JSX.Element {
  const [logs, setLogs] = useState<Monitoring[]>([]);
  useEffect(() => {
    const addr = `http://planz-proxy.com:3000/getLogs`;
    const params = { key: 'planz!3245' };
    axios.get(addr, { params }).then((res) => {
      setLogs(res.data);
    });
  }, []);
  return (
    <Container maxWidth="xl" style={{ marginTop: '19px', overflow: 'scroll' }}>
      <Typography variant="h4" gutterBottom>
        VM 모니터링
      </Typography>
      <MonitoringTable
        logs={logs
          .filter((log) => !DEPRICATED_VMS.includes(log.vmId))
          .sort((a, b) => extractNumber(a.vmId) - extractNumber(b.vmId))}
      />
    </Container>
  );
}

export default MonitoringControl;
