import { Container } from '@definitions/container';
import {
  RecipeType,
  BeverageType,
  MountPosition,
  CombinationObjectType,
  PriceType,
  BothCustomCombination,
  SizeCharge,
  CustomCombinationObjectType,
  IngredientType,
  IngredientTypes,
} from '@definitions/recipes';
import { Keg, Pump, Location } from '@definitions/vm';

type Concentrates = CombinationObjectType[];

// recipe를 받아서 해당 레시피의 원액(케그,펌프)의 컴비네이션 객체를 리턴하는 함수
export function concentratesFilter(recipe: RecipeType): Concentrates {
  // both일 땐, 각각 hot, iced 음료의 원액(케그, 펌프)을 리턴
  if (recipe.beverageType === BeverageType.both) {
    const hot = recipe.combination.hot.filter(({ ingredientId }) => {
      if (ingredientId === 'water' || ingredientId === 'ice' || ingredientId === 'hotWater') {
        return false;
      }

      return true;
    });

    const iced = recipe.combination.iced.filter(({ ingredientId }) => {
      if (ingredientId === 'water' || ingredientId === 'ice' || ingredientId === 'hotWater') {
        return false;
      }

      return true;
    });

    return [...hot, ...iced];

    // icedOnly 일땐, iced 음료의 원액(케그, 펌프)만 리턴
  } else if (recipe.beverageType === BeverageType.icedOnly) {
    return recipe.combination.iced.filter(({ ingredientId }) => {
      if (ingredientId === 'water' || ingredientId === 'ice' || ingredientId === 'hotWater') {
        return false;
      }

      return true;
    });

    // hotOnly 일땐, hot 음료의 원액(케그, 펌프)만 리턴
  } else {
    return recipe.combination.hot.filter(({ ingredientId }) => {
      if (ingredientId === 'water' || ingredientId === 'ice' || ingredientId === 'hotWater') {
        return false;
      }

      return true;
    });
  }
}

function getOldestContainer(containers: any[]) {
  if (containers.length === 0) {
    return { mountPosition: MountPosition.hotWater };
  }

  return containers.reduce((acc, cur) => {
    return acc.mountDate < cur.mountDate ? acc : cur;
  });
}

export function getMountableRecipes(
  recipes: RecipeType[],
  mountedRecipes: RecipeType[],
  kegs: Keg[],
  pumps: Pump[],
  prices: { [recipeId: string]: PriceType },
  location: Location,
  vmId: string,
): RecipeType[] {
  const mountableRecipes = recipes.filter((recipe: RecipeType) => {
    const isExist = mountedRecipes.filter(({ detail: { name } }) => name === recipe.detail.name).length !== 0;
    if (isExist) {
      return false;
    }

    return true;
  });

  return mountableRecipes
    .filter((mountableRecipe) => {
      // 아이스컵, 얼음물 때문에 예외처리 두개 추가
      const isIceCupRecipe =
        mountableRecipe.beverageType === BeverageType.icedOnly &&
        mountableRecipe.combination.iced.length === 1 &&
        mountableRecipe.combination.iced[0].ingredientId === 'ice';

      const isIceWaterRecipe =
        mountableRecipe.beverageType === BeverageType.icedOnly &&
        mountableRecipe.combination.iced.length === 2 &&
        mountableRecipe.combination.iced[0].ingredientId === 'ice' &&
        mountableRecipe.combination.iced[1].ingredientId === 'water';

      if (isIceCupRecipe || isIceWaterRecipe) {
        return mountableRecipe.targetLocations.includes(vmId);
      }

      const concentrates = concentratesFilter(mountableRecipe);
      const [concentrate] = concentrates;

      const concentrateNames = concentrates.map(({ ingredientId }) => ingredientId);
      const nameSet = new Set(concentrateNames);

      // 두개 이상의 서로 다른 ingredient를 조합해야 하는 음료
      if (mountableRecipe.locationName && mountableRecipe.locationName !== location.name) {
        return false;
      }

      const isUnmountableLoaction =
        mountableRecipe.targetLocations &&
        mountableRecipe.targetLocations.filter((locationId: string) => locationId === vmId).length === 0;
      if (isUnmountableLoaction) {
        return false;
      }

      if (nameSet.size > 1) {
        let availableIngredientNum = 0;
        const ingredientsInKegsAndPumps = [
          ...kegs.map(({ ingredientId }) => ingredientId),
          ...pumps.map(({ ingredientId }) => ingredientId),
        ];

        nameSet.forEach((ingredientId) => {
          if (ingredientsInKegsAndPumps.includes(ingredientId)) {
            availableIngredientNum++;
          }
        });
        if (nameSet.size === availableIngredientNum) {
          return true;
        }

        return false;

        // 1개의 ingredient만 필요한 레시피
      } else {
        const isAvailableRecipeUsingKegs =
          kegs.filter(({ ingredientId }) => {
            console.log(concentrate, ingredientId);
            if (concentrate.ingredientId === ingredientId) {
              return true;
            }

            return false;
          }).length !== 0;

        const isAvailableRecipeUsingPumps =
          pumps.filter(({ ingredientId }) => {
            if (concentrate.ingredientId === ingredientId) {
              return true;
            }

            return false;
          }).length !== 0;

        if (isAvailableRecipeUsingKegs || isAvailableRecipeUsingPumps) {
          return true;
        }

        return false;
      }
    })
    .map((recipe) => {
      if (recipe.beverageType === BeverageType.both) {
        const combination = {
          hot: [
            ...recipe.combination.hot.map((combinationObject) => {
              const containers = [...kegs, ...pumps];
              let mountPosition = MountPosition.hotWater;
              let mountedContainers = [];
              containers.forEach((container) => {
                if (container.ingredientId === combinationObject.ingredientId) {
                  mountedContainers = [...mountedContainers, container];
                }
              });
              mountPosition = getOldestContainer(mountedContainers).mountPosition;
              if (combinationObject.ingredientId === 'hotWater') {
                mountPosition = MountPosition.hotWater;
              } else if (combinationObject.ingredientId === 'water') {
                mountPosition = MountPosition.water;
              } else if (combinationObject.ingredientId === 'ice') {
                mountPosition = MountPosition.ice;
              }

              return { ...combinationObject, mountPosition };
            }),
          ],
          iced: [
            ...recipe.combination.iced.map((combinationObject) => {
              const containers = [...kegs, ...pumps];
              let mountPosition = MountPosition.water;
              let mountedContainers = [];
              containers.forEach((container) => {
                if (container.ingredientId === combinationObject.ingredientId) {
                  mountedContainers = [...mountedContainers, container];
                }
              });
              mountPosition = getOldestContainer(mountedContainers).mountPosition;
              if (combinationObject.ingredientId === 'hotWater') {
                mountPosition = MountPosition.hotWater;
              } else if (combinationObject.ingredientId === 'water') {
                mountPosition = MountPosition.water;
              } else if (combinationObject.ingredientId === 'ice') {
                mountPosition = MountPosition.ice;
              }

              return { ...combinationObject, mountPosition };
            }),
          ],
        };

        return { ...recipe, combination };
      } else if (recipe.beverageType === BeverageType.icedOnly) {
        const combination = {
          iced: [
            ...recipe.combination.iced.map((combinationObject) => {
              const containers = [...kegs, ...pumps];
              let mountPosition = MountPosition.water;
              let mountedContainers = [];
              containers.forEach((container) => {
                if (container.ingredientId === combinationObject.ingredientId) {
                  mountedContainers = [...mountedContainers, container];
                }
              });
              mountPosition = getOldestContainer(mountedContainers).mountPosition;
              if (combinationObject.ingredientId === 'hotWater') {
                mountPosition = MountPosition.hotWater;
              } else if (combinationObject.ingredientId === 'water') {
                mountPosition = MountPosition.water;
              } else if (combinationObject.ingredientId === 'ice') {
                mountPosition = MountPosition.ice;
              }

              return { ...combinationObject, mountPosition };
            }),
          ],
        };

        return { ...recipe, combination };
      } else {
        const combination = {
          hot: [
            ...recipe.combination.hot.map((combinationObject) => {
              const containers = [...kegs, ...pumps];
              let mountPosition = MountPosition.hotWater;
              let mountedContainers = [];
              containers.forEach((container) => {
                if (container.ingredientId === combinationObject.ingredientId) {
                  mountedContainers = [...mountedContainers, container];
                }
              });
              mountPosition = getOldestContainer(mountedContainers).mountPosition;
              if (combinationObject.ingredientId === 'hotWater') {
                mountPosition = MountPosition.hotWater;
              } else if (combinationObject.ingredientId === 'water') {
                mountPosition = MountPosition.water;
              } else if (combinationObject.ingredientId === 'ice') {
                mountPosition = MountPosition.ice;
              }

              return { ...combinationObject, mountPosition };
            }),
          ],
        };

        return { ...recipe, combination };
      }
    })
    .map((recipe: RecipeType) => {
      const { customCombination } = recipe;
      if (customCombination) {
        const { iced_m, iced_xl, hot_m } = customCombination as BothCustomCombination;
        const cc: any = {};
        iced_m && Object.assign(cc, { iced_m: fillCustomCombinationWithContainers(iced_m, [...kegs, ...pumps]) });
        iced_xl && Object.assign(cc, { iced_xl: fillCustomCombinationWithContainers(iced_xl, [...kegs, ...pumps]) });
        hot_m && Object.assign(cc, { hot_m: fillCustomCombinationWithContainers(hot_m, [...kegs, ...pumps]) });

        return { ...recipe, customCombination: cc };
      }
      return recipe;
    })
    .map((recipe: RecipeType) => {
      if (vmId === 'BETA0016') {
        const { recipeId, beverageType } = recipe;
        const customCombination = recipe.customCombination as BothCustomCombination;
        if (recipeId === 'modernAmericano') {
          return {
            ...recipe,
            customCombination: {
              ...customCombination,
              iced_m: customCombination.iced_m.map((comb) => {
                if (comb.ingredientId === 'modAmericano') {
                  return { ...comb, amount: 42 };
                }
                return comb;
              }),
            },
          };
        }

        return recipe;
      }
      return recipe;
    });
}

const fillCustomCombinationWithContainers = (
  customCombination: CustomCombinationObjectType[],
  containers: (Keg | Pump)[],
): CustomCombinationObjectType[] => {
  return customCombination.map((comb) => {
    if (
      comb.ingredientId === 'clear' ||
      comb.ingredientId === 'led' ||
      comb.ingredientId === 'ice' ||
      comb.ingredientId === 'cup'
    ) {
      return { ...comb, mountPosition: 0, isComplete: false };
    } else if (comb.ingredientId === 'water') {
      return { ...comb, mountPosition: MountPosition.water, isComplete: false };
    } else if (comb.ingredientId === 'hotWater') {
      return { ...comb, mountPosition: MountPosition.hotWater, isComplete: false };
    } else {
      const filteredContainer = containers
        .filter((container) => container.ingredientId === comb.ingredientId)
        .sort((left, right) => right.mountDate.seconds - left.mountDate.seconds);
      if (filteredContainer.length > 0) {
        return {
          ...comb,
          mountPosition: filteredContainer[filteredContainer.length - 1].mountPosition,
          isComplete: false,
        };
      } else if (filteredContainer.length === 0 && comb.ingredientId === 'sugarSyrup') {
        return { ...comb, mountPosition: -1, isComplete: false };
      }
      throw Error('커스텀 컴비네이션의 재료 중에 해당 VM에 존재 하지 않는 재료가 있습니다.');
    }
  });
};

const addHoneyMilkTea = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_ONLY',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 32,
          ingredientId: 'blackTeaHoney',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 218,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 21,
          ingredientId: 'blackTeaHoney',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 134,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: false,
    },
    detail: {
      beverageGroup: 'NON-COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbrewed%2Fidle.png?alt=media&token=755b213b-18c9-4d40-9cbb-0851699451b7',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fhoney_milk_tea%2FhoneyMilkTea.png?alt=media&token=a2ff03d7-8176-480d-b6e4-2aeaeb7b0d6d',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fhoney_milk_tea%2FhoneyMilkTeaMain.png?alt=media&token=a9f399c6-6b17-467d-a037-f5fd40f56c25',
      name: '허니밀크티',
      simpleInfo: '우롱과 얼그레이와 꿀과 우유',
      summary: '우롱과 얼그레이를 우린 차에 유기농 사탕수수 고이아사 설탕과 꿀을 넣은 밀크티 음료입니다.',
      tagType: 'NEW',
    },
    isSoldout: false,
    price: {
      basic: { iced: 2800 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { iced: 900, type: SizeCharge.iced },
    },
    recipeId: 'honeyMilkTea',
  });
};

const addRichChocolate = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_ONLY',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 169,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 81,
          ingredientId: 'chocolateWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 102,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 53,
          ingredientId: 'chocolateWater',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: false,
    },
    detail: {
      beverageGroup: 'NON-COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbrewed%2Fidle.png?alt=media&token=755b213b-18c9-4d40-9cbb-0851699451b7',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Frich_chocolate%2FrichChocolate.png?alt=media&token=c03093ec-fa81-41ad-947a-e09510456699',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Frich_chocolate%2FrichChocolateMain.png?alt=media&token=11b8ea73-d73b-4b4e-a597-78417972f8ff',
      name: '리치초콜릿',
      simpleInfo: '우깊고 진한 리치초콜릿',
      summary: '깊고 진한 리치 초콜릿이 입안 가득 부드럽게 녹아드는 초코 음료입니다.',
      tagType: 'NEW',
    },
    isSoldout: false,
    price: {
      basic: { iced: 2800 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { iced: 900, type: SizeCharge.iced },
    },
    recipeId: 'honeyMilkTea',
  });
};

const addHolidayMoka = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_ONLY',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 41,
          ingredientId: 'brewedCoffee',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 160,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 49,
          ingredientId: 'chocolateWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 26,
          ingredientId: 'brewedCoffee',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 97,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 32,
          ingredientId: 'chocolateWater',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: 'COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbrewed%2Fidle.png?alt=media&token=755b213b-18c9-4d40-9cbb-0851699451b7',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fholiday_moka%2FholidayMoka.png?alt=media&token=b13a36e1-d023-451c-a4dc-8790e26167d2',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fholiday_moka%2FholidayMokaMain.png?alt=media&token=a407802f-0aa0-49cd-826c-41e9e11e3b0c',
      name: '할리데이모카',
      roastingPoint: 3,
      simpleInfo: '카페라떼와 다크초콜릿',
      summary:
        '마일드커피와 깊고 진한 초콜릿, 부드러운 우유가 더해진 할리데이모카로 올 한해 동안 지친 몸과 마음을 달래봐요.',
      tagType: 'NEW',
    },
    isSoldout: false,
    price: {
      basic: { iced: 2800 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { iced: 900, type: SizeCharge.iced },
    },
    recipeId: 'holidayMoka',
  });
};

const addPinkGrapeFruitMixed = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_HOT_BOTH',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 36,
          ingredientId: 'pinkGrapeFruitMixed',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 19,
          ingredientId: 'sugarSyrup',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 215,
          ingredientId: 'hotWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 29,
          ingredientId: 'pinkGrapeFruitMixed',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 143,
          ingredientId: 'water',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: 'NON-COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fpink_grapefruit%2Fidle.png?alt=media&token=108e2820-abea-487c-bf70-cd24fa5397e4',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fpink_grapefruit%2Fitem(1006).png?alt=media&token=1f438b75-63ed-4102-b643-4746cc157dce',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fpink_grapefruit%2Fmain1.png?alt=media&token=2c906cf4-3962-4d59-8eb9-2ab75b32144a',
      name: '핑크자몽',
      simpleInfo: '쓴맛이 덜하고 깔끔한 자몽',
      summary:
        '자칫 부담스러울수 있는 자몽주스를 잘 블렌딩하여 단맛이 돌면서 뒷맛이 산뜻하고 밸런스가 좋은 음료입니다.',
    },
    isSoldout: false,
    price: {
      basic: { hot: 2400, iced: 2400 },
      concnCharge: { type: 'concn_none' },
      sizeCharge: { iced: 700, type: 'size_iced' },
    },
    recipeId: 'pinkGrapeFruitMixedRecipe',
  });
};

const addColdBrew = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_HOT_BOTH',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 75,
          ingredientId: 'brewedCoffee',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 75,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 75,
          ingredientId: 'hotWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 57,
          ingredientId: 'brewedCoffee',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 124,
          ingredientId: 'milk',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: 'COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbrewed%2Fidle.png?alt=media&token=755b213b-18c9-4d40-9cbb-0851699451b7',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fcafelatte%2Fitem.png?alt=media&token=73f8266e-66c8-46b6-a7fa-fc33ad298a7c',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fcafelatte%2Fmain.png?alt=media&token=46a86e12-0d3e-4f7c-84dd-7b425ecf0caf',
      name: '카페라떼',
      roastingPoint: 1,
      simpleInfo: '커피 샷과 부드러운 우유',
      summary: '마일드한 커피 샷에 부드러운 우유를 더해 고소하고 산뜻하게 마실 수 있는 라떼음료.',
      tagType: 'NEW',
    },
    isSoldout: false,
    price: {
      basic: { hot: 2200, iced: 2200 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { hot: 500, iced: 700, type: 'size_both' },
    },
    recipeId: 'cafelatte',
  });
};

const addMarketBlending = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_HOT_BOTH',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 75,
          ingredientId: 'marketBlending',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 198,
          ingredientId: 'hotWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 57,
          ingredientId: 'marketBlending',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 124,
          ingredientId: 'water',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: 'COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Froastery%2Fdukes%2Fidle.png?alt=media&token=2ed16221-2526-4fa0-ba33-62d20c6af26e',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fmarket_blending_original%2FmarketBlendingOriginal.png?alt=media&token=f6865441-50fa-46fa-94cf-68b2fd5131e5',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fmarket_blending_original%2FmarketBlendingOriginalMain.png?alt=media&token=86d566c3-2329-43be-918c-ef1cf65201f5',
      name: '마켓에스프레소',
      roastingPoint: 1,
      simpleInfo: '듁스 커피',
      summary:
        '호주 멜버른에 위치한 듁스 커피는 엄격한 기준을 가지고 품질을 관리하며  최상의 블렌딩과 로스팅을 선보입니다. 듁스 마켓 에스프레소 블렌딩으로 확인해보세요.',
    },
    isSoldout: false,
    price: {
      basic: { hot: 2700, iced: 2700 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { iced: 900, type: SizeCharge.iced },
    },
    recipeId: 'marketBlendingOriginal',
  });
};

const addBabyBlending = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_HOT_BOTH',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 75,
          ingredientId: 'babyBlending',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 198,
          ingredientId: 'hotWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 57,
          ingredientId: 'babyBlending',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 124,
          ingredientId: 'water',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: 'COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Froastery%2Fwerk%2Fidle.png?alt=media&token=f942edaa-1842-4dd0-a84c-5d59dff4bfa7',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbaby_blending_original%2FbabyBlendingOriginal.png?alt=media&token=149287fa-5e7b-4acd-be06-716bd8786450',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbaby_blending_original%2FbabyBlendingOriginalMain.png?alt=media&token=b77c2d3c-9409-4169-8664-802e66aa8298',
      name: '베이비블랜딩',
      roastingPoint: 3,
      simpleInfo: '베르크 로스터스',
      summary:
        '베르크 로스터스는 스페셜티 커피 로스터이자 국가대표 바리스타  심사위원으로 일한 바 있는 박현동 로스터의 커피를 만날 수 있습니다. 특히 베이비 블렌드는 그중 가장 사랑 받는 커피 입니다.',
    },
    isSoldout: false,
    price: {
      basic: { hot: 2700, iced: 2700 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { iced: 900, type: SizeCharge.iced },
    },
    recipeId: 'babyBlendingOriginal',
  });
};

const addGoldenEra = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_HOT_BOTH',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 75,
          ingredientId: 'goldenEra',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 198,
          ingredientId: 'hotWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 57,
          ingredientId: 'goldenEra',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 124,
          ingredientId: 'water',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: 'COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Froastery%2Funder_pressure%2Fidle.png?alt=media&token=29b844fb-fc95-4771-b986-fc237bbce922',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fgoldenera_original%2FgoldenEraOriginal.png?alt=media&token=7450bdff-1f8b-4b51-acd4-425ed35fac60',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fgoldenera_original%2FgoldenEraOriginalMain.png?alt=media&token=3b95a20f-5dac-44c8-8574-c4c9b8b62efb',
      name: '골든에라',
      roastingPoint: 3,
      simpleInfo: '언더프레셔',
      summary:
        '언더프레셔는 도산공원과 삼성동의 카페로 잘 알려진 커피 컴퍼니 입니다. 일상 속에서 즐기는 커피는 그 무엇보다 편하고 여유로워야 한다는 철학으로 커피를 디자인합니다.',
    },
    isSoldout: false,
    price: {
      basic: { hot: 2700, iced: 2700 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { iced: 900, type: SizeCharge.iced },
    },
    recipeId: 'goldenEraOriginal',
  });
};

const addBrooklynComfort = (firestore: any) => {
  firestore.collection('/version/v3/recipes').add({
    beverageType: 'ICED_HOT_BOTH',
    category: 'Planz',
    combination: {
      hot: [
        {
          amount: 75,
          ingredientId: 'brooklynComfort',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 198,
          ingredientId: 'hotWater',
          metric: 1,
          startAt: 0,
        },
      ],
      iced: [
        {
          amount: 57,
          ingredientId: 'brooklynComfort',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 124,
          ingredientId: 'water',
          metric: 1,
          startAt: 0,
        },
        {
          amount: 120,
          ingredientId: 'ice',
          metric: 1,
          startAt: 0,
        },
      ],
    },
    condition: {
      syrupAdjustable: true,
    },
    detail: {
      beverageGroup: 'COFFEE',
      // eslint-disable-next-line prettier/prettier
      idleImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Froastery%2Funder_pressure%2Fidle.png?alt=media&token=29b844fb-fc95-4771-b986-fc237bbce922',
      image:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbrooklyn_comfort_original%2FbrooklynComfortOriginal.png?alt=media&token=b0bf945a-a93f-45b2-8333-c031310ca240',
      mainImage:
        'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/v3%2Forder%2Foriginal%2Fbrooklyn_comfort_original%2FbrooklynComfortOriginalMain.png?alt=media&token=6cc97334-f687-4389-958c-60e8ee82dad7',
      name: '브루클린컴포트',
      roastingPoint: 1,
      simpleInfo: '언더프레셔',
      summary:
        '언더프레셔는 도산공원과 삼성동의 카페로 잘 알려진 커피 컴퍼니 입니다. 일상 속에서 즐기는 커피는 그 무엇보다 편하고 여유로워야 한다는 철학으로 커피를 디자인합니다.',
    },
    isSoldout: false,
    price: {
      basic: { hot: 2700, iced: 2700 },
      concnCharge: { hot: 500, iced: 500, type: 'concn_both' },
      sizeCharge: { iced: 900, type: SizeCharge.iced },
    },
    recipeId: 'brooklynComfortOriginal',
  });
};
