//외부모듈
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

interface ConfirmProps {
  openConfirm: boolean;
  closeHandler: () => void;
  submitHandler: () => void;
}

function ConfirmModal({ openConfirm, closeHandler, submitHandler }: ConfirmProps) {
  return (
    <>
      <Dialog
        fullWidth={true}
        open={openConfirm}
        // style={{ padding: '0px' }}
        onClose={closeHandler}
        aria-labelledby="alert-dialog-title"
      >
        <DialogTitle id="alert-dialog-title">{'이대로 공급가액을 변경하시겠습니까?'}</DialogTitle>
        <DialogActions>
          <Button variant="outlined" color="secondary" onClick={closeHandler}>
            아니요
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              closeHandler();
              submitHandler();
            }}
            autoFocus
          >
            예
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ConfirmModal;
