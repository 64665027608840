// 외부모듈
import React from 'react';
import { css } from '@emotion/core';
import { withStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { Pipe, Offset, PumpStatus } from '@definitions/vm';

interface LineControlProps {
  offset: Offset;
  updateOffset: (offset: Offset) => void;
  pipe: Pipe;
  updatePipes: (pipe: Pipe) => void;
}

const StyledSelect = withStyles({
  root: {
    fontSize: '14px',
    height: '80px',
    width: '200px',
    backgroundColor: 'white',
  },
  select: {
    fontSize: '28px', // 글자 크기
    fontWeight: 'bold', // 굵기
    textAlign: 'center', // 중앙 정렬
    lineHeight: '80px',
    // padding: "8px 16px",
  },
  // '.MuiSelect-icon' 등 아이콘 위치나 크기 커스텀도 가능
})(Select);

const StyledMenuItem = withStyles({
  root: {
    fontSize: '28px',
  },
  selected: {
    fontWeight: 700, // 선택된 항목만 두껍게
  },

  // '.MuiSelect-icon' 등 아이콘 위치나 크기 커스텀도 가능
})(MenuItem);

const check = (value: any, validate: (value: any) => boolean, update: (value: any) => void) => {
  if (!validate(value)) {
    window.alert(`변경하고자 하는 값의 포맷이 잘못되었습니다: ${value}`);
  }
  const answer = window.confirm('정말 변경하겠습니까?');
  if (!answer) {
    return;
  }

  update(value);
};

function LineControl({ offset, updateOffset, pipe, updatePipes }: LineControlProps) {
  return (
    <div css={ps__wrap}>
      <div css={ps__inner}>
        <h1 style={{ color: '#3fb566' }}>펌프 속도</h1>
        <FormControl>
          <StyledSelect
            value={offset.pumpSpeed || 1}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              const newPumpSpeed = e.target.value;
              check(
                { ...offset, pumpSpeed: newPumpSpeed },
                (offset: Offset) => {
                  if (
                    offset.pumpSpeed === 1 ||
                    offset.pumpSpeed === 2 ||
                    offset.pumpSpeed === 3 ||
                    offset.pumpSpeed === 4
                  ) {
                    return true;
                  }

                  return false;
                },
                updateOffset,
              );
            }}
          >
            <StyledMenuItem value={1}>1</StyledMenuItem>
            <StyledMenuItem value={2}>2</StyledMenuItem>
            <StyledMenuItem value={3}>3</StyledMenuItem>
            <StyledMenuItem value={4}>4</StyledMenuItem>\
          </StyledSelect>
        </FormControl>
      </div>
      {pipe && (
        <div css={ps__inner}>
          <h1 style={{ width: '180px', color: '#3f51b5' }}>세척 배관 용량</h1>
          <FormControl>
            <StyledSelect
              value={pipe.flushAmount}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                const flushAmount = e.target.value;
                check(
                  { ...pipe, flushAmount },
                  (pipe: Pipe) => {
                    if (0 <= pipe.flushAmount && pipe.flushAmount <= 25) {
                      return true;
                    }

                    return false;
                  },
                  updatePipes,
                );
              }}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map(
                (arr, index) => (
                  <StyledMenuItem value={index} key={`${index}`}>
                    {index}g
                  </StyledMenuItem>
                ),
              )}
            </StyledSelect>
          </FormControl>
        </div>
      )}
    </div>
  );
}

interface LineControlProps {
  pumpStatus: PumpStatus;
  updatePumpStatus: (pumpStatus: PumpStatus) => void;
  pipe: Pipe;
  updatePipes: (pipe: Pipe) => void;
}

export function LineControlCntnr({ pumpStatus, updatePumpStatus, pipe, updatePipes }: LineControlProps) {
  return (
    <div css={ps__wrap}>
      <div css={ps__inner}>
        <h1 style={{ color: '#3fb566' }}>펌프 속도</h1>
        <FormControl>
          <StyledSelect
            value={pumpStatus.pumpSpeed || 1}
            onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
              const newPumpSpeed = e.target.value;
              check(
                { ...pumpStatus, pumpSpeed: newPumpSpeed },
                (offset: Offset) => {
                  if (
                    offset.pumpSpeed === 1 ||
                    offset.pumpSpeed === 2 ||
                    offset.pumpSpeed === 3 ||
                    offset.pumpSpeed === 4 ||
                    offset.pumpSpeed === 5 ||
                    offset.pumpSpeed === 6 ||
                    offset.pumpSpeed === 7 ||
                    offset.pumpSpeed === 8 ||
                    offset.pumpSpeed === 9 ||
                    offset.pumpSpeed === 10
                  ) {
                    return true;
                  }

                  return false;
                },
                updatePumpStatus,
              );
            }}
          >
            <StyledMenuItem value={1}>1</StyledMenuItem>
            <StyledMenuItem value={2}>2</StyledMenuItem>
            <StyledMenuItem value={3}>3</StyledMenuItem>
            <StyledMenuItem value={4}>4</StyledMenuItem>
            <StyledMenuItem value={5}>5</StyledMenuItem>
            <StyledMenuItem value={6}>6</StyledMenuItem>
            <StyledMenuItem value={7}>7</StyledMenuItem>
            <StyledMenuItem value={8}>8</StyledMenuItem>
            <StyledMenuItem value={9}>9</StyledMenuItem>
            <StyledMenuItem value={10}>10</StyledMenuItem>
          </StyledSelect>
        </FormControl>
      </div>
      {pipe && (
        <div css={ps__inner}>
          <h1 style={{ width: '180px', color: '#3f51b5' }}>세척 배관 용량</h1>
          <FormControl>
            <StyledSelect
              value={pipe.flushAmount}
              onChange={(e: React.ChangeEvent<{ value: unknown }>) => {
                const flushAmount = e.target.value;
                check(
                  { ...pipe, flushAmount },
                  (pipe: Pipe) => {
                    if (0 <= pipe.flushAmount && pipe.flushAmount <= 25) {
                      return true;
                    }

                    return false;
                  },
                  updatePipes,
                );
              }}
            >
              {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25].map(
                (arr, index) => (
                  <StyledMenuItem value={index} key={`${index}`}>
                    {index}g
                  </StyledMenuItem>
                ),
              )}
            </StyledSelect>
          </FormControl>
        </div>
      )}
    </div>
  );
}

const ps__wrap = css`
  height: 160px;
  width: 100%;
  //   background-color: white;
  font-family: Times Sans Serif;

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-top: 1px solid #dddddd;
  h1 {
    width: 120px;
    background-color: transparent;
    color: black;
    font-size: 34px;
    text-align: center;
  }
  overflow: hidden;
`;

const ps__inner = css`
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export default LineControl;
