function padZero(num: number) {
  return (num < 10 ? '0' : '') + num;
}

export function formatDate(d: Date) {
  const yyyy = d.getFullYear();
  const mm = padZero(d.getMonth() + 1); // getMonth()는 0부터 시작하기 때문에 1을 더해줍니다.
  const dd = padZero(d.getDate());
  const HH = padZero(d.getHours());
  const MM = padZero(d.getMinutes());

  return `${yyyy}-${mm}-${dd}_${HH}:${MM}`;
}

export function formatDateToKorean(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1 해줍니다.
  const day = String(date.getDate()).padStart(2, '0');

  const daysInKorean = ['일', '월', '화', '수', '목', '금', '토'];

  return `${year}년 ${month}월 ${day}일`;
}

export const getN = (ingredientId: string) => {
  if (ingredientId === 'milk') {
    return 14;
  } else if (ingredientId === 'coffeeMilk') {
    return 60;
  } else {
    return 90;
  }
};

export function getDateAfterNDays(days: number) {
  // 현재 날짜를 가져옵니다.
  const currentDate = new Date();

  // 현재 날짜에 30일을 더합니다.
  currentDate.setDate(currentDate.getDate() + days);

  // 수정된 날짜를 반환합니다.

  const year = currentDate.getFullYear();
  let month = currentDate.getMonth() + 1; // 월은 0부터 시작하므로 1을 더해줍니다.
  let day = currentDate.getDate();

  // 월과 일이 한 자리 수일 경우 앞에 '0'을 붙여줍니다.
  month = month < 10 ? `0${month}` : month;
  day = day < 10 ? `0${day}` : day;

  // 'YYYY-MM-DD' 형식으로 날짜를 포맷합니다.
  return `${year}-${month}-${day}`;
}
