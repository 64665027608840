import React, { useEffect, useState } from 'react';
import { Container, Typography, Box } from '@material-ui/core';
import OprCalendar from './\boprCalendar';
import SummaryCharts from './SummaryCharts';
import LowPerformanceLocations from './LowPerformanceLocations';
import axios from 'axios';
import { Opr } from './types';

function OperationAvailability(): JSX.Element {
  const [oprs, setOprs] = useState<Opr[]>([]);
  useEffect(() => {
    const addr = `http://planz-proxy.com:3000/getOprs`;
    const params = { key: 'planz!3245', start: '2024-10-14', end: '2024-10-15' };
    axios.get(addr, { params }).then((res) => {
      console.log(res.data);
      // const { keys, transformedData } = transformData(res.data);
      setOprs(res.data);
    });
  }, []);
  return (
    <Container maxWidth="xl" style={{ marginTop: '19px', overflow: 'scroll' }}>
      <Typography variant="h4" gutterBottom>
        운영 가용 현황
      </Typography>
      <OprCalendar />
      {/* <SummaryCharts />
      <LowPerformanceLocations /> */}
    </Container>
  );
}
const formatDuration = (milliseconds: number) => {
  const totalSeconds = milliseconds / 1000;
  const totalMinutes = totalSeconds / 60;
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);

  return `${hours}시간 ${minutes}분`;
};

export default OperationAvailability;
