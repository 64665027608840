//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send } from '@material-ui/icons';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { ConcnCharge, RecipeType, SizeCharge } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';
import { ProdPriceInfoType } from './ProdPriceRaw';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

interface ProdPriceInputsProps {
  price: ProdPriceType;
  type: string;
  editOnChangeHandler?: (e: React.ChangeEvent<any>) => void;
  editSelectHandler?: (e: React.ChangeEvent<any>) => void;
  addOnChangeHandler?: (e: React.ChangeEvent<any>) => void;
  addSelectHandler?: (e: React.ChangeEvent<any>) => void;
}

export interface ProdPriceType {
  basic: {
    iced: number;
    hot: number;
  };
  concnCharge: {
    iced?: number;
    hot?: number;
    type: string;
  };
  sizeCharge: {
    iced?: number;
    hot?: number;
    type: string;
  };
}

function ProdPriceInputs({
  price,
  type,
  editOnChangeHandler,
  editSelectHandler,
  addOnChangeHandler,
  addSelectHandler,
}: ProdPriceInputsProps) {
  const { basic, concnCharge, sizeCharge } = price;

  const inputHandler = (e: React.ChangeEvent<any>) => {
    switch (type) {
      case 'edit':
        return editOnChangeHandler(e);
      case 'add':
        return addOnChangeHandler(e);
    }
  };

  const selectHandler = (e: React.ChangeEvent<any>) => {
    switch (type) {
      case 'edit':
        return editSelectHandler(e);
      case 'add':
        return addSelectHandler(e);
    }
  };

  return (
    <div css={input__controller__wrap}>
      <TextField label="기본(Iced)" name="basic/iced" value={basic.iced || 0} onChange={(e) => inputHandler(e)} />
      <TextField label="기본(Hot)" name="basic/hot" value={basic.hot || 0} onChange={(e) => inputHandler(e)} />
      <FormControl style={{ marginLeft: '30px' }} margin="normal">
        <InputLabel style={{ top: '-12px' }}>SizeType</InputLabel>
        <Select
          style={{ width: '90px' }}
          name="sizeCharge"
          onChange={(e) => selectHandler(e)}
          value={sizeCharge ? sizeCharge.type || 'size_none' : 'size_none'}
        >
          <MenuItem value="size_both">Both</MenuItem>
          <MenuItem value="size_iced">IcedOnly</MenuItem>
          <MenuItem value="size_hot">HotOnly</MenuItem>
          <MenuItem value="size_none">None</MenuItem>
        </Select>
      </FormControl>
      {sizeCharge && (sizeCharge.type === SizeCharge.iced || sizeCharge.type === 'size_both') && (
        <TextField label="Iced" name="sizeCharge/iced" value={sizeCharge.iced || 0} onChange={(e) => inputHandler(e)} />
      )}
      {sizeCharge && (sizeCharge.type === SizeCharge.hot || sizeCharge.type === 'size_both') && (
        <TextField label="Hot" name="sizeCharge/hot" value={sizeCharge.hot || 0} onChange={(e) => inputHandler(e)} />
      )}
      <FormControl style={{ marginLeft: '30px' }} margin="normal">
        <InputLabel style={{ top: '-12px' }}>ConcnType</InputLabel>
        <Select
          style={{ width: '100px' }}
          name="concnCharge"
          onChange={(e) => selectHandler(e)}
          value={concnCharge ? concnCharge.type || 'concn_none' : 'concn_none'}
        >
          <MenuItem value={'concn_both'}>Both</MenuItem>
          <MenuItem value="concn_iced">IcedOnly</MenuItem>
          <MenuItem value="concn_hot">HotOnly</MenuItem>
          <MenuItem value="concn_none">None</MenuItem>
        </Select>
      </FormControl>
      {concnCharge && (concnCharge.type === ConcnCharge.iced || concnCharge.type === ConcnCharge.both) && (
        <TextField
          label="Iced"
          name="concnCharge/iced"
          value={concnCharge.iced || 0}
          onChange={(e) => inputHandler(e)}
        />
      )}
      {concnCharge && (concnCharge.type === ConcnCharge.hot || concnCharge.type === ConcnCharge.both) && (
        <TextField label="Hot" name="concnCharge/hot" value={concnCharge.hot || 0} onChange={(e) => inputHandler(e)} />
      )}
      {/* <TextField label="priority" name="priority" value={priority || 0} onChange={(e) => inputHandler(id, e)} /> */}
    </div>
  );
}

export default ProdPriceInputs;

const input__controller__wrap = css`
  /* display: flex; */
  /* justify-content: space-between;
  align-items: center; */
  width: 100%;
  height: 60px;
  white-space: nowrap;
  input {
    width: 70px;
    margin: 5px;
    margin-bottom: 6px;
  }

  .MuiTextField-root {
    margin: 5px;
  }
`;
