import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Button } from '@material-ui/core';
import { VendingMachine } from '@definitions/vm';
import { useFirestore, ExtendedFirestoreInstance } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/index';

function Test(): JSX.Element {
  const firestore = useFirestore();
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  return (
    <Container maxWidth="xl" style={{ marginTop: '19px', overflow: 'scroll' }}>
      <Typography variant="h4" gutterBottom>
        테스트 기능
      </Typography>
      <Box>
        <Button onClick={() => DownloadDipsenses(firestore, vms)} color="primary">
          추출 기록 다운로드 받기
        </Button>
        <Button onClick={() => UpdateCoupoenHistory(firestore)} color="primary">
          MDS 주문기록 업데이트 하기
        </Button>
        <Button onClick={() => resetCouponHistory(firestore)} color="primary">
          MDS 쿠폰 초기화 하기
        </Button>
        <Button onClick={() => updateUserInfo(firestore)} color="primary">
          동국제약 가입 정보 수정
        </Button>
        <Button onClick={() => DownloadUsers(firestore)} color="primary">
          고객 정보 다운로드 받기
        </Button>
      </Box>
    </Container>
  );
}

const updateUserInfo = async (firestore: ExtendedFirestoreInstance) => {
  const query_ = firestore.collection('version/v3/users').where('signedUpMembers', 'array-contains', 'dkMembers');
  const dkUsers = await query_.get();
  dkUsers.forEach((row) => {
    const user = row.data();
    const batch = firestore.batch();
    batch.update(row.ref, {
      signedUpMembers: ['dkMembers', 'originalMembers'],
    });
    batch.commit();
  });
};

const resetCouponHistory = async (firestore: ExtendedFirestoreInstance) => {
  const query_ = firestore.collection('version/v3/users/03106273000/coupons').where('isExpired', '==', false);
  const coupons = await query_.get();
  console.log(coupons.docs.length);
  coupons.forEach((row) => {
    const batch = firestore.batch();
    const coupon = row.data();
    batch.update(row.ref, {
      isExpired: true,
      usedDate: new Date(),
    });
    batch.commit();
  });
};

const UpdateCoupoenHistory = async (firestore: ExtendedFirestoreInstance) => {
  let query_ = firestore.collection('version/v3/vms/BETA0027/orders/2024/06').limit(1);
  const cnt = 0;
  let lastDoc = null; // 이전 쿼리의 마지막 문서 저장
  let finished = false;
  while (!finished) {
    const batch = firestore.batch();
    if (lastDoc) {
      query_ = query_.startAfter(lastDoc);
    }
    const orders_ = await query_.get();
    orders_.forEach((row) => {
      console.log(row.id);
      const order = row.data();
      console.log(order.session.history);
      batch.update(row.ref, {
        session: {
          ...order.session,
          history: order.session.history.map((hist) => {
            if (hist.action === 'SIGN_IN_SUCCESS') {
              return {
                ...hist,
                payload: {
                  ...hist.payload,
                  userData: {
                    ...hist.payload.userData,
                    userCouponList: [],
                  },
                  userSickerList: [],
                },
              };
            }
            return hist;
          }),
        },
      });
    });
    batch.commit();
    const lastVisible = orders_.docs[orders_.docs.length - 1];
    if (orders_.docs.length < 1) {
      // 쿼리가 더 이상 결과를 가져오지 못하는 경우(배치 크기보다 작음) 종료합니다.
      finished = true;
    } else {
      console.log('다운로드 수: ', 1);
      // 아직 처리되지 않은 데이터가 있는 경우 이전 쿼리의 마지막 위치에서 다음 쿼리를 시작합니다.
      lastDoc = lastVisible;
    }
  }
};

const DownloadDipsenses = async (firestore: ExtendedFirestoreInstance, vms: VendingMachine[]) => {
  const queryDispensesForVmId = (vmId: string) => {
    const dispensesRef = firestore.collection(`version/v3/vms/${vmId}/dispenses`);

    // mountPosition이 140인 경우의 쿼리
    const query1 = dispensesRef.where('mountPosition', '==', 140).where('initialTime', '>=', '2024-01-18 00:00:00.00');

    // mountPosition이 141인 경우의 쿼리
    const query2 = dispensesRef.where('mountPosition', '==', 141).where('initialTime', '>=', '2024-01-18 00:00:00.00');

    // 두 쿼리를 병렬로 실행하고 결과를 결합합니다.
    return Promise.all([query1.get(), query2.get()]).then(([result1, result2]) => {
      // 각 문서에 vmId 추가
      const combinedResults = [...result1.docs, ...result2.docs].map((doc) => {
        const { timelime, ...rest } = doc.data();
        return { vmId, ...rest };
      });
      return combinedResults; // vmId가 포함된 결합된 결과 반환
    });
  };

  // 모든 vms에 대한 쿼리를 순차적으로 실행합니다.
  const allResults = vms.map((vm) => queryDispensesForVmId(vm.id));

  Promise.all(allResults)
    .then((results) => {
      // 여기에서 모든 결과가 results 배열에 포함됩니다.
      // 각 vmId에 대한 결과가 results의 각 요소로 포함됩니다.
      // 결과를 처리합니다.

      let csvContent = '';
      let headers: string[] = [];
      let headersSet = false;
      let cnt = 0;

      results.flat(2).forEach((result) => {
        if (cnt++ > 20) {
          console.log(cnt);
        }
        // CSV 헤더 설정
        if (!headersSet) {
          csvContent += `${Object.keys(result).join(',')}\n`;
          headers = Object.keys(result);
          headersSet = true;
        }

        // CSV 내용 추가
        const row = headers.map((header) => result[header]);
        csvContent += `${Object.values(row).join(',')}\n`;
      });

      const downloadLink = document.createElement('a');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `우유펌프라인_${new Date().toDateString()}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch((error) => {
      // 에러 처리
      console.error('Error fetching documents: ', error);
    });
};

const DownloadDispenseCollectionGroup = async (firestore: ExtendedFirestoreInstance) => {
  const startAtDate = new Date('2024-01-05');

  // 다방밀크커피, 다방원두커피, 바닐라라떼
  firestore
    .collectionGroup('dispenses')
    .where('mountPosition', 'in', [140, 141])
    .orderBy('initialTime')
    .startAt(startAtDate)
    .get()
    .then((snapshot) => {
      if (snapshot.empty) {
        console.log('No matching documents.');
        return;
      }

      let csvContent = '';
      let headersSet = false;

      snapshot.forEach((doc) => {
        const data = doc.data();
        delete data.timeline; // timeline 속성 제거

        // CSV 헤더 설정
        if (!headersSet) {
          csvContent += `${Object.keys(data).join(',')}\n`;
          headersSet = true;
        }

        // CSV 내용 추가
        csvContent += `${Object.values(data).join(',')}\n`;
      });

      const downloadLink = document.createElement('a');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `우유펌프라인_${new Date().toDateString()}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    })
    .catch((err) => {
      console.log('Error getting documents', err);
    });
};
async function getUsersByIds(userIds: string[], firestore: ExtendedFirestoreInstance) {
  const userPromises = userIds.map(async (id) => {
    // const userDoc = await getDoc(doc(db, 'version/v3/users', id));
    const userDoc = await firestore.doc(`version/v3/users/${id}`).get();
    if (userDoc.exists) {
      return { id, ...userDoc.data() };
    } else {
      console.log(`No such document for ID: ${id}`);
      return null;
    }
  });

  // 모든 프라미스를 기다립니다
  const users = await Promise.all(userPromises);
  return users.filter((user) => user !== null);
}

const DownloadUsers = async (firestore: ExtendedFirestoreInstance) => {
  let csvContent = '전화번호,쿠폰 수,스티커 수\n';
  const query = firestore.collectionGroup('stickers').where('publisher', '==', 'BETA0046');
  const ids: string[] = [];
  const qr = await query.get();
  console.log(qr.docs.length);
  qr.forEach((s) => {
    const id = s.ref.parent.parent.id;

    const exist = ids.find((id_) => id_ === id);
    if (!exist) {
      ids.push(id);
    }
    // console.log(s.ref.parent.parent.id);
  });

  // 사용자 데이터를 가져옵니다
  getUsersByIds(ids, firestore)
    .then((users) => {
      users.forEach((user) => {
        csvContent += `${user.id},${user.availableMembershipCouponNum},${user.availableStickerNum}\n`;
      });
      const downloadLink = document.createElement('a');
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = `장소별가입자정보_${new Date().toDateString()}.csv`;

      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
      console.log('Fetched users:', users);
    })
    .catch((error) => {
      console.error('Error fetching users:', error);
    });
  // console.log(ids);
  // firestore.collection('version/v3/users').where('id')

  // let headersSet = false;

  // const downloadLink = document.createElement('a');
  // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
  // const url = URL.createObjectURL(blob);
  // downloadLink.href = url;
  // downloadLink.download = `우유펌프라인_${new Date().toDateString()}.csv`;

  // document.body.appendChild(downloadLink);
  // downloadLink.click();
  // document.body.removeChild(downloadLink);
};

export default Test;
