export enum QueueStep {
  ready = 'READY',
  requested = 'REQUESTED',
  readyToCheckComplete = 'READY_TO_CHECK_COMPLETE',
  waitForComplete = 'WAIT_FOR_COMPLETE',
  readyToCheckCupout = 'READY_TO_CHECK_CUPOUT',
  waitForCupout = 'WAIT_FOR_CUPOUT',
  success = 'SUCCESS',
  failed = 'FAILED',
  waitForReset = 'WAIT_FOR_RESET',
}
