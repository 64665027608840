import * as React from 'react';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import CheckCircle from '@material-ui/icons/CheckCircle';
import Cancel from '@material-ui/icons/Cancel';

// 타입 정의
import { Monitoring } from '@definitions/monitoring';
import TooltipTableCell from './TooltipTableCell';
import NestedTable from './NestedTable';

function GreenCheckCircle(): JSX.Element {
  return <CheckCircle style={{ fill: 'rgb(122, 211, 116)' }} />;
}

function formatTimeAgo(heartBeatTimestamp: number): string {
  const now = Date.now(); // 현재 시간을 밀리초 단위로 가져옵니다.
  const difference = now - heartBeatTimestamp; // 현재 시간과 타임스탬프의 차이를 계산합니다.
  const minutes = Math.floor(difference / 60000); // 밀리초를 분으로 변환합니다.
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return `${days}일 전`;
  } else if (hours > 0) {
    const remainingMinutes = minutes % 60;
    return `${hours}시간 ${remainingMinutes}분 전`;
  } else {
    return `${minutes}분 전`;
  }
}

function timeAgo(heartBeatTimestamp: number): { days: number; hours: number; minutes: number } {
  const now = Date.now(); // 현재 시간을 밀리초 단위로 가져옵니다.
  const difference = now - heartBeatTimestamp; // 현재 시간과 타임스탬프의 차이를 계산합니다.
  const minutes = Math.floor(difference / 60000); // 밀리초를 분으로 변환합니다.
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return { days, hours, minutes };
}

function calculateMinutesAgo(heartBeatTimestamp: number): number {
  const now = Date.now(); // 현재 시간을 밀리초 단위로 가져옵니다.
  const difference = now - heartBeatTimestamp; // 현재 시간과 타임스탬프의 차이를 계산합니다.
  return Math.floor(difference / 60000); // 밀리초를 분으로 변환합니다 (1분 = 60000밀리초).
}

function Row(props: { row: Monitoring }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow onClick={() => setOpen(!open)}>
        <TableCell align="center">{row.vmId}</TableCell>
        <TableCell align="center">{row.location}</TableCell>
        <TableCell align="center">
          {row.kiosk.version}/{row.piiVersion.appVersion}
        </TableCell>
        <TableCell align="center">
          {row.pcb.dispenser === '에러 없음' ? <GreenCheckCircle /> : <Cancel color="secondary" />}
        </TableCell>
        <TableCell align="center">
          [{row.kiosk.dispensers.map((dp) => (dp.disable ? 'X' : 'O')).join(',')}]<br />[
          {row.pcb.barrel
            .filter((br, index) => index < row.kiosk.dispensers.length)
            .map((br) => (br ? 'O' : 'X'))
            .join(',')}
          ]
        </TableCell>
        <TableCell align="center">
          {row.kiosk.dispensers
            .map((dp) => dp.currentCupCnt && dp.maxCupCapacity && `${dp.currentCupCnt}/${dp.maxCupCapacity}`)
            .join('\n')}
        </TableCell>
        <TableCell align="center">
          {row.pcb.boilerLevel ? <GreenCheckCircle /> : <Cancel color="secondary" />}
        </TableCell>
        <TableCell align="center">{row.pcb.boilerAuto ? <GreenCheckCircle /> : <Cancel color="secondary" />}</TableCell>
        <TableCell align="center">
          {row.pcb.boilerHeat || row.temp ? <GreenCheckCircle /> : <Cancel color="secondary" />}
        </TableCell>
        <TableCell align="center">
          {!row.pcb.boilerError ? <GreenCheckCircle /> : <Cancel color="secondary" />}
        </TableCell>
        <TableCell align="center">
          {row.temp && (
            <Typography color={row.temp.boilerTemp > 80 ? 'secondary' : 'primary'}>
              {row.temp.boilerTemp}&#176;/{row.temp.boilerTargetTemp}&#176;
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {row.temp && (
            <Typography color={row.temp.refrTemp / 10 > 6 ? 'secondary' : 'primary'}>
              {row.temp.refrTemp / 10}&#176;/
              {row.temp.refrTargetTemp === 1000 ? 'X' : row.temp.refrTargetTemp / 10}&#176;
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {row.iceMaker ? (
            row.iceMaker.errorCode === 'NONE' ? (
              <GreenCheckCircle />
            ) : (
              <Typography color={'secondary'}>{row.iceMaker.errorCode}</Typography>
            )
          ) : (
            <Typography color="secondary">?</Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {row.indicator ? (
            row.indicator.status === 'OVERLOAD' ? (
              <Cancel color="secondary" />
            ) : (
              <GreenCheckCircle />
            )
          ) : (
            <Typography color="secondary">?</Typography>
          )}
        </TableCell>
        <TableCell align="center">{row.kiosk.admin.isAdminSignedIn && row.kiosk.admin.adminName}</TableCell>
        <TableCell align="center">
          {row.displays.filter((dp) => dp.isAligned && dp.resolution.width === 1920 && dp.resolution.height === 1080)
            .length === row.displays.length ? (
            <GreenCheckCircle />
          ) : (
            <Cancel color="secondary" />
          )}
        </TableCell>
        <TableCell align="center">{row.kiosk.totalMenuLength}</TableCell>
        <TableCell align="center">{row.kiosk.soldoutMenuLength}</TableCell>
        <TableCell align="center">
          {row.kiosk.beverageType === 'ICED_HOT_BOTH' ? (
            <GreenCheckCircle />
          ) : (
            <Typography color={row.kiosk.beverageType === 'ICED_ONLY' ? 'primary' : 'secondary'}>
              {row.kiosk.beverageType}
            </Typography>
          )}
        </TableCell>
        <TableCell align="center">
          {row.kiosk.boilerState === 0 ? (
            <GreenCheckCircle />
          ) : row.kiosk.boilerState === 1 ? (
            <Cancel color="secondary" />
          ) : (
            '온수부족'
          )}
        </TableCell>
        <TableCell align="center">
          {row.kiosk.iceMakerState === 0 ? (
            <GreenCheckCircle />
          ) : row.kiosk.iceMakerState === 1 ? (
            <Cancel color="secondary" />
          ) : (
            '얼음부족'
          )}
        </TableCell>
        <TableCell align="center">
          {row.kiosk.cupCnt ? row.kiosk.cupCnt.hot : 'X'}/{row.kiosk.cupCnt ? row.kiosk.cupCnt.ice : 'X'}
        </TableCell>
        <TableCell align="center">
          {row.kiosk.isKioskAlive ? <GreenCheckCircle /> : <Cancel color="secondary" />}
        </TableCell>
        <TableCell align="center">
          {row.kiosk.memoryUsage && `${(row.kiosk.memoryUsage.heapUsed / 1024 / 1024).toFixed(2)}MB`}
        </TableCell>
        <TableCell align="center">
          <Typography color={timeAgo(row.kiosk.heartBeatTimestamp).hours > 0 ? 'secondary' : 'inherit'}>
            {formatTimeAgo(row.kiosk.heartBeatTimestamp)}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color={timeAgo(new Date(row.timeStamp).getTime()).hours > 0 ? 'secondary' : 'inherit'}>
            {formatTimeAgo(new Date(row.timeStamp).getTime())}
          </Typography>
        </TableCell>
        <TableCell align="center">
          <Typography color={timeAgo(row.system.bootTime).days > 30 ? 'secondary' : 'inherit'}>
            {formatTimeAgo(row.system.bootTime)}
          </Typography>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                세부내역
              </Typography>
              <NestedTable data={row} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

interface CollapsibleTableProps {
  rows: Monitoring[];
}
export default function CollapsibleTable({ rows }: CollapsibleTableProps) {
  return (
    <TableContainer component={Paper} style={{ overflowX: 'hidden' }}>
      <Table aria-label="collapsible table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell align="center" colSpan={3}>
              INDEX
            </TableCell>
            <TableCell align="center" colSpan={7}>
              PCB
            </TableCell>
            <TableCell align="center" colSpan={2}>
              온도(현재/설정)
            </TableCell>
            <TableCell align="center" colSpan={1}>
              제빙기
            </TableCell>
            <TableCell align="center" colSpan={1}>
              로드셀
            </TableCell>
            <TableCell align="center" colSpan={1}>
              화면
            </TableCell>
            <TableCell align="center" colSpan={10}>
              키오스크
            </TableCell>
            <TableCell align="center" colSpan={2}>
              시스템
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell align="center">ID</TableCell>
            <TooltipTableCell tooltipText="장소 이름입니다." align="center">
              이름
            </TooltipTableCell>
            <TooltipTableCell tooltipText="키오스크 버전/PII 버전" align="center">
              버전
            </TooltipTableCell>
            {/** PCB */}
            {/* <TableCell>배럴</TableCell> */}
            <TooltipTableCell
              tooltipText="디스펜서 상태를 보여줍니다. 에러가 없으면 녹색 체크 아이콘이 표시됩니다."
              align="center"
            >
              디스펜서
            </TooltipTableCell>
            <TooltipTableCell tooltipText="디스펜서의 ON/OFF 상태와 베럴센서 값을 보여줍니다." align="center">
              배럴 (ON/OFF, 센서)
            </TooltipTableCell>
            <TooltipTableCell tooltipText="디스펜서 별, 컵 개수를 보여줍니다." align="center">
              컵 개수
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="온수탱크에 물이 가득 차있는지 여부를 나타 냅니다. 가득찬 경우 녹색 체크 아이콘이 표시됩니다."
              align="center"
            >
              온수탱크 만수
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="온수탱크 급수가 자동으로 채워지는 기능의 ON/OFF 여부를 보여줍니다. 켜져있는 경우 녹색 체크 아이콘이 표시됩니다."
              align="center"
            >
              온수탱크 AUTO
            </TooltipTableCell>

            <TooltipTableCell
              tooltipText="온수를 가열 중인지 여부를 ON/OFF로 보여줍니다. ON인 경우 녹색 체크 아이콘이 표시됩니다."
              align="center"
            >
              온수 가열
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="온수 급수 중, 급수벨브가 충분히(약 1분) 열려있었는데도 불구하고, 수위가 가득차지 않았을때 발생하는 에러입니다. 에러가 없는 경우, 녹색 체크 아이콘이 표시됩니다."
              align="center"
            >
              온수탱크 급수 에러
            </TooltipTableCell>
            {/** 온도 컨트롤러 */}
            <TableCell align="center">온수탱크</TableCell>
            <TableCell align="center">냉장고</TableCell>
            {/** 제빙기 */}
            <TooltipTableCell
              tooltipText="제빙기 에러 상태를 표시해줍니다. 에러가 없는 경우, 녹색 체크 아이콘이 표시됩니다. E1: 급수안됨, E2: 배수 부적합, E3: 제품 온도 너무 낮음, E4: 제품 온도 너무 높음, E5: 응축기 과열, E6: 냉각기 극저온, E7: 제빙기 모터 이상, E8: 만빙 안됨, E9: 정기점검"
              align="center"
            >
              에러코드
            </TooltipTableCell>
            {/** 무게센서 */}
            <TooltipTableCell
              tooltipText="과적인지 유무를 보여줍니다. 과적된 경우(600g 이상의 무게 감지) X 아이콘이 표시됩니다."
              align="center"
            >
              무게센서
            </TooltipTableCell>
            {/** 화면 */}
            <TooltipTableCell tooltipText="관리자 모드가 켜져있는 경우, 관리자 이름을 보여줍니다." align="center">
              관리자모드
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="두 디스플레이의 해상도(1920x1080), 터치 정렬(좌/우)이 모두 정상이면 녹색 체크 아이콘이 표시됩니다."
              align="center"
            >
              터치정렬
            </TooltipTableCell>
            {/** 키오스크 */}
            <TooltipTableCell
              tooltipText="해당 장소에서 장착되어있는 커피,우유,시럽을 기반으로 판매할 수 있는 총 메뉴 개수를 표시합니다. 키오스크가 처음 켜지고 한번도 메뉴 화면이 열리지 않은 경우 0개로 표시됩니다."
              align="center"
            >
              메뉴수
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="솔드아웃된 메뉴 개수를 표시합니다. 키오스크가 처음 켜지고 한번도 메뉴 화면이 열리지 않은 경우 0개로 표시됩니다."
              align="center"
            >
              솔드아웃 수
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="기기의 Policy 중 BEVERAGE_TYPE을 보여줍니다. BOTH의 경우 초록색 아이콘, 이외에는 텍스트로 표시해줍니다. ICED_ONLY: 아이스 주문만 가능, HOT_ONLY: 뜨거운 음료 주문만 가능"
              align="center"
            >
              주문 정책
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="온수탱크의 컨디션을 보여줍니다. 온수 재고가 부족하거나, 온도가 낮은경우 X 아이콘이 표시됩니다."
              align="center"
            >
              HOT 주문
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="제비익의 컨디션을 보여줍니다. 얼음 재고가 부족하거나, 얼음 막힘 현상이 발생하면 X 아이콘이 표시됩니다."
              align="center"
            >
              HOT 주문
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="하루동안 판매된 뜨거운 음료와 아이스 음료 잔수를 각각 보여줍니다. 매일 AM 00시 ~ 1시 사이에 초기화 됩니다."
              align="center"
            >
              [H/I]잔수
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="키오스크 프로세스가 살아있는지 여부를 보여줍니다. 화이트 스크린의 경우 키오스크 프로세스는 살아있습니다. 바탕화면으로 표시되고 있을때 X 아이콘으로 표시됩니다."
              align="center"
            >
              키오스크
            </TooltipTableCell>
            <TooltipTableCell tooltipText="현재 키오스크 프로세스의 메모리(힙)사용량입니다." align="center">
              메모리
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="키오스크 프로세스가 PII에게 2분마다 자신이 정상상태라는 것을 알린 마지막 시점입니다. 화이트 스크린의 경우 이 시간이 매우 길 수 있습니다."
              align="center"
            >
              키오스크 로그
            </TooltipTableCell>
            {/** 시스템 */}
            <TooltipTableCell
              tooltipText="현재 표시되고 있는 값이 얼마나 오래되었는지를 표시해줍니다. 현재는 5분마다 값을 갱신하고 있습니다."
              align="center"
            >
              로그시간
            </TooltipTableCell>
            <TooltipTableCell
              tooltipText="마지막으로 부팅된 시간을 표시합니다. 이 값을 통해 기기가 언제 재부팅되었는지 파악할 수 있습니다."
              align="center"
            >
              부팅시간
            </TooltipTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row: Monitoring) => (
            <Row key={row.vmId} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
