// 외부모듈
import { all } from 'redux-saga/effects';
// 내부모듈
import { fsSaga } from '@saga/fs';
import { controllerSaga } from './formController';
import { initSaga } from './init';

export function* rootSaga() {
  yield initSaga();
  yield all([fsSaga(), controllerSaga()]);
}
