// 외부모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useFirestore } from 'react-redux-firebase';
import { CSVLink } from 'react-csv';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';

// 내부모듈
import { price } from '../../constants/fstPrice';

// 타입
import { SessionHistory, AUTH_ACTIONS } from '@definitions/session';
import { Item } from '@definitions/order';
import { RootStateType } from '@reducers';
import { Admin } from '@definitions/admins';

function FSTSales(): JSX.Element {
  const [fstData, setFstData] = useState<any[]>([]);
  const firestore = useFirestore();
  const [startDate, setStartDate] = useState<Date>(new Date());
  const [endDate, setEndDate] = useState<Date>(new Date());
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const adminsArray = Object.values(admins) as Admin[];
  const planzAdminNumArr: string[] = adminsArray.reduce((acc: string[], cur: Admin): string[] => {
    if (cur.level < 2) {
      acc.push(cur.phoneNum);
    }
    return acc;
  }, []);
  const importFstData = async (startingTimeStamp: Date, endingTimeStamp: Date) => {
    const snapshot = await firestore.collection(`/version/v3/vms/`).get();
    const targetVm = snapshot.docs.map((doc) => doc.data().id).filter((vm) => vm === 'BETA0013');
    const vm = targetVm[0];

    const lastYear = endingTimeStamp.getFullYear() - 1;
    const currentYear = endingTimeStamp.getFullYear();

    // timestamp의 month 는 0부터 시작하므로 현 월을 알기위해 +1 하였음
    let currentMonth: number | string = endingTimeStamp.getMonth() + 1;

    // 현 월이 1월인경우 이전년도의 12월을 가져오기 위한 코드
    let lastMonth: number | string = endingTimeStamp.getMonth() === 0 ? 12 : endingTimeStamp.getMonth();
    if (currentMonth < 10) {
      currentMonth = `0${currentMonth}`;
    }
    if (lastMonth < 10) {
      lastMonth = `0${lastMonth}`;
    }

    let targetOrder = [];

    const thisMonthTargetOrder = await firestore
      .collection(`/version/v3/vms/${vm}/orders/${currentYear}/${currentMonth}`)
      .where('timeStamp', '>', startingTimeStamp)
      .where('timeStamp', '<', endingTimeStamp)
      .get();
    targetOrder = thisMonthTargetOrder.docs.map((doc) => doc.data());
    if (endingTimeStamp.getMonth() === startingTimeStamp.getMonth() + 1) {
      const LastMonthTargetOrder = await firestore
        .collection(`/version/v3/vms/${vm}/orders/${currentYear}/${lastMonth}`)
        .where('timeStamp', '>', startingTimeStamp)
        .get();

      const lastMonthTargetItems = LastMonthTargetOrder.docs.map((doc) => doc.data());
      targetOrder = targetOrder.concat(lastMonthTargetItems);
    } else if (startingTimeStamp.getMonth() === 11) {
      const lastMonthLastYearOrder = await firestore
        .collection(`/version/v3/vms/${vm}/orders/${lastYear}/${lastMonth}`)
        .where('timeStamp', '>', startingTimeStamp)
        .get();

      const lastMonthLastYearTargetItems = lastMonthLastYearOrder.docs.map((doc) => doc.data());
      targetOrder = targetOrder.concat(lastMonthLastYearTargetItems);
    }

    // target기간의 order를 가져와서 fst 정산이 필요한 order를 filter 함
    const paidFstOrder = targetOrder.filter(
      (data) =>
        data.order.payment.type !== 0 &&
        data.session.history[0].action === 'SET_MEMBERS' &&
        data.session.history[0].payload.membersId === 'fstMembers',
    );

    const mappingData = paidFstOrder.map((orders) => {
      const orderedItem = orders.order.items.map((item: Item) => item.recipeName);
      if (
        orders.session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS)
          .length > 0
      ) {
        const signInInfoArray = orders.session.history.filter(
          (history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS,
        );
        const signInInfo = signInInfoArray[signInInfoArray.length - 1];
        const priceArray = orderedItem.map((menu: string) => price[menu]);
        const amount = priceArray.reduce((acc: number, cur: number) => acc + cur, 0);

        return {
          company: signInInfo.payload.userData.company,
          phoneNum: signInInfo.payload.userData.phoneNum,
          time: orders.session.timeStamp.toDate().toLocaleString(),
          item: orderedItem,
          discountedAmount: amount,
          isPlanzAdmin: planzAdminNumArr.includes(signInInfo.payload.userData.phoneNum) ? 'O' : 'X',
        };
      } else if (
        orders.session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.ADD_SIGN_UP_DETAIL)
          .length > 0
      ) {
        const signUpInfoArray = orders.session.history.filter(
          (history: SessionHistory) => history.action === AUTH_ACTIONS.ADD_SIGN_UP_DETAIL,
        );
        const signUpInfo = signUpInfoArray[signUpInfoArray.length - 1];
        const priceArray = orderedItem.map((menu: string) => price[menu]);
        const amount = priceArray.reduce((acc: number, cur: number) => acc + cur, 0);

        return {
          company: signUpInfo.payload.userData.company,
          phoneNum: signUpInfo.payload.userData.phoneNum,
          time: orders.session.timeStamp.toDate().toLocaleString(),
          item: orderedItem,
          discountedAmount: amount,
          isPlanzAdmin: planzAdminNumArr.includes(signUpInfo.payload.userData.phoneNum) ? 'O' : 'X',
        };
      }
    });
    setFstData(mappingData);
  };

  return (
    <>
      <div css={fstData__wrap}>
        <h1>
          1. 아래<span>시간</span>을 먼저 입력한 후 날짜를 클릭하세요
        </h1>
        <h2>시작일</h2>
        <DatePicker
          selected={startDate}
          onChange={(date: Date) => setStartDate(date)}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
        />

        <h2>마지막일</h2>
        <DatePicker
          selected={endDate}
          onChange={(date: Date) => setEndDate(date)}
          timeInputLabel="Time:"
          dateFormat="MM/dd/yyyy h:mm aa"
          showTimeInput
        />
        <h1>
          2. <span>시작일과 마지막일(시간포함) 선택한 후</span> 아래 data export 버튼을 눌러주세요
        </h1>
        <Button content="data export" onClick={() => importFstData(startDate, endDate)}></Button>
        <h1>
          3. <span>data export 버튼을 누른 후</span> 아래 download fst 버튼을눌러 csv 파일을 다운받으세요
        </h1>
        <CSVLink css={download__btn} data={fstData} filename={`${endDate.getMonth() + 1}월 fst판매.csv`}>
          Download FST
        </CSVLink>
      </div>
    </>
  );
}

const fstData__wrap = css`
  display: flex;
  flex-direction: column;
  font-family: Spoqa Han Sans;
  h1 {
    margin-top: 24px;
    font-size: 20px;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.3px;
    color: #333333;
    span {
      font-weight: bold;
    }
  }
  h2 {
    font-size: 18px;
    font-weight: normal;
    letter-spacing: -0.3px;
    color: #666666;
  }
`;

const download__btn = css`
  border: 1px solid rgb(225, 223, 221);
  box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
  color: black;
  margin-bottom: 200px;
  text-decoration: none;
  text-align: center;
  width: 280px;
  height: 32px;
  font-weight: bold;
  line-height: 28px;
`;

export default FSTSales;
