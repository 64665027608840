// 외부 모듈
import React, { useEffect, useState } from 'react';
import { css } from '@emotion/core';

// 내부 모듈
import BackgroundWave from './BackgroundWave';

// 상수
import { ContainerWidth } from '@constants/css.json';
import { useFirebase } from 'react-redux-firebase';

function SignIn(): JSX.Element {
  const firebase = useFirebase();
  const auth = firebase.auth();
  const [disabled, setDisabled] = useState<boolean>(true);
  const [id, setId] = useState<string>('');
  const [pwd, setPwd] = useState<string>('');

  useEffect(() => {
    if (id && pwd) {
      if (id.includes('@') && id.includes('.')) {
        setDisabled(false);
      } else {
        setDisabled(true);
      }
    } else {
      setDisabled(true);
    }
  }, [id, pwd]);

  const onClick = async () => {
    try {
      const user = await auth.signInWithEmailAndPassword(id, pwd);
      // if (onSuccess) onSuccess(user, name);
    } catch (error) {
      // If there is an error, pass it back to `redux-form`
      throw new Error('로그인 실패');
    }
  };

  return (
    <div css={sign_in_wrap}>
      <BackgroundWave />
      <h1>PLANZ</h1>
      <label>
        아이디 <input type="email" maxLength={30} onChange={(e) => setId(e.target.value)} />
      </label>
      <label>
        비밀번호 <input type="password" maxLength={30} onChange={(e) => setPwd(e.target.value)} />
      </label>
      <button css={[button, disabled && disabled_button]} value="로그인" onClick={() => onClick()} disabled={disabled}>
        로그인
      </button>
    </div>
  );
}

const sign_in_wrap = css`
  width: 100vw;
  height: 100vh;
  background-color: rgba(250, 207, 167, 20%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: Spoqa Han Sans;
  h1 {
    height: 80px;
    font-family: Times Sans Serif;
    font-size: 112px;
    @media (max-width: ${ContainerWidth}) {
      font-size: 142px;
    }
    margin-bottom: 200px;
  }
  label {
    font-size: 38px;
    width: 400px;
    height: 100px;
    @media (max-width: ${ContainerWidth}) {
      width: 80%;
      height: 200px;
    }

    margin-bottom: 20px;
    display: flex;
    flex-direction: column;
    input {
      margin-top: 12px;
      height: 60px;
      @media (max-width: ${ContainerWidth}) {
        margin-top: 24px;
        height: 120px;
      }
      width: 100%;
      font-size: 28px;
      padding-left: 12px;
      border: none;
      border-radius: 12px;
      box-shadow: 0 20px 30px 0 rgba(141, 85, 37, 0.3);
    }
  }
`;

const button = css`
  margin-top: 80px;
  height: 80px;
  width: 400px;
  @media (max-width: ${ContainerWidth}) {
    width: 80%;
    height: 160px;
  }
  font-size: 48px;
  border-radius: 25px;
  box-shadow: 0 20px 30px 0 rgba(141, 85, 37, 0.3);
  background-color: #b67d56;

  color: white;
  border: none;
`;

const disabled_button = css`
  background-color: #bda395;
`;

export default SignIn;
