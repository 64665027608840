import {
  AUTH_ACTIONS,
  COUPON_ACTIONS,
  ORDER_ACTIONS,
  PAYMENT_ACTIONS,
  SessionHistory,
  SH_TYPE,
} from '@definitions/session';
import { WalletUserSignInSuccess, WALLET_ACTIONS } from '@definitions/walletAuth';
import {
  isNonPaymentOrder,
  isAdminOrder,
  getFinalPrice,
  isCouponMembersSession,
  getStepsFromSession,
  getInfoForRefunds,
  getItemsFromSession,
  getPaymentWayUserId,
  getPaymentWay,
  getCompanyNameFromSession,
  getCouponPrice,
  getMembersId,
  getAdminSignInSuccessInfo,
  getUseCoupons,
} from '../getSessionInfo';
import { getRefundText } from './ver1.2.4Order';

export const get123Order = (obj: any) => {
  const {
    timeStamp,
    session,
    type,
    order: { items: o_items, payment },
  } = obj;

  const itemRecipes: { recipeId: string; recipeName: string }[] = o_items.map(
    (item: { recipeId: string; recipeName: string }) => ({ recipeId: item.recipeId, recipeName: item.recipeName }),
  );

  const paymentInfo: {
    paymentWayUserId: string;
    infoForRefunds: string;
    totalPrice: number;
  } =
    payment.status && payment.status.result && payment.status.result.successMsg
      ? {
          paymentWayUserId: payment.status.result.successMsg.cardNumber,
          infoForRefunds: payment.status.result.successMsg.successNumber,
          totalPrice: parseInt(payment.status.result.successMsg.total),
        }
      : {
          paymentWayUserId: 'N/A',
          infoForRefunds: 'N/A',
          totalPrice: 0,
        };
  let signInNumber;
  let email;
  let membersCouponNum;
  const isAdminSession = isAdminOrder(session);
  const adminSignInInfo = getAdminSignInSuccessInfo(session);
  const { phoneNum: adminPhoneNum, name: adminName } = adminSignInInfo;
  const membersId = getMembersId(session);

  const items = getItemsFromSession(session);
  const steps = getStepsFromSession(session);
  const isSignInMembersOrder =
    session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS).length > 0;
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isCouponMembersOrder = isCouponMembersSession(session);
  const companyName = getCompanyNameFromSession(session);
  const finalPrice = !isNonPaymentSession && !isAdminSession && getFinalPrice(session, isCouponMembersOrder);
  const walletsignedInInfoList: WalletUserSignInSuccess[] = session.history.filter(
    (history: SessionHistory) =>
      history.type === SH_TYPE.WALLET_AUTH && history.action === WALLET_ACTIONS.USER_SIGN_IN_SUCCESS,
  );
  const walletsignedInInfo =
    walletsignedInInfoList.length > 0 ? walletsignedInInfoList[walletsignedInInfoList.length - 1] : undefined;
  const isUsedCoupon = getUseCoupons(session) !== undefined;

  const getTotalPriceText = () => {
    if (type >= 90) {
      return getRefundText(type);
    }
    if (isNonPaymentSession && isUsedCoupon) {
      return '쿠폰결제';
    }
    if (!isNonPaymentSession) {
      return finalPrice;
    }
    if (isAdminSession) {
      return adminName;
    }
    if (walletsignedInInfo) {
      return '지갑결제';
    }
    return 0;
  };

  const totalPrice = getTotalPriceText();

  if (isCouponMembersOrder) {
    membersCouponNum = session.history.filter(
      (history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON,
    )[0].payload.selectedCouponIdList.length;
  }

  if (isSignInMembersOrder) {
    signInNumber = session.history.filter(
      (history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS,
    )[0].payload.userData.phoneNum;
    email = session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS)[0]
      .payload.userData.email;
  }
  const isCouponUsed = isCouponMembersOrder ? '사용O' : '사용X';
  const usedCouponNum = isCouponMembersOrder ? membersCouponNum : 0;
  const infoForRefunds = getInfoForRefunds(session, walletsignedInInfo);
  const paymentWayUserId = getPaymentWayUserId(session);
  const paymentWay = getPaymentWay(session);
  const phoneNum = isAdminSession
    ? '관리자주문'
    : !isSignInMembersOrder
    ? walletsignedInInfo
      ? walletsignedInInfo.payload.phoneNum
      : '미가입주문'
    : signInNumber;
  const couponPrice = getCouponPrice(session);

  const balance = session.history
    .filter((h: SessionHistory) => h.type === SH_TYPE.PAYMENT && h.action === PAYMENT_ACTIONS.SET_WALLET_PAYMENT_RESULT)
    .reduce((acc, cur) => acc + Number(cur.payload.result.total), 0);

  return {
    items,
    itemRecipes,
    steps,
    timeStamp,
    paymentWayUserId: paymentWayUserId === 'N/A' ? paymentInfo.paymentWayUserId : paymentWayUserId,
    infoForRefunds: infoForRefunds === 'N/A' ? paymentInfo.infoForRefunds : infoForRefunds,
    isCouponUsed,
    totalPrice: totalPrice || paymentInfo.totalPrice,
    usedCouponNum,
    phoneNum,
    paymentWay,
    companyName,
    couponPrice,
    membersId,
    email,
    adminName,
    balance,
  };
};
