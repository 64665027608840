// 외부모듈
import React from 'react';
import { Button } from '@material-ui/core';
import { CSVLink } from 'react-csv';
import { css } from '@emotion/core';

function SampleFormat() {
  const data = [
    ['id', 'phoneNumber', 'tags'],
    ['A-01', '01012345678', 'company=플랜즈커피&team=개발팀'],
    ['A-02', '01011112222', 'company=플랜즈커피&team=경영팀'],
    ['A-03', '01077776666', 'company=플랜즈커피'],
  ];
  return (
    <Button variant="contained" color="primary" size="small" style={{ marginLeft: '30px', height: '40px' }}>
      <CSVLink data={data} css={btn} filename="wallets_기본명단" enclosingCharacter={''}>
        포맷파일 다운
      </CSVLink>
    </Button>
  );
}

const btn = css`
  outline: none;
  text-decoration: none;
  color: #fff;
`;

export default SampleFormat;
