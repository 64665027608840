//외부모듈
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send, FiberNew } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { RecipeType } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';
import AddRecipeOrder from './AddRecipeOrder';
import { RecipeOrder } from './RecipeOrderRaw';
import RecipeOrderRaw from './RecipeOrderRaw';
import ConfirmModal from './confirmModal';

export interface RecipeOrderControlsProps {
  originalData: RecipeOrder[];
  editedData: RecipeOrder[];
  orderEditHandler: (type: string, recipeOrderInfo?: RecipeOrder, idx?: number) => void;
  orderAddHandler: (recipeOrderInfo: RecipeOrder, idx: number) => void;
  submitHandler: () => void;
  checkHandler: (idx: number) => void;
  allCheckHandler: () => void;
  vmRecipes: RecipeType[];
}

function RecipeOrderControl({
  originalData,
  editedData,
  orderEditHandler,
  orderAddHandler,
  submitHandler,
  checkHandler,
  allCheckHandler,
  vmRecipes,
}: RecipeOrderControlsProps) {
  const scrollRef = useRef<HTMLElement>(null);

  const scrollToBottom = () => {
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  };

  return (
    <section css={prod__price__control__wrap}>
      <div css={button__wrap}>
        <Button variant="outlined" color="primary" startIcon={<CheckBox />} onClick={allCheckHandler}>
          전체선택
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          variant="outlined"
          color="secondary"
          startIcon={<Delete />}
          onClick={() => orderEditHandler('delete')}
        >
          삭제등록
        </Button>
      </div>
      <div ref={scrollRef} css={prod__price__control}>
        {editedData.map((recipeOrder: RecipeOrder, idx: number) => {
          return (
            <RecipeOrderRaw
              originalData={originalData}
              curData={recipeOrder}
              idx={idx}
              key={idx}
              orderEditHandler={orderEditHandler}
              checkHandler={checkHandler}
              isLastRecipe={editedData.length - 1 === idx}
              scrollToBottom={scrollToBottom}
            />
          );
        })}
      </div>
      {vmRecipes.length > 0 && (
        <AddRecipeOrder vmRecipes={vmRecipes} editedData={editedData} orderAddHandler={orderAddHandler} />
      )}
    </section>
  );
}

export default RecipeOrderControl;

const prod__price__control__wrap = css`
  width: 600px;
  height: 70%;
  margin-top: 10px;
`;
const prod__price__control = css`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  overflow: scroll;
  padding: 10px;
  margin-bottom: 10px;
`;
const button__wrap = css`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
