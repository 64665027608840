import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { storage } from 'app/common/firebase';

import crypto from 'crypto';
import {
  Button,
  TextField,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { Task } from '.';
import { DropzoneState } from 'react-dropzone';
import { css } from '@emotion/core';
import { useFirestore } from 'react-redux-firebase';

interface TaskFormProps {
  formData: Task;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSubmit: (task: Task) => void;
  // onSubmit?: (task: Task) => void;
}

export function waitForDone(arr: any[], length: number): Promise<URL[]> {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (arr.length >= length) {
        clearInterval(interval);
        resolve(arr);
      }
    }, 500);
  });
}

interface URL {
  name: string;
  type: string;
  url: string;
}

function TaskForm({ formData, handleChange, onSubmit }: TaskFormProps) {
  const [uploadedHtmls, setUploadedHtmls] = useState<File[]>([]);
  const [uploadedResources, setUploadedResources] = useState<File[]>([]);
  const [isUploading, setIsUploading] = useState(false);
  const [htmls, setHtmls] = useState<URL[]>(formData.urls.filter(({ type }) => type === 'text/html'));
  const [resources, setResources] = useState<URL[]>(formData.urls.filter(({ type }) => type !== 'text/html'));
  const firestore = useFirestore();
  const onDrop = useCallback(
    ([file]) => {
      handleFileAdd([file], [...htmls, ...uploadedHtmls, ...resources, ...uploadedResources]);
    },
    [htmls, uploadedHtmls, resources, uploadedResources],
  );

  const drop = useDropzone({ onDrop });
  const { getRootProps, getInputProps } = drop;
  // 파일 드롭존에서 파일을 추가할 때 호출되는 함수
  const handleFileAdd = (files: File[], urls_: (URL | File)[]) => {
    files.forEach((file) => {
      if (file.type === 'text/html') {
        const found = urls_.find((data) => data.name === file.name);
        if (found) {
          window.alert(`동일한 이름의 파일을 업로드하셨습니다: ${file.name}`);
        } else {
          setUploadedHtmls((prevHtmls) => [...prevHtmls, file]);
        }
      } else if (
        file.type === 'image/png' ||
        file.type === 'image/jpg' ||
        file.type === 'image/jpeg' ||
        file.type === 'image/gif'
      ) {
        const found = urls_.find((data) => data.name === file.name);
        if (found) {
          window.alert(`동일한 이름의 파일을 업로드하셨습니다: ${file.name}`);
        } else {
          setUploadedResources((prevRes) => [...prevRes, file]);
        }
      } else {
        window.alert(`지원하지 않는 형식의 파일을 업로드하셨습니다: ${file.type}`);
      }
    });
  };

  // HTML 파일 리스트에서 파일을 삭제할 때 호출되는 함수
  const handleHtmlRemove = (data: any) => {
    setHtmls(htmls.filter((html) => html.name !== data.name));
    setUploadedHtmls(uploadedHtmls.filter((html) => html.name !== data.name));
  };

  // Resource 파일 리스트에서 파일을 삭제할 때 호출되는 함수
  const handleResourceRemove = (data: any) => {
    setResources(resources.filter((res) => res.name !== data.name));
    setUploadedResources(uploadedResources.filter((res) => res.name !== data.name));
  };

  const isEditing = Boolean(formData.id);
  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleClickDropzone = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    // e.stopPropagation();
  };

  // "Submit" 버튼을 클릭했을 때 호출되는 함수
  const handleSubmit = () => {
    setIsUploading(true);
    if (!formData.name) {
      setIsUploading(false);
      return window.alert('태스크 이름을 입력해주세요.');
    }

    if (!formData.author) {
      setIsUploading(false);
      return window.alert('작성자 이름을 입력해주세요.');
    }

    if ([...uploadedHtmls, ...htmls].length < 1) {
      setIsUploading(false);
      return window.alert('html 파일은 반드시 1개 이상 필요합니다.');
    }
    if (isEditing) {
      const taskId = formData.id;
      const storageRef = storage.ref();
      const stepRef = storageRef.child(`tasks/${formData.name}-${taskId}/`);
      const uploads = [...uploadedHtmls, ...uploadedResources];

      const deletes = formData.urls.filter(
        (url) => ![...htmls, ...resources].map(({ name }) => name).includes(url.name),
      );

      deletes.forEach((d) => {
        stepRef
          .child(`${d.name}`)
          .delete()
          .then(() => {
            console.log(`${d.name} 삭제 완료`);
          });
      });

      const urls: { name: string; type: string; url: string }[] = [];
      console.log(uploads);
      uploads.forEach((file) => {
        stepRef
          .child(`${file.name}`)
          .put(file)
          .then((result) => {
            storage
              .ref()
              .child(result.metadata.fullPath)
              .getDownloadURL()
              .then((url) => {
                urls.push({
                  name: file.name,
                  type: file.type,
                  url,
                });
              });
          });
      });

      waitForDone(urls, uploads.length).then((arr) => {
        firestore.doc(`/tasks/${taskId}`).update({
          name: formData.name,
          author: formData.author,
          tags: formData.tags,
          createDate: new Date(),
          latestEditedDate: new Date(),
          visible: formData.visible,
          urls: [...arr, ...htmls, ...resources],
        });
        setIsUploading(false);
        onSubmit({ ...formData, urls: [...htmls, ...resources, ...arr] });
      });
    } else {
      const taskId = crypto.randomBytes(6).toString('hex');
      const storageRef = storage.ref();
      const urls: { name: string; type: string; url: string }[] = [];
      const stepRef = storageRef.child(`tasks/${formData.name}-${taskId}/`);
      const uploads = [...uploadedHtmls, ...uploadedResources];
      uploads.forEach((file) => {
        stepRef
          .child(`${file.name}`)
          .put(file)
          .then((result) => {
            storage
              .ref()
              .child(result.metadata.fullPath)
              .getDownloadURL()
              .then((url) => {
                urls.push({
                  name: file.name,
                  type: file.type,
                  url,
                });
              });
            // console.log(result);
            // console.log('업로드 완료');
          });
      });

      waitForDone(urls, uploads.length).then((arr) => {
        console.log(arr);
        firestore.doc(`/tasks/${taskId}`).set({
          name: formData.name,
          author: formData.author,
          tags: formData.tags,
          createDate: new Date(),
          latestEditedDate: new Date(),
          visible: formData.visible,
          urls: arr,
        });
        setIsUploading(false);
        onSubmit(formData);
      });
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">태스크 양식</Typography>
      </Grid>
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <TextField name="name" label="태스크 이름" fullWidth value={formData.name} onChange={handleChange} />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <TextField name="author" label="작성자" fullWidth value={formData.author} onChange={handleChange} />
      </Grid>
      {/* You can add other fields similar to above */}
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <FormControlLabel
          control={<Checkbox checked={formData.visible} onChange={handleChange} name="visible" />}
          label="노출 여부"
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <TextField
          name="tags"
          label="태그"
          placeholder="ex) 플랜즈전용,전체공개,점주전용  **뛰어쓰기 없이 , 로 구분"
          fullWidth
          value={formData.tags}
          onChange={handleChange}
        />
      </Grid>
      <Grid item xs={12} style={{ marginTop: '20px' }}>
        <Typography variant="h6">스탭(html)</Typography>
        <div {...getRootProps({ onClick: handleClickDropzone })}>
          <input {...getInputProps({ accept: '.html' })} />
          <div css={dropzone}>파일을 드래그앤 드랍하거나, 클릭하여 업로드하세요</div>
        </div>
        <List>
          {[...uploadedHtmls, ...htmls].map((data, index) => (
            <ListItem key={index}>
              <ListItemText primary={data.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleHtmlRemove(data)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6">리소스(이미지)</Typography>
        <div {...getRootProps({ onClick: handleClickDropzone })}>
          <input {...getInputProps()} />
          <div css={dropzone}>파일을 드래그앤 드랍하거나, 클릭하여 업로드하세요</div>
        </div>
        <List>
          {[...uploadedResources, ...resources].map((data, index) => (
            <ListItem key={index}>
              <ListItemText primary={data.name} />
              <ListItemSecondaryAction>
                <IconButton edge="end" onClick={() => handleResourceRemove(data)}>
                  <DeleteIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={handleSubmit} disabled={isUploading}>
          {isUploading ? <CircularProgress color="secondary" /> : isEditing ? 'Edit' : 'Submit'}
        </Button>
      </Grid>
    </Grid>
  );
}

const dropzone = css`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
`;

export default TaskForm;
