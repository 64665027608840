export const price: { [key: string]: number } = {
  브루드커피: 400,
  카페라떼: 500,
  할리데이모카: 800,
  바닐라라떼: 700,
  허니밀크티: 700,
  핑크자몽: 400,
  허니유자: 200,
  리치초콜릿: 600,
  골든에라: 700,
  '브루클린 커피': 700,
  바닐라빈우유: 600,
  모던아메리카노: 400,
  블랙밀크티: 700,
  핑크자몽블랙티: 700,
};
