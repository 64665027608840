//외부모듈
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send, FiberNew } from '@material-ui/icons';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { RecipeType } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';
import AddProdPrice from './AddProdPrice';
import ProdPriceRaw, { ProdPriceInfoType } from './ProdPriceRaw';
import ConfirmModal from './confirmModal';
import { ProdPriceType } from './ProdPriceInputs';

export interface ProdPriceControlProps {
  originalData: ProdPriceInfoType[];
  editedData: ProdPriceInfoType[];
  prodPriceEditHandler: (type: string, prodPriceOrderInfo?: ProdPriceInfoType, idx?: number) => void;
  prodPriceAddHandler: (recipeOrderInfo: ProdPriceInfoType, idx: number) => void;
  submitHandler: () => void;
  checkHandler: (idx: number) => void;
  allCheckHandler: () => void;
  vmRecipes: RecipeType[];
}

function ProdPriceControl({
  originalData,
  editedData,
  prodPriceEditHandler,
  prodPriceAddHandler,
  submitHandler,
  checkHandler,
  allCheckHandler,
  vmRecipes,
}: ProdPriceControlProps) {
  const scrollRef = useRef<HTMLElement>(null);
  const [openAlert, setOpenAlert] = useState(false);

  const scrollToBottom = () => {
    scrollRef.current.scrollTo(0, scrollRef.current.scrollHeight);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setOpenAlert(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [openAlert]);

  return (
    <section css={prod__price__control__wrap}>
      {openAlert && (
        <Alert variant="filled" onClose={() => setOpenAlert(false)} css={alert} severity="error">
          <AlertTitle>Warning</AlertTitle>
          빈칸을 모두 채워주세요!
        </Alert>
      )}

      <div css={button__wrap}>
        <Button variant="outlined" color="primary" startIcon={<CheckBox />} onClick={allCheckHandler}>
          전체선택
        </Button>
        <Button
          style={{ marginLeft: '10px' }}
          variant="outlined"
          color="secondary"
          startIcon={<Delete />}
          onClick={() => prodPriceEditHandler('delete')}
        >
          삭제등록
        </Button>
      </div>
      <div ref={scrollRef} css={prod__price__control}>
        {editedData.map((prodPriceInfo: ProdPriceInfoType, idx: number) => {
          return (
            <ProdPriceRaw
              originalData={originalData}
              curData={prodPriceInfo}
              idx={idx}
              key={idx}
              prodPriceEditHandler={prodPriceEditHandler}
              checkHandler={checkHandler}
              isLastRecipe={editedData.length - 1 === idx}
              scrollToBottom={scrollToBottom}
            />
          );
        })}
      </div>
      {vmRecipes.length > 0 && (
        <AddProdPrice vmRecipes={vmRecipes} editedData={editedData} prodPriceAddHandler={prodPriceAddHandler} />
      )}
    </section>
  );
}

export default ProdPriceControl;

const prod__price__control__wrap = css`
  width: 960px;
  height: 70%;
  margin-top: 10px;
`;
const prod__price__control = css`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  overflow: scroll;
  padding: 10px;
  margin-bottom: 10px;
`;
const button__wrap = css`
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
const alert = css`
  position: absolute;
  top: 50%;
  left: 35%;
  transform: translateX(-50%);
  transform: translateY(-50%);
  width: 300px;
  height: 80px;
  z-index: 999;
  /* display: flex;
  align-items: center; */
`;
