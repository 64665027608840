// 외부 모듈
import React from 'react';
import { css } from '@emotion/core';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Avatar } from '@fluentui/react-northstar';

// 내부모듈
import { getColorForAmountTable } from '@utils/getStatusColor';

// 타입
import { VendingMachine } from '@definitions/vm';

type VmDashboardTableProps = { vms: VendingMachine[]; ingredients: any };

function VmDashboardTable({ vms, ingredients }: VmDashboardTableProps) {
  return (
    <TableContainer style={{ maxWidth: '2000px' }} component={Paper}>
      <Table size="small" aria-label="a dense table">
        <TableHead>
          <TableRow style={{ backgroundColor: '#EDEBE8', fontWeight: 'bold' }}>
            <TableCell style={{ fontWeight: 'bold' }}>INGREDIENTS / VMs</TableCell>
            {Object.keys(ingredients).map((ingredient, idx) => (
              <TableCell key={idx} style={{ fontWeight: 'bold' }}>
                <div css={table__header}>
                  <Avatar
                    css={avatar__img}
                    image={ingredients[ingredient].image}
                    label="ingredients"
                    name={ingredient}
                  />
                  <span>{ingredients[ingredient].titleKor}</span>
                </div>
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {vms.map((vm, idx) => {
            const { kegs, pumps, id } = vm;
            const vmContainers = kegs.concat(pumps);
            return (
              <>
                <TableRow key={idx} style={{ fontWeight: 'bold' }}>
                  <TableCell style={{ fontWeight: 'bold' }} key={id} align="left">
                    {id}
                  </TableCell>
                  {Object.keys(ingredients).map((ingredient) => {
                    const getAmountOfIngredient = vmContainers.filter((item) => item.ingredientId === ingredient);
                    let amount, containerType;
                    if (getAmountOfIngredient.length === 0) {
                      amount = 'N/A';
                    } else if (getAmountOfIngredient.length === 1) {
                      amount = getAmountOfIngredient[0].amount;
                    } else if (getAmountOfIngredient.length > 1) {
                      amount = getAmountOfIngredient.reduce((a, b) => a + b.amount, 0);
                    } else {
                      amount = 'N/A';
                    }

                    if (getAmountOfIngredient.length === 0) {
                      containerType = 'N/A';
                    } else if (getAmountOfIngredient[0].kegId === undefined) {
                      containerType = 'bottle';
                    } else {
                      containerType = 'keg';
                    }

                    return (
                      <TableCell
                        style={{
                          color: getColorForAmountTable(containerType, amount),
                        }}
                        key={ingredient}
                        align="right"
                      >
                        {amount}
                      </TableCell>
                    );
                  })}
                </TableRow>
              </>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default VmDashboardTable;

const avatar__img = css`
  height: 20px;
  width: 20px;
`;

const table__header = css`
  display: flex;
  span {
    margin-left: 3px;
  }
`;
