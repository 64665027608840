import { AUTH_ACTIONS, COUPON_ACTIONS, SessionHistory, SH_TYPE } from '@definitions/session';
import { WalletUserSignInSuccess, WALLET_ACTIONS } from '@definitions/walletAuth';
import {
  isNonPaymentOrder,
  isAdminOrder,
  getFinalPrice,
  isCouponMembersSession,
  getStepsFromSession,
  getInfoForRefunds,
  getItemsFromSession,
  getPaymentWayUserId,
  getPaymentWay,
  getCompanyNameFromSession,
  getCouponPrice,
  getMembersId,
} from '../getSessionInfo';

export const get121Order = (obj: any) => {
  const { timeStamp, session } = obj;

  let signInNumber;
  let membersCouponNum;
  const membersId = getMembersId(session);

  const items = getItemsFromSession(session);
  const steps = getStepsFromSession(session);
  const isSignInMembersOrder =
    session.history.filter((history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS).length > 0;
  const isNonPaymentSession = isNonPaymentOrder(session);
  const isAdminSession = isAdminOrder(session);
  const isCouponMembersOrder = isCouponMembersSession(session);
  const companyName = getCompanyNameFromSession(session);
  const finalPrice = !isNonPaymentSession && !isAdminSession && getFinalPrice(session, isCouponMembersOrder);
  const walletsignedInInfoList: WalletUserSignInSuccess[] = session.history.filter(
    (history: SessionHistory) =>
      history.type === SH_TYPE.WALLET_AUTH && history.action === WALLET_ACTIONS.USER_SIGN_IN_SUCCESS,
  );
  const walletsignedInInfo =
    walletsignedInInfoList.length > 0 ? walletsignedInInfoList[walletsignedInInfoList.length - 1] : undefined;
  const totalPrice = isNonPaymentSession
    ? isAdminSession
      ? 'admin'
      : walletsignedInInfo
      ? finalPrice
      : 0
    : finalPrice;

  if (isCouponMembersOrder) {
    membersCouponNum = session.history.filter(
      (history: SessionHistory) => history.action === COUPON_ACTIONS.USE_COUPON,
    )[0].payload.selectedCouponIdList.length;
  }

  if (isSignInMembersOrder) {
    signInNumber = session.history.filter(
      (history: SessionHistory) => history.action === AUTH_ACTIONS.SIGN_IN_SUCCESS,
    )[0].payload.userData.phoneNum;
  }
  const isCouponUsed = isCouponMembersOrder ? '사용O' : '사용X';
  const usedCouponNum = isCouponMembersOrder ? membersCouponNum : 0;
  const infoForRefunds = getInfoForRefunds(session, walletsignedInInfo);
  const paymentWayUserId = getPaymentWayUserId(session);
  const paymentWay = getPaymentWay(session);
  const phoneNum = isAdminSession
    ? '관리자주문'
    : !isSignInMembersOrder
    ? walletsignedInInfo
      ? walletsignedInInfo.payload.phoneNum
      : '미가입주문'
    : signInNumber;
  const couponPrice = getCouponPrice(session);

  return {
    items,
    steps,
    timeStamp,
    paymentWayUserId,
    infoForRefunds,
    isCouponUsed,
    totalPrice,
    usedCouponNum,
    phoneNum,
    paymentWay,
    companyName,
    couponPrice,
    membersId,
  };
};
