import { AntSwitch } from '@components/Status';
import { Module } from '@definitions/vm';
import { css } from '@emotion/core';
import { Button, Dialog, Grid, Switch, Typography, makeStyles, withStyles, IconButton } from '@material-ui/core';

import React, { useState } from 'react';
import ResultPhoto, { PhotoResult } from './ResultPhoto';

const useStyles = makeStyles({
  normalText: {
    color: '#3f51b5',
  },
  warnningText: {
    color: 'orange',
  },
  dangerText: {
    color: '#f50057',
  },
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // 검은색 투명도 80%
  },
  dailogPaper: {
    width: '85%',
    maxWidth: 'none', // maxWidth를 제거하여 width 85% 적용을 확실하게 함
  },
  imageContainer: {
    width: '300px',
    height: '300px',
    border: '1px solid gray',
    position: 'relative',
    display: 'inline-block',
    margin: '10px',
  },
  input: {
    display: 'none',
  },
});

interface DispenserProps {
  dispensers: Module[];
  setActivationDispenserModule: (id: string) => void;
  fillCups: () => void;
  upload: (results: PhotoResult[]) => void;
}
function Dispenser({ dispensers, setActivationDispenserModule, fillCups, upload }: DispenserProps): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState(null);
  const onSubmit = async (results: PhotoResult[], length: number) => {
    if (results.length < length) {
      window.alert(`모든 결과물들을 촬영해주세요: (필요 장 수: ${length}, 결과 장 수: ${results.length})`);
      return;
    }
    await upload(results);
    await fillCups();

    window.alert('저장이 완료되었습니다. 모든 컵 개수를 최대로 초기화 합니다.');
  };
  return (
    <div style={{ width: '100%' }}>
      {dispensers.map((dispenser) => {
        const { id, type, mountPosition, disable, maxCupCapacity, currentCupCnt } = dispenser;
        const isCupCapcityControllable = typeof currentCupCnt === 'number' && typeof maxCupCapacity === 'number';
        const cupCntColor = isCupCapcityControllable
          ? currentCupCnt / maxCupCapacity < 0.2
            ? classes.dangerText
            : currentCupCnt / maxCupCapacity <= 0.5
            ? classes.warnningText
            : classes.normalText
          : classes.normalText;

        return (
          <div key={id} css={dispenser__item__wrap}>
            <h2>{`디스펜서 번호 [${mountPosition - 9999}`}]</h2>
            {isCupCapcityControllable && (
              <Typography variant="h3" className={cupCntColor}>{`(${currentCupCnt}/${maxCupCapacity})개`}</Typography>
            )}
            <Typography component="div" style={{ marginRight: '20px' }}>
              <Grid component="label" container alignItems="center" spacing={1}>
                <Grid item>Off</Grid>
                <Grid item>
                  <AntSwitch checked={!disable} onChange={() => setActivationDispenserModule(id)} name="checkedC" />
                </Grid>
                <Grid item>On</Grid>
              </Grid>
            </Typography>
          </div>
        );
      })}

      <Button variant="contained" onClick={() => setOpen(true)} color="primary" css={fill__button}>
        컵 채우기
      </Button>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.dailogPaper }}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <ResultPhoto
          title="컵 채운 사진"
          subTitle="모든 디스팬서에 컵을 채워주시고, 아래 대표사진과 같이 촬영해주세요."
          examples={[
            {
              name: '디스팬서 정면',
              url:
                'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/tasks%2Fdispenser%2Fimage%20(10).png?alt=media&token=ba898508-a5bc-4690-8090-33c3775e4bd9&_gl=1*g4samn*_ga*OTUyMjUxOTQ2LjE2OTU2MTY5Nzg.*_ga_CW55HF8NVT*MTY5NzAxMjk5My4xOC4xLjE2OTcwMTMxNjMuMTAuMC4w',
            },
          ]}
          close={() => setOpen(false)}
          onSubmit={async (reslts: PhotoResult[], length: number) => {
            await onSubmit(reslts, length);
            setOpen(false);
          }}
        />
      </Dialog>
    </div>
  );
}

const dispenser__item__wrap = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 38px;
  margin-bottom: 10px;
  h2 {
    font-weight: bold;
  }
`;

const fill__button = css`
  width: calc(100% - 24px);
  height: 120px;
`;

export default Dispenser;
