//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send } from '@material-ui/icons';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { RecipeType } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';
import { RecipeOrder } from './RecipeOrderRaw';

export interface AddRecipeOrderProps {
  vmRecipes: RecipeType[];
  editedData: RecipeOrder[];
  orderAddHandler: (recipeOrderInfo: RecipeOrder, idx: number) => void;
}

function AddRecipeOrder({ vmRecipes, editedData, orderAddHandler }: AddRecipeOrderProps) {
  const [recipeNameList, setRecipeNameList] = useState<string[]>([]);
  const [recipeName, setRecipeName] = useState('');
  const [recipeOrder, setRecipeOrder] = useState(0);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const recipeCandidates = vmRecipes
      .map((recipe: RecipeType) => {
        const {
          detail: { name },
        } = recipe;
        return name;
      })
      .filter((candidate) => !editedData.map(({ recipeName }) => recipeName).includes(candidate));

    const set = new Set(recipeCandidates);
    const uniqueCandidates = [...set];
    setRecipeNameList(uniqueCandidates);
  }, [vmRecipes, editedData]);

  const onChangeNameHandler = (e: React.ChangeEvent<any>, value: string) => {
    setRecipeName(value);
    // initHandler();
  };
  const onChangeOrderHandler = (e: React.ChangeEvent<any>) => {
    setRecipeOrder(e.target.value);
    // initHandler();
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertOpen(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [alertOpen]);

  useEffect(() => {
    const lastOrderNum = Math.max(...editedData.map((data) => data.recipeOrder));
    if (recipeName !== '') {
      setRecipeOrder(lastOrderNum + 1);
    }
  }, [recipeName]);

  const addButtonHandler = () => {
    if (recipeName === '' || recipeName === undefined) {
      setAlertOpen(true);
    } else {
      orderAddHandler(
        {
          recipeName,
          recipeOrder,
          checked: false,
          beDeleted: false,
        },
        vmRecipes.length,
      );
      setRecipeName('');
      setRecipeOrder(0);
    }
  };

  return (
    <>
      {alertOpen && (
        <Alert onClose={() => setAlertOpen(false)} severity="warning">
          레시피 이름 또는 순서가 비었습니다!
        </Alert>
      )}

      <section css={add_recipe__order__wrap}>
        <Autocomplete
          options={recipeNameList}
          style={{ width: '300px' }}
          noOptionsText="해당 값이 존재하지 않습니다."
          onInputChange={(e, value) => onChangeNameHandler(e, value)}
          renderInput={(params) => <TextField {...params} label="추가 할 레시피 이름을 선택하세요." />}
          value={recipeName}
        />
        <input css={add__order__input} type="number" value={recipeOrder} onChange={(e) => onChangeOrderHandler(e)} />
        <Button variant="contained" color="inherit" style={{ marginLeft: '15px' }} onClick={addButtonHandler}>
          추가
        </Button>
      </section>
    </>
  );
}

export default AddRecipeOrder;

const add_recipe__order__wrap = css`
  display: flex;
  width: 100%;
  height: 60px;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`;
const add__order__input = css`
  display: flex;
  justify-content: start;
  align-items: center;
  width: 60px;
  height: 60%;
  text-align: center;
`;
