import { PaymentType, PaymentWay } from './payment';
import { QueueStep } from './queue';
import { Item, SelectedOptions } from './order';
import { CombinationObjectType, Metric } from './recipes';
import { WalletAuth } from './walletAuth';
import { WalletCartHsitory } from './walletCart';

export enum COUPON_ACTIONS {
  USE_COUPON = 'USE_COUPON',
  SKIP_COUPON = 'SKIP_COUPON',
  SELECT_COUPON = 'SELECT_COUPON',
  SET_COUPON_PRICE = 'SET_COUPON_PRICE',
}

export enum ORDER_ACTIONS {
  SET_ITEMS = 'SET_ITEMS',
  SET_CUP_DISPENSER_NUM = 'SET_CUP_DISPENSER_NUM',
}

export enum AUTH_ACTIONS {
  // SIGN_IN은 번호입력하여 존재 하면 SIGN_IN_REQUEST를 추가함
  SIGN_IN_REQUEST = 'SIGN_IN_REQUEST',

  // MEMBERS랑 MEMBERSHIP 둘다 약관 동의 할때에 해당 됨
  SIGN_UP_REQUEST = 'SIGN_UP_REQUEST',

  // 결제완료하면 로그아웃 시킴
  SIGN_OUT = 'SIGN_OUT',

  // MEMBERS에서 로그인 할때 추가정보를 입력후 추가 할때 입력한다.
  ADD_SIGN_UP_DETAIL = 'ADD_SIGN_UP_DETAIL',

  // SIGN_IN에 성공하면 payload에 전체정보 넣어주기(약관정보)
  SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS',

  // SIGN_IN에 실패한경우
  SIGN_IN_FAILED = 'SIGN_IN_FAILED',

  // SIGN_UP에 성공하면 해당 세션을 넣어줌
  SIGN_UP_SUCCESS = 'SIGN_UP_SUCCESS',

  SIGN_UP_FAILED = 'SIGN_UP_FAILED',

  ADMIN_SIGN_IN_SUCCESS = 'ADMIN_SIGN_IN_SUCCESS',

  ADMIN_SIGN_OUT = 'ADMIN_SIGN_OUT',
}

export enum PAYMENT_ACTIONS {
  SET_FINAL_PAYMENT = 'SET_FINAL_PAYMENT',
  SET_PAYMENT = 'SET_PAYMENT',
  SET_PAYMENT_SUMMARY = 'SET_PAYMENT_SUMMARY',
  SET_WALLET_BALANCE_PAYMENT = 'SET_WALLET_BALANCE_PAYMENT',
  SET_WALLET_PAYMENT_RESULT = 'SET_WALLET_PAYMENT_RESULT',
}

export enum QUEUE_ACTIONS {
  SET_STEPS = 'SET_STEPS',
}

export enum SH_TYPE {
  SELECTED_MEMBERS = 'SELECTED_MEMBERS',
  AUTH = 'AUTH',
  COUPON = 'COUPON',
  PAYMENT = 'PAYMENT',
  QUEUE = 'QUEUE',
  ORDER = 'ORDER',
  WALLET_CART = 'WALLET_CART',
  WALLET_AUTH = 'WALLET_AUTH',
  CUSTOM_RECIPE = 'CUSTOM_RECIPE',
}

import { Ordered } from '@definitions/order';
import { WalletRefund, WalletRefundSuccess } from './walletRefund';

export enum CUSTOM_RECIPE_ACTIONS {
  SET_ORDER = 'SET_ORDER',
}

export interface SetCustomRecipeOrder {
  type: SH_TYPE.CUSTOM_RECIPE;
  action: CUSTOM_RECIPE_ACTIONS.SET_ORDER;
  payload: Ordered;
  timeStamp: Date;
}

export type CustomRecipe = SetCustomRecipeOrder;

export interface Session {
  id: string;
  vmId: string;
  timeStamp: Date;
  version: string;
  history: SessionHistory[];
}

export interface SetItems {
  type: SH_TYPE.ORDER;
  action: ORDER_ACTIONS.SET_ITEMS;
  payload: {
    items: {
      recipeName: string;
      price: {
        basic: number;
        concnCharge: number;
        sizeCharge: number;
      };
      combination: CombinationObjectType[];
      selectedOptions: SelectedOptions;
    }[];
  };
  timeStamp: Date;
}

export interface SetCupDispenserNum {
  type: SH_TYPE.ORDER;
  action: ORDER_ACTIONS.SET_CUP_DISPENSER_NUM;
  payload: {
    cupDispenser: { recipeName: string; dispenserNum: Metric }[];
  };
  timeStamp: Date;
}

export interface AuthAdminSignInSuccess {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.ADMIN_SIGN_IN_SUCCESS;
  timeStamp: Date;
  signInType?: string;
  payload: {
    name: string;
    phoneNum: string;
    level: number;
    adminPw: string;
    vms?: string[];
    type?: string;
    csNumber?: string;
  };
}

export interface AuthAdminSignOut {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.ADMIN_SIGN_OUT;
  timeStamp: Date;
}

export interface AuthSignInRequest {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.SIGN_IN_REQUEST;
  payload: {
    userData: {
      phoneNum: string;
    };
  };
  timeStamp: Date;
}

export interface AuthSignInFailed {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.SIGN_IN_FAILED;
  payload: {
    userData: {
      phoneNum: string;
    };
    errorMsg: any;
  };
  timeStamp: Date;
}

export interface AuthSignUpRequest {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.SIGN_UP_REQUEST;
  timeStamp: Date;
  payload: {
    userData: {
      [key: string]: any;
    };
  };
}

export interface AuthSignOut {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.SIGN_OUT;
  timeStamp: Date;
  payload: { id: string; paymentType: PaymentType };
}

export interface AuthAddSignUpDetail {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.ADD_SIGN_UP_DETAIL;
  timeStamp: Date;
  payload: {
    userData: {
      [key: string]: any;
    };
  };
}

export interface AuthSignInSuccess {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.SIGN_IN_SUCCESS;
  timeStamp: Date;
  payload: {
    userData: {
      [key: string]: any;
    };
  };
}

export interface AuthSignUpSuccess {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.SIGN_UP_SUCCESS;
  timeStamp: Date;
  payload: {
    userData: {
      [key: string]: any;
    };
  };
}

export interface AuthSignUpFailed {
  type: SH_TYPE.AUTH;
  action: AUTH_ACTIONS.SIGN_UP_FAILED;
  timeStamp: Date;
  payload: {
    userData: {
      [key: string]: any;
    };
    errorMsg: any;
  };
}

export interface SetCouponPrice {
  type: SH_TYPE.COUPON;
  action: COUPON_ACTIONS.SET_COUPON_PRICE;
  payload: {
    couponPrice: number;
  };
  timeStamp: Date;
}

export interface CouponUse {
  type: SH_TYPE.COUPON;
  action: COUPON_ACTIONS.USE_COUPON;
  payload: {
    selectedCouponIdList: string[];
    totalCouponPrice: number;
  };
  timeStamp: Date;
}

export interface CouponSkip {
  type: SH_TYPE.COUPON;
  action: COUPON_ACTIONS.USE_COUPON;
  timeStamp: Date;
}

export interface CouponSelect {
  type: SH_TYPE.COUPON;
  action: COUPON_ACTIONS.SELECT_COUPON;
  timeStamp: Date;
  payload: {
    selectedCouponIdList: string[];
  };
}

export interface SetFinalPayment {
  type: SH_TYPE.PAYMENT;
  action: PAYMENT_ACTIONS.SET_FINAL_PAYMENT;
  payload: {
    finalPrice: number;
  };
  timeStamp: Date;
}

export interface SetPayment {
  type: SH_TYPE.PAYMENT;
  action: PAYMENT_ACTIONS.SET_PAYMENT;
  payload: {
    recipeName: string;
    price: number;
  }[];
  timeStamp: Date;
}

export interface SetPaymentSummary {
  type: SH_TYPE.PAYMENT;
  action: PAYMENT_ACTIONS.SET_PAYMENT_SUMMARY;
  payload: {
    paymentWay: PaymentWay;
    totalPrice: number;
    paymentWayUserId: string;
    infoForRefunds: string;
  };
  timeStamp: Date;
}

export interface SetWalletBalancePayment {
  type: SH_TYPE.PAYMENT;
  action: PAYMENT_ACTIONS.SET_WALLET_BALANCE_PAYMENT;
  payload: {
    items: Item[];
    balance: number;
  };
  timeStamp: Date;
}

export interface SetWalletPaymentResult {
  type: SH_TYPE.PAYMENT;
  action: PAYMENT_ACTIONS.SET_WALLET_PAYMENT_RESULT;
  payload: {
    is: string;
    result: {
      cardNumber: string;
      date: string;
      fee: string;
      identicalNumbeer: string;
      installment: string;
      posNumber: string;
      shopNumber: string;
      successNumber: string;
      tax: string;
      time: string;
      total: string;
      transactionCode: string;
      transactionWay: string;
    };
  };
  timeStamp: Date;
}

export interface SetSteps {
  type: SH_TYPE.QUEUE;
  action: QUEUE_ACTIONS.SET_STEPS;
  payload: {
    steps: QueueStep[];
  };
  timeStamp: Date;
}

export type Order = SetItems | SetCupDispenserNum;

export type Queue = SetSteps;

export type Payment =
  | SetFinalPayment
  | SetPayment
  | SetPaymentSummary
  | SetWalletBalancePayment
  | SetWalletPaymentResult;

export type Coupon = CouponUse | CouponSkip | CouponSelect | SetCouponPrice;

export type Auth =
  | AuthSignInRequest
  | AuthSignUpRequest
  | AuthSignOut
  | AuthAddSignUpDetail
  | AuthSignInSuccess
  | AuthAdminSignInSuccess
  | AuthAdminSignOut
  | AuthSignUpFailed;

export type SessionHistory =
  | Payment
  | Auth
  | Coupon
  | Queue
  | Order
  | WalletAuth
  | WalletCartHsitory
  | CustomRecipe
  | WalletRefund;
