export enum PaymentType {
  none,
  test,
  general,
}

export enum PaymentStep {
  ready,
  request,
  success,
  failed,
}

export enum RequestType {
  ACK,
  NACK,
  CHECK,
  READY,
  APPROVAL,
}

export enum PaymentWay {
  card = 'CREDIT_CARD',
  paycoQR = 'PAYCO_QR',
  paycoBarcode = 'PAYCO_BARCODE',
  wallet = 'WALLET',
  'N/A' = 'N/A',
}

// TO-DO: 2020-08-23 지환, 결제 모듈 구현하면서 추가
export type PaymentSuccessResult = {
  cardNumber: string;
  date: string;
  fee: string;
  identicalNumbeer: string;
  installment: string;
  posNumber: string;
  shopNumber: string;
  successNumber: string;
  tax: string;
  time: string;
  total: string;
  transactionCode: string;
  transactionWay: string;
};

export type PaymentFailedResult = {
  failedCode: string;
  failedMessage: string;
};

// type ReadyPayment = {
//   step: PaymentStep.ready;
//   failedCnt: number;
//   nackCnt: number;
//   isCardInserted: boolean;
// };

// type RequestPayment = {
//   step: PaymentStep.request;
//   failedCnt: number;
//   nackCnt: number;
//   isCardInserted: boolean;
// };

// type SuccessPayment = {
//   step: PaymentStep.success;
//   result: PaymentSuccessResult;
//   failedCnt: number;
//   nackCnt: number;
//   isCardInserted: boolean;
// };

// type FailedPayment = {
//   step: PaymentStep.failed;
//   result: PaymentFailedResult;
//   failedCnt: number;
//   nackCnt: number;
//   isCardInserted: boolean;
// };

export type Status = {
  step: PaymentStep;
  result?: PaymentSuccessResult | PaymentFailedResult;
  failedCnt: number;
  nackCnt: number;
  isCardInserted: boolean;
  msg: Buffer[] | Buffer;
};

export type GeneralPayment = {
  type: PaymentType.general;
  status: Status;
};

type NonPayment = {
  type: PaymentType.none | PaymentType.test;
};

export type Payment = GeneralPayment | NonPayment;
