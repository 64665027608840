export enum ContainerType {
  bottle = 'bottle',
  keg = 'keg',
}

export enum ContainerStatus {
  empty = 'empty',
  filled = 'filled',
  allocated = 'allocated',
  mounted = 'mounted',
  deprecated = 'deprecated',
}

export enum ContainerHistoryType {
  create = 'create',
  fill = 'fill',
  allocate = 'allocate',
  deallocate = 'deallocate',
  mount = 'mount',
  unmount = 'unmount',
  clear = 'clear',
  amountUpdateInVm = 'amountUpdateInVm',
  deprecate = 'deprecate',
  extract = 'extract',
}

export const ContainerHistoryVersion = '1.0';

export type EmptyContainer = {
  id: string;
  type: ContainerType;
  status: ContainerStatus.empty;
  target?: string[];
};

export type FilledContainer = {
  id: string;
  type: ContainerType;
  status: ContainerStatus.filled;
  ingredientId: string;
  amount: number;
  fillDate: Date;
  expirationDate: Date;
  target?: string[];
};

export type AllocatedContainer = {
  id: string;
  type: ContainerType;
  status: ContainerStatus.allocated;
  ingredientId: string;
  amount: number;
  allocation: string;
  allocationDate: Date;
  fillDate: Date;
  expirationDate: Date;
  target?: string[];
};

export type MountedContainer = {
  id: string;
  type: ContainerType;
  status: ContainerStatus.mounted;
  ingredientId: string;
  amount: number;
  allocation: string;
  mount: string;
  mountDate: Date;
  allocationDate: Date;
  fillDate: Date;
  expirationDate: Date;
  target?: string[];
};

export type DeprecatedContainer = {
  id: string;
  type: ContainerType;
  target?: string[];
  status: ContainerStatus.deprecated;
};

export type Container = EmptyContainer | FilledContainer | AllocatedContainer | MountedContainer | DeprecatedContainer;
