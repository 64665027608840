// 외부모듈
import { createStore, applyMiddleware } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { getFirebase } from 'react-redux-firebase';

// 내부모듈
import rootReducer from '@reducers/index';
import { rootSaga } from '../saga/index';

function configureStore() {
  const sagaMiddleware = createSagaMiddleware();
  const enhancer = applyMiddleware(sagaMiddleware);
  const store = createStore(rootReducer, {}, enhancer);

  sagaMiddleware.run(rootSaga, getFirebase);

  return store;
}

export default configureStore;
