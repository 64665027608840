import React, { useState } from 'react';
import { css } from '@emotion/core';

import { Location } from '@definitions/vm';
import { Button, FormControl, makeStyles, Select, TextField, Typography, withStyles } from '@material-ui/core';
import { sendJob } from '@components/Status';
import { MountPosition } from '@definitions/recipes';
import { Ingredient } from '@definitions/ingredient';
import useBottomSheetSelect from 'app/hooks/useBottomSheetSelect';
import { Option } from '@reducers/bottomSheet';
import { ArrowDropDown, ArrowDropDownCircleOutlined, ArrowDropDownRounded } from '@material-ui/icons';
import { getPosition, getPositionName } from 'app/utils/mountposition';

interface DispenserOprProps {
  setOpen: (p1: boolean) => void;
  dispenserModules: { mountPosition: number }[];
  vmId: string;
  adminUser: { phoneNum: string; key: string };
  location: Location;
  version: string;
}

const DispenserOpr: React.FC<DispenserOprProps> = ({
  setOpen,
  dispenserModules,
  vmId,
  adminUser,
  location,
  version = '3.8',
}) => {
  const { openSelect, BottomSheetComponent } = useBottomSheetSelect('');
  const [selected, setSelected] = useState<Option | null>(null);
  const [dispenserData, setDispenserData] = useState({ mountPosition: -1, positionName: '' });
  const dispensers = dispenserModules.map((d) => ({
    value: d.mountPosition - 10000,
    label: `디스팬서 ${d.mountPosition - 10000 + 1}`,
  }));

  const handleClick = async () => {
    const option = await openSelect(dispensers);

    if (option) {
      setSelected(option);
      setDispenserData({
        ...dispenserData,
        mountPosition: option.value,
        positionName: option.label,
      });
    }

    console.log(option);
  };

  return (
    <div css={admin__status__wrap}>
      <h2>컵토출</h2>
      <button css={select__btn} onClick={handleClick}>
        {selected ? selected.label : '선택'}
        <ArrowDropDown />
      </button>
      <Button
        variant="contained"
        size="large"
        color={'primary'}
        css={admin__status__btn}
        onClick={async () => {
          try {
            const answer = window.confirm(`컵 토출 위치 :${dispenserData.positionName}\n정말 실행하시겠습니까?`);
            if (!answer) {
              return;
            }
            const { connectionType, ip } = location;
            const { key } = adminUser;
            // console.log(dispenserData);
            // console.log(connectionType, ip, key);

            if (version === '3.8') {
              await sendJob(setOpen, connectionType || 'socket.io', vmId, ip || `10.8.0.0`, key, {
                id: 'dispenseCup',
                data: dispenserData,
              });
            } else {
              await sendJob(setOpen, connectionType || 'socket.io', vmId, ip || `10.8.0.0`, key, {
                id: 'dispenserOpr',
                data: dispenserData,
              });
            }
          } catch (e) {
            window.alert(e);
          }
        }}
      >
        실행
      </Button>
      {BottomSheetComponent}
    </div>
  );
};

const admin__status__wrap = css`
  width: 100%;
  margin-top: 15px;
  font-size: 38px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: bold;
  }
`;

const admin__status__btn = css`
  width: 160px;
  max-height: 84px;
`;

const select__btn = css`
  width: 190px;
  max-height: 84px;
  background-color: white;
  border: 1px solid rgba(150, 150, 150, 0.87) !important;
  color: rgba(10, 10, 10, 0.87) !important;
  font-weight: bold;
  line-height: 84px;
  overflow: hidden;
`;

export default DispenserOpr;
