// 외부 모듈
import React from 'react';
import { css } from '@emotion/core';
import { Card, Text } from '@fluentui/react-northstar';

// 내부 모듈
import { VendingMachine } from '@definitions/vm';

type VmAmountCardProps = {
  item: VendingMachine;
};

function VMCsNumber({ item }: VmAmountCardProps) {
  const {
    id,
    location: { serviceCenter, name },
  } = item;
  if (serviceCenter === undefined) {
    console.error('vm에 serviceCenter가 존재하지 않습니다.');
    return null;
  }
  const { phoneNum } = serviceCenter;

  return (
    <div css={ingredient__wrap}>
      <Card style={{ width: '90vw', marginBottom: '5px' }}>
        <Text content={`[${id} : ${name}] : ${phoneNum}`} style={{ fontSize: '20px' }} weight="semibold" />
      </Card>
    </div>
  );
}

const ingredient__wrap = css`
  display: flex;
  justify-content: center;
`;

export default VMCsNumber;
