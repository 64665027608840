import React, { useState } from 'react';
import { css } from '@emotion/core';

import { Location } from '@definitions/vm';
import { Button, FormControl, makeStyles, Select, TextField, Typography, withStyles } from '@material-ui/core';
import { sendJob } from '@components/Status';
import { MountPosition } from '@definitions/recipes';
import { Ingredient } from '@definitions/ingredient';
import useBottomSheetSelect from 'app/hooks/useBottomSheetSelect';
import { Option } from '@reducers/bottomSheet';
import { ArrowDropDown, ArrowDropDownCircleOutlined, ArrowDropDownRounded } from '@material-ui/icons';
import { getPosition, getPositionName } from 'app/utils/mountposition';

interface SolOprProps {
  setOpen: (p1: boolean) => void;
  mps: { mountPosition: MountPosition; ingredientId: string }[];
  ingredients: { [key: string]: Ingredient };
  vmId: string;
  adminUser: { phoneNum: string; key: string };
  location: Location;
  version: string;
}

const SolOpr: React.FC<SolOprProps> = ({ setOpen, mps, ingredients, vmId, adminUser, location, version = '3.8' }) => {
  const { openSelect, BottomSheetComponent } = useBottomSheetSelect('');
  const [selected, setSelected] = useState<Option | null>(null);
  const [solData, setSolData] = useState({ mountPosition: -1, duration: 0, positionName: '' });
  const solvs =
    version === '3.8'
      ? [
          { value: 0, label: '정수' },
          { value: 1, label: '온수' },
          { value: 40, label: '세척(독립) 1번' },
          { value: 41, label: '세척(독립) 2번' },
          { value: 60, label: '세척(병합:1~4) 커피' },
        ]
      : [
          { value: 0, label: '정수' },
          { value: 1, label: '온수' },
          { value: 7, label: '세척(독립) 1번' },
          { value: 8, label: '세척(독립) 2번' },
          { value: 9, label: '세척(독립) 3번' },
          { value: 10, label: '세척(독립) 4번' },
          { value: 11, label: '세척(독립) 5번' },
          { value: 12, label: '세척(독립) 6번' },
          { value: 13, label: '세척(병합:1~6) 커피' },
        ];
  const handleClick = async () => {
    const option = await openSelect(solvs);

    if (option) {
      setSelected(option);
      setSolData({
        ...solData,
        mountPosition: option.value,
        positionName: option.label,
      });
    }

    console.log(option);
  };

  return (
    <div css={admin__status__wrap}>
      <h2>솔밸 동작</h2>
      <button css={select__btn} onClick={handleClick}>
        {selected ? selected.label : '선택'}
        <ArrowDropDown />
      </button>

      <FormControl>
        <TextField
          id="dispense_duration"
          label="동작 시간(초)"
          variant="outlined"
          type="number"
          InputProps={{ style: { fontSize: '28px' } }}
          InputLabelProps={{
            style: { fontSize: '22px' }, // 여기에 원하는 스타일을 적용
          }}
          onChange={(event) =>
            // 펌프 동작 시간
            setSolData({
              ...solData,
              duration: Number(event.target.value),
            })
          }
        />
      </FormControl>

      <Button
        variant="contained"
        size="large"
        color={'primary'}
        css={admin__status__btn}
        onClick={async () => {
          try {
            if (!solData.duration || solData.duration <= 0) {
              window.alert('최소 0초 이상의 시간을 입력해 주세요.');
              return;
            }
            const answer = window.confirm(
              `추출 위치(${solData.positionName}), 시간(${solData.duration})\n정말 실행하시겠습니까?`,
            );
            if (!answer) {
              return;
            }
            const { connectionType, ip } = location;
            const { key } = adminUser;
            console.log(solData);
            console.log(connectionType, ip, key);
            if (version === '4.0') {
              await sendJob(setOpen, connectionType || 'socket.io', vmId, ip || `10.8.0.0`, key, {
                id: 'solOpr',
                data: solData,
              });
            } else {
              await sendJob(setOpen, connectionType || 'socket.io', vmId, ip || `10.8.0.0`, key, {
                id: 'cleanDuration',
                data: solData,
              });
            }
          } catch (e) {
            window.alert(e);
          }
        }}
      >
        실행
      </Button>
      {BottomSheetComponent}
    </div>
  );
};

const admin__status__wrap = css`
  width: 100%;
  margin-top: 15px;
  font-size: 38px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: bold;
  }
`;

const admin__status__btn = css`
  width: 160px;
  max-height: 84px;
`;

const select__btn = css`
  width: 190px;
  max-height: 84px;
  background-color: white;
  border: 1px solid rgba(150, 150, 150, 0.87) !important;
  color: rgba(10, 10, 10, 0.87) !important;
  font-weight: bold;
  line-height: 84px;
  overflow: hidden;
`;

export default SolOpr;
