// 외부모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { ExtendedFirestoreInstance } from 'react-redux-firebase';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Dropdown } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { firestore } from 'app/common/firebase';

// 내부모듈
import { omitKeys } from '@utils/omitObject';

// 타입
import { DocumentData } from '@firebase/firestore-types';
import { RecipeType, CombinationObjectType } from '@definitions/recipes';
import { TimeStamp, VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers/index';
import { DEPRICATED_VMS } from '@constants/vm';
import { format } from 'app/utils/sales';

function formatDateToKorean(date: Date) {
  if (!(date instanceof Date)) {
    throw new Error('유효한 Date 객체를 입력하세요.');
  }

  const year = date.getFullYear();
  const month = date.getMonth() + 1; // 월은 0부터 시작하므로 1을 더함
  const day = date.getDate();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  return `${year}년 ${month}월 ${day}일 ${hours}시 ${minutes}분`;
}

const excludeVmFilter = (vm: VendingMachine) => !DEPRICATED_VMS.includes(vm.id);
const pageSize = 10;
function trackPromise(promise, vmId) {
  console.log(`Starting import for VM ID: ${vmId}`);
  return new Promise((resolve, reject) => {
    promise
      .then((result) => {
        console.log(`Completed import for VM ID: ${vmId}`);
        resolve(result);
      })
      .catch((error) => {
        console.log(`Error in import for VM ID: ${vmId}`);
        reject(error);
      });
  });
}

async function loadOrders(
  vmId: string,
  firestore: ExtendedFirestoreInstance,
  query: string,
  startingTimeStamp: Date,
  endingTimeStamp: Date,
) {
  const targetOrder = [];
  let lastVisible = null;
  let cnt = 0;
  let currentQuery = firestore
    .collection(query)
    .where('timeStamp', '>', startingTimeStamp)
    .where('timeStamp', '<', endingTimeStamp)
    .orderBy('timeStamp')
    .limit(pageSize);

  do {
    const snapshot = await currentQuery.get();
    const documents = snapshot.docs;

    if (documents.length === 0) {
      console.log(`[${vmId}] 읽기 완료`);
      break;
    }

    targetOrder.push(...documents.map((doc) => doc.data()));
    lastVisible = documents[documents.length - 1];
    console.log(`읽은 데이터: ${cnt++ * pageSize}개 `);
    currentQuery = firestore
      .collection(query)
      .where('timeStamp', '>', startingTimeStamp)
      .where('timeStamp', '<', endingTimeStamp)
      .orderBy('timeStamp')
      .startAfter(lastVisible)
      .limit(pageSize);
  } while (lastVisible);

  return targetOrder;
}

function VmUsedAmount(): JSX.Element {
  // const [vmData, setVmData] = useState<any[]>([]);
  // const firestore = useFirestore();
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const [start, setStart] = useState<string>('');
  const today = new Date();
  const [end, setEnd] = useState<string>(format(today));
  const [selectedVmLocation, setSelectedVmLocation] = useState<string>('');
  // const [selectedVm, setSelectedVm] = useState<string>('');
  // const handleVmSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //   setSelectedVm(event.target.value);
  // };
  let locations: { name: string; startDate: TimeStamp }[] = [];
  let isLoaded = false;

  if (vms !== undefined && vms !== null && vms.length !== 0) {
    locations = vms
      .filter(({ location }) => location.type !== '테스트')
      .map(({ id, location: { name, startDate } }) => ({
        id,
        name,
        startDate,
      }));
    locations = [
      ...locations,
      { name: '모든장소', startDate: { nanoseconds: 1, seconds: 2, toDate: () => new Date('2019-03-29') } },
    ];
    isLoaded = true;
  }

  const getA11ySelectionMessage = {
    onAdd: (item) => {
      setSelectedVmLocation(item);
      setStart('');
    },
  };

  // const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);

  // 원액소모량 vm은 test,init,사용하지않는 vm은 빼고 보여준다

  const filterValidVms = vms.filter(excludeVmFilter);

  const exportData = async (startingTimeStamp: Date, endingTimeStamp: Date, selectedVm: string) => {
    let csv = 'vmId,';
    if (selectedVm === '모든장소') {
      let all = {};
      const recipeNames: string[] = [];
      const promises = filterValidVms.map((vm) =>
        trackPromise(importData(startingTimeStamp, endingTimeStamp, vm.id), vm.id),
      );

      Promise.all(promises)
        .then((results) => {
          results.forEach((result, index) => {
            const vmId = filterValidVms[index].id;
            console.log(vmId);
            all = { ...all, [vmId]: result };

            result.forEach((data) => {
              Object.keys(data).forEach((key) => {
                if (!recipeNames.includes(key)) {
                  recipeNames.push(key);
                }
              });
            });
          });
          // console.log(all);
          // // 동일한 결과 처리 로직...
          console.log(recipeNames);

          recipeNames.forEach((key) => {
            csv += `${key},`;
          });
          csv += '\r\n';
          Object.keys(all).forEach((vmId) => {
            csv += `${vmId},`;
            recipeNames.map((name) => {
              all[vmId].forEach((info) => {
                let flag = true;
                Object.keys(info).forEach((rn) => {
                  if (rn === name) {
                    flag = false;
                    csv += `${info[rn]},`;
                  }
                });
                if (flag) {
                  csv += '0,';
                }
              });
              if (all[vmId].length === 0) {
                csv += '0,';
              }
            });

            csv += '\r\n';
          });

          const downloadLink = document.createElement('a');
          const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
          const url = URL.createObjectURL(blob);
          downloadLink.href = url;
          downloadLink.download = `${selectedVm} ${endingTimeStamp.getMonth() + 1}월 원액소모량.csv`;

          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        })
        .catch((error) => {
          // 오류 처리 로직...
        });

      return;
    }

    const result = await importData(startingTimeStamp, endingTimeStamp, selectedVm);
    result.forEach((a) => {
      const keys = Object.keys(a);
      keys.forEach((key) => {
        csv += `${key},`;
      });
      csv += '\r\n';
      csv += `${selectedVm},`;
      keys.forEach((key) => {
        if (a[key]) {
          csv += `${a[key]},`;
        } else {
          csv += '0,';
        }
      });
      csv += '\r\n';
    });
    const downloadLink = document.createElement('a');
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
    const url = URL.createObjectURL(blob);
    downloadLink.href = url;
    const selectedVmInstance = vms.find((vm) => vm.id === selectedVm);
    const selectedLocation = selectedVmInstance ? selectedVmInstance.location.name : selectedVm;
    downloadLink.download = `${selectedLocation} ${formatDateToKorean(startingTimeStamp)}-${formatDateToKorean(
      endingTimeStamp,
    )} 원액소모량.csv`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const importData = async (startingTimeStamp: Date, endingTimeStamp: Date, selectedVm: string) => {
    // const snapshot = await firestore.collection(`/version/v3/vms/`).get();
    // const targetVm = snapshot.docs.map((doc) => doc.data().id).filter((vm) => vm === selectedVm);
    // const vm = targetVm[0];

    const lastYear = endingTimeStamp.getFullYear() - 1;
    const currentYear = endingTimeStamp.getFullYear();

    // timestamp의 month 는 0부터 시작하므로 현 월을 알기위해 +1 하였음
    let currentMonth: number | string = endingTimeStamp.getMonth() + 1;

    // 현 월이 1월인경우 이전년도의 12월을 가져오기 위한 코드
    let lastMonth: number | string = endingTimeStamp.getMonth() === 0 ? 12 : endingTimeStamp.getMonth();
    if (currentMonth < 10) {
      currentMonth = `0${currentMonth}`;
    }
    if (lastMonth < 10) {
      lastMonth = `0${lastMonth}`;
    }

    let targetOrder = [];
    console.log(`${selectedVm}: <1> 요청완료`);
    const query = `/version/v3/vms/${selectedVm}/orders/${currentYear}/${currentMonth}`;
    console.log(query);
    // const thisMonthTargetOrder = await firestore
    //   .collection(query)
    //   .where('timeStamp', '>', startingTimeStamp)
    //   .where('timeStamp', '<', endingTimeStamp)
    //   .get();

    targetOrder = await loadOrders(selectedVm, firestore, query, startingTimeStamp, endingTimeStamp);
    console.log(`${selectedVm}: <2> 응답완료`);
    // targetOrder = thisMonthTargetOrder.docs.map((doc) => doc.data());
    if (endingTimeStamp.getMonth() === startingTimeStamp.getMonth() + 1) {
      const LastMonthTargetOrder = await firestore
        .collection(`/version/v3/vms/${selectedVm}/orders/${currentYear}/${lastMonth}`)
        .where('timeStamp', '>', startingTimeStamp)
        .get();

      const lastMonthTargetItems = LastMonthTargetOrder.docs.map((doc) => doc.data());
      targetOrder = targetOrder.concat(lastMonthTargetItems);
    } else if (startingTimeStamp.getMonth() === 11) {
      const query_ = `/version/v3/vms/${selectedVm}/orders/${lastYear}/${lastMonth}`;
      // const lastMonthLastYearOrder = await firestore
      //   .collection(`/version/v3/vms/${selectedVm}/orders/${lastYear}/${lastMonth}`)
      //   .where('timeStamp', '>', startingTimeStamp)
      //   .get();

      const lastMonthLastYearTargetItems = await loadOrders(
        selectedVm,
        firestore,
        query_,
        startingTimeStamp,
        endingTimeStamp,
      );
      targetOrder = targetOrder.concat(lastMonthLastYearTargetItems);
    }

    const getTargetOrderCombination = targetOrder
      .map((items: DocumentData) => items.order.items.map((orderitem: RecipeType) => orderitem.combination))
      .flat()
      .flat();

    const combinationReducer = (accumulator: any, value: CombinationObjectType) => {
      if (accumulator.hasOwnProperty(value.ingredientId)) {
        accumulator[value.ingredientId] = accumulator[value.ingredientId] + value.amount;
      } else {
        accumulator[value.ingredientId] = value.amount;
      }
      return accumulator;
    };

    const getOrderFlatResult = getTargetOrderCombination.reduce(combinationReducer, {});
    const orderFlatResult = omitKeys(getOrderFlatResult, ['water', 'ice', 'hotWater']);
    console.log(`${selectedVm}: <3> 파싱완료`);
    return Array(orderFlatResult);
  };

  return (
    <>
      <div css={fstData__wrap}>
        <h1>
          1. <span>원액 소모량을 구하려는 vm</span>을 선택하세요
        </h1>
        <Dropdown
          loading={!isLoaded}
          items={locations.map(({ name }) => name)}
          placeholder="기기장소"
          checkable
          getA11ySelectionMessage={getA11ySelectionMessage}
          style={{ marginTop: '4px', marginBottom: '12px' }}
        />
        <h1>
          2. <span>시간을 먼저 입력한 후</span> 날짜를 클릭하세요
        </h1>
        시작일
        <input
          id="datepicker_start"
          style={{ marginTop: '4px', marginBottom: '12px', width: '350px' }}
          type="date"
          value={start}
          min={
            selectedVmLocation === ''
              ? undefined
              : selectedVmLocation === '모든장소'
              ? format(
                  locations.reduce((prev, { name, startDate }) => {
                    console.log(name, ': ', startDate);
                    return startDate.toDate() > prev ? startDate.toDate() : prev;
                  }, new Date('2019-03-29')),
                )
              : format(
                  locations
                    .filter(({ name }) => {
                      if (name === selectedVmLocation) {
                        return true;
                      }

                      return false;
                    })[0]
                    .startDate.toDate(),
                )
          }
          max={format(today)}
          onChange={(event) => {
            // 시작일이 수정되면 end 도 수정
            setStart(event.target.value);
          }}
        />
        마지막일
        <input
          id="datepicker_end"
          style={{ marginTop: '4px', marginBottom: '18px', width: '350px' }}
          type="date"
          value={end}
          max={format(today)}
          onChange={(event) => {
            setEnd(event.target.value);
          }}
        />
        <h1>
          3. <span>시작일과 마지막일 선택한후 </span>아래 data export 버튼을 눌러주세요
        </h1>
        <Button
          content="data export"
          onClick={async () => {
            if (start === '' || end === '') {
              window.alert('시작일과 마지막일을 설정해주세요(기기를 수정하면 시작일이 초기화 됩니다).');

              return;
            } else if (new Date(start) > new Date(end)) {
              window.alert('시작일이 마지막일보다 클 수 없습니다.');

              return;
            } else if (selectedVmLocation === '') {
              window.alert('기기 장소를 정해주세요.');

              return;
            }

            window.alert(
              `[${formatDateToKorean(new Date(`${start}T00:00:00`))}] ~ [${formatDateToKorean(
                new Date(`${end}T24:00:00`),
              )}] 다운로드 예정입니다.`,
            );

            const vms_ =
              selectedVmLocation === '모든장소'
                ? [{ id: '모든장소' }]
                : vms.filter(({ location: { name } }) => name === selectedVmLocation);

            await exportData(new Date(`${start}T00:00:00`), new Date(`${end}T24:00:00`), vms_[0].id);
          }}
        />
        {/* <h1>
          4. <span>data export 버튼을 누른 후</span> 아래 원액 소모량 파일 다운로드 버튼을눌러 csv 파일을 다운받으세요
        </h1>
        <CSVLink
          css={download__btn}
          data={vmData}
          filename={`${selectedVm} ${endDate.getMonth() + 1}월 원액소모량.csv`}
        >
          원액 소모량 파일 다운로드
        </CSVLink> */}
      </div>
    </>
  );
}

const fstData__wrap = css`
  display: flex;
  flex-direction: column;
  font-family: Spoqa Han Sans;
  h1 {
    margin-top: 24px;
    font-size: 20px;
    font-stretch: normal;
    line-height: 1.38;
    letter-spacing: -0.3px;
    color: #333333;
    span {
      font-weight: bold;
    }
  }

  select {
    width: 280px;
  }
`;

const download__btn = css`
  border: 1px solid rgb(225, 223, 221);
  box-shadow: rgb(0 0 0 / 10%) 0px 0.2rem 0.4rem -0.075rem;
  color: black;
  margin-bottom: 200px;
  text-decoration: none;
  text-align: center;
  width: 280px;
  height: 32px;
  font-weight: bold;
  line-height: 28px;
`;

export default VmUsedAmount;
