// 외부모듈
import { combineReducers } from 'redux';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

// 내부모듈
import { ControllerActions } from './formController';
import process, { ProcessingActions } from './process';
import sagaInit, { SagaInitActions } from './init';
import bottomSheet from './bottomSheet';
import vm, { VmActions } from './selectedVm';

// 내부모듈

// Firebase reducer
const rrfReducer = { firebase: firebaseReducer, firestore: firestoreReducer };

// Root reducer
const rootReducer = combineReducers({
  vm,
  process,
  sagaInit,
  bottomSheet,
  ...rrfReducer,
});

export default rootReducer;

// types
export type RootStateType = ReturnType<typeof rootReducer>;
export type RootActions = ControllerActions | ProcessingActions | SagaInitActions | VmActions;
