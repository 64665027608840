export const firebaseConfig = {
  apiKey: 'AIzaSyCHWIPcLvzqIR1Jug4E4IItrF4godwydWc',
  authDomain: 'planz-6761f.firebaseapp.com',
  databaseURL: 'https://planz-6761f.firebaseio.com',
  projectId: 'planz-6761f',
  storageBucket: 'planz-6761f.appspot.com',
  messagingSenderId: '658949834296',
  appId: '1:658949834296:web:f759906534b84a6f0808cd',
  measurementId: 'G-00WX4G79BN',
};
