// 외부모듈
import { createAction, createReducer, PayloadAction } from '@reduxjs/toolkit';

// 내부모듈
import { CustomPayloadAction } from '@definitions/redux';

export const startProcess = createAction('@START_PROCESS');
export const endProcess = createAction('@END_PROCESS');
export const setProcessingPercent = createAction('@SET_PROCESSING_PERCENT', (percent: number) => ({
  payload: percent,
}));
export const addProcessingPercent = createAction('@ADD_PROCESSING_PERCENT', (percent: number) => ({
  payload: percent,
}));

export interface ProcessState {
  isProcessing: boolean;
  processingPercent: number;
}

const initialState: ProcessState = {
  isProcessing: false,
  processingPercent: 0,
};

const processing = createReducer(initialState, {
  [startProcess.type]: (state) => ({ ...state, isProcessing: true }),
  [endProcess.type]: (state) => ({ ...state, isProcessing: false }),
  [addProcessingPercent.type]: (state, action: PayloadAction<number>) => ({
    ...state,
    processingPercent: state.processingPercent + action.payload,
  }),
  [setProcessingPercent.type]: (state, action: PayloadAction<number>) => ({
    ...state,
    processingPercent: action.payload,
  }),
});

export default processing;
export type ProcessingActions =
  | CustomPayloadAction<'@START_PROCESS'>
  | CustomPayloadAction<'@ENT_PROCESS'>
  | CustomPayloadAction<'@SET_PROCESSING_PERCENT', number>;
