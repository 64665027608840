// 외부 모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { useFirestore } from 'react-redux-firebase';
import { Button } from '@fluentui/react-northstar';
import { Select, InputLabel, MenuItem, FormControl } from '@material-ui/core';

// 내부 모듈
import VmCsNumber from './VmCsNumber';
import { VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers';
import { vmCsGroupArr, vmCsGroup } from '@utils/csGroup';

function CSnumber() {
  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);
  const firestore = useFirestore();
  if (!admins || !uid) {
    return null;
  }

  const [csVmList, setCsVmList] = useState(
    vms.map((vm) => vm.id).filter((id) => !vmCsGroup['exceptGroup'].includes(id)),
  );

  const filterValidVms = vms.filter((vm) => csVmList && csVmList.includes(vm.id));

  const curCsNumber = filterValidVms[0].location.serviceCenter.phoneNum;
  console.log(curCsNumber);
  if (admins[uid].level > 1) {
    return <div>접근권한 없음</div>;
  }

  const handleCsGroupSelect = (event: any) => {
    if (event.target.value === 'wholeVmGroup') {
      setCsVmList(vms.map((vm) => vm.id).filter((id) => !vmCsGroup['exceptGroup'].includes(id)));
    } else {
      setCsVmList(vmCsGroup[event.target.value]);
    }
  };

  const updateCsNumber = (csNumber: string) => {
    csVmList.map((vmId) =>
      firestore.doc(`/version/v3/vms/${vmId}`).set(
        {
          location: {
            serviceCenter: {
              phoneNum: csNumber,
            },
          },
        },
        { merge: true },
      ),
    );
  };

  return (
    <div css={dashboard__wrap}>
      <h1>CS NUMBER 적용</h1>
      <FormControl style={{ width: '90%', marginBottom: '10px', marginLeft: '40px' }}>
        <InputLabel style={{ fontSize: '30px' }}>VM 그룹 명</InputLabel>
        <Select style={{ fontSize: 30 }} onChange={handleCsGroupSelect} defaultValue="wholeVmGroup">
          {vmCsGroupArr.map((vm, idx) => (
            <MenuItem style={{ fontSize: 30, marginTop: '10px' }} key={idx} value={vm} placeholder={'그룹명선택하기'}>
              {vm}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <FormControl style={{ width: '90%', marginBottom: '10px', marginLeft: '40px' }}>
        <InputLabel style={{ fontSize: '30px' }}>관리자 선택</InputLabel>
        <Select
          style={{ fontSize: 30 }}
          onChange={(event: any) => updateCsNumber(event.target.value)}
          defaultValue={curCsNumber}
        >
          <MenuItem style={{ fontSize: 30, marginTop: '10px' }} value={'1644  6513'}>
            메인(1655 6513)
          </MenuItem>
          <MenuItem style={{ fontSize: 30, marginTop: '10px' }} value={'1644  5126'}>
            서브(1655 5126)
          </MenuItem>
        </Select>
      </FormControl>
      <h1>CS NUMBER BOARD</h1>
      {filterValidVms.map((item, idx) => (
        <VmCsNumber key={idx} item={item} />
      ))}
    </div>
  );
}

export default CSnumber;

const dashboard__wrap = css`
  height: calc(100vh - 360px);
  font-family: Spoqa Han Sans;
  h1 {
    font-size: 30px;
    font-weight: bold;
    margin-top: 30px;
  }
  button {
    width: 300px;
    height: 50px;
    span {
      font-size: 25px;
      padding: 10px;
    }
  }
`;
