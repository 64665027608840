// 외부모듈
import React from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// 내부모듈
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { css } from '@emotion/core';

interface UserInfoProps {
  user: any;
  userRefreshHandler: () => void;
}

function UserInfo({ user, userRefreshHandler }: UserInfoProps): JSX.Element {
  const { signUpDate, availableMembershipCouponNum, availableStickerNum, marketingAgreement } = user;

  return (
    <Card style={{ width: '70%', height: '800px', borderRadius: '25px', position: 'relative', marginBottom: '20px' }}>
      <CardMedia
        image="https://react.semantic-ui.com/images/avatar/large/matthew.png"
        style={{ objectFit: 'cover', height: '70%' }}
      ></CardMedia>
      <CardContent></CardContent>
      <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
        <p>가입날짜 : {signUpDate.toDate().toDateString()}</p>
        <p></p>
      </Typography>
      <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
        <p>마케팅 동의 : {marketingAgreement ? '동의' : '비동의'}</p>
      </Typography>
      {/* <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
        <p>사용 가능한 스티커 갯수 : {availableStickerNum}</p>
      </Typography> */}
      <Typography variant="h4" component="h4" style={{ margin: '7px' }}>
        <p>사용 가능한 쿠폰 갯수 : {availableMembershipCouponNum}</p>
      </Typography>
      <div css={redo__icon} onClick={userRefreshHandler}>
        <FontAwesomeIcon size="2x" icon={faRedo} flip="horizontal" />
      </div>
    </Card>
  );
}

const redo__icon = css`
  position: absolute;
  right: 15px;
  bottom: 15px;
  color: #3f51b5;
`;

export default React.memo(UserInfo);
