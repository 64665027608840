//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField, Backdrop, CircularProgress, Fab } from '@material-ui/core';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send, Check } from '@material-ui/icons';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { green } from '@material-ui/core/colors';

interface ProgressProps {
  progress: boolean;
  success: boolean;
  successInitHandler: () => void;
}

function Progress({ progress, success, successInitHandler }: ProgressProps) {
  useEffect(() => {
    const timer = setTimeout(() => {
      successInitHandler();
    }, 700);

    return () => clearTimeout(timer);
  }, [success]);
  return (
    <Backdrop style={{ color: '#fff', zIndex: 999 }} open={progress}>
      <Box style={{ position: 'relative' }}>
        {success && (
          <Fab css={progress__check} style={{ backgroundColor: green[500] }}>
            <Check />
          </Fab>
        )}
        {!success && <CircularProgress css={circle__progress} style={{ color: green[500] }} size={68} />}
      </Box>
    </Backdrop>
  );
}

const progress__check = css`
  /* position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  transform: translateY(-50%); */
  /* color: green[500]; */
`;

const circle__progress = css`
  /* position: absolute;
  top: -6px;
  left: -6px; */
  /* top: 50%;
  left: 50%;
  transform: translateX(-50%);
  transform: translateY(-50%); */
`;

export default Progress;
