import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableRow, Paper } from '@material-ui/core';
import { Monitoring } from '@definitions/monitoring';

function renderData(data: Monitoring) {
  if (Array.isArray(data)) {
    return data.map((item, index) => (
      <TableRow key={index}>
        <TableCell>{index}</TableCell>
        <TableCell>{renderData(item)}</TableCell>
      </TableRow>
    ));
  } else if (typeof data === 'object' && data !== null) {
    return Object.entries(data).map(([key, value]) => (
      <TableRow key={key}>
        <TableCell>{key}</TableCell>
        <TableCell>{renderData(value)}</TableCell>
      </TableRow>
    ));
  } else {
    return <span>{data}</span>;
  }
}

interface NestedTableProps {
  data: Monitoring;
}

function NestedTable({ data }: NestedTableProps) {
  return (
    <Table size="small" aria-label="detials">
      <TableBody>{renderData(data)}</TableBody>
    </Table>
  );
}

export default NestedTable;

// 사용 예시
// <NestedTable data={yourData} />
