import React, { useEffect, useState } from 'react';
import { Container, Typography, Box, Select, MenuItem } from '@material-ui/core';
import axios from 'axios';
import { Temp } from '@definitions/monitoring';
import { Button, Dropdown, Flex, IDropdownOption } from '@fluentui/react-northstar';
import { useSelector } from 'react-redux';
import { VendingMachine } from '@definitions/vm';
import { RootStateType } from '@reducers/index';
import { format } from 'app/utils/sales';
import Chart from 'chart.js/auto';

import { DEPRICATED_VMS } from '@constants/vm';
import TempChart from './tempChart';

function HistoryControl(): JSX.Element {
  const [history, setHistory] = useState<{ temp: Temp; timestamp: string }[]>([]);
  const [startDate, setStartDate] = useState<Date>();
  const [endDate, setEndDate] = useState<Date>();

  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const [selectedVmLocation, setSelectedVmLocation] = useState<string>('');
  const today = new Date();
  let locations: { name: string; id: string }[] = [];
  let isLoaded = false;

  if (vms !== undefined && vms !== null && vms.length !== 0) {
    locations = vms
      .filter(({ location }) => location.type !== '테스트')
      .filter(({ id }) => !DEPRICATED_VMS.includes(id))
      .filter((vm) => vm.modules.filter((md) => md.type === 'tempCtrl').length > 0)
      .map(({ id, location: { name, startDate } }) => ({
        id,
        name,
        startDate,
      }));
    isLoaded = true;
  }

  const getA11ySelectionMessage = {
    onAdd: (item) => {
      setSelectedVmLocation(item);
    },
  };

  return (
    <Container maxWidth="xl" style={{ marginTop: '19px', overflow: 'scroll' }}>
      <Typography variant="h4" gutterBottom>
        장소별 온도 기록
      </Typography>
      <Flex column>
        VM_ID
        <Dropdown
          options={locations.map(({ id, name }) => ({
            key: id,
            text: name,
          }))}
          items={locations.map(({ name }) => name)}
          placeholder="기기장소"
          checkable
          getA11ySelectionMessage={getA11ySelectionMessage}
          style={{ marginTop: '4px', marginBottom: '12px' }}
        />
        시작일
        <input
          id="datepicker_start"
          style={{ marginTop: '4px', marginBottom: '12px' }}
          type="date"
          value={startDate}
          max={format(today)}
          onChange={(event) => {
            // 시작일이 수정되면 end 도 수정
            setStartDate(event.target.value);
          }}
        />
        마지막일
        <input
          id="datepicker_end"
          style={{ marginTop: '4px', marginBottom: '18px' }}
          type="date"
          value={endDate}
          max={format(today)}
          min={startDate}
          onChange={(event) => {
            setEndDate(event.target.value);
          }}
        />
      </Flex>
      <Button
        onClick={() => {
          if (!selectedVmLocation) {
            window.alert('VM(기기)를 선택해주세요');
            return;
          }
          if (!startDate || !endDate) {
            window.alert('시작날짜, 마지막날짜 을 제대로 입력해주세요');
            return;
          }
          const addr = `http://planz-proxy.com:3000/getHistory`;
          const params = {
            key: 'planz!3245',
            vmIds: vms.filter((vm) => vm.location.name === selectedVmLocation).map((vm) => vm.id),
            fields: ['temp'],
            start: startDate,
            end: endDate,
          };
          axios.get(addr, { params }).then((res) => {
            setHistory(res.data);
          });
        }}
      >
        가져오기
      </Button>
      <TempChart data={history} />
      {/* {
        new Chart(document.getElementById('acquisitions'), {
          type: 'bar',
          data: {
            labels: data.map((row) => row.year),
            datasets: [
              {
                label: 'Acquisitions by year',
                data: data.map((row) => row.count),
              },
            ],
          },
        })
      } */}
      {/* <MonitoringTable logs={logs.sort((a, b) => extractNumber(a.vmId) - extractNumber(b.vmId))} /> */}
    </Container>
  );
}

export default HistoryControl;
