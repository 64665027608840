import { Keg, Pump } from '@definitions/vm';
import {
  CONTAINER_FIRST_WARNING_DUE,
  CONTAINER_LAST_WARNING_DUE,
  KEG_AMOUNT_FIRST_WARNING,
  KEG_AMOUNT_LAST_WARNING,
  PUMP_AMOUNT_FIRST_WARNING,
  PUMP_AMOUNT_LAST_WARNING,
  COLOR_FOR_LAST_WARNING,
  COLOR_FOR_FIRST_WARNING,
  COLOR_FOR_NO_WARNING_STATUS,
  COLOR_FOR_NO_WARNING,
} from '@constants/statusStandard.json';

export const getColorForExpirationDate = (container: Keg | Pump) => {
  const expirationDateFromNow =
    container.expirationDate !== undefined &&
    (new Date(container.expirationDate.toDate()).getTime() - new Date(Date.now()).getTime()) / (1000 * 3600 * 24);
  if (expirationDateFromNow === false || expirationDateFromNow > CONTAINER_FIRST_WARNING_DUE) {
    return COLOR_FOR_NO_WARNING;
  } else if (expirationDateFromNow < CONTAINER_LAST_WARNING_DUE) {
    return COLOR_FOR_LAST_WARNING;
  } else if (expirationDateFromNow < CONTAINER_FIRST_WARNING_DUE) {
    return COLOR_FOR_FIRST_WARNING;
  }
};

export const getColorForAmount = (container: Keg | Pump) => {
  if (container.kegId === undefined) {
    if (container.amount < PUMP_AMOUNT_LAST_WARNING) {
      return COLOR_FOR_LAST_WARNING;
    } else if (container.amount < PUMP_AMOUNT_FIRST_WARNING) {
      return COLOR_FOR_FIRST_WARNING;
    } else {
      return COLOR_FOR_NO_WARNING;
    }
  } else {
    if (container.amount < KEG_AMOUNT_LAST_WARNING) {
      return COLOR_FOR_LAST_WARNING;
    } else if (container.amount < KEG_AMOUNT_FIRST_WARNING) {
      return COLOR_FOR_FIRST_WARNING;
    } else {
      return COLOR_FOR_NO_WARNING;
    }
  }
};

export const getStatusColorForAmount = (container: Keg | Pump) => {
  if (container.kegId === undefined) {
    if (container.amount < PUMP_AMOUNT_LAST_WARNING) {
      return COLOR_FOR_LAST_WARNING;
    } else if (container.amount < PUMP_AMOUNT_FIRST_WARNING) {
      return COLOR_FOR_FIRST_WARNING;
    } else {
      return COLOR_FOR_NO_WARNING_STATUS;
    }
  } else {
    if (container.amount < KEG_AMOUNT_LAST_WARNING) {
      return COLOR_FOR_LAST_WARNING;
    } else if (container.amount < KEG_AMOUNT_FIRST_WARNING) {
      return COLOR_FOR_FIRST_WARNING;
    } else {
      return COLOR_FOR_NO_WARNING_STATUS;
    }
  }
};

export const getColorForAmountTable = (containerType: string, amount: string | number) => {
  if (containerType === 'bottle') {
    if (amount < PUMP_AMOUNT_LAST_WARNING) {
      return COLOR_FOR_LAST_WARNING;
    } else if (amount < PUMP_AMOUNT_FIRST_WARNING) {
      return COLOR_FOR_FIRST_WARNING;
    } else {
      return COLOR_FOR_NO_WARNING;
    }
  } else if (containerType === 'keg') {
    if (amount < KEG_AMOUNT_LAST_WARNING) {
      return COLOR_FOR_LAST_WARNING;
    } else if (amount < KEG_AMOUNT_FIRST_WARNING) {
      return COLOR_FOR_FIRST_WARNING;
    } else {
      return COLOR_FOR_NO_WARNING;
    }
  } else {
    return COLOR_FOR_NO_WARNING;
  }
};
