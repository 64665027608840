/* eslint-disable curly */
// 외부모듈
import React, { useState } from 'react';
import { css } from '@emotion/core';
import { useDispatch, useSelector } from 'react-redux';
import produce from 'immer';
import Button from '@material-ui/core/Button';
import _ from 'lodash';

// 내부모듈
import { RootStateType } from '@reducers/index';
import SearchBar from './SearchBar';
import BenefitTypeSelect from './benefitTypeSelect';
import PriceController from './PriceController';
import {
  Benefit,
  BenefitType,
  CombinationBenefit,
  CombinationTarget,
  Condition,
  CouponPriceBenefit,
  CouponPriceTarget,
  Members,
  PriceBenefit,
  StickerBenefit,
  Target,
} from '@definitions/benefits';
import CouponPriceController from './CouponPriceController';
import CombinationController from './CombinationController';
import { controlForm } from '@reducers/formController';
import { ControllerType } from '@definitions/mock';

interface ControlPageProps {
  members: Members;
  membersHandler: (members: any) => void;
  selectBenefitTypeHanlder: (e: React.ChangeEvent<{ value: unknown }>) => void;
  selectedBenefitType: string;
}

const defaultPriceBenefit: PriceBenefit = {
  benefitType: BenefitType.price,
  targets: [],
};

const defaultCombinationBenefit: CombinationBenefit = {
  benefitType: BenefitType.combination,
  targets: [],
};

const defaultStickerBenefit: StickerBenefit = {
  benefitType: BenefitType.sticker,
  targets: [
    { id: 'membersipSticker', priority: 10, publishStickerNum: 1, conditions: [{ type: 'recipe', recipeNames: [] }] },
  ],
};

const defaultCouponPriceBenefit: CouponPriceBenefit = {
  benefitType: BenefitType.couponPrice,
  targets: [
    {
      id: 'membershipCoupon',
      conditions: [
        {
          type: 'recipe',
          recipeNames: [],
        },
      ],
      price: 1800,
      priority: 10,
    },
  ],
};

const defaultMembers: Members = {
  id: '',
  name: '',
  additionalCustomerInfo: [],
  benefits: [
    { ...defaultPriceBenefit },
    { ...defaultCombinationBenefit },
    { ...defaultCouponPriceBenefit },
    { ...defaultStickerBenefit },
  ],
};

const fillMembers = (members: Members) => {
  const newMembers = _.cloneDeep(members);
  const priceBenbefit = newMembers.benefits.find((benefit) => benefit.benefitType === BenefitType.price);
  const combinationBenefit = newMembers.benefits.find((benefit) => benefit.benefitType === BenefitType.combination);
  const couponPriceBenefit = newMembers.benefits.find((benefit) => benefit.benefitType === BenefitType.couponPrice);
  const stickerBenefit = newMembers.benefits.find((benefit) => benefit.benefitType === BenefitType.sticker);

  if (!priceBenbefit) newMembers.benefits.push({ ...defaultPriceBenefit });
  if (!combinationBenefit) newMembers.benefits.push({ ...defaultCombinationBenefit });
  if (!couponPriceBenefit) newMembers.benefits.push({ ...defaultCouponPriceBenefit });
  if (!stickerBenefit) newMembers.benefits.push({ ...defaultStickerBenefit });

  return newMembers;
};

function ControlPage({ members, membersHandler, selectBenefitTypeHanlder, selectedBenefitType }: ControlPageProps) {
  const benefitTypes: BenefitType[] = [BenefitType.price, BenefitType.combination, BenefitType.couponPrice];

  const printBenefitTypePage = () => {
    switch (selectedBenefitType) {
      case BenefitType.price: {
        const membersPriceHandler = (value: Target[]) => {
          const { benefits } = members;
          if (benefits) {
            if (benefits.findIndex((benefit: Benefit) => benefit.benefitType === BenefitType.price) === -1) {
              const updateMembers = {
                ...members,
                benefits: [...members.benefits, { benefitType: 'price', targets: value }],
              };
              membersHandler({ ...updateMembers });
            } else {
              const updateMembers = {
                ...members,
                benefits: members.benefits.map((benefit: Benefit) => {
                  if (benefit.benefitType === selectedBenefitType) {
                    return { ...benefit, targets: value };
                  } else {
                    return benefit;
                  }
                }),
              };
              membersHandler({ ...updateMembers });
            }
          } else {
            const updateMembers = { ...members, benefits: [{ benefitType: 'price', targets: value }] };
            membersHandler({ ...updateMembers });
          }
        };

        return (
          <div>
            <PriceController data={members} onChange={membersPriceHandler} controlType="MEMBERS" />
          </div>
        );
      }
      case BenefitType.couponPrice: {
        const couponPriceHandler = (
          e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
          recipeNames: string[],
        ) => {
          const nextState = produce(members, (draft: any) => {
            if (e) {
              const { name, value } = e.target;
              const couponPriceTarget = draft.benefits
                .find((benefit: CouponPriceBenefit) => benefit.benefitType === 'couponPrice')
                .targets.find((target: CouponPriceTarget) => target.id === 'membershipCoupon');
              couponPriceTarget[name] = Number(value);
            } else if (recipeNames) {
              const recipeCondition = draft.benefits
                .find((benefit: CouponPriceBenefit) => benefit.benefitType === 'couponPrice')
                .targets.find((target: CouponPriceTarget) => target.id === 'membershipCoupon')
                .conditions.find((condition: Condition) => condition.type === 'recipe');
              recipeCondition.recipeNames = recipeNames;
            } else {
              const recipeCondition = draft.benefits
                .find((benefit: CouponPriceBenefit) => benefit.benefitType === 'couponPrice')
                .targets.find((target: CouponPriceTarget) => target.id === 'membershipCoupon')
                .conditions.find((condition: Condition) => condition.type === 'recipe');
              recipeCondition.recipeNames = ['allDisable'];
            }
          });
          membersHandler(nextState);
        };

        // coupon 한 종류만 컨트롤하는 코드입니다.
        const couponPriceBenefit = members.benefits.find(
          (benefit) => benefit.benefitType === BenefitType.couponPrice,
        ) as CouponPriceBenefit;

        if (!couponPriceBenefit) return null;

        const target = couponPriceBenefit.targets.find((target: Target) => target.id === 'membershipCoupon');

        if (!target) return null;

        return (
          <div>
            <CouponPriceController data={target} onChange={couponPriceHandler} />
          </div>
        );
      }

      case BenefitType.combination:
        const combinationHandler = (targets: CombinationTarget[]) => {
          const nextState = produce(members, (draft: any) => {
            const combinationBenefit: CombinationBenefit | undefined = draft.benefits.find(
              (benefit: Benefit) => benefit.benefitType === BenefitType.combination,
            );

            if (combinationBenefit) {
              const combinationBenefitIdx: number = draft.benefits.findIndex(
                (benefit: Benefit) => benefit.benefitType === BenefitType.combination,
              );
              if (combinationBenefitIdx !== -1) {
                draft.benefits[combinationBenefitIdx].targets = targets;
              } else {
                const newCombinationBenefit: CombinationBenefit = { benefitType: BenefitType.combination, targets };
                draft.benefits.push(newCombinationBenefit);
              }
            } else {
              const newCombinationBenefit: CombinationBenefit = { benefitType: BenefitType.combination, targets };
              const vmRecipes = { id: 'vmRecipes', benefits: [newCombinationBenefit] };
              draft.vmRecipes = vmRecipes;
            }
          });
          membersHandler(nextState);
        };

        return (
          <div>
            <CombinationController data={members} onChange={combinationHandler} />
          </div>
        );
      default:
        return <div></div>;
    }
  };

  return (
    <div>
      <BenefitTypeSelect onChange={selectBenefitTypeHanlder} value={selectedBenefitType} benefitTypes={benefitTypes} />
      {printBenefitTypePage()}
    </div>
  );
}

function MembersControl() {
  const membersList: { [key: string]: Members } = useSelector(
    (state: RootStateType) => state.firestore.data['version/v3/members'],
  );
  const [selectedMembers, setSelectedMembers] = useState<Members>();
  const [selectedBenefitType, setSelectedBenefitType] = useState<BenefitType | ''>('');
  const dispatch = useDispatch();

  const selectBenefitTypeHanlder = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBenefitType(e.target.value as BenefitType);
  };

  const searchHandler = (e: React.ChangeEvent<any>, key: string) => {
    const selectedMembers = membersList[key];
    if (selectedMembers) {
      // 첫 멤버스 선택시 비어있는 베네핏을 채운다.
      setSelectedMembers(fillMembers(selectedMembers));
    } else {
      setSelectedMembers(undefined);
    }
  };

  const membersHandler = (members: Members) => {
    setSelectedMembers(members);
  };

  // 새로운 멤버스를 생성하는 함수입니다.
  const createMembers = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, membersId: string) => {
    if (membersId !== '' && membersId) {
      dispatch(
        controlForm({
          type: ControllerType.createMembers,
          members: { ...defaultMembers, id: membersId, name: membersId },
        }),
      );
    }
  };

  // benefit 을 적용하는 핸들러 함수 입니다.
  const applyBtn = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (selectedMembers !== undefined && selectedBenefitType !== '') {
      dispatch(controlForm({ type: ControllerType.members, selectedBenefitType, members: selectedMembers }));
    }
  };

  return (
    <form css={vm__recipe__control__wrap} onSubmit={(e) => applyBtn(e)}>
      <SearchBar data={membersList} onChange={searchHandler} onClick={createMembers} />
      {selectedMembers && (
        <>
          <ControlPage
            members={selectedMembers}
            membersHandler={membersHandler}
            selectedBenefitType={selectedBenefitType}
            selectBenefitTypeHanlder={selectBenefitTypeHanlder}
          />
          <div css={apply__btn}>
            <Button type="submit" variant="contained" color="secondary">
              적용
            </Button>
          </div>
        </>
      )}
    </form>
  );
}

const vm__recipe__control__wrap = css``;
const apply__btn = css`
  width: 100px;
  height: 50px;
  position: absolute;
  top: 110px;
  right: 50px;
`;

export default MembersControl;
