import { CustomPayloadAction } from '@definitions/redux';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface Option {
  value: string | number;
  label: string;
}

interface BottomSheetState {
  open: boolean;
  options: Option[];
  title?: string;
}

const initialState: BottomSheetState = {
  open: false,
  options: [],
  title: undefined,
};

const bottomSheetSlice = createSlice({
  name: 'bottomSheet',
  initialState,
  reducers: {
    openBottomSheet: (state, action: PayloadAction<{ options: Option[]; title?: string }>) => {
      state.open = true;
      state.options = action.payload.options;
      state.title = action.payload.title;
    },
    closeBottomSheet: (state) => {
      state.open = false;
      state.options = [];
      state.title = undefined;
    },
  },
});

export const { openBottomSheet, closeBottomSheet } = bottomSheetSlice.actions;
export default bottomSheetSlice.reducer;
