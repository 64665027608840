// 외부모듈
import React, { useCallback, useState } from 'react';
import { css } from '@emotion/core';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import Input from '@material-ui/core/Input';

// 내부모듈
import { InputType, UserControlData } from '@definitions/mock';
import { controlForm } from '@reducers/formController';

interface UserControllerProps {
  formData: UserControlData;
  phoneNum: string;
}

function UserController({ formData, phoneNum }: UserControllerProps) {
  const { inputs, buttonTitle, title, disabled = false } = formData;
  const [inputsData, setInputsData] = useState(inputs);
  const dispatch = useDispatch();

  const inputHandler = useCallback((e) => {
    const { name, value } = e.target;
    setInputsData(
      inputsData.map((input) => {
        if (input.name === name) {
          return { ...input, value: Number(value) };
        } else {
          return input;
        }
      }),
    );
  }, []);

  const buttonHandler = () => {
    if (phoneNum.length !== 11) {
      return alert('전화번호 형식을 확인해주세요');
    }

    dispatch(controlForm({ ...formData, inputs: inputsData, phoneNum }));
  };

  return (
    <div css={title_style}>
      <span>{`${title} : `}</span>
      <div css={{ display: 'flex', flexDirection: 'column' }}>
        {inputs.map((input, i) => (
          <Input
            key={i}
            disabled={disabled}
            defaultValue={input.value}
            placeholder={input.placeholder}
            onChange={inputHandler}
            name={input.name}
            type={input.inputType}
            style={{ height: '70px', margin: '15px', fontSize: '32px' }}
          ></Input>
        ))}
      </div>
      <Button
        variant="contained"
        disabled={disabled}
        onClick={buttonHandler}
        style={{ width: '100px', height: '80px', margin: '15px', fontSize: '21px' }}
        color="primary"
      >
        {buttonTitle}
      </Button>
    </div>
  );
}

const title_style = css`
  font-family: Spoqa Han Sans;
  font-size: 32px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  margin-top: 10px;

  display: flex;
  flex-direction: 'row';
`;

export default React.memo(UserController);
