import React from 'react';
import { css } from '@emotion/core';

function Profile(): JSX.Element {
  return <div css={profile__wrap}>프로필 화면(준비중)</div>;
}

const profile__wrap = css`
  display: flex;
`;
export default Profile;
