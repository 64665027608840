/* eslint-disable padding-line-between-statements */
import axios from 'axios';

interface Attachments {
  fallback?: string;
  color?: string;
  pretext?: string;
  author_name?: string;
  author_icon?: string;
  title?: string;
  title_link?: string;
  text?: string;
  image_url?: string;
  tumb_url?: string;
  footer?: string;
  footer_icon?: string;
  ts?: number;
  fields?: any;
}

class Slack {
  static get StatusColors() {
    return {
      success: '#28a745',
      warning: '#ffc107',
      danger: '#dc3545',
    };
  }

  // webhot url
  static get Channels() {
    return {
      general: 'https://hooks.slack.com/services/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',
      cs: 'https://hooks.slack.com/services/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',
      refund: 'https://hooks.slack.com/services/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',
    };
  }

  static async sendMessage(message: string | Attachments, footer: string, channel: string) {
    if (!message) {
      throw new Error('message를 입력해주세요 ');
      return;
    }
    const data: { mrkdwn: boolean; text: string; attachments: Attachments[] } = {
      mrkdwn: true,
      text: '',
      attachments: [],
    };

    const defaultAttachments: Attachments = {
      color: this.StatusColors.danger,
    };

    if (typeof message === 'string') {
      data.text = message;
    } else {
      if (!message.title && !message.text) {
        throw new Error('Attachments에는 반드시 title,text가 포함되어야 합니다.');
      }
      data.attachments.push({ ...defaultAttachments, ...message, footer: `From 알림 서버 ${footer}` });
    }

    try {
      axios
        .post(channel, data)
        .then()
        .catch((err) => console.error(err));
    } catch (error) {
      console.error(error);
    }
  }
}

export default Slack;
