import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, IconButton, Chip } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { Task } from './index';

interface TaskListProps {
  tasks: Task[];
  onEdit: (task: Task) => void;
  onRemove: (task: Task) => void;
}

function formatDate(date: Date) {
  return new Intl.DateTimeFormat('ko-KR', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }).format(date);
}

function TaskList({ tasks, onEdit, onRemove }: TaskListProps) {
  return (
    <List>
      {tasks.map((task, index) => (
        <ListItem key={task.id} divider>
          {/* 번호를 추가한 부분 */}
          <ListItemText
            primary={`#${index + 1}`}
            style={{ flex: '0 0 40px' }} // 고정 너비
          />
          <ListItemText
            primary={<>{task.name}</>}
            secondary={`작성자: ${task.author} | 마지막 수정일: ${formatDate(task.latestEditedDate)}`}
          />
          {task.tags && (
            <ListItemText
              primary={
                <>
                  {task.tags.split(',').map((tag, index) => (
                    <Chip style={{ marginLeft: '12px' }} key={index} label={tag} />
                  ))}
                </>
              }
            />
          )}
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="edit" onClick={() => onEdit(task)}>
              <EditIcon />
            </IconButton>
            <IconButton edge="end" color="secondary" aria-label="delete" onClick={() => onRemove(task)}>
              <DeleteIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      ))}
    </List>
  );
}

export default TaskList;
