import React, { useState, useEffect } from 'react';
import { css } from '@emotion/core';
import { Keg, VendingMachine } from '@definitions/vm';
import { Ingredient } from '@definitions/ingredient';
import { useFirestore } from 'react-redux-firebase';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/index';
import {
  Container,
  ContainerStatus,
  ContainerType,
  AllocatedContainer,
  ContainerHistoryType,
  ContainerHistoryVersion,
} from '@definitions/container';
import { getMountableRecipes } from 'app/utils/recipe';
import { RecipeType } from '@definitions/recipes';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { formatDateToKorean, getDateAfterNDays, getN } from 'app/utils/format';
import CIP from './Instructions/CIP';
import axios from 'axios';
import { getCleaningPipeNum, getMountPosition } from 'app/utils/pii';
import ExpirationDateModal from './modal/ExpirationDateModal';
import { createExpirationDate } from '@utils/createExpirationDate';

type KegItemEmptyParam = {
  keg: Keg;
  mount: (containerId: string) => void;
  containers: Container[];
  vmId: string;
  isCleaned: boolean;
  onExtract: (amount: number, isClean: boolean) => void;
};
type KegItemMountedParam = {
  keg: Keg;
  ingredients: { [ingredientId: string]: Ingredient };
  dismount: () => void;
  update: (amount: number, expirationDate: Date) => void;
};
type KegItemProps = {
  ip: string;
  vmId: string;
  keg: Keg;
  ingredients: { [ingredientId: string]: Ingredient };
  containers: Container[];
  isCleaned: boolean;
};

function KegItemEmpty({ vmId, keg, mount, containers, isCleaned, onExtract }: KegItemEmptyParam) {
  const firestore = useFirestore();
  const [containerId, setContainerId] = useState<string>('');
  const [cipComplete, setCipComplete] = useState(false);
  const { mountPosition } = keg;
  const filteredKegContainers = containers.filter(
    (container) => container.type === 'keg' && container.status === 'allocated' && container.allocation === vmId,
  ) as AllocatedContainer[];
  return (
    <div css={keg__item__empty} key={mountPosition}>
      <span></span>
      <div css={keg__item__body} style={{ borderRight: '1px solid #dddddd' }}>
        <h2>{mountPosition + 1}번 자리</h2>
        {!isCleaned && !cipComplete ? (
          <CIP
            onComplete={() => {
              firestore
                .collection(`/version/v3/vms/${vmId}/tasks`)
                .add({
                  type: 'cip',
                  timeStamp: new Date(),
                  mountPosition: keg.mountPosition,
                })
                .then(() => {
                  setCipComplete(true);
                });
            }}
            onExtract={onExtract}
          />
        ) : (
          <>
            <h3>장착할 컨테이너 ID:</h3>
            <FormControl style={{ width: '375px', height: '40px' }}>
              <Select
                defaultValue=""
                style={{ fontSize: '28px', width: '100%' }}
                onChange={(e: React.ChangeEvent<{ value: unknown }>) => setContainerId(e.target.value as string)}
              >
                {filteredKegContainers.map((keg, idx) => (
                  <MenuItem style={{ width: '100%', fontSize: '28px', marginTop: '5px' }} key={idx} value={keg.id}>
                    {keg.id}/{keg.ingredientId}/{keg.amount}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        )}
      </div>

      <button css={keg__item__empty__button} onClick={() => mount(containerId)}>
        장착
      </button>
    </div>
  );
}
function KegItemMounted({ keg, ingredients, dismount, update }: KegItemMountedParam) {
  const [amountToUpdate, setAmountToUpdate] = useState<number>(0);
  const { mountPosition, kegId, ingredientId, mountDate, amount } = keg;
  const [open, setOpen] = useState(false);
  const [expirationDate, setExpirationDate] = useState(createExpirationDate(getN(ingredientId)));

  const onChangeAmount = (amountToUpdate: string) => {
    const amnt = Number(amountToUpdate);

    if (isNaN(amnt)) {
      window.alert('재고량은 숫자로만 이뤄져야 합니다.');

      return;
    }

    if (amnt < 0) {
      window.alert('재고량은 음수 값이 될 수 없습니다.');

      return;
    }
    setAmountToUpdate(amnt);
  };

  const expirationDateHandler = (expirationDate: string) => setExpirationDate(new Date(expirationDate));
  const clickHandler = () => {
    setOpen(false);
    update(amountToUpdate, expirationDate);
  };

  return (
    <>
      <ExpirationDateModal
        open={open}
        onClose={() => setOpen(false)}
        onChange={expirationDateHandler}
        onClick={clickHandler}
        defaultDate={getDateAfterNDays(getN(ingredientId))}
      />
      <div css={keg__item__wrap} key={mountPosition}>
        <div css={keg__item}>
          <img src={ingredients[ingredientId].image} />
          <div>
            <h2>{mountPosition + 1}번 자리</h2>
            <h3>{kegId}</h3>
            <h3>{ingredientId}</h3>
            <h3>재고: {amount}ml</h3>
            <h3>유통기한: {keg.expirationDate ? formatDateToKorean(keg.expirationDate.toDate()) : 'x'}</h3>
            <h3>장착 날짜: {formatDateToKorean(mountDate.toDate())}</h3>
          </div>
          <button onClick={dismount}> 탈착 </button>
        </div>
        <div css={keg__item__stock__update}>
          <label> </label>
          <div>
            <h2>변경할 재고량(ml)</h2>
            <input
              type="text"
              name="amount"
              value={amountToUpdate}
              onChange={(e) => onChangeAmount(e.target.value)}
              maxLength={8}
            />
          </div>
          <button onClick={() => setOpen(true)}> 변경 </button>
        </div>
      </div>
    </>
  );
}

function KegItem({ vmId, keg, ingredients, containers, isCleaned, ip }: KegItemProps): JSX.Element {
  const firestore = useFirestore();

  const vms: VendingMachine[] = useSelector(({ firestore: { ordered } }: RootStateType) => ordered['version/v3/vms']);
  const recipes: RecipeType[] = useSelector(
    ({ firestore: { ordered } }: RootStateType) => ordered['version/v3/recipes'],
  );

  const admins = useSelector(({ firestore: { data } }: RootStateType) => data['version/v3/admins']);
  const { uid } = useSelector(({ firebase }: RootStateType) => firebase.auth);

  const onExtract = (mp: number) => {
    const mountPosition = getMountPosition(mp);
    const found = vms.find((vm) => vm.id === vmId);
    const pipes = found ? found.cleaning.pipes : [];

    return async function (amount: number, isClean: boolean) {
      const user = admins[uid] || { phoneNum: 'x', name: 'x' };
      const { key } = user;

      if (isClean) {
        const addr = `http://planz-proxy.com:3000/clean`;
        const params = {
          target: ip,
          key,
          mps: [getCleaningPipeNum(mp, pipes)],
          pumpSpeed: 2,
          amount,
          histories: [],
        };
        const res = await axios.get(addr, { params });
      } else {
        const addr = `http://planz-proxy.com:3000/dispense`;
        const params = { target: ip, key, mps: [mountPosition], pumpSpeed: 2, amount, histories: [] };
        const res = await axios.get(addr, { params });
      }
    };
  };

  const fsRef = firestore.collection('version/v3/vms/').doc(vmId);
  const mount = (containerId: string) => {
    const batch = firestore.batch();
    const container = containers.filter((container) => container.id === containerId)[0];
    if (container === undefined) {
      window.alert('존재하지 않는 컨테이너 아이디를 입력하셨습니다.');

      return;
    }

    if (container.type === ContainerType.bottle) {
      window.alert('시럽통은 커플러에 꽂을 수 없습니다.');

      return;
    }

    if (container.status !== ContainerStatus.allocated) {
      window.alert('할당된 컨테이너만 장착할 수 있습니다.');

      return;
    }

    const fsContainerRef = firestore.collection('version/v3/containers').doc(container.id);
    const { mountPosition: selectedMountPosition } = keg;

    const mountDate = new Date();
    const mountVm = vms.filter((vm) => vm.id === vmId)[0];

    batch.update(fsRef, {
      kegs: [
        ...mountVm.kegs.map((keg: Keg) => {
          if (selectedMountPosition === keg.mountPosition) {
            return {
              kegId: container.id,
              status: ContainerStatus.mounted,
              ingredientId: container.ingredientId,
              fillDate: container.fillDate,
              allocationDate: container.allocationDate,
              mountDate,
              mountPosition: selectedMountPosition,
              amount: container.amount,
              expirationDate: container.expirationDate,
            };
          }

          return keg;
        }),
      ],
    });
    batch.update(fsContainerRef, {
      mount: vmId,
      mountDate,
      status: ContainerStatus.mounted,
    });
    batch.set(fsContainerRef.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.mount,
      vmId,
      ingredientId: container.ingredientId,
      amount: container.amount,
      mountPosition: selectedMountPosition,
      timeStamp: new Date(),
      version: ContainerHistoryVersion,
    });
    batch.commit().then(() => {
      firestore.get(`version/v3/vms/${vmId}`).then((doc) => {
        if (doc.exists) {
          const mountVm = doc.data() as VendingMachine;
          const fsRef = firestore.collection('version/v3/vms/').doc(vmId);
          fsRef.update({
            recipes: [
              ...getMountableRecipes(recipes, [], mountVm.kegs, mountVm.pumps, mountVm.prices, mountVm.location, vmId),
            ],
          });
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      });
    });
  };
  const dismount = () => {
    const answer = window.confirm('정말 탈착하시겠습니까?');
    if (!answer) {
      return;
    }
    const batch = firestore.batch();
    const container = containers.filter((container) => container.id === keg.kegId)[0];
    const fsContainerRef = firestore.collection('version/v3/containers').doc(container.id);

    const { mountPosition: selectedMountPosition } = keg;
    const mountVm = vms.filter((vm) => vm.id === vmId)[0];
    batch.update(fsRef, {
      kegs: [
        ...mountVm.kegs.map((keg: Keg) => {
          if (selectedMountPosition === keg.mountPosition) {
            return { mountPosition: selectedMountPosition };
          }

          return keg;
        }),
      ],
    });
    batch.update(fsContainerRef, {
      mount: '',
      mountDate: null,
      status: ContainerStatus.allocated,
    });
    batch.set(fsContainerRef.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.unmount,
      mountPosition: selectedMountPosition,
      timeStamp: new Date(),
      version: ContainerHistoryVersion,
    });
    batch.commit().then(() => {
      firestore.get(`version/v3/vms/${vmId}`).then((doc) => {
        if (doc.exists) {
          const mountVm = doc.data() as VendingMachine;
          const fsRef = firestore.collection('version/v3/vms/').doc(vmId);
          fsRef.update({
            recipes: [
              ...getMountableRecipes(recipes, [], mountVm.kegs, mountVm.pumps, mountVm.prices, mountVm.location, vmId),
            ],
          });
        } else {
          // doc.data() will be undefined in this case
          console.log('No such document!');
        }
      });
    });
  };

  const update = (amount: number, expirationDate: Date) => {
    const batch = firestore.batch();
    const container = containers.filter((container) => container.id === keg.kegId)[0];
    const fsContainerRef = firestore.collection('version/v3/containers').doc(container.id);
    const { mountPosition: selectedMountPosition } = keg;
    const mountVm = vms.filter((vm) => vm.id === vmId)[0];
    console.log(container);
    if (container.status !== ContainerStatus.mounted) {
      window.alert('장착된 컨테이너만 용량을 조절할 수 있습니다.');

      return;
    }

    if (!(expirationDate instanceof Date)) {
      window.alert('expirationDate 데이터 형식이 잘못되었습니다.');
      return;
    } else if (new Date() >= expirationDate) {
      window.alert('expirationDate은 현재시간보다 작을수 없습니다.');
      return;
    }

    batch.update(fsRef, {
      kegs: [
        ...mountVm.kegs.map((keg: Keg) => {
          if (selectedMountPosition === keg.mountPosition) {
            return { ...keg, amount, expirationDate };
          }

          return keg;
        }),
      ],
    });

    batch.update(fsContainerRef, {
      amount,
      expirationDate,
    });
    batch.set(fsContainerRef.collection('history').doc(), {
      auth: admins[uid],
      type: ContainerHistoryType.amountUpdateInVm,
      vmId,
      ingredientId: container.ingredientId,
      amountBefore: container.amount,
      amountAfter: amount,
      expirationDate,
      timeStamp: new Date(),
      version: ContainerHistoryVersion,
    });
    batch.commit();
  };

  if (keg.ingredientId === undefined || keg.ingredientId === '') {
    return (
      <KegItemEmpty
        keg={keg}
        mount={mount}
        containers={containers}
        vmId={vmId}
        isCleaned={isCleaned}
        onExtract={onExtract(keg.mountPosition)}
      />
    );
  }

  return <KegItemMounted keg={keg} ingredients={ingredients} dismount={dismount} update={update} />;
}
const keg__item__wrap = css`
  width: 100%;
  height: 420px;
  display: flex;
  flex-direction: column;
`;
const keg__item = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 280px;
  border-bottom: 1px solid #dddddd;
  img {
    width: 120px;
    height: 160px;
    margin-right: 24px;
  }
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 400px;
    height: 100%;
    border-right: 1px solid #dddddd;
    padding-right: 24px;
    h2 {
      font-weight: bold;
      font-size: 48px;
      line-height: 1.1;
    }
    h3 {
      font-weight: normal;
      font-size: 28px;
      line-height: 1.2;
    }
  }
  button {
    width: 120px;
    height: 84px;
    border: none;
    border-radius: 10px;
    background-color: #b67d56;
    color: white;
    font-size: 28px;
  }
`;

const keg__item__stock__update = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 140px;
  border-bottom: 2px solid #cccccc;
  label {
    width: 120px;
    margin-right: 24px;
  }
  div {
    width: 400px;
    height: 100%;
    padding-right: 24px;
    border-right: 1px solid #dddddd;
    display: flex;
    flex-direction: column;
    justify-content: center;
    h2 {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 12px;
      line-height: 1.1;
    }
    input {
      height: 40px;
      font-size: 28px;
      padding-left: 12px;
      border: none;
      border-radius: 4px;
      box-shadow: 0 5px 6px 0 rgba(141, 85, 37, 0.3);
    }
  }
  button {
    width: 120px;
    height: 84px;
    border: none;
    border-radius: 10px;
    background-color: #b67d56;
    color: white;
    font-size: 28px;
  }
`;

const keg__item__empty__button = css`
  width: 120px;
  height: 84px;
  border: none;
  border-radius: 10px;
  background-color: #b67d56;
  color: white;
  font-size: 28px;
`;

const keg__item__body = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  width: 400px;
  padding-right: 24px;
  h2 {
    font-weight: bold;
    font-size: 48px;
    line-height: 1.1;
  }
  h3 {
    font-size: 32px;
    letter-spacing: -0.3px;
    margin-bottom: 24px;
  }
  input {
    height: 40px;
    font-size: 28px;
    padding-left: 12px;
    border: none;
    border-radius: 4px;
    box-shadow: 0 5px 6px 0 rgba(141, 85, 37, 0.3);
  }
`;

const keg__item__empty = css`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  height: 280px;
  border-bottom: 1px solid #dddddd;
  span {
    width: 120px;
    height: 160px;
    margin-right: 24px;
  }
`;
export default KegItem;
