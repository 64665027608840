//외부모듈
import React, { useEffect, useState } from 'react';

import { Box, Button, Tab, Tabs, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { Delete, CheckBox, CheckBoxOutlined, CheckBoxOutlineBlankOutlined, Send } from '@material-ui/icons';
import { Autocomplete, TabPanel } from '@material-ui/lab';
import { css } from '@emotion/core';
import { useSelector } from 'react-redux';
import { RootStateType } from '@reducers/';
import { PriceType, RecipeType } from '@definitions/recipes';
import { VendingMachine } from '@definitions/vm';

// 외부모듈
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import ProdPriceInputs, { ProdPriceType } from './ProdPriceInputs';
import { ProdPriceInfoType, setOptionPrice } from './ProdPriceRaw';

export interface AddProdPriceProps {
  vmRecipes: RecipeType[];
  editedData: ProdPriceInfoType[];
  prodPriceAddHandler: (prodPriceInfo: ProdPriceInfoType, idx: number) => void;
}

const INIT_PRICE = {
  basic: { iced: 0, hot: 0 },
  concnCharge: { type: '' },
  sizeCharge: { type: '' },
};
function AddProdPrice({ vmRecipes, editedData, prodPriceAddHandler }: AddProdPriceProps) {
  const [recipeIdList, setRecipeIdList] = useState<string[]>([]);
  const [addRecipeId, setAddRecipeId] = useState('');
  const [addRecipePrice, setAddRecipePrice] = useState<ProdPriceType>(INIT_PRICE);
  const [alertOpen, setAlertOpen] = useState(false);

  useEffect(() => {
    const recipeCandidates = vmRecipes
      .map((recipe: RecipeType) => {
        const { recipeId } = recipe;
        return recipeId;
      })
      .filter((candidate) => !editedData.map(({ recipeId }) => recipeId).includes(candidate));

    const set = new Set(recipeCandidates);
    const uniqueCandidates = [...set];
    setRecipeIdList(uniqueCandidates);
  }, [vmRecipes, editedData]);

  const onChangeRecipeHandler = (e: React.ChangeEvent<any>, value: string) => {
    setAddRecipeId(value);
  };
  const addOnChangeHandler = (e: React.ChangeEvent<any>) => {
    const pathList = e.target.name.split('/');
    const value = Number(e.target.value);

    setAddRecipePrice((prevState: ProdPriceType) => {
      return {
        ...prevState,
        [pathList[0]]: { ...prevState[pathList[0]], [pathList[1]]: value },
      };
    });
    // initHandler();
  };
  const addSelectHandler = (e: React.ChangeEvent<any>) => {
    const path = e.target.name;
    const value = e.target.value;

    setAddRecipePrice((prevState: ProdPriceType) => {
      const { sizeCharge, concnCharge } = prevState;
      const chargePrice = path === 'sizeCharge' ? sizeCharge : concnCharge;

      return {
        ...prevState,
        [path]: setOptionPrice(value, chargePrice),
      };
    });
    // setRecipeOrder(e.target.value);
    // initHandler();
  };
  useEffect(() => {
    if (addRecipeId !== '') {
      const { price } = vmRecipes.find((recipe) => recipe.recipeId === addRecipeId);
      setAddRecipePrice(price);
    }
    if (addRecipeId === '') {
      setAddRecipePrice(INIT_PRICE);
    }
  }, [addRecipeId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setAlertOpen(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, [alertOpen]);

  // useEffect(() => {
  //   const lastOrderNum = Math.max(...editedData.map((data) => data.recipeOrder));
  //   if (recipeName !== '') {
  //     setRecipeOrder(lastOrderNum + 1);
  //   }
  // }, [recipeName]);

  const addButtonHandler = () => {
    if (addRecipeId === '' || addRecipeId === undefined) {
      setAlertOpen(true);
    } else {
      prodPriceAddHandler(
        {
          recipeId: addRecipeId,
          price: addRecipePrice,
          checked: false,
          beDeleted: false,
        },
        vmRecipes.length,
      );
      setAddRecipeId('');
      setAddRecipePrice(INIT_PRICE);
    }
  };

  return (
    <>
      {alertOpen && (
        <Alert onClose={() => setAlertOpen(false)} severity="warning">
          레시피 이름 또는 순서가 비었습니다!
        </Alert>
      )}
      <section css={add_prod__price__wrap}>
        <div css={select__recipe}>
          <Autocomplete
            options={recipeIdList}
            style={{ width: '300px', marginBottom: '10px' }}
            noOptionsText="해당 값이 존재하지 않습니다."
            onInputChange={(e, value) => onChangeRecipeHandler(e, value)}
            renderInput={(params) => <TextField {...params} label="추가 할 레시피 이름을 선택하세요." />}
            value={addRecipeId}
          />
          <Button
            variant="contained"
            color="inherit"
            style={{ marginLeft: '15px', height: '40px' }}
            onClick={addButtonHandler}
          >
            추가
          </Button>
        </div>
        <ProdPriceInputs
          price={addRecipePrice}
          type={'add'}
          addOnChangeHandler={addOnChangeHandler}
          addSelectHandler={addSelectHandler}
        />
      </section>
    </>
  );
}

export default AddProdPrice;

const add_prod__price__wrap = css`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 130px;
  justify-content: center;
  align-items: start;
  margin-bottom: 10px;
`;
const select__recipe = css`
  display: flex;
  flex-direction: low;
  justify-content: space-between;
  width: 400px;
`;
