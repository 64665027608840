// 외부모듈
import React from 'react';
import { css } from '@emotion/core';
import { Avatar, Card, Flex, Text } from '@fluentui/react-northstar';

// 내부모듈
import { MountedContainer } from '@definitions/container';
import { getLocationByVm } from '@utils/getLocationByVm';
import { VendingMachine } from '@definitions/vm';
import { getColorForExpirationDate, getColorForAmount, getStatusColorForAmount } from '@utils/getStatusColor';

type VmAmountCardProps = {
  item: VendingMachine;
  ingredients: any;
};

function VMamountCard({ item, ingredients }: VmAmountCardProps) {
  const {
    kegs,
    pumps,
    id,
    location: { name },
  } = item;
  const validKegs = kegs.filter((keg) => keg.kegId !== undefined);
  const validPumps = pumps.filter((pump) => pump.bottleId !== undefined);

  return (
    <div css={ingredient__wrap}>
      <Card size="medium">
        <Text content={id} size="large" weight="semibold" />
        <Text styles={{ marginBottom: '10px' }} temporary content={name} size="medium" />
        <Text styles={{ marginBottom: '10px' }} content={'KEGS'} size="medium" weight="bold" />
        {validKegs.map((keg, idx) => (
          <Card.Header style={{ marginBottom: '15px' }} key={idx} fitted>
            <Flex gap="gap.small">
              <Avatar
                image={ingredients[keg.ingredientId].image}
                label="ingredients"
                name={keg.ingredientId}
                status={{
                  color: getStatusColorForAmount(keg),
                }}
              />
              <Flex column>
                <Text content={keg.ingredientId} size="medium" weight="semibold" />
                <Text color={getColorForAmount(keg)} content={`재고 : ${keg.amount}ml`} />
                <Text
                  content={`유통기한 :${
                    keg.expirationDate === undefined ? 'N/A' : keg.expirationDate.toDate().toDateString()
                  } `}
                  size="small"
                  color={getColorForExpirationDate(keg)}
                />
              </Flex>
            </Flex>
          </Card.Header>
        ))}
        <Text styles={{ marginBottom: '10px' }} content={'PUMPS'} size="medium" weight="bold" />
        {validPumps.map((pump, idx) => (
          <Card.Header style={{ marginBottom: '15px' }} key={idx} fitted>
            <Flex gap="gap.small">
              <Avatar
                image={ingredients[pump.ingredientId].image}
                label="ingredients"
                name={pump.ingredientId}
                status={{
                  color: getStatusColorForAmount(pump),
                }}
              />
              <Flex column>
                <Text content={pump.ingredientId} size="medium" weight="semibold" />
                <Text color={getColorForAmount(pump)} content={`재고 : ${pump.amount}ml`} />
                <Text
                  content={`유통기한 :${
                    pump.expirationDate === undefined ? 'N/A' : pump.expirationDate.toDate().toDateString()
                  } `}
                  size="small"
                  color={getColorForExpirationDate(pump)}
                />
              </Flex>
            </Flex>
          </Card.Header>
        ))}
      </Card>
    </div>
  );
}

const ingredient__wrap = css`
  display: flex;
`;

export default VMamountCard;
