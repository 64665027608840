import { css } from '@emotion/core';
import { Button, Dialog, makeStyles } from '@material-ui/core';
import React, { useState } from 'react';
import ResultPhoto, { PhotoResult } from './ResultPhoto';

const useStyles = makeStyles({
  backdrop: {
    backgroundColor: 'rgba(0, 0, 0, 0.9)', // 검은색 투명도 80%
  },
  dailogPaper: {
    width: '85%',
    maxWidth: 'none', // maxWidth를 제거하여 width 85% 적용을 확실하게 함
  },
  imageContainer: {
    width: '300px',
    height: '300px',
    border: '1px solid gray',
    position: 'relative',
    display: 'inline-block',
    margin: '10px',
  },
  input: {
    display: 'none',
  },
});

interface CleaningProps {
  upload: (results: PhotoResult[]) => void;
}

function Cleaning({ upload }: CleaningProps): JSX.Element {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const onSubmit = async (results: PhotoResult[], length: number) => {
    if (results.length < length) {
      window.alert(`모든 결과물들을 촬영해주세요: (필요 장 수: ${length}, 결과 장 수: ${results.length})`);
      return;
    }
    await upload(results);
  };

  return (
    <div>
      <h1>기기 위생 상태 점검</h1>
      <Button variant="contained" onClick={() => setOpen(true)} color="primary" css={fill__button}>
        촬영 시작
      </Button>
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        classes={{ paper: classes.dailogPaper }}
        BackdropProps={{
          classes: {
            root: classes.backdrop,
          },
        }}
      >
        <ResultPhoto
          title="점검 리스트"
          subTitle="주요 스팟들 모두 점검 기록해주세요."
          examples={[
            {
              name: '냉장고 내부',
              url:
                'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/tasks%2Fcleaning%2F%E1%84%82%E1%85%A2%E1%86%BC%E1%84%8C%E1%85%A1%E1%86%BC%E1%84%80%E1%85%A9%20%E1%84%82%E1%85%A2%E1%84%87%E1%85%AE.jpg?alt=media&token=7f99c355-6d41-4c40-853c-76252f01ce49&_gl=1*7o9sgt*_ga*OTUyMjUxOTQ2LjE2OTU2MTY5Nzg.*_ga_CW55HF8NVT*MTY5NzA3NTQzOS4yNi4xLjE2OTcwNzY3ODAuMzEuMC4w',
            },
            {
              name: '컵 하우징',
              url:
                'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/tasks%2Fcleaning%2F%E1%84%90%E1%85%A9%E1%84%8E%E1%85%AE%E1%86%AF%E1%84%87%E1%85%AE.jpg?alt=media&token=1f50dcea-3977-4f45-8513-881eec0519c3&_gl=1*1kz0l8w*_ga*OTUyMjUxOTQ2LjE2OTU2MTY5Nzg.*_ga_CW55HF8NVT*MTY5NzA3NTQzOS4yNi4xLjE2OTcwNzY4MjYuNDcuMC4w',
            },
            {
              name: '디스펜서 가이드',
              url:
                'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/tasks%2Fcleaning%2Fy%E1%84%80%E1%85%AA%E1%86%AB.jpg?alt=media&token=034d0833-f439-4c5a-b113-1f1f570a3ea5&_gl=1*dhdt12*_ga*OTUyMjUxOTQ2LjE2OTU2MTY5Nzg.*_ga_CW55HF8NVT*MTY5NzA3NTQzOS4yNi4xLjE2OTcwNzU3NjkuNjAuMC4w',
            },
            {
              name: '컵 디스펜서 걸쇠',
              url:
                'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/tasks%2Fcleaning%2F%E1%84%83%E1%85%B5%E1%84%89%E1%85%B3%E1%84%91%E1%85%A2%E1%86%AB%E1%84%89%E1%85%A5%E1%84%80%E1%85%A5%E1%86%AF%E1%84%89%E1%85%AC.jpg?alt=media&token=6caee73f-e7ca-401d-a52f-d851dbb3da29&_gl=1*866v9s*_ga*OTUyMjUxOTQ2LjE2OTU2MTY5Nzg.*_ga_CW55HF8NVT*MTY5NzA3NTQzOS4yNi4xLjE2OTcwNzY3OTYuMTUuMC4w',
            },
            {
              name: '시럽 거치대',
              url:
                'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/tasks%2Fcleaning%2F%E1%84%89%E1%85%B5%E1%84%85%E1%85%A5%E1%86%B8%E1%84%89%E1%85%A5%E1%86%AB%E1%84%87%E1%85%A1%E1%86%AB.jpg?alt=media&token=e1bf1a09-4ece-44f6-9d47-76455a4bb673&_gl=1*lcb46c*_ga*OTUyMjUxOTQ2LjE2OTU2MTY5Nzg.*_ga_CW55HF8NVT*MTY5NzA3NTQzOS4yNi4xLjE2OTcwNzY4MTMuNjAuMC4w',
            },
            {
              name: '기기 외관',
              url:
                'https://firebasestorage.googleapis.com/v0/b/planz-6761f.appspot.com/o/tasks%2Fcleaning%2F%E1%84%80%E1%85%B5%E1%84%80%E1%85%B5%E1%84%8B%E1%85%AC%E1%84%80%E1%85%AA%E1%86%AB.png?alt=media&token=63b22890-ec72-4563-b75b-0c6d7758ed35&_gl=1*1cxcys6*_ga*OTUyMjUxOTQ2LjE2OTU2MTY5Nzg.*_ga_CW55HF8NVT*MTY5NzA3NTQzOS4yNi4xLjE2OTcwNzY3NjQuNDcuMC4w',
            },
          ]}
          close={() => setOpen(false)}
          onSubmit={async (reslts: PhotoResult[], length: number) => {
            await onSubmit(reslts, length);
            setOpen(false);
          }}
        />
      </Dialog>
    </div>
  );
}

const admin__status__btn = css`
  width: 160px;
  max-height: 84px;
  border-radius: 15px;
  .MuiButton-label {
    font-size: 32px;
  }
`;

const cip__wrap = css`
  width: 100%;
  margin-top: 15px;
  font-size: 38px;
  padding-bottom: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    font-weight: bold;
  }
`;

const fill__button = css`
  width: calc(100% - 24px);
  height: 120px;
`;

export default Cleaning;
