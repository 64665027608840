// 외부모듈
import { createAction } from '@reduxjs/toolkit';

// 내부모듈
import { VmRecipesControlData, UserControlData, MembersControlData, CreateMembersData } from '@definitions/mock';
import { CustomPayloadAction } from '@definitions/redux';

export const controlForm = createAction(
  '$CONTROL_FORM',
  (data: UserControlData | VmRecipesControlData | MembersControlData | CreateMembersData) => ({
    payload: data,
  }),
);

export type ControllerActions = CustomPayloadAction<
  '$CONTROL_FORM',
  UserControlData | VmRecipesControlData | MembersControlData | CreateMembersData
>;
