/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable curly */
// 외부모듈
import React from 'react';
import { CorpGiftWallet } from '@definitions/wallet';
import { Chip } from '@material-ui/core';
import { GridCellParams, GridColDef, GridEditCellValueParams } from '@material-ui/data-grid';
import { css } from '@emotion/core';
import CloseIcon from '@material-ui/icons/Close';
import { firestore } from 'app/common/firebase';

const tagColorTable = {
  0: { styles: { color: '#fff', backgroundColor: '#686de0' }, owner: '' },
  1: { styles: { color: '#fff', backgroundColor: '#f0932b' }, owner: '' },
  2: { styles: { color: '#fff', backgroundColor: '#eb4d4b' }, owner: '' },
  3: { styles: { color: '#fff', backgroundColor: '#6ab04c' }, owner: '' },
  4: { styles: { color: '#fff', backgroundColor: '#7ed6df' }, owner: '' },
};

const tagColorMap = new Map();

function handleRemoveTag(params: GridCellParams, id: string, tag: string, e: any) {
  if (params.value) {
    const newValue = params.value
      .toString()
      .replace(`${id}=${tag}`, '')
      .replace(/(^&|&$)/g, '')
      .replace(/&&/g, '&');

    if (e.target.parentElement.classList.contains('tagRemoveBtn')) {
      e.target.parentElement.click();
    } else {
      console.log(e.target.parentElement.parentElement);
      e.target.parentElement.parentElement.click();
    }
    firestore.doc(`version/v3/wallets/${params.id}`).update({ tags: newValue });
  }
}

export const createColumns = (): GridColDef[] => [
  { field: 'id', headerName: 'PID', width: 120, disableExport: true, hide: true },
  { field: 'cid', headerName: 'ID', width: 120, headerAlign: 'center', align: 'right' },
  {
    field: 'phoneNumber',
    headerName: '전화번호',
    width: 180,
    headerAlign: 'center',
    align: 'right',
    valueFormatter(params) {
      if (params.value) {
        return params.value.toString().replace(/^(\d{2,3})(\d{3,4})(\d{4})$/, `$1-$2-$3`);
      }
    },
  },
  {
    field: 'point',
    headerName: '포인트',
    type: 'number',
    width: 150,
    headerAlign: 'center',
    align: 'right',
  },
  {
    field: 'tags',
    headerName: '태그(최대 5개)',
    width: 600,
    headerAlign: 'center',
    align: 'left',
    editable: true,
    renderCell(params) {
      if (params.value && params.value.toString() !== '') {
        const tagArray = params.value
          .toString()
          .trim()
          .split('&')
          .map((str) => str.split('='));
        return tagArray.map(([id, tag], i) => {
          if (i >= 5) return;
          // @ts-ignore
          if (!tagColorMap.get(id)) tagColorMap.set(id, tagColorTable[i]);
          return (
            <Chip
              className="tagRemoveBtn"
              key={id}
              // onDelete={(e) => handleRemoveTag.call(this, params, id, tag, e)}
              // deleteIcon={<CloseIcon fontSize="small" />}
              label={tag}
              style={{ fontWeight: 'bold', margin: '0px 5px', ...tagColorMap.get(id).styles }}
            />
          );
        });
      } else {
        return null;
      }
    },
  },
];

// 기존에 docId를 walletId로 사용하고 있고 grid data 는 column에 id를 기반으로 움직이기 때문에 스위칭했습니다.
export const createRows = (wallets: CorpGiftWallet[]) =>
  wallets.map((wallet) => ({
    id: wallet.walletId,
    cid: wallet.id,
    phoneNumber: wallet.receiver,
    point: wallet.point,
    tags: wallet.tags,
  }));
